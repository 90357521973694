import CheckBox from "components/shared/Chekbox";
import { useGlobalState } from "context/global";
import Locale from "translations";

export default function PermissionGroup({
	permission,
	handelCheck,
	selectedPermissions,
	handelCheckAll,
}) {
	const { backOffice, teamManagement } = Locale;
	const { locale } = useGlobalState();

	return (
		<div className="permission-card col-4 col-md-4 p-0 mt-3 mx-2">
			<p className="h6 bg-teamMange_filter dark-blue-color  rounded mb-1  font-weight-bold py-2  w-100  px-2">
				<CheckBox
					type="Checkbox"
					checked={selectedPermissions.includes(permission.id)}
					text={permission.name}
					onChange={(e) => {
						handelCheck(permission.id, e.target.checked);
					}}
				/>
			</p>
		</div>
	);
}
