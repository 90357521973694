import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalDispatch, useGlobalState } from "context/global";
import { usePropertyDispatch } from "context/property";
import "date-fns";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link, useHistory, useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import { cleanEmpty } from "services/general";
import { addHotel, editHotel, viewHotel } from "services/hotels";
import { fetchExternalHotels, fetchSeason, fetchSupplier } from "services/lookups";
import { listPlatforms } from "services/platform";
import Locale from "translations";
import Commission from '../AddEditCommission/Commission';
import HotelSpecialOffer from './SpecialOffer';



const AddEditHotelContent = ({ state, isEditMode }) => {
	const { backOffice } = Locale;
	let locale = localStorage.getItem("currentLocale") || "en";
	const { allCountries } = useGlobalState();
	const dispatch = useGlobalDispatch();
	const propertyDispatch = usePropertyDispatch();
	const history = useHistory();
	const [canceled, setCanceled] = useState(false);
	const [CommissionsList, setCommissionsList] = useState([]);
	const [SpecialOfferList, setSpecialOfferList] = useState([]);

	const [selectorsSelected, setSelectorsSelected] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [selectedCity, setSelectedCity] = useState(null);
	const [selectedArea, setSelectedArea] = useState(null);
	const [selectedHotelId, setSelectedHotelId] = useState(null);


	const [hotelMainDetails, setHotelMainDetails] = useState({
		name: "",
		website: "",
		country: { name: "", name_en: "", name_ar: "" },
		city: { name: "", name_en: "", name_ar: "" },
		area: { name: "", name_en: "", name_ar: "" },
		contract_start_date: undefined,
		contract_end_date: undefined,
		reservation_officer_name: "",
		mobile: "",
		email: "",
		spin: false,
		commissions: CommissionsList,
		specialOffers: SpecialOfferList,
		hotel_id: null,
	});

	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [hotels, setHotels] = useState([]);
	const [countries, setCountries] = useState([]);
	// const [cities, setCities] = useState([]);
	// const [areas, setAreas] = useState([]);
	const [hotelEdit, setHotelEdit] = useState(null);

	const [season, setSeason] = useState([]);
	const [hotelIndex, setHotelIndex] = useState(null);
	const [platform, setPlatform] = useState([]);
	const [supplier, setSupplier] = useState([]);

	const [searchHotel, setSearchHotel] = useState();

	const { id } = useParams();

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "name", value: hotelMainDetails.name },
				{ required: true }
			),
			// ...validate({ name: "country", value: hotelMainDetails.country }, { required: true }),
			// ...validate({ name: "city", value: hotelMainDetails.city }, { required: true }),
			// ...validate({ name: "area", value: hotelMainDetails.area }, { required: true }),
			...validate(
				{ name: "contract_date", value: hotelMainDetails.contract_start_date },
				{ required: true }
			),
			...validate(
				{
					name: "contract_end_date",
					value: hotelMainDetails.contract_end_date,
				},
				{ required: true }
			),
			...validate(
				{
					name: "reservation_officer_name",
					value: hotelMainDetails.reservation_officer_name,
				},
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "mobile", value: hotelMainDetails.mobile },
				{ required: true, number: true }
			),
			...validate(
				{ name: "email", value: hotelMainDetails.email },
				{ required: true, email: true }
			),
		});
	};

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setHotelMainDetails({ ...hotelMainDetails, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			if (name === "country") {
				// setCities([]);
				setHotelMainDetails({
					...hotelMainDetails,
					city: "",
					area: "",
					mobile: "",
					[name]: value,
				});
			} else setHotelMainDetails({ ...hotelMainDetails, [name]: value });
		}
	};

	// Fetch Cities
	useEffect(() => {
		async function fetchLookups() {
			const data = {
				search: searchHotel,
			};

			const res = await fetchExternalHotels(cleanEmpty(data));
			const format = res.data.map((t) => ({
				value: t.id,
				label: t.facility_name,
				hotelCountry: t.country,
				hotelCountryId: t.country_id,
				hotelCity: t.city,
				hotelCity_id: t.city_id,
				hotelArea: t.area,
				hotelArea_id: t.area_id,
			}));
			setHotels(format);

			if (id) {
				const resplatform = await viewHotel(id);
				const htlsIndx = res.data.findIndex(
					(x) => x.id == resplatform.data.hotel.hotel_id
				);
				setHotelIndex(htlsIndx);
			}
		}
		fetchLookups();
	}, [searchHotel]);

	// Fetch Countries
	useEffect(() => {
		async function fetchLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);

			const resSeason = await fetchSeason();
			const format_season = resSeason.seasons.map((t) => ({
				value: t.id,
				label: t.name,
			}));
			setSeason(format_season);

			const resplatform = await listPlatforms(null, 1);
			const formatplateform = resplatform.data.platforms.map((t) => ({
				value: t.id,
				label: t.name,
			}));
			setPlatform(formatplateform);
			const supplierRes = await fetchSupplier();
			const formatSupplier = supplierRes.data?.data?.map((t) => ({
				value: t.id,
				label: t.full_name,
			}));
			setSupplier(formatSupplier);
			if (id) {
				const resplatform = await viewHotel(id);
				setHotelEdit({ ...resplatform?.data.hotel, name: { label: resplatform?.data.hotel.name } })
				setCommissionsList(resplatform.data.hotel.commissions);

				setSpecialOfferList(
					resplatform.data.hotel.offers.map((res) => {
						return {
							form: res.from_date,
							to: res.to_date,
							offer: res.value,
							id: res.id,
						};
					})
				);
				setHotelMainDetails({
					...hotelMainDetails,
					name: resplatform.data.hotel.name,
					website: resplatform.data.hotel.website,
					// country: resplatform.data.hotel.country.name,
					// city: resplatform.data.hotel.city.name,
					// area: resplatform.data.hotel.area.name,
					contract_start_date: resplatform.data.hotel.contract_start_date,
					contract_end_date: resplatform.data.hotel.contract_end_date,
					reservation_officer_name:
						resplatform.data.hotel.reservation_officer_name,
					mobile: resplatform.data.hotel.mobile,
					email: resplatform.data.hotel.email,
					spin: false,
					commissions: CommissionsList,
					hotel_id: resplatform.data.hotel.hotel_id,
				});

				setSelectedStartDate(resplatform.data.hotel.contract_start_date);
				setSelectedEndDate(resplatform.data.hotel.contract_end_date);
			}
		}
		fetchLookups();
	}, []);


	const [selectedEndDate, setSelectedEndDate] = useState(new Date());

	useEffect(() => {
		const submitAction = async () => {
			if (isFormValid(errors)) {
				if (id) {
					// const data = formatPostBasicInfo(hotelMainDetails);
					const res = await editHotel(id, {
						...hotelMainDetails,
						// website: 'https://www.google.com/',
						hotel_id: selectedHotelId,
					});
					if (res.status === 200) {
						// dispatch({ type: "progress", step: "2", current: "2" });
						// propertyDispatch({ type: "hotelMainDetails", payload: res.data });
						store.addNotification({
							title: "Done!",
							message: res.data.message,
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: {
								duration: 3000,
								onScreen: true,
								pauseOnHover: true,
							},
						});
						history.push(`/hotel-commissions-list`);
					}
				} else {
					//const data = formatPostBasicInfo(hotelMainDetails);
					const res = await addHotel({
						...hotelMainDetails,
						// website: 'https://www.google.com/',
						hotel_id: selectedHotelId,
					});
					if (res.status === 200) {
						store.addNotification({
							title: "Done!",
							message: res.data.message,
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: {
								duration: 3000,
								onScreen: true,
								pauseOnHover: true,
							},
						});
						history.push(`/hotel-commissions-list`);
						// dispatch({
						//   type: "progress",
						//   step: "2",
						//   current: "2"
						// });
						// propertyDispatch({ type: "hotelMainDetails", payload: res.data });
					}
				}
			}
		};

		submitAction();
	}, [isErrorLoaded]);
	const [selectedStartDate, setSelectedStartDate] = useState(new Date());

	const handleStartDateChange = (date) => {
		setSelectedStartDate(date);
		setHotelMainDetails({
			...hotelMainDetails,
			contract_start_date: date,
		});
	};

	const handleEndDateChange = (date) => {
		setSelectedEndDate(date);
		setHotelMainDetails({
			...hotelMainDetails,
			contract_end_date: date,
		});
	};
	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const reset = () => {
		setCanceled(true);
	};

	return (
		<>
			<div className="property-details">
				<div className="row custom--row">
					<div className="col-md-4">
						<SelectField
							label={backOffice.hotelName}
							placeholder={backOffice.select + " " + backOffice.hotelName}
							errors={errors.name}
							color={errors.name?.required ? "danger" : ""}
							options={hotels}
							id="basic-hotels"
							onInputChange={(e) => setSearchHotel(e)}
							onChange={(e) => {
								handleChange({ name: "name", value: e });
								setErrors({
									...errors,
									...validate({ name: "name", value: e }, { required: true, })
								});
								setSelectedCountry(e.hotelCountry);
								setSelectedCity(e.hotelCity);
								setSelectedArea(e.hotelArea);
								setSelectedHotelId(e.value);
								setHotelEdit({ ...hotelEdit, name: e })
							}}
							value={hotels[hotelIndex] ? hotels[hotelIndex] : hotelEdit?.name}
						/>
					</div>
					<div className="col-md-4">
						<TextField
							type="text"
							label={backOffice.country + "/" + backOffice.region}
							placeholder={backOffice.country + "/" + backOffice.region}
							name="hotelCountry"
							value={selectedCountry ? selectedCountry : hotelEdit?.country?.name}
							disabled
						/>
					</div>
					<div className="col-md-4">
						<TextField
							type="text"
							label={backOffice.city}
							placeholder={backOffice.city}
							name="hotelCity"
							value={selectedCity ? selectedCity : hotelEdit?.city?.name}
							disabled
						/>
					</div>
				</div>

				<div className="row custom--row">
					<div className="col-md-4">
						<TextField
							type="text"
							label={backOffice.area}
							placeholder={backOffice.area}
							name="hotelArea"
							value={selectedArea ? selectedArea : hotelEdit?.area?.name}
							disabled
						/>
					</div>
					<div className="col-md-4">
						<span className="d-block mt-3">{backOffice.contractStartDate}</span>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								className="my-2 DatePicker-Form w-100"
								variant="outlined"
								inputVariant="outlined"
								id="date-picker-dialog"
								//label={backOffice.contractStartDate}
								format="dd/MM/yyyy"
								value={selectedStartDate}
								color={errors?.contract_date?.required ? "danger" : ""}
								errors={errors?.contract_date}
								onChange={handleStartDateChange}
							/>
						</MuiPickersUtilsProvider>
					</div>

					<div className="col-md-4">
						<span className="d-block mt-3">{backOffice.contractEndDate}</span>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								className="my-2 DatePicker-Form w-100"
								variant="outlined"
								inputVariant="outlined"
								id="date-picker-dialog"
								// label={backOffice.contractEndDate}
								format="dd/MM/yyyy"
								value={selectedEndDate}
								color={errors?.contract_end_date?.required ? "danger" : ""}
								errors={errors?.contract_end_date}
								onChange={handleEndDateChange}
							/>

						</MuiPickersUtilsProvider>
					</div>
				</div>

				<div className="row custom--row">
					<div className="col-md-4">
						<TextField
							type="text"
							label={backOffice.reservationOfficerName}
							placeholder={backOffice.reservationOfficerName}
							value={hotelMainDetails?.reservation_officer_name}
							name="reservation_officer_name"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										min: 3,
									}),
								});
								setHotelMainDetails({
									...hotelMainDetails,
									reservation_officer_name: e.target.value,
								});
							}}
							min={3}
							color={
								errors?.reservation_officer_name?.required ||
									errors?.reservation_officer_name?.min
									? "danger"
									: ""
							}
							errors={errors?.reservation_officer_name}
						/>
					</div>
					<div className="col-md-4">
						<TextField
							type="text"
							label={backOffice.mobileNumber}
							placeholder={backOffice.mobileNumber}
							name="mobile"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
									}),
								});
								setHotelMainDetails({
									...hotelMainDetails,
									mobile: e.target.value,
								});
							}}
							color={errors?.mobile?.required ? "danger" : ""}
							value={hotelMainDetails?.mobile}
							errors={errors?.mobile}
						/>
					</div>
					<div className="col-md-4">
						<TextField
							type="text"
							label={backOffice.email}
							placeholder={backOffice.email}
							name="email"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
									}),
								});
								setHotelMainDetails({
									...hotelMainDetails,
									email: e.target.value,
								});
							}}
							color={errors?.email?.required ? "danger" : ""}
							value={hotelMainDetails?.email}
							errors={errors?.email}
						/>
					</div>
				</div>
				<Commission
					setCommissionsList={setCommissionsList}
					commissionsList={CommissionsList}
					errors={errors}
					setErrors={setErrors}
					countries={countries}
					season={season}
					platform={platform}
					supplier={supplier}
				/>
				<HotelSpecialOffer
					specialOfferList={SpecialOfferList}
					setSpecialOfferList={setSpecialOfferList}
				/>

				{/* <div className="row custom--row">
					<div className="col">
						<div className="personal-data">
							<div className="col-md-12 head-manager p-0">
								<p>{backOffice.SpecialOffers}</p>
							</div>

							{SpecialOfferList?.length > 0 ? (
								<div className="col our-border px-4">
									<table className="custom-table back-office-table table table-striped">
										<thead>
											<tr>
												<th style={{ maxWidth: "320px", width: "320px" }}>
													{backOffice.From}
												</th>
												<th style={{ maxWidth: "320px", width: "320px" }}>
													{backOffice.To}
												</th>
												<th style={{ maxWidth: "320px", width: "320px" }}>
													{backOffice.Offer}
												</th>
												<th>&nbsp;</th>
											</tr>
										</thead>
										<tbody>
											{SpecialOfferList
												? SpecialOfferList?.map((item, index) => (
													<SpecialOffer
														item={item}
														index={index}
														changeValueOffer={changeValueOffer}
														deleteOfferfn={deleteOfferfn}
														addOfferItem={addOfferItem}
													/>
												))
												: null}
										</tbody>
									</table>
								</div>
							) : null}

							<a className="btn btn-lg text-success d-inline-block my-2" href={() => false}
								onClick={() => {
									let x = {
										edit: true,
										form: new Date(),
										offer: "",
										id: null,
										to: new Date(),
									};
									setSpecialOfferList([...SpecialOfferList, x]);
								}}
							>
								<i className="fas fa-plus-circle"></i>{" "}
								{backOffice.AddSpecialOffers}
							</a>
						</div>
					</div>
				</div> */}

				<div className="row custom--row mt-2">
					<div className="col-md-12 col-md-12 d-flex justify-content-end">
						<Link
							className={`btn btn-sbs-secondary mx-1`}
							to={`/hotel-commissions-list`}
						>
							{backOffice.back}
						</Link>
						<button
							className="btn btn-sbs-primary mx-1"
							onClick={submit}
							disabled={hotelMainDetails.spin}
						>
							{hotelMainDetails.spin ? (
								<Spinner color="light" size="sm" />
							) : null}{" "}
							{id ? backOffice.edit : backOffice.addHotel}
						</button>
					</div>
				</div>

			</div>
		</>
	)
}

export default AddEditHotelContent;
