import React from "react";
import { Link, useParams } from "react-router-dom";
import Locale from "translations";

export default function Reservation({data}) {
  const { backOffice} = Locale;

console.log("data",data);
  return (
    <tr key={data?.id}>
        <td>{data?.name}</td>
        <td>{data?.ref_num}</td>
        <td>{data?.created_at}</td>
        <td>{data?.check_in}</td>
        <td>{data?.nights}</td>
        <td>{data?.pax}</td>
        <td>{data?.price}</td>
        <td className={data.status == 'confirmed'? 'text-success' : 'text-warning'}><span className="text-capitalize">{data.status}</span></td>
        <td className="text-orange">
          {data?.total_commission}{" "}{ backOffice.SAR }({data?.base_commission}%)
        </td>
        <td className="text-orange">
          {data?.safa_value}{" "}{ backOffice.SAR }({data?.safa_commission}%) 
        </td>
        <td className="text-orange">
          {data?.platform_value}{" "}{ backOffice.SAR } ({data?.platform_commission}%)          
        </td>
      
      </tr>
  );
}
