import React from "react";
import Locale from "translations";
import { useCountUp } from "react-countup";

export default function CurrentBalance(props) {
  const { countUp } = useCountUp({
    end: 10000,
    duration: 0.8,
  });

  const { payment } = Locale;

  return (
    <>
      <div className="current-balance-box">
        <h5 className="current-head font-weight-bold">
          {payment.messages.currentBalance}
        </h5>

        <div className="d-flex align-items-center">
          <h2 className="font-weight-bold current-price">{countUp}</h2>
          <select className="mx-2  select-balance">
            <option selected="selected" value="1">
              SAR
            </option>
            <option value="2">EGP</option>
            <option value="3">DSP</option>
          </select>
        </div>
      </div>
    </>
  );
}
