import PasswordField from "components/shared/PasswordField";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import { changePasswordApi } from "services/auth";
import Locale from "translations";

export default function ChangePassword() {
	const { backOffice } = Locale;
	let history = useHistory();
	const [changePassword, setChangePassword] = useState({
		oldPassword: "",
		newPassword: "",
		passwordConfirmation: "",
	});

	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	// Check Errors
	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "oldPassword", value: changePassword.oldPassword },
				{ required: true, min: 8 }
			),
			...validate(
				{ name: "newPassword", value: changePassword.newPassword },
				{ required: true, min: 8 }
			),
			...validate(
				{
					name: "passwordConfirmation",
					value: changePassword.passwordConfirmation,
				},
				{ required: true, confirm: true },
				changePassword.newPassword
			),
		});
	};

	const handleCreate = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	useEffect(() => {
		if (isFormValid(errors)) {
			const update = async () => {
				let date = {
					old_password: changePassword.oldPassword,
					new_password: changePassword.newPassword,
					new_password_confirmation: changePassword.passwordConfirmation,
				};
				const res = await changePasswordApi(date);
				//;
				if (res.status == 200 || res.status == 201) {
					store.addNotification({
						title: "Info!",
						message: "change password Successfully",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 2000,
							onScreen: true,
							pauseOnHover: true,
						},
						onRemoval: () => {
							history.push("/");
						},
					});
				}
			};

			update();
		}
	}, [isErrorLoaded]);
	return (
		<>
			<div className=" container bg-white  border rounded p-0">
				<div className="d-flex justify-content-between p-4 border-bottom">
					<h6 className="font-weight-bold">{backOffice.changePassword}</h6>
				</div>
				<div className="row m-0 p-5 align-items-center">
					<div className="col-6 m-auto">
						<div className="row m-0 w-80 m-auto">
							<div className="col-md-12">
								<PasswordField
									placeholder={backOffice.oldPasswordPlaceholder}
									label={backOffice.oldPassword}
									name="oldPassword"
									value={changePassword.oldPassword}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: "oldPassword", value: e.target.value },
												{
													required: true,
													min: 8,
												}
											),
										});
										setChangePassword({
											...changePassword,
											oldPassword: e.target.value,
										});
									}}
									min={8}
									color={
										errors?.oldPassword?.required || errors?.oldPassword?.min
											? "danger"
											: ""
									}
									errors={errors?.oldPassword}
								/>
							</div>
							<div className="col-md-12">
								<PasswordField
									placeholder={backOffice.newPasswordPlaceholder}
									label={backOffice.newPassword}
									name="newPassword"
									value={changePassword.newPassword}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: "newPassword", value: e.target.value },
												{
													required: true,
													min: 8,
												}
											),
										});
										setChangePassword({
											...changePassword,
											newPassword: e.target.value,
										});
									}}
									min={8}
									color={
										errors?.newPassword?.required || errors?.newPassword?.min
											? "danger"
											: ""
									}
									errors={errors?.newPassword}
								/>
							</div>
							<div className="col-md-12">
								<PasswordField
									placeholder={backOffice.confirmPasswordPlaceholder}
									label={backOffice.confirmPassword}
									name="confirmationPassword"
									value={changePassword.passwordConfirmation}
									onChange={(e) => {
										setChangePassword({
											...changePassword,
											passwordConfirmation: e.target.value,
										});
										setErrors({
											...errors,
											...validate(
												{
													name: "passwordConfirmation",
													value: e.target.value,
												},
												{
													required: true,
													confirm: true,
												},
												changePassword.newPassword
											),
										});
									}}
									color={
										errors?.passwordConfirmation?.required ||
										errors?.passwordConfirmation?.confirm
											? "danger"
											: ""
									}
									errors={errors?.passwordConfirmation}
								/>
							</div>
							<div className="col-md-12">
								<button
									className="btn-sbs-primary p-2  text-white mx-1 w-100"
									onClick={handleCreate}
								>
									{backOffice.changePassword}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
