import resetIcon from "assets/images/wallet/resetIcon.png";
import { useState } from "react";

// import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import DatePickerField from "components/shared/DatePickerField";
// import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import SelectField from "components/shared/SelectField";
import Locale from "translations";

export default function TravelFilter(props) {
	const { backOffice } = Locale;

	const [countries] = [{}];

	const [filterState, setFilterState] = useState({
		search: "",
		date: undefined,
		country: "",
	});

	const resetFilters = () => {
		setFilterState({
			search: "",
			date: "",
			country: "",
		});
	};

	return (
		<>
			<>
				<div className="main-filter bg-white-blue py-2 my-2">
					<div className="row align-items-center w-100 no-gutter m-0">
						<div className="col-md-2">
							<div className="main-label">
								<TextField
									type="text"
									placeholder={backOffice.search}
									hasLabel={false}
									value={filterState.search}
									onChange={(e) => {
										setFilterState({
											...filterState,
											search: e.target.value,
										});
									}}
								/>
								<i className="fas fa-search fa-fw"></i>
							</div>
						</div>

						<div className="col-md-2">
							<DatePickerField
								hasLabel={false}
								date={filterState.date}
								placeholder={backOffice.date}
								onChangeDate={(filterDate) => {
									setFilterState({
										...filterState,
										date: filterDate,
									});
								}}
							/>
						</div>

						<div className="col-md-2">
							<SelectField
								hasLabel={false}
								value={filterState.country?.label}
								placeholder={backOffice.status}
								name="country"
								options={countries}
								onChange={(e) => {
									setFilterState({
										...filterState,
										country: e,
									});
								}}
							/>
						</div>

						<div className="col-md">
							<SelectField
								hasLabel={false}
								value={filterState.country?.label}
								placeholder={backOffice.city}
								name="country"
								options={countries}
								onChange={(e) => {
									setFilterState({
										...filterState,
										country: e,
									});
								}}
							/>
						</div>

						<div className="col-md">
							<SelectField
								hasLabel={false}
								value={filterState.country?.label}
								placeholder={backOffice.area}
								name="country"
								options={countries}
								onChange={(e) => {
									setFilterState({
										...filterState,
										country: e,
									});
								}}
							/>
						</div>

						<div className="col-md">
							<SelectField
								hasLabel={false}
								value={filterState.country?.label}
								placeholder={backOffice.country}
								name="country"
								options={countries}
								onChange={(e) => {
									setFilterState({
										...filterState,
										country: e,
									});
								}}
							/>
						</div>

						<div className="col-md" onClick={resetFilters}>
							<div
								onClick={props.onReset}
								className="d-flex align-items-center text-secondary pointer mt-4 reset-btn"
							>
								<img src={resetIcon} alt="" srcset="" />
								<p className="text-caption">{backOffice.resetFilter}</p>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	);
}
