import Model from 'components/model';
import Pagination from "components/shared/Pagination";
import { useState } from "react";
import Locale from "translations";
import PayForm from '../paymentform';

function Tablelist() {
	const { backOffice } = Locale;
	const [select, setselect] = useState(false);
	const [selectAll, setselectall] = useState(false);
	const [isOpen,setIsopen]=useState(false)
	const toggle =()=>{
		setIsopen(!isOpen)
	}
	return (
		<>
			<div className="d-flex align-items-center row border-box">
				<div className="d-flex align-items-center justify-content-center col-10 gap-3">
					<div className="text-center amount total">
						<span>Total</span>
						<h5 className="type">600 SAR</h5>
					</div>
					<div className="text-center amount paid">
						<span>Paid </span>
						<h5 className="type">600 SAR</h5>
					</div>
					<div className="text-center amount Remaining">
						<span>Remaining</span>
						<h5 className="type text-danger">600 SAR</h5>
					</div>
				</div>
				<button type="button" class="btn btn-primary">
					<i class="far fa-file-excel"></i>
					Export Excel
				</button>
				<button type="button" class="btn btn-success ms-2 px-5"
				onClick={toggle}
				>
					Pay
				</button>
			</div>
			<div className="our-border ">
				<table className="financialTable  align-middle table table-striped">
					<thead>
						<tr>
							<th>
							<input className='me-2'  type="checkbox" name="row" id="row" />
							<label htmlFor="row">	{backOffice.ref}</label>
							</th>
							<th>{backOffice.confirmationNo}</th>
							<th>{backOffice.checkIn}</th>
							<th>{backOffice.checkOut}</th>
							<th>{backOffice.hotelName}</th>
							<th>{backOffice.Rooms}</th>
							<th>{backOffice.NetRate}</th>
							<th>{backOffice.companyMarkup}</th>
							<th>{backOffice.safaMarkup}</th>
							<th>{backOffice.SellingRate}</th>

							<th colSpan="2">
								<div className="d-flex" style={{ minHeight: "55px" }}>
									<table className="w-100 ">
										<tr style={{ borderBottom: "1px solid #D1D6DD" }}>
											<td colSpan="2">{backOffice.transaction}</td>
										</tr>
										<tr>
											<td
												style={{
													width: "50%",
													borderRight: "1px solid #D1D6DD",
												}}
												colSpan="1"
											>
												{backOffice.Debit}
											</td>
											<td
												style={{
													width: "50%",
													borderleft: "1px solid #D1D6DD",
												}}
												colSpan="1"
											>
												{backOffice.Credit}
											</td>
										</tr>
									</table>
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr className={`${select && "bg-danger"}`}>
							<td>
								{" "}
								<input
									type="checkbox"
									onChange={() => {
										setselect(!select);
									}}
									name="row"
									id="1"
								/>{" "}
								<span>SAF12345</span>
							</td>
							<td>12345678901234</td>
							<td>01-12-2022</td>
							<td>01-12-2022</td>
							<td>Royal Clock Tower a Fairmont</td>
							<td>(2) DBL, (1) TPL, (1) SGL</td>
							<td>100.00 SAR</td>
							<td>120.00 SAR</td>
							<td>20.00 SAR</td>
							<td>20.00 SAR</td>
							<td>0.00 SAR</td>
							<td>0.00 SAR</td>
						</tr>
						<tr>
							<td>
								{" "}
								<input
									type="checkbox"
									onChange={() => {
										setselect(!select);
									}}
									name="row"
									id="1"
								/>{" "}
								<span>SAF12345</span>
							</td>
							<td>12345678901234</td>
							<td>01-12-2022</td>
							<td>01-12-2022</td>
							<td>Royal Clock Tower a Fairmont</td>
							<td>(2) DBL, (1) TPL, (1) SGL</td>
							<td>100.00 SAR</td>
							<td>120.00 SAR</td>
							<td>20.00 SAR</td>
							<td>20.00 SAR</td>
							<td>0.00 SAR</td>
							<td>0.00 SAR</td>
						</tr>
						<tr>
							<td>
								{" "}
								<input
									type="checkbox"
									onChange={() => {
										setselect(!select);
									}}
									name="row"
									id="1"
								/>{" "}
								<span>SAF12345</span>
							</td>
							<td>12345678901234</td>
							<td>01-12-2022</td>
							<td>01-12-2022</td>
							<td>Royal Clock Tower a Fairmont</td>
							<td>(2) DBL, (1) TPL, (1) SGL</td>
							<td>100.00 SAR</td>
							<td>120.00 SAR</td>
							<td>20.00 SAR</td>
							<td>20.00 SAR</td>
							<td>0.00 SAR</td>
							<td>0.00 SAR</td>
						</tr>
					</tbody>
				</table>
				<Pagination />
			</div>
			<Model
				isOpen={isOpen}
				title={'Financial payment'}
				toggle={toggle}
				size="lg"
				hasFooter={false}
			>
			<PayForm />	
			</Model>
		</>
	);
}

export default Tablelist;
