import { useHistory } from "react-router-dom";

export default function Notification({ notification, readed }) {
	const history = useHistory();

	return (
		<div
			className={`notification-items pointer mt-3 ${
				notification?.read_at ? " " : "notification-readed"
			}`}
			onClick={() => {
				if (!notification?.read_at) {
					readed(notification.id);
				}
				if (notification.item_type == "company") {
					history.push(`/tourism-company/profile/${notification.item_id}`);
				} else if (notification.item_type == "brn") {
					history.push(`/booking/view/${notification.item_id}`);
				}
			}}
		>
			<div>
				<p className="my-1 h5">{notification.title}</p>
				<p className="notification-content">{notification.body}</p>
			</div>
			{notification?.read_at ? null : <i class="fas fa-circle dot"></i>}
		</div>
	);
}
