import Pagination from "components/shared/Pagination";
import Locale from "translations";
import FinancialPaymentFilter from "./financialPaymentFilter";
import PaymentTablecontent from "./paymentTablecontent";

const FinancialPaymentTable = (props) => {
	const { filter, setFilter, payment, meta, goTo, currency } = props;
	const { backOffice } = Locale;

	const allPayment =
		payment.length > 0 ? (
			payment.map((item, index) => {
				return <PaymentTablecontent data={item} currency={currency} />;
			})
		) : (
			<tr>
				<td colSpan="8">
					<div className="product-no-data">
						<i className="fas fa-info-circle fa-lg"></i>{" "}
						<h4>{backOffice.noResult}</h4>
					</div>
				</td>
			</tr>
		);

	return (
		<div className="financial-payment-table my-3">
			<div className=" border rounded p-0">
				<div className="d-flex justify-content-between pt-3 pb-1 px-3  border-bottom">
					<FinancialPaymentFilter filter={filter} setFilter={setFilter} />
				</div>
				<div className="align-items-center py-2">
					<div className=" table-responsive px-4 ">
						<table className="custom-table back-office-table table table-striped">
							<thead>
								<tr>
									<th width="2%"></th>
									<th width="30%">Transaction Code</th>
									<th>Amount</th>
									<th>Remaining</th>
									<th>Type</th>
									<th>Transaction Type</th>
									<th>Date</th>
									<th>Employee</th>
								</tr>
							</thead>

							<tbody>{allPayment}</tbody>
						</table>
						<div className="px-2">
							<Pagination info={meta} goTo={goTo} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FinancialPaymentTable;
