import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate from "helpers/validate";

const HotelDetails = ({
	setSupplier,
	supplier,
	setErrors,
	errors,
	countries,
	cities,
	handleHotels,
	getHotelsCities,
	getHotelsArea,
	areas,
	deleteHotel,
	addOneHotel,
	ratingOptions,
}) => {
	return (
		<div className="supplier-details">
			<div className="bankConfigrationHeader p-3 text-visa-header font-weight-bold mt-3">
				<p className="m-0">Hotels</p>
			</div>
			<div className="row pb-3 px-5 our-border py-0 m-0">
				{supplier?.hotel &&
					supplier?.hotel?.length >= 0 &&
					supplier?.hotel.map((item, index) => (
						<>
							<div className="col-3 mt-2">
								<TextField
									type="text"
									label={"Hotel Name"}
									placeholder={"Hotel Name"}
									value={item.hotelName}
									name={`hotelName${index}`}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
											}),
										});
										handleHotels(e.target.value, index, "hotelName");
									}}
									color={errors[`hotelName${index}`]?.required ? "danger" : ""}
									errors={errors[`hotelName${index}`]}
								/>
							</div>
							<div className="col-2">
								<SelectField
									label={"Rating"}
									value={item.hotelRating}
									name={`hotelRating${index}`}
									options={ratingOptions}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: `hotelRating${index}`, value: e.value },
												{
													required: true,
												}
											),
										});

										handleHotels(e, index, "hotelRating");
									}}
									color={
										errors[`hotelRating${index}`]?.required ? "danger" : ""
									}
									errors={errors[`hotelRating${index}`]}
								/>
							</div>
							<div className="col-2">
								<SelectField
									label={"Country"}
									value={item.hotelCountry}
									name={`hotelCountry${index}`}
									options={countries}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: `hotelCountry${index}`, value: e.value },
												{
													required: true,
												}
											),
										});

										handleHotels(e, index, "hotelCountry");
										getHotelsCities(item);
									}}
									color={
										errors[`hotelCountry${index}`]?.required ? "danger" : ""
									}
									errors={errors[`hotelCountry${index}`]}
								/>
							</div>
							<div className="col-2">
								<SelectField
									disabled={item.hotelCountry == ""}
									label={"City"}
									value={item.hotelCity}
									name={`hotelCity${index}`}
									options={cities}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: `hotelCity${index}`, value: e.value },
												{
													required: true,
												}
											),
										});

										handleHotels(e, index, "hotelCity");
										getHotelsArea(item);
									}}
									color={errors[`hotelCity${index}`]?.required ? "danger" : ""}
									errors={errors[`hotelCity${index}`]}
								/>
							</div>
							<div className="col-2">
								<SelectField
									disabled={item.hotelCity == ""}
									label={"Area"}
									value={item.area}
									name={`hotelArea${index}`}
									options={areas}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: `hotelArea${index}`, value: e.value },
												{
													required: false,
												}
											),
										});

										handleHotels(e, index, "hotelArea");
									}}
									// color={
									// 	errors[`hotelArea${index}`]?.required ? "danger" : ""
									// }
									// errors={errors[`hotelArea${index}`]}
								/>
							</div>
							{index != 0 ? (
								<a
									className="col-1 mt-5 btn-link text-danger pointer"
									href={() => false}
									onClick={() => deleteHotel(index)}
								>
									<i class="fas fa-trash mx-1"></i>
								</a>
							) : (
								<span className="col-1"></span>
							)}
						</>
					))}
				<button
					className="btn-link text-success d-flex justify-content-end w-90"
					onClick={addOneHotel}
				>
					<i class="fas fa-plus-circle text-success"></i> Add New
				</button>
			</div>

			<div className="d-flex justify-content-end"></div>
		</div>
	);
};

export default HotelDetails;
