import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import { getNotification } from "helpers/makeNotifications";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { addEmployee, deleteGroup, listGroups } from "services/companies";
import Locale from "translations";
import LooksupBranches from "../lookups/fetchBranches";
import Group from "./Group";

export default function ListGroups() {
	const { Companies, backOffice } = Locale;
	const { allCountries } = useGlobalState();
	const { status } = useParams();
	const [errors, setErrors] = useState({});
	const [Reload, setReload] = useState(true);
	const { company_id } = useParams();
	const [Groups, setGroupsLookups] = useState([]);
	const [countries, setCountries] = useState([]);
	let locale = localStorage.getItem("currentLocale") || "en";
	const Branches = LooksupBranches(company_id);
	const [user, setUser] = useState({
		full_name: "",
		user_id: "",
		position: "",
		phone: "",
		groups: null,
		branch_id: null,
		nationality: null,
		password: "",
		password_confirmation: "",
	});

	const history = useHistory();

	const checkFormErrors = () => {
		let SubmitError = {};
		Object.keys(user).forEach((key) => {
			SubmitError = {
				...SubmitError,
				...validate(
					{
						name: key,
						value: user[key],
					},
					{ required: true }
				),
			};
		});
		setErrors(SubmitError);
	};

	const createUser = async () => {
		let res;
		checkFormErrors();
		if (isFormValid(errors)) {
			res = await addEmployee(company_id, {
				...user,
				branch_id: user.branch_id.value,
				lang: locale,
				groups: [user.groups.value],
				nationality: user.nationality.value,
				phone: `+${user.nationality.value}${user.phone}`,
			});
			if (res.status === 200 || res.status === 201) {
				getNotification(res?.data?.message);
				history.push(`/tourism-company/employees/${company_id}`);
			}
		}
	};

	const [isOpen, setIsOpen] = useState(false);
	const [groups, setGroups] = useState([]);

	const toggle = (group) => {
		setIsOpen(!isOpen);
		setUser({
			...user,
			groups: { value: group.id, label: group.name },
			branch_id: {
				value: group?.branch?.id,
				label: group?.branch?.name,
			},
		});
	};

	useEffect(async () => {
		const res = await listGroups(company_id, {});
		if (res?.status === 200) {
			setGroups(res?.data?.data);
		}
	}, [Reload]);

	const removeGroup = async (id) => {
		const res = await deleteGroup(company_id, id);
		if (res.status === 200 || res.status === 201) {
			getNotification(res?.data?.message);
			setReload(!Reload);
		}
	};

	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);

	const allGroups =
		groups.length > 0 ? (
			groups.map((group) => (
				<Group
					group={group}
					key={group.id}
					toggleAdd={toggle}
					removeGroup={removeGroup}
				/>
			))
		) : (
			<h2>{Companies.noGroups}</h2>
		);

	return (
		<>
			<div>{allGroups}</div>
			<div>
				<Modal isOpen={isOpen} toggle={toggle}>
					<div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
						<p className=" text-secondary ">{Companies.addEmployee}</p>
						<i
							className="far fa-times-circle fa-lg text-secondary pointer"
							onClick={toggle}
						></i>
					</div>

					<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3">
						<div className=" m-0 p-0 w-75">
							<div className="">
								<TextField
									type="text"
									placeholder={"full name"}
									label={"full name"}
									hasLabel={false}
									name="full name"
									value={user.full_name}
									errors={errors?.full_name}
									color={errors?.full_name?.required ? "danger" : ""}
									onChange={(e) => {
										setUser({ ...user, full_name: e.target.value });
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
											}),
										});
									}}
								/>
							</div>
							<div className="">
								<TextField
									type="text"
									placeholder={"position"}
									label={"position"}
									hasLabel={false}
									name="position"
									value={user.position}
									errors={errors?.position}
									color={errors?.position?.required ? "danger" : ""}
									onChange={(e) => {
										setUser({ ...user, position: e.target.value });
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
											}),
										});
									}}
								/>
							</div>

							<div className="">
								<TextField
									type="text"
									placeholder={Companies.email}
									label={Companies.email}
									hasLabel={false}
									name="user_id"
									disabled={status === "edit"}
									value={user.user_id}
									errors={errors?.user_id}
									color={errors?.user_id?.required ? "danger" : ""}
									onChange={(e) => {
										setUser({ ...user, user_id: e.target.value });
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
											}),
										});
									}}
								/>
							</div>
						</div>
						<div className="w-75 m-0 p-0">
							<div className="">
								<SelectField
									hasLabel={false}
									placeholder={Companies.country}
									label={Companies.country}
									name="nationality"
									options={countries}
									value={user.nationality}
									errors={errors?.nationality}
									color={errors?.nationality?.required ? "danger" : ""}
									onChange={(e) => {
										const { label: value } = e;
										setUser({ ...user, nationality: e });
										setErrors({
											...errors,
											...validate(
												{ name: "nationality", value },
												{
													required: true,
												}
											),
										});
									}}
								/>
							</div>
							<div className="">
								<TextField
									type="text"
									placeholder={Companies.phoneNumber}
									label={Companies.phoneNumber}
									hasLabel={false}
									name="phone"
									value={user.phone}
									errors={errors?.phone}
									min={11}
									color={
										errors?.phone?.required
											? "danger"
											: "" || errors?.phone?.min
											? "danger"
											: ""
									}
									onChange={(e) => {
										setUser({ ...user, phone: e.target.value });
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
												min: 11,
											}),
										});
									}}
								/>
							</div>
							<div className="">
								<SelectField
									hasLabel={false}
									placeholder={Companies.branch}
									label={Companies.branch}
									options={Branches}
									name="branch_id"
									value={user.branch_id}
									disabled={true}
									errors={errors?.branch_id}
									color={errors?.branch_id?.required ? "danger" : ""}
									onChange={(e) => {
										const { label: value } = e;
										setUser({ ...user, branch_id: e });
										setErrors({
											...errors,
											...validate(
												{ name: "branch_id", value },
												{
													required: true,
												}
											),
										});
									}}
								/>
							</div>
						</div>

						<div className="w-75 m-0 p-0">
							<div className="">
								<SelectField
									hasLabel={false}
									placeholder={Companies.group}
									label={Companies.group}
									options={Groups}
									name="groups"
									disabled={true}
									value={user.groups}
									errors={errors?.groups}
									color={errors?.groups?.required ? "danger" : ""}
									onChange={(e) => {
										const { label: value } = e;
										setUser({ ...user, groups: e });
										setErrors({
											...errors,
											...validate(
												{ name: "groups", value },
												{
													required: true,
												}
											),
										});
									}}
								/>
							</div>
							{/*     <div className=" ">
          <TextField
            type="text"
            placeholder={backOffice.newPassword}
            hasLabel={false}
          />
        </div>
        <div className="">
          <TextField
            type="text"
            placeholder={backOffice.confirmPassword}
            hasLabel={false}
          />
        </div> */}
						</div>
					</ModalBody>
					<div className="w-100 pb-5 border-top-0  text-center    p-0 ">
						<button
							className="btn rounded companies-btn  w-75"
							onClick={createUser}
						>
							{Companies.addEmployee}
						</button>
					</div>
				</Modal>
			</div>
		</>
	);
}
