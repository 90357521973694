import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import moment from "moment";
import { useEffect, useState } from "react";
import { isInclusivelyBeforeDay } from "react-dates";
import Locale from "translations";
import WarningModal from "../../VisaConfiguration/addCountry/warningModal";

export default function VisaRow({
	index,
	country: countryBase,
	visa,
	handleDelete,
	destination,
	setDestination,
	indexConfiguration,
	setviewConfiguration,
	viewConfiguration,
	setIndexConfiguration,
	inputsState,
	setInputsState,
	arrayCountry,
	setArrayCountry,
	configData,
	allConfigData,
	visaLabel,
	setVisaRowState,
	VisaRowState,
	visaIndexState,
	currencies,
	countries,
	serviceType,
	addToggle,
	alertToggle,
	setAlertToggle,
}) {
	console.log("countryBasecountryBase", countryBase);
	const { backOffice } = Locale;
	let locale = localStorage.getItem("currentLocale") || "en";
	console.log("inputsStateinputsState", inputsState);
	//Fetch currencies

	const [allInputs, setAllInputs] = useState([]);
	const transportationType = [
		{
			value: "maritime",
			label: "maritime",
		},
		{
			value: "air",
			label: "air",
		},
		{
			value: "land",
			label: "land",
		},
	];
	const [inputsStates, setInputsStates] = useState([]);
	const [VisaLabel, setVisaLabel] = useState();

	useEffect(() => {
		setAllInputs([...inputsState]);
		setVisaLabel(visaLabel);
	}, []);

	const add = () => {
		setInputsStates([...inputsStates, {}]);
	};
	const addEdit = (ind) => {
		let newData = viewConfiguration;

		let counrtyX = newData.filter((res) => res.country == countryBase)[0];

		counrtyX.residences.push({
			residence: " ",
			currency: " ",
		});
		setviewConfiguration([...newData]);
	};

	const handleChenageinArray = (
		name,
		value,
		index,
		ind = 0,
		selectKeyId = null,
		selectKeyLabel = null
	) => {
		let input = inputsState[index];
		let newData = viewConfiguration;
		let counrtyX = newData.filter((res) => res.country == countryBase)[0];
		counrtyX.residences[index][name] = value;
		setviewConfiguration([...newData]);
	};

	//Fetch Countries

	useEffect(() => {
		if (!arrayCountry.includes(countryBase?.value)) {
			setArrayCountry([...arrayCountry, countryBase?.value]);
		}
	}, []);

	const deleteVise = (ind, indexs) => {
		let newData = viewConfiguration;
		let counrtyX = newData.filter((res) => res.country == countryBase)[0];
		counrtyX.residences = counrtyX.residences.filter(
			(res, index) => ind != index
		);
		if (counrtyX.residences.length == 0) {
			newData = newData.filter((res) => res.country != countryBase);
			console.log(newData);
		}
		setviewConfiguration([...newData]);
	};
	const handleCopy = (index, ind) => {
		let allConfigurationClone = viewConfiguration;
		let selectedConfig = allConfigurationClone.filter(
			(res) => res.country == countryBase
		)[0];
		let configureRowCopied = selectedConfig.residences;

		configureRowCopied?.push({
			...configureRowCopied[ind],
			id: configureRowCopied?.length + 1,
		});
		setviewConfiguration([...allConfigurationClone]);
	};
	useEffect(() => {
		let allConfigurationClone = viewConfiguration;
		let selectedConfig = allConfigurationClone.filter(
			(res) => res.country == countryBase
		);
		console.log(selectedConfig);

		if (selectedConfig?.length > 1) {
			setAlertToggle(true);
			selectedConfig = selectedConfig[0];
			allConfigurationClone.pop(selectedConfig[1]);
			console.log(allConfigurationClone);
		}
		setviewConfiguration([...allConfigurationClone]);
	}, [addToggle]);

	return (
		<>
			{!visa && inputsState && inputsState?.length > 0
				? inputsState.map((inputsStateData, ind) => {
						return (
							<div className="visa-row ground-config-row">
								<div className="col-md-12 m-auto">
									<div className="row visa-row-content flex-wrap bg-gray-white-100 px-3">
										<>
											<div className=" col-md-2">
												<SelectField
													label={backOffice.residence}
													placeholder={backOffice.residence}
													options={countries}
													id="basic-countries"
													name="residences"
													onChange={(e) => {
														handleChenageinArray(
															"residence",
															e,
															ind,
															index,
															"country_id",
															"name"
														);
													}}
													value={
														!inputsStateData?.residence
															? {
																	value: inputsStateData?.residence_id,
																	label: inputsStateData?.residence_name,
															  }
															: inputsStateData?.residence
													}
												/>
											</div>

											<div className="col-md-2">
												<TextField
													type="text"
													label={backOffice.price}
													placeholder={backOffice.price}
													name="price"
													onChange={(e) => {
														handleChenageinArray(
															"price",
															e.target.value,
															ind,
															index
														);
													}}
													value={inputsStateData?.price}
												/>
											</div>
											<div className="col-md-2">
												<SelectField
													label={backOffice.currency}
													placeholder={backOffice.currency}
													options={currencies}
													id="basic-countries"
													name="currency"
													onChange={(e) => {
														handleChenageinArray(
															"currency",
															e,
															ind,
															index,
															"id",
															"currency_code"
														);
													}}
													value={
														!inputsStateData?.currency
															? {
																	value:
																		inputsStateData?.currency?.label ??
																		inputsStateData?.currency_code,
																	label:
																		inputsStateData?.currency?.label ??
																		inputsStateData?.currency_code,
															  }
															: inputsStateData.currency
													}
												/>
											</div>
											{/*******Start New Fields *********** */}
											<div className=" col-md-2">
												<SelectField
													label={"Service Type"}
													placeholder={"Service Type"}
													options={serviceType}
													name="service_type"
													onChange={(e) => {
														handleChenageinArray("service_type", e, ind, index);
													}}
													value={
														inputsStateData?.service_type?.id
															? {
																	value: inputsStateData?.service_type?.id,
																	label: inputsStateData?.service_type?.name,
															  }
															: inputsStateData?.service_type
													}
												/>
											</div>
											<div className="col-md-2  dataPicker-configRow">
												<DatePickerField
													hasLabel={true}
													label={"Start Date"}
													placeholder={"Start Date"}
													date={
														inputsStateData?.start_date
															? moment(inputsStateData?.start_date)
															: inputsStateData?.start_date
													}
													name="start_date"
													onChangeDate={(e) => {
														handleChenageinArray("start_date", e, ind, index);
													}}
													isOutsideRange={(day) =>
														day.isBefore(moment().add(-1, "days"))
													}
												/>
											</div>
											<div className="col-md-2 dataPicker-configRow">
												<DatePickerField
													hasLabel={true}
													label={"End Date"}
													placeholder={"End Date"}
													date={
														inputsStateData?.end_date
															? moment(inputsStateData?.end_date)
															: inputsStateData?.end_date
													}
													name="end_date"
													onChangeDate={(e) => {
														handleChenageinArray("end_date", e, ind, index);
													}}
													isOutsideRange={(day) =>
														isInclusivelyBeforeDay(day, moment())
													}
												/>
											</div>
											<div className="col-md-2">
												<TextField
													type="number"
													label={"Expire Days"}
													placeholder={"Expire Days"}
													name="expiration_days"
													onChange={(e) => {
														handleChenageinArray(
															"expiration_days",
															e.target.value,
															ind,
															index
														);
													}}
													value={inputsStateData?.expiration_days}
													color={
														inputsStateData?.expiration_days <= 0 &&
														inputsStateData?.expiration_days != ""
															? "danger"
															: ""
													}
												/>
												{inputsStateData?.expiration_days <= 0 &&
												inputsStateData?.expiration_days != "" ? (
													<span className="text-danger">
														ExpireDays Must Be Positive Number
													</span>
												) : (
													""
												)}
											</div>

											{/*******End New Fields *********** */}
											<div className="col-md-10 d-flex justify-content-end align-items-center mt-4">
												<button
													className="btn copyIcon-configRow"
													onClick={() => handleCopy(index, ind)}
												>
													<i class="fas fa-copy"></i> Duplicate
												</button>
												<a
													className="btn-link text-danger mx-1"
													onClick={() => {
														deleteVise(ind, index);
													}}
													href={() => false}
												>
													<i class="fas fa-trash"></i> Remove
												</a>
											</div>
										</>
									</div>
								</div>
								{inputsState.length == ind + 1 ? (
									<button
										className="btn-link text-success btn-add-ground-input mt-1"
										onClick={() => addEdit(ind)}
										style={{ fontSize: "16px" }}
									>
										<i class="fas fa-plus-circle text-success"></i>
										Add
									</button>
								) : (
									""
								)}
							</div>
						);
				  })
				: ""}
			{alertToggle ? (
				<WarningModal isOpen={alertToggle} toggle={setAlertToggle} />
			) : (
				""
			)}
		</>
	);
}
