import Carousel from "react-multi-carousel";

function ImageGallery(props) {
	const { images } = props;
	return (
		<div className={`w-100`}>
			<Carousel
				additionalTransfrom={-12}
				arrows
				autoPlaySpeed={3000}
				centerMode={false}
				// className={`${styles.image_gallery}`}
				containerClass="container"
				dotListClass=""
				draggable
				focusOnSelect={false}
				infinite
				itemClass={`image_gallery_item`}
				keyBoardControl
				minimumTouchDrag={80}
				pauseOnHover
				renderArrowsWhenDisabled={false}
				renderButtonGroupOutside={false}
				renderDotsOutside={false}
				responsive={{
					desktop: {
						breakpoint: {
							max: 3000,
							min: 1024,
						},
						items: 1,
					},
					mobile: {
						breakpoint: {
							max: 464,
							min: 0,
						},
						items: 1,
					},
					tablet: {
						breakpoint: {
							max: 1024,
							min: 464,
						},
						items: 1,
					},
				}}
				rewind={false}
				rewindWithAnimation={false}
				// rtl={true}
				shouldResetAutoplay
				sliderClass=""
				slidesToSlide={1}
				swipeable
			>
				{images.map((i) => (
					<img key={i.alt} src={i.src} className="w-100" alt={i.alt} />
				))}
			</Carousel>
		</div>
	);
}

export default ImageGallery;
