import Locale from "translations";

export default function Pagination({ info, goTo }) {
	const { commons } = Locale;

	return (
		<>
			{info?.total > info?.per_page ? (
				<div className="d-flex justify-content-between align-items-center  px-3 mb-2 mr-2 ml-2">
					<div className="text-black font-weight-bold text-caption">
						<span className="font-weight-bold ">
							{commons.show} {info?.from} {commons.to} {info?.to} {commons.of}{" "}
							{info?.total} {commons.entries}
						</span>
					</div>
					<nav aria-label="Page navigation  example">
						<ul className="pagination my-4  me-3 row">
							{info?.links?.map((page) => {
								let string = page?.url?.lastIndexOf("page=");
								let goToPage = page?.url?.substring(string + 5);

								return (
									<li
										key={`page-${page.label} `}
										className={`${
											page.active ? "page-item active" : "page-item"
										} ${
											page.url == null && page.label == "..."
												? "disabled-li"
												: ""
										}`}
										onClick={() =>
											goToPage != undefined ? goTo(goToPage) : false
										}
									>
										<span
											style={{ cursor: "pointer" }}
											dangerouslySetInnerHTML={{ __html: page.label }}
											className={`page-link ${
												page.url === null ? "false" : ""
											}`}
										></span>
									</li>
								);
							})}
						</ul>
					</nav>
				</div>
			) : null}
		</>
	);
}

//AnotherPagination
// export function PaginationTwo({ info, goTo }) {
// 	const { commons } = Locale;

// 	const paginate = (page) => {
// 		let current;
// 		if (page.url !== null) {
// 			current =
// 				page.label === "Next &raquo;"
// 					? info.current_page + 1
// 					: page.label === "&laquo; Previous"
// 						? info.current_page - 1
// 						: page.label;
// 		}

// 		goTo(current);
// 	};

// 	return (
// 		<>
// 			{info?.total > info?.per_page ? (
// 				<div className="d-flex align-items-center justify-content-between mb-2 mr-2 ml-2">
// 					<div className="text-caption font-weight-bold text-blue-black">
// 						{commons.show} {info.from} {commons.to} {info.to}{" "}
// 						{commons.of} {info.total} {commons.entries}
// 					</div>
// 					<nav aria-label="Page navigation example">
// 						<ul className="pagination">
// 							{info?.links?.map((page) => {
// 								return page.url ? (
// 									<li
// 										key={`page-${page.label}`}
// 										className={page.active ? "page-item active" : "page-item"}
// 										onClick={() => paginate(page)}
// 									>
// 										<a
// 											href={() => false}
// 											className={`page-link ${page.url === null ? "false" : ""
// 												}`}
// 											dangerouslySetInnerHTML={{ __html: page.label }}
// 										>
// 										</a>
// 									</li>
// 								) : (
// 									<li
// 										key={`page-${page.label}`}
// 										className={"page-item disabled"}
// 										onClick={() => false}
// 									>
// 										<a className={`page-link disabled`} dangerouslySetInnerHTML={{ __html: page.label }} href={() => false}>
// 										</a>
// 									</li>
// 								);
// 							})}
// 						</ul>
// 					</nav>
// 				</div>
// 			) : null}
// 		</>
// 	);
// }
