import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useEffect, useRef, useState } from "react";
import {
  Add_Commission,
  editHotelCommission,
  viewHotel
} from "services/hotels";
import { fetchSeason, fetchSupplier } from "services/lookups";
import { listPlatforms } from "services/platform";
import Locale from "translations";

import validate from "helpers/validate";
import { store } from "react-notifications-component";
const HotelCommissionInfo = ({
  index,
  CommissionsSelect,
  addCommission,
  SetCommissionsList,
  CommissionsList,
  countries,
  ModelCommissions,
  CommissionsSelectIndex,
  HotelID,
  AllSeasons,
  AllPlatform,
  AllSupplier,
}) => {
  const { backOffice } = Locale;
  const [commissionState, setCommissionState] = useState({
    platformName: HotelID
      ? CommissionsSelect?.name
      : CommissionsSelect?.name?.en,
    platform_type: CommissionsSelect?.platform_type,
    commissionType: CommissionsSelect?.type,
    selectors: "",
    baseCommission: CommissionsSelect?.base_commission,
    safaCommission: CommissionsSelect?.safa_commission,
    platformCommission: CommissionsSelect?.platform_commission,
    specialCode: CommissionsSelect?.special_code,
    platforms: "",
    applyCommission: CommissionsSelect?.apply_type,
  });


  const [season, setSeason] = useState([]);
  const [platform, setPlatform] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const firstRender = useRef(false);

  const [Countries, setCountries] = useState([]);
  const [Seasons, setSeasons] = useState([]);
  const [HotelLevel, setHotelLevel] = useState([]);
  const [CommissionType, setCommissionType] = useState();
  const [platformType, setPlatformType] = useState();

  const [PlatformList, setPlatformList] = useState([]);
  const [ApplyCommission, setApplyCommission] = useState();

  const [PercentageError, setPercentageError] = useState(false);

  const [CountriesWithALL, setCountriesWithALL] = useState([]);
  const [error, setError] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  const checkFormErrors = () => {
    setError({
      ...error,
      ...validate(
        { name: "commission_name", value: commissionState.platformName },
        { required: true, min: 3 }
      ),
      ...validate(
        { name: "platform_type", value: commissionState.platform_type },
        { required: true }
      ),
      ...validate(
        { name: "commission_type", value: commissionState.commissionType },
        { required: true, min: 3 }
      ),
      ...validate(
        { name: "selectors", value: commissionState.selectors },
        { required: true }
      ),
      ...validate(
        { name: "base_commission", value: commissionState.baseCommission },
        { required: true }
      ),
      ...validate(
        { name: "safa_commission", value: commissionState.safaCommission },
        { required: true }
      ),
      ...validate(
        {
          name: "platform_commission",
          value: commissionState.platformCommission,
        },
        { required: true }
      ),
      ...validate(
        { name: "special_code", value: commissionState.specialCode },
        { required: true }
      ),
      ...validate(
        { name: "platform", value: commissionState.platforms },
        { required: true }
      ),
    });
  };


  useEffect(() => {
    let newCountries = [];
    newCountries.push({
      code: "all",
      value: 0,
      label: "All",
    });
    countries.map((country) => newCountries.push(country));

    setCountriesWithALL(newCountries);

    if (ModelCommissions === "edit") {
      getCommissionType(CommissionsSelect?.type);
      getPlateformType(CommissionsSelect?.platform_type);
      getApplyCommission(CommissionsSelect?.apply_type);
      getSelectors(CommissionsSelect?.selectors);
      setPlatformsFormat(CommissionsSelect?.platforms);
      //commissionState
      //CommissionsSelect?.platforms
    }

    async function fetchLookups() {
      const res = await fetchSeason();
      let newFormat = [];
      newFormat.push({
        value: 0,
        label: "All",
      });
      const format = res.seasons.map((t) => {
        newFormat.push({
          value: t.id,
          label: t.name,
        });
      });
      setSeason(newFormat);

      const resplatform = await listPlatforms(null, 1);
      const formatplateform = resplatform.data.platforms.map((t) => ({
        value: t.id,
        label: t.name,
      }));
      setPlatform(formatplateform);

      const supplierRes = await fetchSupplier();

      const formatSupplier = supplierRes.data?.data?.map((t) => ({
        value: t.id,
        label: t.full_name,
      }));
      setSupplier(formatSupplier);
    }
    fetchLookups();
  }, []);

  const getCommissionType = (type) => {
    let Returned = "";

    switch (type) {
      case "base":
        Returned = {
          value: "base",
          label: "Base",
        };
        break;
      case "fixed":
        Returned = {
          value: "fixed",
          label: "Fixed",
        };
        break;
      default:
        Returned = {
          value: "",
          label: "",
        };
        break;
    }

    setCommissionType(Returned);

    return Returned;
  };
  const getPlateformType = (type) => {
    let Returned = "";

    switch (type) {
      case "platform":
        Returned = {
          label: "Through Platform",
          value: "platform",
        };
        break;
      case "supplier":
        Returned = {
          label: "Through Supplier",
          value: "supplier",
        };
        break;
      default:
        Returned = {
          value: "",
          label: "",
        };
        break;
    }

    setPlatformType(Returned);

    return Returned;
  };
  const getApplyCommission = (type) => {
    let Returned = "";

    switch (type) {
      case "add":
        Returned = {
          value: "add",
          label: "Add",
        };
        break;
      case "deduct":
        Returned = {
          value: "deduct",
          label: "Deduct",
        };
        break;
      default:
        Returned = {
          value: "",
          label: "",
        };
        break;
    }

    setApplyCommission(Returned);

    return Returned;
  };
  const getSelectors = (selector) => {
    let arr = [];
    Object.entries(selector).map((item) => {
      arr.push(getSelectorItem(item[0], item[1]));
    });

    setSelectedOptions(arr);

    if (arr.length > 0) {
      setIsOpen(true);
    }

    setCommissionState({
      ...commissionState,
      selectors: arr,
    });
  };

  const getSelectorItem = (selector, values) => {
    let Returned = "";
    switch (selector) {
      case "country":
        Returned =
          values !== ""
            ? {
              value: 1,
              label: "Country",
            }
            : "";
        setCountriesFormat(values);
        break;
      case "season":
        Returned =
          values !== ""
            ? {
              value: 2,
              label: "Season",
            }
            : "";
        setSeasonsFormat(values);
        break;
      case "hotel":
        Returned =
          values !== ""
            ? {
              value: 3,
              label: "Hotel Level",
            }
            : "";
        setHotelLevelFormat(values);
        break;
      default:
        Returned = {
          value: "",
          label: "",
        };
        break;
    }

    return Returned;
  };

  const setCountriesFormat = (countriesInSelector) => {
    if (countriesInSelector !== "") {
      if (countriesInSelector === "all") {
        let arr = [];
        arr.push({
          code: "all",
          value: 0,
          label: "All",
        });
        setCountries(arr);
      } else {
        let arr = [];
        if (HotelID) {
          countriesInSelector.map((country) => {
            let obj = {
              code: country.country_code,
              value: country.country_id,
              label: country.name?.en,
            };
            arr.push(obj);
          });
        } else {
          countriesInSelector.map((country) => {
            arr.push(countries.find((item) => item.value === country));
          });
        }
        setCountries(arr);
      }
    }
  };

  const setSeasonsFormat = (seasonsInSelector) => {
    if (seasonsInSelector !== "") {
      if (seasonsInSelector === "all") {
        let arr = [];
        arr.push({
          value: 0,
          label: "All",
        });
        setSeasons(arr);
      } else {
        let arr = [];

        if (HotelID) {
          seasonsInSelector.map((season) => {
            let obj = {
              label: season.name.en,
              value: season.id,
            };
            arr.push(obj);
          });
        } else {
          seasonsInSelector.map((seasonItem) => {
            arr.push(AllSeasons.find((item) => item.value === seasonItem));
          });
        }

        setSeasons(arr);
      }
    }
  };

  const setHotelLevelFormat = (hotelLevel) => {
    if (hotelLevel !== "") {
      if (hotelLevel === "all") {
        let arr = [];
        arr.push({
          value: 0,
          label: "All",
        });
        setHotelLevel(arr);
      } else {
        let arr = [];

        if (HotelID) {
          hotelLevel.map((level) => {
            let obj = {
              label: level.id,
              value: level.id,
            };
            arr.push(obj);
          });
        } else {
          hotelLevel.map((level) => {
            arr.push(HotelLevelCommission.find((item) => item.value === level));
          });
        }

        setHotelLevel(arr);
      }
    }
  };
  const setPlatformsFormat = (platformSelection) => {
    let arr = [];
    if (platformSelection !== "") {
      if (HotelID && CommissionsSelect?.platform_type != "supplier") {
        platformSelection.map((platformItem) => {
          let obj = {
            label: platformItem.name,
            value: platformItem.id,
          };
          arr.push(obj);
        });
      } else {
        platformSelection.map((platformItem) => {
          arr.push(AllPlatform.find((item) => item.value === platformItem));
        });
      }
      //AllSupplier
      setPlatformList(arr);
    }
    if (CommissionsSelect?.platform_type == "supplier") {
      platformSelection.map((platformItem) => {
        arr.push(
          ...AllSupplier.filter(
            (item) => item.value == platformItem.supplier_id
          )
        );
      });
      setPlatformList(arr);
    }
  };

  const handleChange = (e) => {
    if ("target" in e) {
      const value = e.target.value;
      const name = e.target.name;
      setCommissionState({ ...commissionState, [name]: value });
    } else {
      const value = e.value;
      const name = e.name;
      if (name === "country") {
        if (e.value !== null) {
          let arr = [];
          if (value.find((item) => item.code === "all")) {
            arr.push(value.find((item) => item.code === "all"));
            setCountries(arr);
          } else {
            setCountries(value);
          }
        } else {
          setCountries(value);
        }

        setCommissionState({
          ...commissionState,
          city: "",
          [name]: value,
        });
      } else {
        setCommissionState({ ...commissionState, [name]: value });
      }
    }
    //setCommissionsSelect({ ...commissionState});
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleButton = () => setIsOpen(!isOpen);
  const Selectors = [
    {
      value: 1,
      label: "Country",
    },
    {
      value: 2,
      label: "Season",
    },
    {
      value: 3,
      label: "Hotel Level",
    },
  ];

  const commissionTypes = [
    {
      value: "base",
      label: "Base",
    },
    // {
    //   value: 'fixed',
    //   label: "Fixed",
    // }
  ];
  const applyCommission = [
    {
      value: "add",
      label: "Add",
    },
    {
      value: "deduct",
      label: "Deduct",
    },
  ];

  const types = [
    // {
    //   value: 'platform',
    //   label: "Through Platform",
    // },
    {
      value: "supplier",
      label: "Through Supplier",
    },
  ];
  const HotelLevelCommission = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedCommissionType, setSelectedCommissionType] = useState("%");

  const addNewCommission = async () => {
    let CommissionsListArr = "";
    if (ModelCommissions === "edit") {
      if (HotelID !== null) {
        let clonedCommissions = [...CommissionsList];
        clonedCommissions.splice(CommissionsSelectIndex, 1);

        CommissionsListArr = clonedCommissions;

        let payload = {
          name: {
            en: commissionState.platformName,
            ar: commissionState.platformName,
          },
          type: CommissionType.value,
          platform_type: platformType?.value,
          platforms: PlatformList?.map((item) => item?.value),

          base_commission: commissionState.baseCommission,
          safa_commission: commissionState.safaCommission,
          platform_commission: commissionState.platformCommission,
          apply_type: ApplyCommission.value,

          special_code: commissionState.specialCode,
          selectors: {
            country: Countries.find((item) => item.value === 0)
              ? "all"
              : Countries.length > 0
                ? Countries.map((item) => item.value)
                : "",
            hotel: HotelLevel.find((item) => item.value === 0)
              ? "all"
              : HotelLevel.length > 0
                ? HotelLevel.map((item) => item.value)
                : "",
            season: Seasons.find((item) => item.value === 0)
              ? "all"
              : Seasons.length > 0
                ? Seasons.map((item) => item.value)
                : "",
          },
        };


        const res = await editHotelCommission(
          HotelID,
          CommissionsSelect.id,
          payload
        );
        if (res.status === 200) {
          store.addNotification({
            title: "Done!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
              pauseOnHover: true,
            },
          });
          const resplatform = await viewHotel(HotelID);
          SetCommissionsList(resplatform?.data?.hotel?.commissions);
          addCommission(index);
        }
      } else {
        let clonedCommissions = [...CommissionsList];
        clonedCommissions.splice(CommissionsSelectIndex, 1);

        CommissionsListArr = clonedCommissions;

        let payload = {
          name: {
            en: commissionState.platformName,
            ar: commissionState.platformName,
          },
          type: CommissionType.value,
          platforms: PlatformList?.map((item) => item.value),
          base_commission: commissionState.baseCommission,
          safa_commission: commissionState.safaCommission,
          platform_commission: commissionState.platformCommission,
          special_code: commissionState.specialCode,
          apply_type: ApplyCommission.value,

          selectors: {
            country: Countries.find((item) => item.value === 0)
              ? "all"
              : Countries.length > 0
                ? Countries.map((item) => item.value)
                : "",
            hotel: HotelLevel.find((item) => item.value === 0)
              ? "all"
              : HotelLevel.length > 0
                ? HotelLevel.map((item) => item.value)
                : "",
            season: Seasons.find((item) => item.value === 0)
              ? "all"
              : Seasons.length > 0
                ? Seasons.map((item) => item.value)
                : "",
          },
        };

        CommissionsListArr.push(payload);
        SetCommissionsList(CommissionsListArr);
        addCommission(index);
      }
    } else {
      if (HotelID !== null) {
        CommissionsListArr = CommissionsList;

        let payload = {
          name: {
            en: commissionState.platformName,
            ar: commissionState.platformName,
          },
          type: CommissionType.value,
          apply_type: ApplyCommission.value,

          platform_type: commissionState.platform_type?.value,
          platforms: PlatformList?.map((item) => item.value),
          base_commission: commissionState.baseCommission,
          safa_commission: commissionState.safaCommission,
          platform_commission: commissionState.platformCommission,
          special_code: commissionState.specialCode,
          selectors: {
            country: Countries.find((item) => item.value === 0)
              ? "all"
              : Countries.length > 0
                ? Countries.map((item) => item.value)
                : "",
            hotel: HotelLevel.find((item) => item.value === 0)
              ? "all"
              : HotelLevel.length > 0
                ? HotelLevel.map((item) => item.value)
                : "",
            season: Seasons.find((item) => item.value === 0)
              ? "all"
              : Seasons.length > 0
                ? Seasons.map((item) => item.value)
                : "",
          },
        };

        const res = await Add_Commission(HotelID, payload);
        if (res.status === 200) {
          store.addNotification({
            title: "Done!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
              pauseOnHover: true,
            },
          });
          const resplatform = await viewHotel(HotelID);
          SetCommissionsList(resplatform?.data?.hotel?.commissions);
          addCommission(index);
        }
      } else {
        CommissionsListArr = CommissionsList;

        let objectSubmitted = {
          name: {
            en: commissionState.platformName,
            ar: commissionState.platformName,
          },
          platform_type: commissionState.platform_type?.value
            ? commissionState.platform_type?.value
            : "supplier",
          type: CommissionType.value,
          platforms: PlatformList?.map((item) => item.value),
          base_commission: commissionState.baseCommission,
          safa_commission: commissionState.safaCommission,
          platform_commission: commissionState.platformCommission,
          apply_type: commissionState.applyCommission.value,

          special_code: commissionState.specialCode,
          selectors: {
            country: Countries.find((item) => item.value === 0)
              ? "all"
              : Countries.length > 0
                ? Countries.map((item) => item.value)
                : "",
            hotel: HotelLevel.find((item) => item.value === 0)
              ? "all"
              : HotelLevel.length > 0
                ? HotelLevel.map((item) => item.value)
                : "",
            season: Seasons.find((item) => item.value === 0)
              ? "all"
              : Seasons.length > 0
                ? Seasons.map((item) => item.value)
                : "",
          },
        };

        CommissionsListArr.push(objectSubmitted);
        SetCommissionsList(CommissionsListArr);
        addCommission(index);
      }
    }
  };

  useEffect(() => {
    if (firstRender.current) {
      if (
        commissionState.platformCommission !== "" &&
        Number(commissionState.safaCommission) +
        Number(commissionState.platformCommission) ===
        100
      ) {
        setPercentageError(false);
      } else {
        setPercentageError(true);
      }
    }
    firstRender.current = true;
  }, [commissionState.platformCommission, commissionState.safaCommission]);
  const [typeState, setTypeState] = useState("");

  const submit = () => {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  };
  return (
    <>
      <div className="row custom--row">
        <div className="col-md-6">
          <TextField
            type="text"
            label={backOffice.commissionName}
            placeholder={backOffice.commissionName}
            name="commission_name"
            value={commissionState.platformName}
            onChange={(e) => {
              setCommissionState({
                ...commissionState,
                platformName: e.target.value,
              });
              setError({
                ...error,
                ...validate(e.target, {
                  required: true,
                  min: 3,
                }),
              });
            }}
            min="3"
            color={
              error.commission_name?.required || error.commission_name?.min
                ? "danger"
                : ""
            }
            errors={error.commission_name}
          />
        </div>
        <div className="col-md-6">
          <SelectField
            label={backOffice.type}
            placeholder={backOffice.type}
            options={types}
            name="platform_type"
            value={platformType}
            defaultValue={
              ModelCommissions != "edit" && {
                value: "supplier",
                label: "Through Supplier",
              }
            }
            onChange={(e) => {
              handleChange({ name: "platform_type", value: e });
              setCommissionState({
                ...commissionState,
                platform_type: e,
              });

              setTypeState(e);
              setPlatformType(e);
              // ModelCommissions=='edit'?PlatformList.splice(0,PlatformList.length):commissionState.platforms.splice(0,commissionState.platforms.length)
            }}
          />
        </div>
        <div className="col-md-6">
          <SelectField
            multi
            label={
              ModelCommissions == "edit"
                ? commissionState.platform_type == "platform"
                  ? backOffice.platformCommissions
                  : backOffice.supplier
                : typeState.value == "platform"
                  ? backOffice.platformCommissions
                  : backOffice.supplier
            }
            placeholder={
              typeState?.value == "platform"
                ? backOffice.select + " " + backOffice.platformCommissions
                : backOffice.select + " " + backOffice.supplier
            }
            options={
              commissionState?.platform_type == "platform" ||
                typeState.value == "platform"
                ? platform
                : supplier
            }
            name="platform"
            color={error.platform?.required ? "danger" : ""}
            errors={error.platform}
            onChange={(e) => {
              handleChange({ name: "platform", value: e });
              setCommissionState({
                ...commissionState,
                platforms: e,
              });
              setPlatformList(e);
              setError({
                ...error,
                ...validate(
                  { name: "platform", value: e },
                  {
                    required: true,
                  }
                ),
              });
            }}
            value={PlatformList}
          />
        </div>
        <div className="col-md-6">
          <SelectField
            label={backOffice.commissionType}
            placeholder={backOffice.select + " " + backOffice.commissionType}
            options={commissionTypes}
            name="commission_type"
            value={CommissionType}
            onChange={(e) => {
              handleChange({ name: "commission_type", value: e });
              setCommissionState({
                ...commissionState,
                commissionType: e,
              });
              setCommissionType(e);
              setSelectedCommissionType(
                e.value == "fixed" ? backOffice.SAR : "%"
              );
            }}
          />
        </div>
      </div>
      <div className="row custom--row">
        <div className="col-md-6">
          <TextField
            type="text"
            label={backOffice.specialCode}
            placeholder={backOffice.specialCode}
            name="special_code"
            value={commissionState.specialCode}
            onChange={(e) => {
              setCommissionState({
                ...commissionState,
                specialCode: e.target.value,
              });

              setError({
                ...error,
                ...validate(e.target, {
                  required: true,
                }),
              });
            }}
            color={error.special_code?.required ? "danger" : ""}
            errors={error.special_code}
          />
        </div>
        <div className="col-md-6">
          <SelectField
            multi
            label={backOffice.selectors}
            placeholder={backOffice.select + " " + backOffice.selectors}
            options={Selectors}
            name="selectors"
            value={commissionState.selectors}
            onChange={(e) => {
              setIsOpen(true);
              setCommissionState({
                ...commissionState,
                selectors: e,
              });
              setSelectedOptions(e);

              setError({
                ...error,
                ...validate(
                  { name: "selectors", value: e },
                  {
                    required: true,
                  }
                ),
              });
            }}
            color={error.selectors?.required ? "danger" : ""}
            errors={error.selectors}
          />
        </div>
      </div>

      {isOpen == true && selectedOptions?.length > 0 ? (
        <div
          className="row custom--row p-2"
          id="selectorsConfig"
          style={{ backgroundColor: "#EDF2F7" }}
        >
          <div className="col-12 text-center gray h5 mb-0">
            {backOffice.selectorsConfiguration}
          </div>

          {selectedOptions.find((item) => item.label === "Country") ? (
            <div className="col-12">
              <SelectField
                multi
                label={backOffice.country}
                placeholder={backOffice.select + " " + backOffice.country}
                options={CountriesWithALL}
                name="countries"
                value={Countries}
                onChange={(e) => {
                  handleChange({ name: "country", value: e });
                }}
              />
            </div>
          ) : null}

          {selectedOptions.find((item) => item.label === "Season") ? (
            <div className="col-12">
              <SelectField
                multi
                label={backOffice.season}
                placeholder={backOffice.select + " " + backOffice.season}
                options={season}
                value={Seasons}
                name="season"
                onChange={(e) => {
                  handleChange({ name: "season", value: e });
                  if (e !== null) {
                    let arr = [];
                    if (e.find((item) => item.value === 0)) {
                      arr.push(e.find((item) => item.value === 0));
                      setSeasons(arr);
                    } else {
                      setSeasons(e);
                    }
                  } else {
                    setSeasons(e);
                  }
                }}
              />
            </div>
          ) : null}

          {selectedOptions.find((item) => item.label === "Hotel Level") ? (
            <div className="col-12">
              <SelectField
                multi
                label={backOffice.hotelLevel}
                placeholder={backOffice.select + " " + backOffice.hotelLevel}
                options={HotelLevelCommission}
                name="hotelLevel"
                value={HotelLevel}
                onChange={(e) => {
                  handleChange({ name: "hotelLevel", value: e });
                  if (e !== null) {
                    let arr = [];
                    if (e.find((item) => item.value === 0)) {
                      arr.push(e.find((item) => item.value === 0));
                      setHotelLevel(arr);
                    } else {
                      setHotelLevel(e);
                    }
                  } else {
                    setHotelLevel(e);
                  }
                }}
              />
            </div>
          ) : null}
        </div>
      ) : null}
      {/***********Start Apply Commtion ************* */}
      <div className="row custom--row">
        <div className="col-md-4">
          <SelectField
            label={backOffice.applyCommission}
            placeholder={backOffice.select + " " + backOffice.applyCommission}
            options={applyCommission}
            name="apply_commission"
            value={ApplyCommission}
            onChange={(e) => {
              handleChange({ name: "apply_commission", value: e });
              setCommissionState({
                ...commissionState,
                applyCommission: e,
              });
              setApplyCommission(e);
            }}
          />
        </div>
      </div>
      {/***********End Apply Commtion ************* */}

      <div className="row custom--row">
        <div className="col-md-4">
          <TextField
            type="number"
            min={0}
            max={100}
            label={
              selectedCommissionType === "%"
                ? backOffice.baseCommission
                : backOffice.fixedCommission
            }
            placeholder={
              selectedCommissionType === "%"
                ? backOffice.baseCommission
                : backOffice.fixedCommission
            }
            name="base_commission"
            value={commissionState.baseCommission}
            onChange={(e) => {
              setCommissionState({
                ...commissionState,
                baseCommission: e.target.value,
              });

              setError({
                ...error,
                ...validate(e.target, {
                  required: true,
                }),
              });
            }}
            extraTextPosition="append"
            extraText={selectedCommissionType}
            color={
              error.base_commission?.required ||
                commissionState.baseCommission > 100
                ? "danger"
                : ""
            }
            errors={error.base_commission}
          />
          {commissionState.baseCommission > 100 ? (
            <p className="text-danger">*Must be equal or less than 100</p>
          ) : null}
        </div>

        <div className="col-md-4">
          <TextField
            type="number"
            label={backOffice.safaCommission}
            placeholder={backOffice.safaCommission}
            name="safa_commission"
            value={commissionState.safaCommission}
            onChange={(e) => {
              setCommissionState({
                ...commissionState,
                safaCommission: e.target.value,
              });

              setError({
                ...error,
                ...validate(e.target, {
                  required: true,
                }),
              });
            }}
            color={PercentageError ? "danger" : ""}
            extraTextPosition="append"
            extraText="%"
            //color={error.safa_commission?.required ? "danger" : ""}
            errors={error.safa_commission}
          />
        </div>

        <div className="col-md-4">
          <TextField
            type="number"
            min="0"
            label={
              ModelCommissions == "edit"
                ? commissionState.platform_type == "platform"
                  ? backOffice.platformCommission
                  : backOffice.supplierCommission
                : typeState.value == "platform"
                  ? backOffice.platformCommission
                  : backOffice.supplierCommission
            }
            placeholder={
              typeState?.value == "platform"
                ? backOffice.platformCommission
                : backOffice.supplierCommission
            }
            name="platform_commission"
            value={commissionState.platformCommission}
            onChange={(e) => {
              setCommissionState({
                ...commissionState,
                platformCommission: e.target.value,
              });
            }}
            color={PercentageError ? "danger" : ""}
            extraTextPosition="append"
            extraText="%"
            errors={error.platform_commission}
          />
        </div>
      </div>
      {PercentageError ? (
        <p className="text-danger">
          SAFA COMMISSION + PLATFORM COMMISSION Must equal 100
        </p>
      ) : null}
      <div className="row custom--row mt-2 flex-row-reverse">
        <div className="col-md-3">
          <button
            disabled={
              (commissionState.selectors !== null ||
                commissionState.selectors !== "") &&
                (Countries?.length > 0 ||
                  Seasons?.length > 0 ||
                  HotelLevel?.length > 0) &&
                commissionState.baseCommission !== undefined &&
                commissionState.safaCommission !== undefined &&
                commissionState.platformCommission !== undefined &&
                commissionState.baseCommission <= 100 &&
                !PercentageError
                ? false
                : true
            }
            className="btn btn-sbs-primary w-100"
            onClick={addNewCommission}
          >
            {backOffice.add}
          </button>
        </div>
      </div>
    </>
  );
};

export default HotelCommissionInfo;
