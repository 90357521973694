import hotelImg from "assets/images/Hotels.svg";
import visaImg from "assets/images/visaOline.svg";
import { store } from "react-notifications-component";
import { Link, useHistory } from "react-router-dom";
import Switch from "react-switch";
import { changeOfferStatus, listSuppliers } from "services/suppliers";
import Locale from "translations";

export default function TableContent({
	setSuppliersList,
	supplierItem,
	index,
	filter,
}) {
	const { backOffice } = Locale;
	const history = useHistory();
	const locale = localStorage.getItem("currentLocale") || "en";

	const changeOffer = async (id, e) => {
		const res = await changeOfferStatus(id, e);

		if (res.status === 200) {
			const rese = await listSuppliers({ ...filter });

			setSuppliersList(rese?.data?.data);
			store.addNotification({
				title: "Done!",
				message: "Done",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	return (
		<>
			<tr key={supplierItem.id}>
				<td>{supplierItem.name}</td>
				<td>{supplierItem?.country?.name} </td>
				<td className="img-service">
					{supplierItem?.services?.some((item) => item.name === "Hotels") ? (
						<div className="mx-2">
							<img src={hotelImg} alt="" width={22} />
						</div>
					) : null}
					{supplierItem?.services?.some(
						(item) => item.name === "Online visa"
					) ? (
						<div>
							<img src={visaImg} alt="" width={22} />
						</div>
					) : null}
				</td>

				<td>{supplierItem.created_at}</td>
				<td>
					<p
						className={
							supplierItem.status == "Active" ? "text-success" : "text-danger"
						}
					>
						{supplierItem.status == "Active"
							? backOffice.active
							: backOffice.inActive}
					</p>
				</td>
				<td>
					<Switch
						height={24}
						width={45}
						handleDiameter={20}
						className="mx-2 align-middle"
						onColor="#1777fb"
						onChange={() =>
							changeOffer(supplierItem.id, !+supplierItem.is_offer)
						}
						checked={supplierItem.is_offer}
					/>
					{/* <a
						href={() => false}
						className="d-inline-block action--btn Button-Pointer mx-3"
						style={{ cursor: "pointer" }}
						onClick={() => {}}
					>
						{+supplierItem.is_offer ? (
							<i class="fa fa-check fa-lg text-success" aria-hidden="true"></i>
						) : (
							<i class="fa fa-ban fa-lg text-danger" aria-hidden="true"></i>
						)}
					</a> */}
				</td>
				<td>
					<Link
						// to={`suppliers/1/1/reservation`}
						to={`/suppliers/${supplierItem?.name}/${supplierItem?.id}/reservation`}
					>
						<i
							class="fas fa-calendar-alt fa-lg mx-1 pointer"
							style={{ color: "#B17A00" }}
						></i>
					</Link>
					<a
						href={() => false}
						className="d-inline-block action--btn Button-Pointer"
						style={{ cursor: "pointer" }}
						onClick={() => {
							history.push({
								pathname: `/suppliers/edit/${supplierItem?.id}`,
								state: { detail: supplierItem },
							});
						}}
					>
						<i className="fas fa-edit fa-lg"></i>
					</a>

					<Link to={`/suppliers/payment/${supplierItem?.id}`}>
						<i
							class="fas fa-wallet mx-1 fa-lg pointer"
							style={{ color: "#00B545" }}
						></i>
					</Link>

					{/* {supplierItem.status === "Active" ? (
						<button
							// onClick={() => changeStatus(supplierItem.id, "deactivate")}
							className="btn btn-link  btn-lg"
							style={{ cursor: "pointer" }}
						>
							<i class="fas fa-stop-circle text-danger"></i>
						</button>
					) : (
						<button
							// onClick={() => changeStatus(supplierItem.id, "activate")}
							className="btn btn-link  btn-lg"
							style={{ cursor: "pointer" }}
						>
							<i class="fas fa-play-circle text-success"></i>
						</button>
					)} */}
				</td>
			</tr>
		</>
	);
}
