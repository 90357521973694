import Model from "components/model";
import DateRangeField from "components/shared/DateRangeField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { formatPluckRoomCalender } from "data/calendar";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router";
import { addPluckRoomCalender } from "services/calendar";
import Locale from "translations";
export default function BulkEdit({
	modal,
	setModal,
	toggle,
	ratesPlans,
	countries,
	getViewCalendar,
	filtersData,
	idRoom,
}) {
	const { id } = useParams();
	const { hotelLayout } = Locale;
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [filters, setFilters] = useState({
		room_id: idRoom,
		bulkValidFrom: undefined,
		bulkValidTo: undefined,
		ratePlan: "",
		customRate: "",
		days: {
			saturday: 0,
			sunday: 0,
			monday: 0,
			tuesday: 0,
			wednesday: 0,
			thursday: 0,
			friday: 0,
		},
		roomsNumber: "",
		ratePrice: "",
	});

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "ratePlan", value: filters.ratePlan },
				{ required: true }
			),
			...validate(
				{ name: "customRate", value: filters.customRate },
				{ required: true }
			),
			...validate(
				{ name: "roomsNumber", value: filters.roomsNumber },
				{ required: true }
			),
			...validate(
				{ name: "ratePrice", value: filters.ratePrice },
				{ required: true }
			),
		});
	};

	const handleSubmit = async () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	useEffect(() => {
		const submit = async () => {
			if (isFormValid(errors)) {
				const data = formatPluckRoomCalender({ ...filters, room_id: idRoom });
				const response = await addPluckRoomCalender(id, data);

				if (response.hasOwnProperty("message")) {
					store.addNotification({
						title: "Info!",
						message: "done",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
					setModal({
						isOpen: !modal.isOpen,
					});

					getViewCalendar({ ...filtersData, id: id });
				}
			}
		};
		submit();
	}, [isErrorLoaded]);

	const daysCheck = (e) => {
		let value = e.target.value;
		let checked = e.target.checked;
		let daysList = filters.days;
		if (checked) {
			daysList[value] = checked ? 1 : 0;
		} else {
			daysList[value] = 0;
		}

		setFilters({
			...filters,
			days: daysList,
		});
	};

	return (
		<>
			<Model
				isOpen={modal.isOpen}
				title={modal.title}
				toggle={toggle}
				hasFooter={false}
				className="bulk-edit"
				// size="lg"
			>
				<div className="py-3 w-100">
					{/* Valid From */}
					<div className="col-md-12 mb-4">
						<DateRangeField
							label={hotelLayout.calendar.date}
							startDate={filters.bulkValidFrom}
							endDate={filters.bulkValidTo}
							startDatePlaceholderText="DD/MM/YYY"
							endDatePlaceholderText="DD/MM/YYY"
							onDatesChange={({ startDate, endDate }) => {
								setFilters({
									...filters,
									bulkValidFrom: moment(startDate)._d,
									bulkValidTo: endDate
										? moment(endDate)._d
										: moment(startDate).add(1, "d")._d,
								});
							}}
							isOutsideRange={() => false}
						/>
					</div>
					<div className="col-md-12 mb-4">
						<SelectField
							label={hotelLayout.calendar.ratePlan}
							placeholder={hotelLayout.calendar.ratePlanName}
							value={filters.ratePlan}
							name="ratePlan"
							id="rate-name"
							multi={true}
							options={ratesPlans}
							onChange={(e) => {
								setFilters({
									...filters,
									ratePlan: e,
								});
								setErrors({
									...errors,
									...validate(
										{ name: "ratePlan", value: e },
										{ required: true }
									),
								});
							}}
							onBlur={() =>
								setErrors({
									...errors,
									...validate(
										{
											name: "ratePlan",
											value: filters.ratePlan,
										},
										{ required: true }
									),
								})
							}
							color={errors?.ratePlan?.required ? "danger" : ""}
							errors={errors?.ratePlan}
						/>
					</div>
					<div className="col-md-12 mb-4">
						<SelectField
							label={hotelLayout.calendar.customRate}
							placeholder={hotelLayout.calendar.selectCustomRate}
							value={filters.customRate}
							name="customRate"
							id="custom-rate"
							multi={true}
							options={countries}
							onChange={(e) => {
								setFilters({
									...filters,
									customRate: e,
								});
								setErrors({
									...errors,
									...validate(
										{ name: "customRate", value: e },
										{ required: true }
									),
								});
							}}
							onBlur={() =>
								setErrors({
									...errors,
									...validate(
										{
											name: "customRate",
											value: filters.customRate,
										},
										{ required: true }
									),
								})
							}
							color={errors?.customRate?.required ? "danger" : ""}
							errors={errors?.customRate}
						/>
					</div>
					<div className="col-md-12 mb-4">
						<h6 className="pb-2 font-weight-bold">
							{hotelLayout.calendar.whichDaysWeek}
						</h6>
						<div class="form-check form-check-inline px-1">
							<input
								class="form-check-input"
								type="checkbox"
								id="room1"
								value="monday"
								onChange={daysCheck}
								checked={filters.days?.monday}
							/>
							<label class="form-check-label pointer" for="room1">
								Mon
							</label>
						</div>
						<div class="form-check form-check-inline px-1">
							<input
								class="form-check-input"
								type="checkbox"
								id="rate1"
								value="tuesday"
								onChange={daysCheck}
								checked={filters.days?.tuesday}
							/>
							<label class="form-check-label" for="rate1">
								Tue
							</label>
						</div>
						<div class="form-check form-check-inline px-1">
							<input
								class="form-check-input"
								type="checkbox"
								id="rate2"
								value="wednesday"
								onChange={daysCheck}
								checked={filters.days?.wednesday}
							/>
							<label class="form-check-label" for="rate2">
								Wed
							</label>
						</div>
						<div class="form-check form-check-inline px-1">
							<input
								class="form-check-input"
								type="checkbox"
								id="rate3"
								value="thursday"
								onChange={daysCheck}
								checked={filters.days?.thursday}
							/>
							<label class="form-check-label" for="rate3">
								Thu
							</label>
						</div>
						<div class="form-check form-check-inline px-1">
							<input
								class="form-check-input"
								type="checkbox"
								id="rate4"
								value="friday"
								onChange={daysCheck}
								checked={filters.days?.friday}
							/>
							<label class="form-check-label" for="rate4">
								Fri
							</label>
						</div>
						<div class="form-check form-check-inline px-1">
							<input
								class="form-check-input"
								type="checkbox"
								id="rate5"
								value="saturday"
								onChange={daysCheck}
								checked={filters.days?.saturday}
							/>
							<label class="form-check-label" for="rate5">
								Sat
							</label>
						</div>
						<div class="form-check form-check-inline px-1">
							<input
								class="form-check-input"
								type="checkbox"
								id="rate6"
								value="sunday"
								onChange={daysCheck}
								checked={filters.days?.sunday}
							/>
							<label class="form-check-label" for="rate6">
								Sun
							</label>
						</div>
					</div>
					<div className="col-md-12 mb-4">
						<TextField
							label={hotelLayout.calendar.numberRoomstoSell}
							placeholder={hotelLayout.calendar.roomsNumber}
							value={filters.roomsNumber}
							name="roomsNumber"
							type={"Number"}
							id="rooms-number"
							onChange={(e) => {
								setFilters({
									...filters,
									roomsNumber: e.target.value,
								});
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
									}),
								});
							}}
							color={errors?.roomsNumber?.required ? "danger" : ""}
							errors={errors?.roomsNumber}
						/>
						<p className="font-12">
							{hotelLayout.calendar.changesWillBeMedia}:{" "}
							{moment(filters.bulkValidFrom).format("MMMM d, y")}
							{" - "}
							{moment(filters.bulkValidTo).format("MMMM d, y")}
						</p>
					</div>
					<div className="col-md-12 mb-4">
						<TextField
							label={hotelLayout.calendar.ratePrice}
							placeholder={hotelLayout.calendar.roomsNumber}
							value={filters.ratePrice}
							type={"Number"}
							name="ratePrice"
							id="rate-price"
							onChange={(e) => {
								setFilters({
									...filters,
									ratePrice: e.target.value,
								});
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
									}),
								});
							}}
							color={errors?.ratePrice?.required ? "danger" : ""}
							errors={errors?.ratePrice}
						/>
						<p className="font-12">
							{hotelLayout.calendar.changesWillBeMedia}:{"  "}
							{moment(filters.bulkValidFrom).format("MMMM d, y")}
							{" - "}
							{moment(filters.bulkValidTo).format("MMMM d, y")}
						</p>
					</div>
					<div className="submit-btn d-flex flex-row-reverse">
						<button
							className="btn btn-primary mx-1 px-5"
							onClick={handleSubmit}
						>
							{hotelLayout.calendar.saveChanges}
						</button>
						<button className="btn btn-secondary mx-1" onClick={toggle}>
							{hotelLayout.calendar.cancel}
						</button>
					</div>
				</div>
			</Model>
		</>
	);
}
