import { useEffect, useState } from "react";
import { listBranches } from "services/companies";

export const LooksupBranches = (id) => {
  const [Branches, setBranches] = useState([]);

  useEffect(() => {
    async function fetchLookups() {
      const res = await listBranches(id , {});
      const formatted = res.data.data.map((branch) => ({
        value: branch.id,
        label: branch.name,
      }));
      setBranches(formatted);
    }
    fetchLookups();
  }, []);

  return Branches;
};
export default LooksupBranches;
