import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
	addBankConfigration,
	editBankConfigration,
	fetchImagesBank,
	viewBankConfigrations,
} from "services/bankConfigrations";
import { uploadFile } from "services/companies";
import Locale from "translations";

export default function MakeBankConfigrations() {
	const { requests } = Locale;
	const [countries, setCountries] = useState([]);
	const { locale, allCountries, allCurrencies } = useGlobalState();
	const [currencies, setCurrencies] = useState([]);
	const [errors, setErrors] = useState({});
	const [IsImgChanged, setIsImgChanged] = useState(false);
	const [selectedCountries, setselectedCountries] = useState([]);
	const { status, id } = useParams();
	let history = useHistory();
	const [bankInfo, setBankInfo] = useState({
		currency: "",
		bank_name: "",
		beneficiary: " ",
		branch: "",
		iban: "",
		account_no: "",
		swift: "",
		logo: "",
	});

	useEffect(() => {
		async function currenciesLookup() {
			const format = allCurrencies?.map((t) => ({
				value: t.id,
				label: t.currency_code,
			}));
			setCurrencies(format);
		}
		currenciesLookup();
	}, [allCurrencies]);

	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);

	const removeCountry = (id) => {
		let newSelectedCountries = selectedCountries.filter(
			(country) => country.value != id
		);
		setselectedCountries(newSelectedCountries);
	};

	const checkFormErrors = () => {
		let SubmitError = {};
		Object.keys(bankInfo).forEach((key) => {
			SubmitError = {
				...SubmitError,
				...validate(
					{
						name: key,
						value: bankInfo[key],
					},
					{ required: true }
				),
			};
		});
		if (selectedCountries.length == 0) {
			SubmitError = {
				...SubmitError,
				Country: { required: true },
			};
		}
		setErrors(() => SubmitError);
		return isFormValid(SubmitError);
	};

	// const addBank = async () => {
	//   checkFormErrors()
	//   let formData = new FormData();
	//   for (const key in bankInfo) {
	//     if (key != "displayImg" && key != "currency") {
	//       formData.append(`${key}`, bankInfo[key])
	//     }
	//   }
	//   formData.append("currency", bankInfo.currency.label)
	//   selectedCountries.forEach((item, index) => {
	//     formData.append(`countries[${index}]`, item.value)
	//   })
	//   if (isFormValid(errors)) {
	//     const res = await addBankConfigration(formData)
	//     if (res.status === 200 || res.  === 201) {
	//       history.push(`/bank-configuration`)
	//     }
	//   }
	// }

	const addBank = async () => {
		let formData = new FormData();
		formData.append("file", bankInfo.logo);
		let isImgSelected = "";
		if (bankInfo.logo) {
			isImgSelected = await uploadFile(formData);
		} else {
			setErrors({
				...errors,
				logo: { required: true },
			});
		}

		if (checkFormErrors() && isImgSelected.status === 200) {
			const res = await addBankConfigration({
				...bankInfo,
				countries: selectedCountries.map((c) => c.value),
				currency: bankInfo.currency.label,
				logo: isImgSelected.data.uuid,
			});
			if (res.status === 200 || res.status === 201) {
				history.push(`/bank-configuration`);
			}
		}
	};

	const editBank = async () => {
		let isImgSelected = "";
		if (IsImgChanged) {
			let formData = new FormData();
			formData.append("file", bankInfo.logo);
			isImgSelected = await uploadFile(formData);
		}
		if (checkFormErrors()) {
			const res = await editBankConfigration({
				...bankInfo,
				countries: selectedCountries.map((c) => c.value),
				currency: bankInfo.currency.label,
				logo: isImgSelected?.data?.uuid ?? bankInfo.logo,
			});
			if (res.status === 200 || res.status === 201) {
				history.push(`/bank-configuration`);
			}
		}
	};

	useEffect(() => {
		const viewBank = async () => {
			const res = await viewBankConfigrations(id);
			if (res.status === 200) {
				const resImg = await fetchImagesBank(res.data.data.logo);
				if (resImg) {
					setBankInfo({
						...res.data.data,
						currency: { value: 1, label: res.data.data.currency },
						logo: res.data.data.logo,
						displayImg: resImg,
					});
				}

				const format = res.data.data.countries.map((t) => ({
					value: t.country_id,
					label: t.name,
				}));
				setselectedCountries(format);
			}
		};

		if (status === "edit") {
			viewBank();
		}
	}, []);

	return (
		<div className="px-3">
			<div className="col-4">
				<SelectField
					hasLabel={true}
					placeholder={requests.Currency}
					name="status"
					label={requests.Currency}
					options={currencies}
					value={bankInfo.currency}
					onChange={(e) => {
						setBankInfo({
							...bankInfo,
							currency: e,
						});
						setErrors({
							...errors,
							...validate(
								{ name: "currency", value: e.value },
								{
									required: true,
								}
							),
						});
					}}
					errors={errors?.currency}
					color={errors?.currency?.required ? "danger" : ""}
				/>
			</div>
			<div className="bankConfigrationHeader  p-3 text-visa-header font-weight-bold mt-3">
				<p className="m-0">{requests.Countries}</p>
			</div>
			<div className=" our-border pb-2">
				<div className="col-4">
					<SelectField
						hasLabel={true}
						placeholder={requests.Country}
						options={countries}
						value={null}
						name="status"
						label={requests.Country}
						onChange={(e) => {
							setselectedCountries([...selectedCountries, e]);
							setErrors({
								...errors,
								...validate(
									{ name: "Country", value: e.value },
									{
										required: true,
									}
								),
							});
						}}
						errors={errors?.Country}
						color={errors?.Country?.required ? "danger" : ""}
					/>
				</div>
			</div>

			<div className="row px-3">
				{selectedCountries.map((country) => {
					return (
						<span className="our-border bg-gray p-2 mx-2 col-2 d-flex justify-content-between align-items-center my-2 ">
							{country.label}{" "}
							<i
								class="fas fa-times text-gray mx-2 pointer"
								onClick={() => removeCountry(country.value)}
							></i>
						</span>
					);
				})}
			</div>
			<div className="bankConfigrationHeader  p-3 text-visa-header font-weight-bold mt-3">
				<p className="m-0">{requests.BankDetails}</p>
			</div>

			<div className="row  pb-3 our-border p-0 m-0">
				<div className="col-4">
					<TextField
						type="text"
						placeholder={requests.BankName}
						hasLabel={true}
						label={requests.BankName}
						value={bankInfo.bank_name}
						onChange={(e) => {
							setBankInfo({
								...bankInfo,
								bank_name: e.target.value,
							});
							setErrors({
								...errors,
								...validate(
									{ name: "bank_name", value: e.target.value },
									{
										required: true,
									}
								),
							});
						}}
						errors={errors?.bank_name}
						color={errors?.bank_name?.required ? "danger" : ""}
					/>
				</div>

				<div className="col-4">
					<TextField
						type="text"
						placeholder={requests.Beneficiary}
						hasLabel={true}
						label={requests.Beneficiary}
						value={bankInfo.beneficiary}
						onChange={(e) => {
							setBankInfo({
								...bankInfo,
								beneficiary: e.target.value,
							});
							setErrors({
								...errors,
								...validate(
									{ name: "beneficiary", value: e.target.value },
									{
										required: true,
									}
								),
							});
						}}
						errors={errors?.beneficiary}
						color={errors?.beneficiary?.required ? "danger" : ""}
					/>
				</div>
				<div className="col-4">
					<TextField
						type="text"
						placeholder={requests.Branch}
						hasLabel={true}
						label={requests.Branch}
						value={bankInfo.branch}
						onChange={(e) => {
							setBankInfo({
								...bankInfo,
								branch: e.target.value,
							});
							setErrors({
								...errors,
								...validate(
									{ name: "branch", value: e.target.value },
									{
										required: true,
									}
								),
							});
						}}
						errors={errors?.branch}
						color={errors?.branch?.required ? "danger" : ""}
					/>
				</div>
				<div className="col-4">
					<TextField
						type="text"
						placeholder={requests.IBAN}
						hasLabel={true}
						label={requests.IBAN}
						value={bankInfo.iban}
						onChange={(e) => {
							setBankInfo({
								...bankInfo,
								iban: e.target.value,
							});

							setErrors({
								...errors,
								...validate(
									{ name: "iban", value: e.target.value },
									{
										required: true,
									}
								),
							});
						}}
						errors={errors?.iban}
						color={errors?.iban?.required ? "danger" : ""}
					/>
				</div>
				<div className="col-4">
					<TextField
						type="text"
						placeholder={requests.AccountNo}
						hasLabel={true}
						label={requests.AccountNo}
						value={bankInfo.account_no}
						onChange={(e) => {
							setBankInfo({
								...bankInfo,
								account_no: e.target.value,
							});
							setErrors({
								...errors,
								...validate(
									{ name: "account_no", value: e.target.value },
									{
										required: true,
									}
								),
							});
						}}
						errors={errors?.account_no}
						color={errors?.account_no?.required ? "danger" : ""}
					/>
				</div>

				<div className="col-4">
					<TextField
						type="text"
						placeholder={requests.Swift}
						hasLabel={true}
						label={requests.Swift}
						value={bankInfo.swift}
						onChange={(e) => {
							setBankInfo({
								...bankInfo,
								swift: e.target.value,
							});
							setErrors({
								...errors,
								...validate(
									{ name: "swift", value: e.target.value },
									{
										required: true,
									}
								),
							});
						}}
						errors={errors?.swift}
						color={errors?.swift?.required ? "danger" : ""}
					/>
				</div>
			</div>

			<div className="bankConfigrationHeader p-3 text-visa-header font-weight-bold mt-3">
				<p className="m-0">{requests.Logo}</p>
			</div>

			<div
				className={`${errors?.logo?.required ? "border border-danger" : ""}`}
			>
				{bankInfo.displayImg ? (
					<figure className="d-flex  justify-content-center ">
						<img src={`${bankInfo.displayImg}`} className="col-2" />
					</figure>
				) : null}

				<div className=" py-5  our-border text-center d-flex  justify-content-center">
					<button className="file-btn shape-border py-2">
						{requests.AddPhoto}
						<input
							type="file"
							className="custom-file"
							accept="image/png, image/gif, image/jpeg"
							onChange={(e) => {
								setBankInfo({
									...bankInfo,
									displayImg: URL.createObjectURL(e.target.files[0]),
									logo: e.target.files[0],
								});
								setErrors({
									...errors,
									...validate(
										{ name: "logo", value: e.target.value },
										{
											required: true,
										}
									),
								});

								if (status === "edit") {
									setIsImgChanged(true);
								}
							}}
						/>
					</button>
				</div>
			</div>

			<div className="d-flex  justify-content-end mt-5">
				<button
					className=" cancel-bg p-2 px-4  mx-3"
					onClick={() => {
						history.push(`/bank-configuration`);
					}}
				>
					Back
				</button>

				{status === "add" ? (
					<button
						className="btn companies-btn"
						onClick={() => {
							addBank();
						}}
					>
						Add
					</button>
				) : (
					<button
						className="btn companies-btn"
						onClick={() => {
							editBank();
						}}
					>
						Edit{" "}
					</button>
				)}
			</div>
		</div>
	);
}
