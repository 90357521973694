import React from "react";
import { useParams } from "react-router-dom";
import Branch from "./Branch";
import Group from "./Branch";

export default function ListBranches({ branches, toggleEdit, removeBranch }) {
  const allBranches =
    branches.length > 0 ? (
      branches.map((branch) => (
        <Branch
          branch={branch}
          key={branch.id}
          toggleEdit={toggleEdit}
          removeBranch={removeBranch}
        />
      ))
    ) : (
      <h2>No branches</h2>
    );

  return <div>{allBranches}</div>;
}
