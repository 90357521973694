import axios from "axios";
const authURL = process.env.REACT_APP_API_URL + "/v1/auth";
const URI = process.env.REACT_APP_FILEUPLOAD_URL;
const URL = process.env.REACT_APP_AUTH_API;
const URLAUTH = process.env.REACT_APP_API_URL;

export const emailAvailability = async (data) => {
	return await axios
		.post(`${URL}/check_avaliable_email`, data)
		.then((res) => res)
		.catch((err) => err);
};

export const nameAvailability = async (data) => {
	return await axios
		.post(`${URL}/check_avaliable_company_name`, data)
		.then((res) => res)
		.catch((err) => err);
};

export const activeAccount = async (data) => {
	return await axios
		.post(`${authURL}/active-account`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const verifyEmail = async (data) => {
	return await axios
		.post(`${URL}/check_company_and_user_status`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const userLogin = async (data) => {
	return await axios
		.post(`${URLAUTH}/api/auth/login`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const forgetPass = async (data) => {
	return await axios
		.post(`${URL}/forget-password`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const passReset = async (data) => {
	return await axios
		.post(`${URL}/forget-password/reset`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const accountRegister = async (data) => {
	return await axios
		.post(`${URL}/register`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const uploadFile = async (data) => {
	return await axios
		.post(
			`${URLAUTH}/api/files/upload`,
			data
			// , {
			// 	headers: {
			// 		"system-name": "Files_MS",
			// 		"system-secret": "secret",
			// 		"content-type": "multipart/form-data",
			// 	},
			// }
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchImages = async (id) => {
	return await axios
		.get(`${URI}/fetch/${id}`)
		.then((res) => res.config.url)
		.catch((err) => err.response);
};

export const getPermissions = async () => {
	return await axios
		.get(`${URLAUTH}/api/auth/my-permissions`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const changePasswordApi = async (data) => {
	return await axios
		.post(`${URLAUTH}/api/team-management/change-password`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
