import DatePickerField from "components/shared/DatePickerField";
import TextField from "components/shared/TextField";
import validate from "helpers/validate";

const PaymentType = ({ payment, setPayment, errors, setErrors }) => {
	return (
		<div className="row payment-type-row">
			<div className="col-12 d-flex align-items-center justify-content-center payment-type ">
				<i class="fas fa-university"></i>
				{payment.transaction_type?.value == 2 ? (
					<span>Transfer</span>
				) : (
					<span>Cheque</span>
				)}
			</div>
			<div className="col-3 ">
				<TextField
					type="text"
					label={"Bank Name"}
					placeholder={"Enter Bank Name"}
					name="bankName"
					value={payment.transfer.bankName}
					onChange={(e) => {
						setErrors({
							...errors,
							...validate(e.target, {
								required: true,
							}),
						});
						setPayment({
							...payment,
							transfer: { ...payment.transfer, bankName: e.target.value },
						});
					}}
					color={errors?.bankName?.required ? "danger" : ""}
					errors={errors?.bankName}
				/>
			</div>
			<div className="col-3">
				<TextField
					type="text"
					label={"Account Holder’s Name"}
					placeholder={"Enter Name"}
					name="accountName"
					value={payment.transfer.accountName}
					onChange={(e) => {
						setErrors({
							...errors,
							...validate(e.target, {
								required: true,
							}),
						});
						setPayment({
							...payment,
							transfer: { ...payment.transfer, accountName: e.target.value },
						});
					}}
					color={errors?.accountName?.required ? "danger" : ""}
					errors={errors?.accountName}
				/>
			</div>

			{payment.transaction_type?.value == 2 ? (
				<>
					<div className="col-3">
						<TextField
							type="text"
							label={"Account No."}
							placeholder={"Enter Account No."}
							name="accountNo"
							value={payment.transfer.accountNo}
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
									}),
								});
								setPayment({
									...payment,
									transfer: { ...payment.transfer, accountNo: e.target.value },
								});
							}}
							color={errors?.accountNo?.required ? "danger" : ""}
							errors={errors?.accountNo}
						/>
					</div>

					<div className="col-3 ">
						<TextField
							type="text"
							label={"Transaction No."}
							placeholder={"Enter Transaction No."}
							name="transactionNo"
							value={payment.transfer.transactionNo}
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
									}),
								});
								setPayment({
									...payment,
									transfer: {
										...payment.transfer,
										transactionNo: e.target.value,
									},
								});
							}}
							color={errors?.transactionNo?.required ? "danger" : ""}
							errors={errors?.transactionNo}
						/>
					</div>
				</>
			) : (
				<>
					<div className="col-3 mt-2">
						<DatePickerField
							hasLabel={true}
							date={payment.cheque.date}
							placeholder={"Date"}
							label={"Date"}
							name="date"
							onChangeDate={(e) => {
								setErrors({
									...errors,
									...validate({ name: "date", value: e }, { required: true }),
								});
								setPayment({
									...payment,
									cheque: { ...payment.cheque, date: e },
								});
							}}
							// isOutsideRange={(day) => isInclusivelyBeforeDay(day, moment())}
							color={errors?.date?.required ? "danger" : ""}
							errors={errors?.date}
						/>
					</div>
					<div className="col-3">
						<TextField
							type="text"
							label={"Cheque No."}
							placeholder={"Enter Cheque No."}
							name="chequeNo"
							value={payment.cheque.chequeNo}
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
									}),
								});
								setPayment({
									...payment,
									cheque: { ...payment.cheque, chequeNo: e.target.value },
								});
							}}
							color={errors?.chequeNo?.required ? "danger" : ""}
							errors={errors?.chequeNo}
						/>
					</div>
				</>
			)}
		</div>
	);
};

export default PaymentType;
