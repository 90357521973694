import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { showWallets } from "services/multiWallets";
import PaymentPopup from "./ui/PaymentPopup";
import ViewTransactionPopup from "./ui/ViewTransactionPopup";
import Wallet from "./Wallet";

export default function ListWalletTransactions() {
	const [popupInfo, setpopupInfo] = useState({});
	const [reload, setReload] = useState(true);
	const { wallet_id } = useParams();
	const [wallet, setWallet] = useState({});

	useEffect(() => {
		const getWallet = async () => {
			const res = await showWallets(wallet_id);
			if (res?.status === 200) {
				setWallet(res.data.data);
			}
		};
		getWallet();
	}, []);

	const [isOpen, setIsOpen] = useState(false);
	const [isOpenView, setIsOpenView] = useState(false);

	const toggle = (
		wallet_id = null,
		currency = null,
		account = null,
		type = null
	) => {
		setpopupInfo({
			wallet_id,
			currency,
			account,
			type,
		});
		setIsOpen(!isOpen);
	};

	const toggleView = (wallet_id = null, deal_id = null, type = null) => {
		setpopupInfo({
			wallet_id,
			deal_id,
			type,
		});
		setIsOpenView(!isOpenView);
	};

	const walletComponent = useMemo(
		() =>
			wallet.id ? (
				<Wallet
					Wallet={wallet}
					toggle={toggle}
					isOpenView={isOpenView}
					toggleView={toggleView}
					viewMore={false}
				/>
			) : null,
		[wallet]
	);

	return (
		<>
			{walletComponent}
			<PaymentPopup
				isOpen={isOpen}
				popupInfo={popupInfo}
				toggle={toggle}
				setpopupInfo={setpopupInfo}
				setReload={setReload}
			/>

			<ViewTransactionPopup
				isOpen={isOpenView}
				toggle={toggleView}
				popupInfo={popupInfo}
			/>
		</>
	);
}
