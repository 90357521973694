import resetIcon from "assets/images/wallet/resetIcon.png";

import TextField from "components/shared/TextField";
import Locale from "translations";

export default function Filter({ filter, setFilter }) {
	const { backOffice } = Locale;
	const resetFilters = () => {
		setFilter({
			search: "",
			name: "",
			page: 1,
		});
	};
	return (
		<>
			<>
				<div className="main-filter bg-white-blue py-2 my-2">
					<div className="row align-items-center w-100 no-gutter m-0">
						<div className="col-2">
							<div className="main-label">
								<TextField
									type="text"
									placeholder={backOffice.search}
									label={backOffice.search}
									hasLabel={true}
									value={filter.search}
									onChange={(e) => {
										setFilter({
											...filter,
											search: e.target.value,
										});
									}}
								/>
							</div>
						</div>
						<div className="col-2">
							<div className="main-label">
								<TextField
									type="text"
									placeholder={backOffice.name}
									label={backOffice.name}
									hasLabel={true}
									value={filter.name}
									onChange={(e) => {
										setFilter({
											...filter,
											name: e.target.value,
										});
									}}
								/>
							</div>
						</div>

						<div className="col-md">
							<div
								onClick={resetFilters}
								className="d-flex align-items-center text-secondary pointer mt-4 reset-btn"
							>
								<img src={resetIcon} alt="" srcset="" />
								<p className="text-caption">{backOffice.resetFilter}</p>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	);
}
