import BackOfficeLayout from "modules/backOffice/Components/backOfficeLayout";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import OnlineVisaSubNav from "./OnlineVisaSubNav";

// React Component
export function OnlineVisaRoute({ component: Component, ...props }) {
    const isAuth = localStorage.getItem("isAuth");
    if (isAuth) {
        return (
            <Route
                {...props}
                render={(matchProps) => (
                    <>
                        <BackOfficeLayout>
                            {/* <OnlineVisaSubNav /> */}
                            <Component {...matchProps} />
                        </BackOfficeLayout>
                    </>
                )}
            />
        );
    } else {
        return <Redirect to="/login" />;
    }
}
