import classnames from "classnames";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { ViewHotelReservations } from "services/companiesReservations";
import Locale from "translations";
import BookingDetails from "./BookingDetails";
import InventoryTab from "./InventoryTab";

export default function ViewReservations() {
	const { company_id, reservation_id } = useParams();
	const [details, setDetails] = useState(null);
	const [activeTab, setActiveTab] = useState("1");
	const { backOffice, rooms, teamManagement } = Locale;
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	async function fetchData() {
		const { data } =
			"hotel" === "hotel"
				? await ViewHotelReservations(company_id, reservation_id)
				: null;
		setDetails(data.data);
	}
	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div className="market-add-hotel">
			<div className="d-flex justify-content-between align-items-center bg-white">
				<h6 className="py-3 px-1 font-weight-bold dark-blue-color container-fluid">
					{/* Fairmont Makkah Clock Tower */}
					{/* {state.tab === "hotel" ? state.hotel : state.transport}  */}
					View Hotel : {details?.hotel_name}
				</h6>
			</div>
			<div className="container-fluid sub-tabs">
				<Nav tabs className="align-items-center main-nav ">
					<NavItem style={{ display: "flex", marginBottom: "10px" }}>
						<NavLink
							className={classnames({ active: activeTab === "1" })}
							onClick={() => {
								toggle("1");
							}}
						>
							{"hotel" === "hotel" ? (
								<i className="fas fa-hotel fa-fw mx-1"></i>
							) : (
								<i className="icx icx-transport fa-fw mx-1"></i>
							)}
							{/*hotelDetails */}
							{"hotel" === "hotel"
								? `${backOffice.bookingDetails}`
								: `${backOffice.transport}`}
						</NavLink>

						{details?.gds == 4 ? (
							<NavLink
								className={classnames({ active: activeTab === "2" })}
								onClick={() => {
									toggle("2");
								}}
							>
								<i className="fas fa-briefcase fa-fw mx-1"></i>
								{backOffice.inventory}
							</NavLink>
						) : null}
					</NavItem>
				</Nav>
			</div>
			<div className="main-tabs container-fluid">
				{details !== null && (
					<TabContent activeTab={activeTab}>
						{activeTab === "1" ? (
							<TabPane tabId="1">
								<BookingDetails details={details} />
							</TabPane>
						) : null}
						{activeTab === "2" ? (
							<TabPane tabId="2">
								<InventoryTab
									details={details}
									setDetails={setDetails}
									fetchData={fetchData}
								/>
							</TabPane>
						) : null}
					</TabContent>
				)}
			</div>
		</div>
	);
}
