export default function Transaction({ transaction, toggleView, currency }) {
	return (
		<tr>
			<td>{transaction?.transaction_number}</td>
			<td>{transaction?.transaction_type} </td>
			<td>
				{transaction?.amount} {currency}{" "}
			</td>
			<td>{transaction?.action_type}</td>
			<td>
				{transaction?.created_at?.slice(0, 10)}{" "}
				{transaction?.created_at?.slice(11, 16)}
			</td>
			{/* <td
					className={`${
						transaction?.status === "approved" ? "text-success" : "text-danger"
					}`}
			>
				{transaction?.status}
			</td> */}
			<td>
				{" "}
				<i
					class="fas fa-eye pointer"
					onClick={() => toggleView(transaction, currency)}
				></i>
			</td>
		</tr>
	);
}
