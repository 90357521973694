import moment from "moment";

export default function Passenger({
	setSelected,
	passangerInfo,
	data,
	index,
	toggleCancel,
	toggleGuset,
	toggleEdit,
	toggleDoc,
	voucherData,
	status,
}) {
	const ToDay = moment().format("yyyy-MM-DD");

	return (
		<tr>
			<td>#{index + 1}</td>
			<td>{passangerInfo.from_date}</td>
			<td>{passangerInfo.to_date}</td>
			<td>
				{passangerInfo?.passengers?.length > 0 ? (
					// passangerInfo?.passengers.map((pass,index)=>{
					//  return <span>
					// 			{pass?.first_name}{" "}
					// 			{pass?.last_name}
					// 		</span>
					// })
					<span>
						{passangerInfo?.passengers[0]?.first_name}{" "}
						{passangerInfo?.passengers[0]?.last_name}
					</span>
				) : (
					""
				)}
			</td>
			<td>{passangerInfo.name}</td>
			<td>{passangerInfo.adults}</td>
			<td>{passangerInfo.children}</td>
			<td>
				{passangerInfo.room_rates && passangerInfo.room_rates.length > 0 ? (
					passangerInfo.room_rates.filter(
						(res) => +res.mealOptionId == +passangerInfo.mealOptionId
					)[0].mealTitle
				) : (
					<span className="text-gray">-</span>
				)}
			</td>
			<td>{passangerInfo.price}</td>

			<td>
				{!passangerInfo?.is_cancelled &&
				data?.reservation_status !== "expired" &&
				data?.start_date > ToDay ? (
					<>
						<i
							onClick={() => {
								toggleEdit();
								setSelected({
									...passangerInfo,
									room_price_currency:
										passangerInfo.room_rates[0]?.room_price_currency,
								});
							}}
							style={{ backgroundColor: "#CB9A51" }}
							class="far fa-edit p-1 text-white mx-1"
						></i>

						<i
							onClick={() => {
								voucherData(passangerInfo?.bookingCode);
							}}
							style={{ backgroundColor: "#28A745" }}
							class="fas fa-cloud-download-alt p-1 text-white mx-1"
						></i>

						<i
							onClick={() => {
								toggleDoc();
								setSelected({
									...passangerInfo,
									room_price_currency:
										passangerInfo.room_rates[0]?.room_price_currency,
								});
							}}
							style={{ backgroundColor: "#0C3B5C" }}
							class="fas fa-file-alt text-white p-1 mx-1"
						></i>

						<i
							onClick={() => {
								toggleGuset();
								setSelected({
									...passangerInfo,
									room_price_currency:
										passangerInfo.room_rates[0]?.room_price_currency,
								});
							}}
							style={{ backgroundColor: "#0C3B5C" }}
							class="fas fa-users text-white p-1 mx-1"
						></i>

						<i
							onClick={() => {
								toggleCancel();

								setSelected({
									...passangerInfo,
									room_price_currency:
										passangerInfo.room_rates[0]?.room_price_currency,
								});
							}}
							style={{ backgroundColor: "#FF6868" }}
							class="fas fa-ban text-white  p-1 mx-1"
						></i>
					</>
				) : passangerInfo?.is_cancelled ? (
					<p className="text-danger m-0">Canceled</p>
				) : data?.reservation_status == "expired" ? (
					<p className="text-danger m-0">Expired</p>
				) : null}
			</td>
		</tr>
	);
}
