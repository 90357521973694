import Pagination from "components/shared/Pagination";
import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
	acceptRequests,
	listWalletsRequests,
	listWalletsTransactions,
	rejectRequests,
} from "services/multiWallets";
import Locale from "translations";
import Request from "./Request";
import Transaction from "./Transaction";
import WalletTable from "./ui/WalletTable";

export default function Wallet({
	Wallet,
	toggle,
	isOpenView,
	toggleView,
	viewMore,
	reload,
	setReload,
}) {
	const { wallet } = Locale;

	const [transactions, setTransactions] = useState([]);
	const [requestes, setRequestes] = useState([]);
	const [selectType, setSelectType] = useState("transactions");
	const { company_id } = useParams();
	const [meta, setMeta] = useState(null);
	const [page, setPage] = useState(1);

	const getDeals = async () => {
		if (selectType === "requests" && Wallet?.type === "debit") {
			const res = await listWalletsRequests({
				payment_card_id: Wallet.id,
				company_id,
				page,
				status: "pending",
			});
			if (res?.status === 200) {
				// setReload((prev) => !prev);
				setRequestes(res.data.data);
				setMeta(res?.data?.meta);
			}
		} else {
			const res = await listWalletsTransactions({
				payment_card_id: Wallet.id,
				company_id,
				page,
			});
			if (res?.status === 200) {
				// setReload((prev) => !prev);
				setTransactions(res.data.data);
				setMeta(res?.data?.meta);
			}
		}
	};
	useEffect(() => {
		getDeals();
	}, [selectType, page]);

	useEffect(() => {
		if (reload === Wallet.id) getDeals();
	}, [reload]);

	const acceptRequst = async (id, receipt_number) => {
		const res = await acceptRequests(id, { receipt_number });
		setSelectType("transactions");
	};

	const rejectRequst = async (id, receipt_number) => {
		const res = await rejectRequests(id, { receipt_number });
		setSelectType("transactions");
	};

	const goTo = (page) => {
		setPage(page);
	};

	const allTransactions = useMemo(() => {
		return transactions?.length > 0
			? transactions.map((transaction) => (
					<Transaction
						key={transaction.id}
						transaction={transaction}
						isOpenView={isOpenView}
						toggleView={toggleView}
					/>
			  ))
			: null;
	}, [transactions]);

	const allRequestes = useMemo(() => {
		return requestes?.length > 0
			? requestes.map((request) => (
					<Request
						key={request.id}
						request={request}
						acceptRequst={acceptRequst}
						rejectRequst={rejectRequst}
						isOpenView={isOpenView}
						toggleView={toggleView}
					/>
			  ))
			: null;
	}, [requestes, reload]);

	return (
		<div
			className={` ${Wallet?.type === "credit" ? "mt-4" : "mt-4"} ${
				viewMore ? "wallet-box" : ""
			}`}
		>
			<div className="row p-0 m-0 justify-content-between ">
				<div className="col-2 bg-wallet-name font-weight-bolder d-flex align-items-center justify-content-center ">
					{Wallet?.type === "credit"
						? "Safa Wallet"
						: Wallet.currency + " Wallet"}
				</div>

				<div className="col-2 p-2">
					<p className="m-0 font-weight-bolder"> {wallet.AccountNo}</p>
					<p className="m-0"> {Wallet.ref_num}</p>
				</div>

				<div className="col-2 p-2">
					<p className="m-0 font-weight-bolder">
						{Wallet?.type === "credit" ? wallet.CreditLeftToUse : wallet.amount}
					</p>
					<p className="m-0">
						{Wallet.amount} {Wallet.currency}
					</p>
				</div>

				{Wallet?.type === "credit" ? (
					<div className="col-2 p-2">
						<p className="m-0 font-weight-bolder">{wallet.TotalLimit}</p>
						<p className="m-0">
							{Wallet.limit} {Wallet.currency}
						</p>
					</div>
				) : null}

				<div
					className={`${
						Wallet?.type === "credit" ? "col-3" : "col-2"
					} d-flex align-items-center`}
				>
					<button
						className="companies-btn btn w-75 rounded  pointer"
						onClick={() => {
							toggle(Wallet.id, Wallet.currency, Wallet.ref_num, Wallet.type);
						}}
					>
						{wallet.AddBalance}
					</button>

					{Wallet?.type === "credit" ? (
						<button
							className="companies-btn btn w-75 mx-2 rounded  pointer"
							onClick={() => {
								toggle(
									Wallet.currency,
									Wallet.limit,
									Wallet.amount,
									"payCredit"
								);
							}}
						>
							{wallet.PayYourWallet}
						</button>
					) : null}

					{Wallet?.type === "credit" ? null : (
						<i class="fas fa-trash-alt mx-3 trash"></i>
					)}
				</div>
			</div>
			<div className="border-top">
				{Wallet?.type === "credit" ? null : (
					<div className=" d-flex justify-content-center mt-4 mb-2 ">
						<div className="d-flex justify-content-between  rounded-pill border shadow-sm  wallet-btns ">
							<span
								className={` p-2  px-4 pointer font-weight-bolder ${
									selectType === "transactions" ? "bg-wallet-btn" : ""
								}`}
								onClick={() => {
									setSelectType("transactions");
									setPage(1);
								}}
							>
								{wallet.Transactions}
							</span>

							<span
								className={` p-2  px-4 pointer  font-weight-bolder ${
									selectType === "requests" ? "bg-wallet-btn" : ""
								}`}
								onClick={() => {
									setSelectType("requests");
									setPage(1);
								}}
							>
								{wallet.Requests}
							</span>
						</div>
					</div>
				)}

				<WalletTable
					thead={[
						wallet.TransactionsID,
						wallet.Type,
						wallet.Balance,
						wallet.Method,
						wallet.Created,
						selectType === "requests" ? wallet.Status : " ",
						wallet.DESCRIPTION,
						selectType != "requests" ? wallet.actionBy : "",
						selectType != "requests" ? wallet.payment_method : "",
					]}
					tbody={selectType === "requests" ? allRequestes : allTransactions}
					viewMore={viewMore}
				/>

				{viewMore &&
				(allRequestes?.length > 5 || allTransactions?.length > 5) ? (
					<div className="d-flex justify-content-end">
						<div className="col-2 my-2">
							<Link
								type="button"
								class="btn btn-success w-75"
								to={{
									pathname: `/tourism-company/${company_id}/wallet/transactions/${Wallet.id}`,
									state: { wallet: Wallet },
								}}
							>
								{wallet.ViewMore}
							</Link>
						</div>
					</div>
				) : null}
			</div>
			{viewMore === false ? (
				<Pagination info={meta} goTo={goTo} items={10} />
			) : null}
		</div>
	);
}
