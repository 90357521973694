import resetIcon from "assets/images/wallet/resetIcon.png";

import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import Locale from "translations";

import moment from "moment";
import { isInclusivelyBeforeDay } from "react-dates";

export default function Filter({ filter, setFilter, countries }) {
	const { backOffice } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";
	const statues = [
		{ value: "active", label: backOffice.active },
		{ value: "inactive", label: backOffice.inActive },
	];

	const Status = [
		{
			value: 0,
			label: "inactive",
		},
		{
			value: 1,
			label: "active",
		},
	];

	const resetFilters = () => {
		setFilter({
			search: "",
			join_date: "",
			country_id: null,
			status: "",
			page: 1,
		});
	};
	return (
		<>
			<>
				<div className="main-filter bg-white-blue py-2 my-2">
					<div className="row align-items-center w-100 no-gutter m-0">
						<div className="col">
							<div className="main-label">
								<TextField
									type="text"
									placeholder={backOffice.search}
									label={backOffice.search}
									hasLabel={true}
									value={filter.search}
									onChange={(e) => {
										setFilter({
											...filter,
											search: e.target.value,
										});
									}}
								/>
							</div>
						</div>

						<div className="col">
							<DatePickerField
								hasLabel={true}
								date={filter.join_date}
								placeholder={backOffice.date}
								label={backOffice.date}
								onChangeDate={(filterDate) => {
									setFilter({
										...filter,
										join_date: filterDate,
									});
								}}
								isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
							/>
						</div>
						<div className="col">
							<SelectField
								hasLabel={true}
								value={filter.country_id}
								placeholder={backOffice.country}
								label={backOffice.country}
								name="country"
								options={countries}
								onChange={(e) => {
									setFilter({
										...filter,
										country_id: e,
									});
								}}
							/>
						</div>

						<div className="col">
							<SelectField
								hasLabel={true}
								value={filter.status}
								placeholder={backOffice.status}
								label={backOffice.status}
								name="status"
								options={Status}
								onChange={(e) => {
									setFilter({
										...filter,
										status: e,
									});
								}}
							/>
						</div>

						<div className="col-md">
							<div
								onClick={resetFilters}
								className="d-flex align-items-center text-secondary pointer mt-4 reset-btn"
							>
								<img src={resetIcon} alt="" srcset="" />
								<p className="text-caption">{backOffice.resetFilter}</p>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	);
}
