import React from "react";

export default function GroupUser({ user }) {
  return (
    <tr>
      <td className="w-25">{user.name}</td>
      <td className="w-25">{user.email}</td>
      <td className="w-25">
        {user?.status?.name === "active" ? (
          <i class="far fa-check-circle icons-teamMange text-success"></i>
        ) : (
          <i class="far fa-times-circle icons-teamMange text-danger"></i>
        )}
      </td>
    </tr>
  );
}
