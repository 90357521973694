import companyLoge from "assets/images/default-img.jpg";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalDispatch, useGlobalState } from "context/global";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import { uploadFile } from "services/auth";
import { fetchImages, updateCompanie, viewCompanie } from "services/companies";
import { fetchCities } from "services/lookups";
import Locale from "translations";

export default function Profile() {
	const { Companies } = Locale;
	const { allCountries } = useGlobalState();
	const [isEdit, setIsEdit] = useState(true);
	const { company_id } = useParams();
	const [company, setCompany] = useState({});
	const [countries, setCountries] = useState([]);
	const [cities, setCities] = useState([]);

	let locale = localStorage.getItem("currentLocale") || "en";
	const dispatch = useGlobalDispatch();
	const backOfficeApi = process.env.REACT_APP_API_URL + "/api/files/fetch/";

	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, []);

	useEffect(async () => {
		const res = await viewCompanie(company_id);
		if (res?.status === 200 || res?.status === 201) {
			const data = {
				business_email: res?.data?.data.business_email,
				name: res?.data?.data.name,
				phone: res?.data?.data.phone,
				safa_key: res?.data?.data.safa_key,
				country: {
					value: res?.data?.data?.country?.id,
					label: res?.data?.data?.country?.name,
				},
				city_id: res?.data?.data?.city?.id,
				city: {
					value: res?.data?.data?.city?.id,
					label: res?.data?.data?.city?.name,
				},
				avatar: res?.data?.data?.avatar,
				address: res?.data?.data?.address,
				auto_reservation: res?.data?.data?.auto_reservation,
			};
			dispatch({ type: "company_name", payload: data?.name });

			setCompany(data);
		}
	}, []);

	useEffect(async () => {
		if (company.imgFile) {
			let formData = new FormData();
			formData.append("file", company.imgFile);
			formData.append("name", company.imgFile.name);
			formData.append("bucket", "user");
			const resFile = await uploadFile(formData);

			if (resFile.status === 200) {
				setCompany({
					...company,
					avatar: resFile.data?.uuid,
				});
			}
		}
	}, [company?.imgFile]);

	useEffect(async () => {
		if (company.avatar) {
			const res = await fetchImages(company?.avatar);
			setCompany({ ...company, displayImg: res?.data?.data?.avatar });
		}
	}, [company.avatar]);

	const updateCompany = async () => {
		const res = await updateCompanie(
			{
				...company,
				country_id: company.country.value,
				city_id: company.city.value,
				phone: `+${company.country.value}${company.phone.phone}`,
				country: null,
				auto_reservation: company?.auto_reservation,
			},
			company_id
		);
		if (res.status == 200) {
			store.addNotification({
				title: "info!",
				message: res.data.message ?? "updated successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			setIsEdit(true);
		}
	};

	useEffect(() => {
		const getCities = async () => {
			if (company?.country?.value) {
				const res = await fetchCities(company?.country?.value);
				console.log("res101", res?.data);
				const format = res?.data?.map((t) => ({
					value: t.id,
					label: t.names[locale],
				}));
				setCities(format);
			}
		};
		getCities();
	}, [company.country]);

	const paymentCheck = (e) => {
		let checked = e.target.checked;
		let clone = company?.auto_reservation;

		if (checked) {
			clone = checked ? 1 : 0;
		} else {
			clone = 0;
		}

		setCompany({
			...company,
			auto_reservation: clone,
		});
	};
	console.log("backOfficeApi", backOfficeApi);
	console.log("company101", company);
	return (
		<div className=" px-5 ">
			<div className="row my-4">
				<div className="col-4">
					<figure className=" shape-border w-75 mb-0">
						<img
							src={
								company.avatar ? backOfficeApi + company.avatar : companyLoge
							}
							alt=""
							className="w-100"
						/>
					</figure>

					{isEdit ? null : (
						<div className=" shape-border mt-1 w-75 border-0">
							<button className="file-btn w-100 ">
								{Companies.changeLogo}
								<input
									type="file"
									className="custom-file"
									accept="image/png, image/gif, image/jpeg"
									onChange={(e) => {
										console.log(e.target.files[0]);
										setCompany({
											...company,
											displayImg: URL.createObjectURL(e.target.files[0]),
											imgFile: e.target.files[0],
										});
									}}
								/>
							</button>
						</div>
					)}
				</div>
				<div className="col-8">
					{!isEdit ? (
						<div className="row">
							<div className="col-6">
								<TextField
									type="text"
									placeholder={Companies.name}
									label={Companies.name}
									hasLabel={true}
									disabled={isEdit}
									value={company?.name?.en}
									onChange={(e) => {
										setCompany({
											...company,
											name: { ...company?.name, en: e.target.value },
										});
									}}
								/>
							</div>
							<div className="col-6">
								<TextField
									type="text"
									placeholder={Companies.nameAr}
									label={Companies.nameAr}
									hasLabel={true}
									disabled={isEdit}
									value={company?.name?.ar}
									onChange={(e) => {
										setCompany({
											...company,
											name: { ...company?.name, ar: e.target.value },
										});
									}}
								/>
							</div>

							<div className="col-12">
								<TextField
									type="text"
									placeholder={Companies.phoneNumber}
									label={Companies.phoneNumber}
									hasLabel={true}
									disabled={isEdit}
									value={company?.phone?.phone}
									onChange={(e) => {
										setCompany({
											...company,
											phone: {
												phone: e.target.value,
												phone_code: `+${company.country.value}`,
											},
										});
									}}
								/>
							</div>

							<div className="col-6">
								<TextField
									type="text"
									placeholder={"Safa Key"}
									label={"Safa Key"}
									hasLabel={true}
									disabled={isEdit}
									value={company?.safa_key}
									onChange={(e) => {
										setCompany({
											...company,
											safa_key: e.target.value,
										});
									}}
								/>
							</div>

							{/* <div className="col-5">
          <TextField
            type="text"
            placeholder={Companies.mobileNumber}
            hasLabel={false}
            disabled={isEdit}
            value={company?.phone?.phone}
            onChange={(e) => {
              setCompany({
                ...company,
                mobile: {
                  ...company.phone,
                  mobile: e.target.value,
                },
              });
            }}
          />
        </div> */}

							<div className="col-6">
								<TextField
									type="text"
									placeholder={Companies.email}
									label={Companies.email}
									hasLabel={true}
									disabled={isEdit}
									value={company?.business_email}
									onChange={(e) => {
										setCompany({
											...company,
											business_email: e.target.value,
										});
									}}
								/>
							</div>

							<div className="col-6">
								<SelectField
									hasLabel={true}
									placeholder={Companies.country}
									label={Companies.country}
									name="status"
									disabled={isEdit}
									options={countries}
									value={company.country}
									onChange={(e) => {
										setCompany({
											...company,
											country: e,
										});
									}}
								/>
							</div>
							<div className="col-6">
								<SelectField
									hasLabel={true}
									placeholder={Companies.city}
									label="city"
									name="status"
									disabled={isEdit}
									options={cities}
									value={company?.city}
									onChange={(e) => {
										setCompany({
											...company,
											city: e,
										});
									}}
								/>
							</div>
							<div className="col-5">
								<TextField
									type="text"
									placeholder={"Address"}
									label={"Address"}
									hasLabel={true}
									disabled={isEdit}
									value={
										company?.address
											? company?.address[locale] ?? company?.address?.ar
											: ""
									}
									onChange={(e) => {
										setCompany({
											...company,
											address: { en: e.target.value },
										});
									}}
								/>
							</div>
							<div className="col-5 mt-5">
								<input
									disabled={isEdit}
									class="form-check-input"
									type="checkbox"
									id="payment"
									onChange={paymentCheck}
									checked={company.auto_reservation == 1 ? true : false}
									style={{ fontSize: "21px" }}
								/>
								<label
									class="form-check-label pointer mx-2 mt-1"
									for="payment"
									style={{ fontSize: "17px" }}
								>
									pay Later
								</label>
							</div>
						</div>
					) : (
						<>
							<div className="row">
								<div className="col-4">
									<h1 className="pro-label">{Companies.nameAr} </h1>
								</div>
								<div className="col-8">
									<h4 className="pro-data">{company?.name?.ar}</h4>
								</div>
								<div className="col-4">
									<h1 className="pro-label">{Companies.name}</h1>
								</div>
								<div className="col-8">
									<h4 className="pro-data">{company?.name?.en}</h4>
								</div>
								<div className="col-4">
									<h1 className="pro-label">{Companies.email}</h1>
								</div>
								<div className="col-8">
									<h4 className="pro-data">{company?.business_email}</h4>
								</div>
								<div className="col-4">
									<h1 className="pro-label">{Companies.phoneNumber}</h1>
								</div>
								<div className="col-8">
									<h4 className="pro-data">{company?.phone?.phone}</h4>
								</div>
								<div className="col-4">
									<h1 className="pro-label">{"Safa Key"}</h1>
								</div>
								<div className="col-8">
									<h4 className="pro-data">{company?.safa_key}</h4>
								</div>
								<div className="col-4">
									<h1 className="pro-label">{Companies.country}</h1>
								</div>
								<div className="col-8">
									<h4 className="pro-data">{company?.country?.label}</h4>
								</div>
								<div className="col-4">
									<h1 className="pro-label">{"city"}</h1>
								</div>
								<div className="col-8">
									<h4 className="pro-data">{company?.city?.label}</h4>
								</div>
								<div className="col-4"></div>
								<div className="col-8">
									<input
										disabled={isEdit}
										class="form-check-input "
										type="checkbox"
										id="payment"
										onChange={paymentCheck}
										checked={company.auto_reservation == 1 ? true : false}
										style={{ fontSize: "21px" }}
									/>
									<label
										class="form-check-label pointer mx-2 mt-1"
										for="payment"
										style={{ fontSize: "17px" }}
									>
										pay Later
									</label>
								</div>
							</div>
						</>
					)}
					<div className=" col-10 p-0 d-flex justify-content-end">
						{isEdit ? (
							<button
								className="btn companies-btn w-25"
								onClick={() => {
									setIsEdit(!isEdit);
								}}
							>
								{Companies.edit}
							</button>
						) : (
							<button
								className="btn companies-btn w-25"
								onClick={updateCompany}
							>
								{Companies.saveChanges}
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
