import { useState } from "react";
import { Collapse } from "reactstrap";

export default function PaymentTablecontent(props) {
	const { data, currency } = props;
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	return (
		<>
			<tr>
				<td>
					<button
						className={`collapse-btn ${
							isOpen ? "bg-dark-blue" : "bg-light-gray"
						}`}
						onClick={() => toggle()}
					>
						{isOpen ? (
							<i class="fas fa-minus  text-white"></i>
						) : (
							<i class="fas fa-plus text-white"></i>
						)}
					</button>
				</td>
				<td>{data.transaction_code}</td>
				<td>
					{data.amount} {currency}
				</td>
				<td>
					{data.remaining} {currency}
				</td>
				<td>{data.type}</td>
				<td>{data.transaction_type}</td>
				<td>{data.created_at}</td>
				<td>{data.admin_name}</td>
			</tr>
			{isOpen ? (
				<tr>
					<td colSpan="8">
						<Collapse isOpen={isOpen}>
							<table className="custom-table back-office-table table mr-5 ">
								<thead>
									{data.transaction_type == "cash" ? (
										<tr className="bg-dark-blue ">
											<th className="CompanyHead-title w-100 text-white">
												Note
											</th>
										</tr>
									) : data.transaction_type == "transfer" ? (
										<tr className="bg-dark-blue">
											<th className="text-white">Note</th>
											<th className="text-white">Bank Name</th>
											<th className="text-white">Account Name</th>
											<th className="text-white">Account Number</th>
											<th className="text-white">Transaction Number</th>
										</tr>
									) : data.transaction_type == "cheque" ? (
										<tr className="bg-dark-blue">
											<th className="text-white">Note</th>
											<th className="text-white">Bank Name</th>
											<th className="text-white">Account Name</th>
											<th className="text-white">Cheque No.</th>
											<th className="text-white">date</th>
										</tr>
									) : null}
								</thead>
								<tbody>
									{data.transaction_type == "cash" ? (
										<tr className="bg-light-gray ">
											<td>{data.note}</td>
										</tr>
									) : data.transaction_type == "transfer" ? (
										<tr className="bg-light-gray">
											<td>{data.note}</td>
											<td>{data?.bank_name}</td>
											<td>{data?.account_holder_name}</td>
											<td>{data?.account_number}</td>
											<td>{data?.transaction_number}</td>
										</tr>
									) : data.transaction_type == "cheque" ? (
										<tr className="bg-light-gray">
											<td>{data.note}</td>
											<td>{data?.bank_name}</td>
											<td>{data?.account_holder_name}</td>
											<td>{data?.transaction_number}</td>
											<td>{data?.cheque_date}</td>
										</tr>
									) : null}
								</tbody>
							</table>
						</Collapse>
					</td>
				</tr>
			) : null}
		</>
	);
}
