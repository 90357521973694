import { useState } from "react";
import { store } from "react-notifications-component";
import { addRefund } from "services/Request";
import Locale from "translations";

export default function Payments({ payment }) {
	const { backOffice, booking } = Locale;
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const toggleModel = () => {
		setModalIsOpen(!modalIsOpen);
	};
	const status = [
		{ label: "Total", value: 1 },
		{ label: "Percentage", value: 2 },
	];
	const [refund, setRefund] = useState({
		status: "",
		input: "",
	});

	const handleRefund = async () => {
		const res = await addRefund({
			brn: payment?.brn,
			refund_percentage:
				refund?.status?.label == "Percentage" ? refund?.input : "100",
			type: refund?.status?.label,
		});

		if (res.status == 200) {
			store.addNotification({
				title: "Info!",
				message: res?.data?.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					setRefund({
						status: "",
						input: "",
					});
					setModalIsOpen(false);
				},
			});
		}
	};

	return (
		<div className="mt-2">
			<div className=" table-responsive">
				<table className="custom-table back-office-table table table-striped border">
					<thead>
						<tr className="text-nowrap">
							<th>{booking.Payment}</th>
							<th>{booking.Date}</th>
							<th>{booking.Amount}</th>
							<th>{booking.RefNo}</th>
							{/* <th>{booking.Refunds}</th> */}
						</tr>
					</thead>

					<tbody>
						{payment !== undefined ? (
							<tr>
								<td>{payment?.payment_method}</td>
								<td>{payment?.created_at}</td>
								<td>
									{payment?.total_price} {payment?.currency}
								</td>
								<td>{payment?.provider_reference}</td>
								{/* <td>
								{payment.reservation_status != "canceled" ? (
									<button
										type="button"
										class="btn btn-primary"
										onClick={toggleModel}
									>
										{booking.Refunds}
									</button>
								) : (
									<p className="text-danger">Refunded</p>
								)}
							</td> */}
							</tr>
						) : (
							<tr>
								<td colSpan="15">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
}
