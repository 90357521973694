import moment from "moment/moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useLocation } from "react-router-dom";
import { exportToExcel, listGroupReservations } from "services/sbsReservations";
import Locale from "translations";
import SBSReservationsFilter from "./Filter";
import SBSReservationsTable from "./Table";

export default function SBSReservationsList() {
	const { backOffice } = Locale;
	const { search } = useLocation();
	const history = useHistory();
	const [_, pages] = search.split("=");
	const [reservations, setReservations] = useState([]);
	const [metaData, setMetaData] = useState(null);
	const [filters, setFilters] = useState({
		hotel_name: "",
		date_from: "",
		destination_id: null,
		status: "",
	});

	// filter
	useEffect(() => {
		async function fetchReservationsFilterdData() {
			const data = {
				hotel_name: filters.hotel_name,
				destination_id: filters.destination_id?.value,
				date_from: filters?.date_from
					? moment(filters?.date_from).format("YYYY-MM-DD")
					: "",
				status: filters.status?.value,
			};
			const response = await listGroupReservations(pages, data);
			setReservations(response?.data.data);
			setMetaData(response.data.meta);
		}
		fetchReservationsFilterdData();
	}, [
		filters.hotel_name,
		filters.date_from,
		filters.destination_id,
		filters.status,
	]);

	const fetchReservationsData = async (page = pages, filters = {}) => {
		const response = await listGroupReservations(page, filters);
		if (response?.status === 200) {
			setReservations(response?.data?.data);
			setMetaData(response?.data?.meta);
		}
	};

	const goTo = (page) => {
		fetchReservationsData(page, {});
		const params = new URLSearchParams();
		if (page) {
			params.append("page", page);
		} else {
			params.delete("page", page);
		}
		history.push({ search: params.toString() });
	};
	const exportToFile = async () => {
		const res = await exportToExcel({
			...filters,
			destination_id: filters.destination_id?.value,
			date_from: filters?.date_from
				? moment(filters?.date_from).format("YYYY-MM-DD")
				: "",
			status: filters.status?.value,
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.click();
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};
	return (
		<>
			<div className="d-flex justify-content-between align-items-center">
				<h6 className="main-title">{backOffice.sbsGroupReservation}</h6>
			</div>
			<SBSReservationsFilter
				reservations={reservations}
				setReservations={setReservations}
				pages={pages}
				setMetaData={setMetaData}
				setFilters={setFilters}
				filters={filters}
				exportToFile={exportToFile}
			/>
			<SBSReservationsTable
				reservations={reservations}
				setReservations={setReservations}
				goTo={goTo}
				metaData={metaData}
			/>
		</>
	);
}
