import Pagination from "components/shared/Pagination";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { listHotelReservations } from "services/hotels";
import Locale from "translations";
import Filter from "./Filter";
import Reservation from "./Reservation";

export default function Reservations() {
	const [reservations, setReservations] = useState();
	const { backOffice, rooms, teamManagement } = Locale;
	const [meta, setMeta] = useState(null);
	const { id } = useParams();

	const [filter, setFilter] = useState({
		name: "",
		date: null,
		page: 1,
	});
	/**Start Api */
	useEffect(() => {
		const fetchData = async () => {
			const res = await listHotelReservations(id, {
				...filter,
				date: filter.date ? moment(filter.date).format("YYYY-MM-DD") : "",
			});
			if (res?.status === 200) {
				setReservations(res?.data?.reservations);
				setMeta(res?.data?.meta);
			}
		};
		fetchData();
	}, [filter]);
	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};
	/**End Api */
	const allReservations = reservations?.map((item, index) => (
		<Reservation key={index} data={item} />
	));

	return (
		<>
			<Filter filter={filter} setFilter={setFilter} />
			<div className="our-border px-4">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<th>{backOffice.hotelName}</th>
						<th>{backOffice.refNo}</th>
						<th>{backOffice.BookingDate}</th>
						<th>{backOffice.checkIn}</th>

						<th>{backOffice.nights}</th>
						<th>{backOffice.pax}</th>
						<th>{backOffice.price}</th>
						<th>{backOffice.status}</th>
						<th className="text-orange">{backOffice.commission}</th>
						<th className="text-orange">{backOffice.safaCommission}</th>
						<th className="text-orange">{backOffice.plateformCommission}</th>

						{/* <tr>
            <th className="CompanyHead-title text-gray ">BRN</th>
            <th className="CompanyHead-title text-gray ">
              {backOffice.hotelName}
            </th>
            <th className="CompanyHead-title text-gray ">{rooms.type}</th>
            <th className="CompanyHead-title text-gray ">
              {backOffice.Availability}
            </th>
            <th className="CompanyHead-title text-gray ">
              {backOffice.BookingDate}
            </th>
            <th className="CompanyHead-title text-gray ">
              {backOffice.checkIn}
            </th>
            <th className="CompanyHead-title text-gray ">
              {backOffice.NoofNights}
            </th>
            <th className="CompanyHead-title text-gray ">
              {backOffice.TotalPrice}
            </th>
            <th className="CompanyHead-title text-gray ">
              {teamManagement.tableStatus}
            </th>
          </tr> */}
					</thead>
					<tbody>
						{allReservations?.length > 0 ? (
							allReservations
						) : (
							<tr>
								<td colSpan="11">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				{reservations?.length > 0 ? (
					<Pagination info={meta} goTo={goTo} />
				) : null}
			</div>
		</>
	);
}
