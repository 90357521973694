import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
} from "@material-ui/core";

export default function PoliciesDialog({
	dialogStatus,
	handleDialogClose,
	policies,
	cancellationPolicy,
	terms,
}) {
	return (
		<Dialog
			open={dialogStatus}
			onClose={handleDialogClose}
			scroll="body"
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
			fullWidth
			maxWidth="md"
		>
			<DialogTitle id="scroll-dialog-title">
				<div className="d-flex justify-content-between">
					<p style={{ color: "var(--navy-600)", fontWeight: "700" }}>
						{"policies"}
					</p>
					<IconButton className={`close_icon`} onClick={handleDialogClose}>
						<i class="fas fa-times" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<div>
					<p className="mb-1 fw-bold">{"bookingConditions"}</p>
					{terms}
				</div>
				{/* <p className="text-danger mt-3">Cancellation Policies may change based on your selected services, Please check the applied policies in checkout page</p> */}
				<div className="mt-3">
					<p className="mb-1 fw-bold">{"cancelationPolicies"}</p>
					{cancellationPolicy?.description}
				</div>
				<div className="mt-3">
					<p className="mb-1 fw-bold">{"policies"}</p>
					{policies?.map((policy, index) => (
						<div className="my-3" key={index}>
							<p className="mb-0  fw-bold">{policy?.type} policy</p>
							<span className="d-block">{policy?.description}</span>
						</div>
					))}
				</div>
			</DialogContent>
		</Dialog>
	);
}
