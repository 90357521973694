import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";
import RangeSlider from "components/shared/RangeSlider";
import TextField from "components/shared/TextField";
import Locale from "translations";

export default function PlatformReservationsFilter({ filter, setFilter }) {
	const { backOffice } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setFilter({ ...filter, [name]: value });
		}
	};

	const resetFilters = () => {
		setFilter({
			name: "",
			date: null,
			price: null,
		});
	};
	let formData = "";
	formData = new FormData();
	// formData.append("price[min]", 50);
	// formData.append("price[max]", 100);

	const handleMinPrice = (min, max) => {
		formData.append("price[min]", min);
		formData.append("price[max]", max);
		console.log("formData", ...formData);

		setFilter({
			...filter,
			price: max,
		});
	};
	return (
		<>
			<div className="main-filter bg-white-blue py-2 my-2">
				<div className="row align-items-center w-100 no-gutter m-0">
					<div className="col-md-2">
						<div className="main-label">
							<TextField
								type="text"
								placeholder={backOffice.hotelName}
								label={backOffice.hotelName}
								/*                 hasLabel={false}
								 */ value={filter.name}
								onChange={(e) => {
									setFilter({
										...filter,
										name: e.target.value,
									});
								}}
							/>
						</div>
					</div>

					<div className="col-md-2">
						<DatePickerField
							/* 							hasLabel={false}
							 */ date={filter.date}
							placeholder={backOffice.checkIn}
							label={backOffice.checkIn}
							onChangeDate={(filterDate) => {
								setFilter({
									...filter,
									date: filterDate,
								});
							}}
							isOutsideRange={(day) => {
								return false;
							}}
						/>
					</div>

					<div className="col-md-2 mt-4">
						<RangeSlider
							min={0}
							max={1000}
							step={250}
							ruler={true}
							label={false}
							minValue={250}
							maxValue={750}
							onChange={(e) => {
								handleMinPrice(e.minValue, e.maxValue);
							}}
						/>
					</div>

					<div className="col-md mt-4">
						<div
							onClick={resetFilters}
							className="d-flex align-items-center text-secondary pointer mt-4 reset-btn"
						>
							<img src={resetIcon} alt="" srcset="" />
							{/* 							<i className="fas fa-retweet position-static px-1 text-secondary text-caption"></i>
							 */}{" "}
							<p className="text-caption">{backOffice.resetFilter}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
