import { TextField } from "@material-ui/core";
import SelectField from "components/shared/SelectField";
import TimePickerField from "components/shared/TimePickerField";
import { useGlobalDispatch, useGlobalState } from "context/global";
import { usePropertyDispatch, usePropertyState } from "context/property";
import { formatGetPolicies, formatPostPolicies } from "data/property";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useParams } from "react-router";
import { FormGroup, Input, Label } from "reactstrap";
import { addPolicies, EditPolicies } from "services/property";
import Locale from "translations";
import CancelModal from "./CancelModal";
import { Tax } from "./Tax";

function Polices(statusNum) {
	const { id } = useParams();
	const { polices } = Locale;
	const { propertyDetails } = usePropertyState();
	const propertyDispatch = usePropertyDispatch();
	const history = useHistory();
	const [canceled, setCanceled] = useState(false);

	const dispatch = useGlobalDispatch();
	const { allCurrencies } = useGlobalState();
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [taxes, setTaxes] = useState([
		{
			type: "",
			name: "",
			rate: "",
			currency: "",
		},
	]);
	const [allPolices, setAllPolices] = useState({
		checkInFrom: "",
		checkInTo: "",
		checkOutFrom: "",
		checkOutTo: "",
		tax_type: 1,
		petsCharges: "",
		haveChildren: propertyDetails?.property?.otherPolicies?.have_childrens,
		petsAllowed: propertyDetails?.property?.otherPolicies?.pets_allowed,
		currencies: propertyDetails?.property?.currencies.map((item, index) => {
			return { label: item?.currency_code, value: item?.currency_code };
		}),
	});

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "checkInFrom", value: allPolices.checkInFrom },
				{ required: true }
			),

			...validate(
				{ name: "checkOutFrom", value: allPolices.checkOutFrom },
				{ required: true }
			),
			...validate(
				{ name: "petsCharges", value: allPolices.petsCharges },
				{ required: true }
			),
		});
	};

	const setTax = (i, name, value) => {
		let taxesClone = [...taxes];
		taxesClone[i] = {
			...taxesClone[i],
			[name]: value,
		};
		setTaxes(taxesClone);
	};

	const deleteTax = (index) => {
		let taxesClone = [...taxes];
		taxesClone.splice(index, 1);
		setTaxes(taxesClone);
	};

	const addTax = () => {
		setTaxes([
			...taxes,
			{
				type: "",
				name: "",
				rate: "",
				currency: "",
			},
		]);
	};

	useEffect(() => {
		if (id && propertyDetails && propertyDetails?.property) {
			const { arrivalPolicy, otherPolicies, taxPolicies } =
				propertyDetails?.property;

			const polices = {
				...arrivalPolicy,
				...otherPolicies,
			};
			const formatted = formatGetPolicies(polices, taxPolicies);
			setTaxes(formatted.taxes);
			setAllPolices({
				...formatted,
				currencies: propertyDetails?.property?.currencies.map((item, index) => {
					return { label: item?.currency_code, value: item?.currency_code };
				}),
			});
			// setAllPolices({
			// 	...allPolices,
			// 	currencies: propertyDetails?.property?.currencies.map((item, index) => {
			// 		return { label: item?.currency_code, value: item?.currency_code };
			// 	}),
			// });
		}
	}, [propertyDetails]);

	const isTaxesValid = () => {
		let isValid = true;
		taxes.forEach((tax) => {
			const { type, name, rate } = tax;
			if (Object.values({ type, name, rate }).some((v) => v === "")) {
				isValid = false;
			}
		});
		return isValid;
	};

	useEffect(async () => {
		if (isFormValid(errors)) {
			if (id) {
				const data = formatPostPolicies(allPolices, taxes);

				const res = await EditPolicies(id, data);
				if (res.status === 200) {
					// dispatch({ type: "progress", step: "6" });
					propertyDispatch({ type: "propertyDetails", payload: res.data });
					store.addNotification({
						title: "info!",
						message: res.data.status,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
					history.push("/property-management");
				}
			} else {
				const data = formatPostPolicies(allPolices, taxes);
				const res = await addPolicies(id, data);
				if (res.status === 201) {
					// dispatch({
					// 	type: "progress",
					// 	step: "6",
					// 	current: res.data.property_id,
					// });
					propertyDispatch({ type: "propertyDetails", payload: res.data });
					store.addNotification({
						title: "info!",
						message: res.data.status,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
					history.push("/property-management");
				}
			}
		}
	}, [isErrorLoaded]);

	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const reset = () => {
		setCanceled(true);
	};
	const [currencyList, setCurrencyList] = useState([]);

	useEffect(() => {
		async function currencies() {
			const format = allCurrencies.map((t) => ({
				value: t.currency_code,
				label: t.currency_code,
			}));
			setCurrencyList(format);
		}
		currencies();
	}, [allCurrencies]);

	return (
		<div>
			<CancelModal isOpen={canceled} toggle={setCanceled} />
			<div className="header-box my-3">{polices.arrivalPolicy}</div>
			<div className="row m-0">
				<div className="col-4 timePicker-property">
					<TimePickerField
						label={polices.checkIn}
						disabled={false}
						fromValue={allPolices.checkInFrom}
						toValue={allPolices.checkInTo}
						id="polices-check-in"
						onFromChange={(value) => {
							setAllPolices({ ...allPolices, checkInFrom: value });
							setErrors({
								...errors,
								...validate(
									{ name: "checkInFrom", value: value },
									{ required: true }
								),
							});
						}}
						onToChange={(value) => {
							setAllPolices({ ...allPolices, checkInTo: value });
							setErrors({
								...errors,
								...validate(
									{ name: "checkInTo", value: value },
									{ required: true }
								),
							});
						}}
						name="checkOut"
						color={
							errors.checkInFrom?.required || errors.checkInTo?.required
								? "danger"
								: ""
						}
						errors={errors.checkInFrom || errors.checkInTo}
					/>
				</div>
				<div className="col-4 timePicker-property">
					<TimePickerField
						label={polices.checkOut}
						disabled={false}
						fromValue={allPolices?.checkOutFrom}
						toValue={allPolices?.checkOutTo}
						id="polices-check-out"
						onFromChange={(value) => {
							setAllPolices({ ...allPolices, checkOutFrom: value });
							setErrors({
								...errors,
								...validate(
									{ name: "checkOutFrom", value: value },
									{ required: true }
								),
							});
						}}
						onToChange={(value) => {
							setAllPolices({ ...allPolices, checkOutTo: value });
							setErrors({
								...errors,
								...validate(
									{ name: "checkOutTo", value: value },
									{ required: true }
								),
							});
						}}
						name="checkOut"
						color={
							errors.checkOutFrom?.required || errors.checkOutTo?.required
								? "danger"
								: ""
						}
						errors={errors.checkOutFrom || errors.checkOutTo}
					/>
				</div>
			</div>
			<div className="header-box my-3">Price</div>
			<div className="row m-0">
				<div className="col-4">
					<SelectField
						label={"currency"}
						placeholder={"currency"}
						name="petsAllowed"
						id="petsAllowed"
						value={allPolices.currencies}
						multi={true}
						onChange={(e) => {
							setAllPolices({ ...allPolices, currencies: e });
							setErrors({
								...errors,
								...validate(
									{ name: "currencies", value: e },
									{ required: true }
								),
							});
						}}
						options={currencyList}
						color={errors.currencies?.required ? "danger" : ""}
						errors={errors.currencies}
					/>
				</div>
			</div>

			<div className="header-box my-3">{polices.tax}</div>

			<div class="form-check form-check-inline pl-2 pr-2">
				<input
					class="form-check-input"
					type="radio"
					name="payment_policy456"
					id="Meals1"
					value="1"
					checked={
						allPolices.tax_type == undefined
							? allPolices.taxes && allPolices.taxes.length > 0
							: allPolices.tax_type == 1
					}
					onChange={(e) => {
						setAllPolices({ ...allPolices, tax_type: 1 });
					}}
				/>
				<label class="form-check-label" for="Meals1">
					exclude
				</label>
			</div>
			<div class="form-check form-check-inline pl-2 pr-2">
				<input
					class="form-check-input"
					type="radio"
					name="payment_policy456"
					id="Meals2"
					value="2"
					checked={
						allPolices.tax_type == undefined
							? allPolices.taxes && allPolices.taxes.length == 0
							: allPolices.tax_type == 2
					}
					onChange={(e) => {
						setAllPolices({ ...allPolices, tax_type: 2 });
						setTaxes([
							{
								type: "",
								name: "",
								rate: "",
								currency: "",
							},
						]);
					}}
				/>
				<label class="form-check-label" for="Meals2">
					include{" "}
				</label>
			</div>
			{(allPolices.taxes && allPolices.taxes.length > 0 && taxes.length > 0) ||
				(allPolices.tax_type == 1 && taxes.length > 0) ? (
				<>
					{taxes.map((tax, i) => {
						return (
							<>
								<div className="row m-0">
									<Tax
										deleteTax={deleteTax}
										index={i}
										key={i}
										tax={tax}
										setTax={setTax}
										currencys={allPolices.currencies}
									/>
								</div>
							</>
						);
					})}
					<div className="text-success mx-3 pointer">
						<i className="fas fa-plus-circle"></i>
						<button
							className="px-1 font14 btn-link text-success"
							onClick={addTax}
							id="add-tax"
						>
							Add Tax
						</button>
					</div>
				</>
			) : (
				<div className="col-4">
					<TextField
						label={polices.taxName}
						placeholder={polices.taxName}
						value={allPolices.tax}
						name="name"
						id="tax-1"
						onChange={(e) => {
							setAllPolices({ ...allPolices, tax: e.target.value });
						}}
					/>
				</div>
			)}

			<div className="header-box my-3">{polices.children}</div>
			<p className="">{polices.accommodateChildren}</p>
			<FormGroup tag="fieldset" className="col-12 name-check">
				<FormGroup
					check
					onClick={() => setAllPolices({ ...allPolices, haveChildren: "yes" })}
				>
					<Label check>
						<Input
							type="radio"
							disabled={false}
							//defaultChecked={ allPolices.haveChildren == "1" || allPolices.haveChildren === "yes" }
							value={allPolices.haveChildren}
							checked={
								allPolices.haveChildren == "1" ||
								allPolices.haveChildren === "yes"
							}
							name="children"
							id="children-yes"
						/>
						Yes
					</Label>
				</FormGroup>
				<FormGroup
					check
					onClick={() => setAllPolices({ ...allPolices, haveChildren: "no" })}
				>
					<Label check>
						<Input
							type="radio"
							disabled={false}
							//defaultChecked={allPolices.haveChildren == "0" || allPolices.haveChildren === "no"}
							value={allPolices.haveChildren}
							checked={
								allPolices.haveChildren === "0" ||
								allPolices.haveChildren === "no"
							}
							name="children"
							id="children-no"
						/>
						No
					</Label>
				</FormGroup>
			</FormGroup>
			<div className="header-box my-3">{polices.pets}</div>
			<p className="">{polices.petsDetails}</p>
			<div className="row m-0">
				<div className="col-4">
					<SelectField
						// disabled
						label={polices.allowPets}
						placeholder={polices.allowPets}
						disabled={false}
						name="petsAllowed"
						id="petsAllowed"
						options={[
							{ label: "No", value: "0" },
							{ label: "Yes", value: "1" },
						]}
						value={allPolices.petsAllowed}
						onChange={(e) => {
							setAllPolices({ ...allPolices, petsAllowed: e });
							setErrors({
								...errors,
								...validate(
									{ name: "petsAllowed", value: e },
									{ required: true }
								),
							});
						}}
						color={errors.petsAllowed?.required ? "danger" : ""}
						errors={errors.petsAllowed}
					/>
				</div>
				<div className="col-4">
					<SelectField
						// disabled
						label={polices.chargesForPets}
						disabled={allPolices?.petsAllowed?.label === "No" ? true : false}
						placeholder={polices.chargesForPets}
						name="petsCharges"
						id="petsCharges"
						options={[
							{ label: "Free", value: "free" },
							{ label: "Paid", value: "paid" },
						]}
						value={allPolices.petsCharges}
						onChange={(e) => {
							setAllPolices({ ...allPolices, petsCharges: e });
							setErrors({
								...errors,
								...validate(
									{ name: "petsCharges", value: e },
									{ required: true }
								),
							});
						}}
						color={errors.petsCharges?.required ? "danger" : ""}
						errors={errors.petsCharges}
					/>
				</div>
			</div>
			{/* <ActionButton ID={id} StatusNum={statusNum} /> */}
			<div className="row my-5 mx-0 justify-content-end">
				<button
					className="btn cancel mx-2 "
					onClick={reset}
					id="policies-cancel"
				>
					cancel
				</button>
				<button
					className="btn btn-primary px-5"
					onClick={submit}
					id="policies-continue"
				>
					continue
				</button>
			</div>
		</div>
	);
}

export default Polices;
