import Pagination from "components/shared/Pagination";
import Locale from "translations";
import HotelCommissionsTableContent from "./HotelCommissionsTableContent";
export default function HotelCommissionsTable({
	HotelList,
	fetchData,
	meta,
	goTo,
}) {
	const { backOffice } = Locale;

	const AllHotels = HotelList?.map((hotelItem, index) => (
		<HotelCommissionsTableContent
			fetchData={fetchData}
			hotelItem={hotelItem}
			index={index}
			key={index}
		/>
	));

	return (
		<>
			<div className=" table-responsive  px-4 our-border ">
				<table className="table custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>{backOffice.hotelName}</th>
							<th>{backOffice.country}</th>
							<th>{backOffice.contractStartDate}</th>
							<th>{backOffice.contractEndDate}</th>
							<th>{backOffice.mobileNumber}</th>
							<th>{backOffice.status}</th>
							<th>{backOffice.tools}</th>
						</tr>
					</thead>
					<tbody>
						{HotelList?.length > 0 ? (
							AllHotels
						) : (
							<tr>
								<td colSpan="7">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				{HotelList?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}
			</div>
		</>
	);
}
