import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { fetchOfflineSuppler } from "services/manualSupplier";
import OfflineFilter from "./OfflineFilter";
import OfflineTable from "./offlineSupplierTable";
const ManualSuppliers = () => {
	const history = useHistory();
	const { search } = useLocation();
	const [_, pages] = search.split("=");
	const [properties, setProperties] = useState([]);
	const [meta, setMeta] = useState(null);

	const fetchOffline = async (filters = null, page = pages) => {
		const offlinedata = await fetchOfflineSuppler(filters, page);
		if (offlinedata.status == 200) {
			setProperties(offlinedata.data.data);
			setMeta(offlinedata.data.meta);
		}
	};
	useEffect(() => {
		fetchOffline();
	}, [pages]);

	const goTo = (page) => {
		fetchOffline(null, page);
		const params = new URLSearchParams();
		if (page) {
			params.append("page", page);
		} else {
			params.delete("page", page);
		}
		history.push({ search: params.toString() });
	};

	return (
		<div className="manual-suppliers">
			<div className="d-flex justify-content-between">
				<h6>Manual Suppliers</h6>
				<button
					className="btn btn-primary"
					onClick={() => history.push("/manual-suppliers/add")}
				>
					Add New Supplier
				</button>
			</div>
			<div className="container-fluid">
				<OfflineFilter
					properties={properties}
					setProperties={setProperties}
					pages={pages}
					setMeta={setMeta}
				/>
				<OfflineTable
					properties={properties}
					setProperties={setProperties}
					goTo={goTo}
					meta={meta}
				/>
			</div>
		</div>
	);
};

export default ManualSuppliers;
