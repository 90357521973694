import { useState } from "react";
import { Collapse } from "reactstrap";
import Amenity from "./Amenity";

function OtherAmenity({
  category = [],
  name,
  allAmenities,
  setAllAmenities,
  roomTypes,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const toggleButton = () => setIsOpen(!isOpen);
  //
  // 	"category",
  // 	category.filter((item) => item.most_requested === 0)
  // );

  const getSelected = () => {
    let selected = [];
    const filtered = category
      .filter((item) => item.most_requested === "0")
      .map((item) => (item = item.id));
    allAmenities.map((item) => {
      if (filtered.includes(item.id)) {
        selected.push(item);
      }
    });

    return selected;
  };

  console.log("allAmenities", allAmenities);
  return (
    <>
      <div className="button-collapse Amenities-collapse" onClick={toggleButton}>
        <div className="d-flex align-items-center justify-content-between ps-3">
          <h6 className='m-0'>{name}</h6>

          <div className="icon-items-section d-flex align-items-center">
            <div className="mx-5 text-bold">
              {getSelected().length}/
              {category.filter((item) => item.most_requested === "0").length}{" "}
              selected
            </div>
            {/* <i
              onClick={toggleButton}
              className={`fas fa-fw pointer fa-lg ${
                isOpen !== true ? "fa-chevron-right " : "fa-chevron-down"
              } `}
            ></i> */}
            <button className="collapse-btn  px-3 py-2" onClick={toggleButton}>
					{isOpen == true? (
						<i class="fas fa-minus  text-white"></i>
					) : (
						<i class="fas fa-plus text-white"></i>
					)}
				</button>
          </div>
        </div>
      </div>
      <Collapse isOpen={isOpen} className="collapse-box">
        {category
          .filter((item) => +item.most_requested === 0)
          .map((amenity, i) => (
            <Amenity
              key={i}
              allAmenities={allAmenities}
              setAllAmenities={setAllAmenities}
              amenity={amenity}
              roomTypes={roomTypes}
            />
          ))}
      </Collapse>
    </>
  );
}

export default OtherAmenity;
