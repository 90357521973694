import { useEffect, useState } from "react";
import { fetchCities } from "services/lookups";

export const LooksupCities = (id) => {
  const [Cities, setCities] = useState([]);
  let locale = localStorage.getItem("currentLocale") || "en";

  useEffect(() => {
       if (id) {
         async function fetchLookups() {
           const res = await fetchCities(id);
           const format = res?.data?.map((t) => ({
             value: t.id,
             label: t.names[locale],
           }));
           setCities(format);
         }
         fetchLookups();
       }
  }, [id]);

  return Cities;
};
export default LooksupCities;
