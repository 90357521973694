import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
// import Summary from "./Summary";
import Locale from "translations";
import ProcessTransactionsRequests from "./ProcessTransactionsRequests/ProcessTransactionsRequests";
import TableTransaction from "./ProcessTransactionsRequests/TableTransaction";
import RequestBank from "./RequestBank/RequestBank";
import CurrentBalance from "./CurrentBalance";

export default function Details(props) {
  const { payment } = Locale;

  const history = useHistory();

  return (
    <>
      <div className="wallet-wrraper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <CurrentBalance />
            </div>
            <div className="col-md-6 text-center">
              {/* <Summary /> */} Summary
            </div>
          </div>
          <RequestBank />
        </div>
      </div>
      {/* <ProcessTransactionsRequests /> */}
      <TableTransaction />
    </>
  );
}
