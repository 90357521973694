import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { fetchSupplierReservations } from "services/suppliers";
import FilterReservation from "./FilterReserviton";
import ReservationTable from "./ReservationTable";
function OnlineSupReservation() {
	const [Reservation, setReservation] = useState(null);
	const { search } = useLocation();
	const history = useHistory();
	const [_, pages] = search.split("=");
	const [meta, setmeta] = useState([]);
	const { name, id } = useParams();

	const [filters, setFilters] = useState({
		search: "",
		check_in: "",
		destination: "",
		status: "",
	});
	// filter
	useEffect(() => {
		async function fetchReservationsFilterdData() {
			const data = {
				...filters,
				search: filters.search,
				destination: filters.destination?.value,
				check_in: filters?.check_in
					? moment(filters?.check_in).format("YYYY-MM-DD")
					: "",
				status: filters.status?.value,
			};
			const response = await fetchSupplierReservations(id, data);
			setReservation(response?.data.data);
			setmeta(response?.data?.meta);
		}
		fetchReservationsFilterdData();
	}, [filters]);

	const goto = (page) => {
		setFilters({ ...filters, page: page });
	};
	return (
		<>
			<h5>
				Reservations <span className="tentative">{`(${name})`}</span>
			</h5>
			<FilterReservation setFilters={setFilters} filters={filters} />
			<ReservationTable
				Reservation={Reservation}
				setReservation={setReservation}
				meta={meta}
				goto={goto}
			/>
		</>
	);
}
export default OnlineSupReservation;
