import { useState } from "react";
import { NavLink } from "react-bootstrap";
import Locale from "translations";
import VendorCommission from "./vendorCommission";
import VendorReservation from "./vendorReservation";
import VendorWallet from "./vendorWallet";

export default function ManageVendor() {
	const { vendor, wallet } = Locale;
	const [activeTab, setActiveTab] = useState(1);
	const [isOpenAdd, setIsOpenAdd] = useState(false);
	const toggleAdd = () => {
		setIsOpenAdd(!isOpenAdd);
	};
	return (
		<div className="vendor">
			<div
				className=" tabs d-flex align-items-center all-tabs"
				style={{ borderBottom: "1px solid #d9dde0" }}
			>
				<div className="d-flex justify-content-between w-100">
					<div className="">
						<NavLink
							exact={true}
							activeClassName="is-active"
							className={`btn mx-1 bg-subNav text-bold px-3 font-weight-bold our-taps
                         ${activeTab === 1 ? " is-active" : ""}`}
							onClick={() => {
								setActiveTab(1);
							}}
						>
							{vendor.commission}
						</NavLink>

						<NavLink
							exact={true}
							activeClassName="is-active"
							className={`btn mx-1 bg-subNav text-bold px-3 font-weight-bold our-taps
					${activeTab === 2 ? "is-active" : ""}`}
							onClick={() => {
								setActiveTab(2);
							}}
						>
							{vendor.reservations}
						</NavLink>
						<NavLink
							exact={true}
							activeClassName="is-active"
							className={`btn mx-1 bg-subNav text-bold px-3 font-weight-bold our-taps
					${activeTab === 3 ? "is-active" : ""}`}
							onClick={() => {
								setActiveTab(3);
							}}
						>
							{vendor.wallet}
						</NavLink>
					</div>
					{activeTab === 3 ? (
						<button
							type="button"
							class="btn btn-success text-white px-5 mx-3 my-1"
							onClick={toggleAdd}
						>
							{wallet.AddWallet}
						</button>
					) : null}
				</div>
			</div>
			{activeTab === 1 ? (
				<VendorCommission />
			) : activeTab === 2 ? (
				<VendorReservation />
			) : activeTab === 3 ? (
				<VendorWallet isOpenAdd={isOpenAdd} toggleAdd={toggleAdd} />
			) : null}
		</div>
	);
}
