import PasswordField from "components/shared/PasswordField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import { getNotification } from "helpers/makeNotifications";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { addNewCompany, uploadFile } from "services/companies";
import { fetchCities, fetchCountries } from "services/lookups";
import Locale from "translations";

export default function AddCompanyDetails() {
	const { backOffice, userDetails, companyDetails } = Locale;
	const { locale } = useGlobalState();
	let history = useHistory();
	const [companyDetailsState, setCompanyDetailsState] = useState({
		user_full_name: "",
		user_email: "",
		user_nationality: "",
		user_phone: "",
		user_password: "",
		user_password_confirmation: "",
		company_name: "",
		lang: locale,
		company_address: "",
		company_business_email: "",
		company_country_id: "",
		company_city_id: "",
		company_phone: "",
		secondPhone: "",
		company_avatar: "",
		displayImg: "",
	});
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "name", value: companyDetailsState.user_full_name },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "email", value: companyDetailsState.user_email },
				{ required: true, email: true }
			),
			...validate(
				{ name: "country", value: companyDetailsState.user_nationality },
				{ required: true }
			),
			...validate(
				{ name: "phone", value: companyDetailsState.user_phone },
				{ required: true, phone: true }
			),
			...validate(
				{ name: "password", value: companyDetailsState.user_password },
				{ required: true, password: true, min: 8 }
			),
			...validate(
				{
					name: "confirm",
					value: companyDetailsState.user_password_confirmation,
				},
				{ required: true, confirm: true },
				companyDetailsState.user_password
					? companyDetailsState.user_password
					: ""
			),
			...validate(
				{ name: "companyName", value: companyDetailsState.company_name },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "companyAddress", value: companyDetailsState.company_address },
				{ required: true }
			),
			...validate(
				{
					name: "businessEmail",
					value: companyDetailsState.company_business_email,
				},
				{ required: true, email: true }
			),
			...validate(
				{
					name: "companyCountry",
					value: companyDetailsState.company_country_id,
				},
				{ required: true }
			),
			...validate(
				{ name: "city", value: companyDetailsState.company_city_id },
				{ required: true }
			),
			...validate(
				{ name: "companyPhone", value: companyDetailsState.company_phone },
				{ required: true, phone: true }
			),
			// ...validate(
			// 	{ name: "companyPhoneSecond", value: companyDetailsState.secondPhone },
			// 	{ required: true, phone: true }
			// ),
			...validate(
				{ name: "logo", value: companyDetailsState.company_avatar },
				{ required: true, phone: true }
			),
		});
	};
	const [countries, setCountries] = useState([]);
	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCountries();
			const format = res.data.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}

		fetchLookups();
	}, []);
	let [cities, setCities] = useState([]);
	const fetchCity = async (id) => {
		const res = await fetchCities(id);
		const formatted = res?.data?.map((country) => ({
			value: country.id,
			label: country.names[locale],
		}));
		setCities(formatted);
	};
	const addCompany = async () => {
		let formData = new FormData();
		formData.append("file", companyDetailsState.company_avatar);
		let isImgSelected = "";
		if (companyDetailsState.company_avatar) {
			isImgSelected = await uploadFile(formData);
		} else {
			setErrors({
				...errors,
				logo: { required: true },
			});
		}
		if (isImgSelected.status === 200) {
			const res = await addNewCompany({
				...companyDetailsState,
				company_country_id: companyDetailsState?.company_country_id?.value,
				company_city_id: companyDetailsState?.company_city_id?.value,
				user_nationality: companyDetailsState?.user_nationality?.value,
				user_phone: `+${companyDetailsState?.user_nationality?.value}${companyDetailsState.user_phone}`,
				company_phone: `+${companyDetailsState?.company_country_id?.value}${companyDetailsState.company_phone}`,
				company_avatar: isImgSelected.data.uuid,
			});
			if (res.status === 200 || res.status === 201) {
				getNotification(res.data.message);
				history.push(`/tourism-company`);
			}
		}
	};

	useEffect(() => {
		if (isFormValid(errors)) {
			addCompany();
		}
	}, [isErrorLoaded]);
	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	console.log("companyDetailsState101", companyDetailsState);
	return (
		<div className="px-3">
			{/*****  Start Personal Info  ******/}
			<div className="bankConfigrationHeader p-3 text-visa-header font-weight-bold mt-3">
				<p className="m-0">Personal Info</p>
			</div>
			<div className="row pb-3 px-3 our-border p-0 m-0">
				<div className="col-4 mt-2">
					<TextField
						type="text"
						label={userDetails.fullName}
						placeholder={userDetails.fullNamePlaceholder}
						value={companyDetailsState.user_full_name}
						name="name"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									min: 3,
								}),
							});
							setCompanyDetailsState({
								...companyDetailsState,
								user_full_name: e.target.value,
							});
						}}
						min={3}
						color={errors?.name?.required || errors?.name?.min ? "danger" : ""}
						errors={errors?.name}
					/>
				</div>
				<div className="col-4 mt-2">
					<TextField
						label={"Email Address"}
						placeholder={userDetails.businessEmailPlaceholder}
						value={companyDetailsState.user_email}
						name="email"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									email: true,
								}),
							});
							setCompanyDetailsState({
								...companyDetailsState,
								user_email: e.target.value,
							});
						}}
						color={
							errors?.email?.required || errors?.email?.email ? "danger" : ""
						}
						errors={errors?.email}
					/>
				</div>
				<div className="col-4">
					<SelectField
						label={userDetails.country}
						value={companyDetailsState.user_nationality}
						name="country"
						options={countries}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "country", value: e.value },
									{ required: true }
								),
							});
							setCompanyDetailsState({
								...companyDetailsState,
								user_nationality: e,
								user_phone: "",
							});
						}}
						color={errors?.country?.required ? "danger" : ""}
						errors={errors?.country}
					/>
				</div>
				<div className="col-4">
					<TextField
						label={userDetails.phoneNumber}
						placeholder={userDetails.phoneNumberPlaceholder}
						name="phone"
						value={companyDetailsState.user_phone}
						extraText={
							companyDetailsState.user_nationality?.value
								? "+" + companyDetailsState.user_nationality?.value
								: "---"
						}
						extraTextPosition="prepend"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "phone", value: e.target.value },
									{
										required: true,
										phone: true,
									}
								),
							});
							setCompanyDetailsState({
								...companyDetailsState,
								user_phone: e.target.value,
							});
						}}
						color={
							errors?.phone?.required || errors?.phone?.phone ? "danger" : ""
						}
						errors={errors?.phone}
					/>
				</div>

				<div className="col-4">
					<PasswordField
						label={userDetails.password}
						placeholder={userDetails.passwordPlaceholder}
						name="password"
						value={companyDetailsState.user_password}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "password", value: e.target.value },
									{
										required: true,
										password: true,
										min: 8,
									}
								),
							});
							setCompanyDetailsState({
								...companyDetailsState,
								user_password: e.target.value,
							});
						}}
						min={8}
						color={
							errors?.password?.required ||
							errors?.password?.password ||
							errors?.password?.min
								? "danger"
								: ""
						}
						errors={errors?.password}
					/>
				</div>
				<div className="col-4">
					<PasswordField
						label={userDetails.repeatPassword}
						placeholder={userDetails.repeatPasswordPlaceholder}
						name="confirm"
						value={companyDetailsState.user_password_confirmation}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "confirm", value: e.target.value },
									{
										required: true,
										confirm: true,
									},
									companyDetailsState.user_password
										? companyDetailsState.user_password
										: ""
								),
							});
							setCompanyDetailsState({
								...companyDetailsState,
								user_password_confirmation: e.target.value,
							});
						}}
						min={8}
						color={
							errors?.confirm?.required || errors?.confirm?.confirm
								? "danger"
								: ""
						}
						errors={errors?.confirm}
					/>
				</div>
			</div>
			{/*****  End Personal Info  ******/}

			{/*****  Start Company Info  ******/}
			<div className="bankConfigrationHeader   p-3 text-visa-header font-weight-bold mt-3">
				<p className="m-0">Company Info</p>
			</div>
			<div className="row pb-3 px-3 our-border p-0 m-0">
				<div className="col-4 mt-2">
					<TextField
						type="text"
						label={companyDetails.companyName}
						placeholder={companyDetails.companyNamePlaceholder}
						value={companyDetailsState.company_name}
						name="companyName"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									min: 3,
								}),
							});
							setCompanyDetailsState({
								...companyDetailsState,
								company_name: e.target.value,
							});
						}}
						min={3}
						color={
							errors?.companyName?.required || errors?.companyName?.min
								? "danger"
								: ""
						}
						errors={errors?.name}
					/>
				</div>
				<div className="col-4 mt-2">
					<TextField
						label={companyDetails.address}
						placeholder={companyDetails.addressPlaceholder}
						value={companyDetailsState.company_address}
						name="companyAddress"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
								}),
							});
							setCompanyDetailsState({
								...companyDetailsState,
								company_address: e.target.value,
							});
						}}
						color={errors?.companyAddress?.required ? "danger" : ""}
						errors={errors?.companyAddress}
					/>
				</div>

				<div className="col-4">
					<TextField
						label={companyDetails.businessEmail}
						placeholder={companyDetails.businessEmailPlaceholder}
						value={companyDetailsState.company_business_email}
						name="businessEmail"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									email: true,
								}),
							});
							setCompanyDetailsState({
								...companyDetailsState,
								company_business_email: e.target.value,
							});
						}}
						color={
							errors?.businessEmail?.required || errors?.businessEmail?.email
								? "danger"
								: ""
						}
						errors={errors?.businessEmail}
					/>
				</div>

				<div className="col-4">
					<SelectField
						label={userDetails.country}
						value={companyDetailsState.company_country_id}
						name="companyCountry"
						options={countries}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "companyCountry", value: e.value },
									{ required: true }
								),
							});
							setCompanyDetailsState({
								...companyDetailsState,
								company_country_id: e,
								company_phone: "",
								company_city_id: "",
							});
							fetchCity(e.value);
						}}
						color={errors?.country?.required ? "danger" : ""}
						errors={errors?.country}
					/>
				</div>
				<div className="col-4">
					<SelectField
						label={companyDetails.city}
						value={companyDetailsState.company_city_id}
						name="city"
						options={cities}
						disabled={cities?.length <= 0}
						placeholder={companyDetails.cityPlaceholder}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "city", value: e.value },
									{ required: true }
								),
							});
							setCompanyDetailsState({
								...companyDetailsState,
								company_city_id: e,
							});
						}}
						color={errors.city?.required ? "danger" : ""}
						errors={errors.city}
					/>
				</div>

				<div className="col-4">
					<TextField
						label={userDetails.phoneNumber}
						placeholder={userDetails.phoneNumberPlaceholder}
						name="companyPhone"
						value={companyDetailsState.company_phone}
						extraText={
							companyDetailsState.company_country_id?.value
								? "+" + companyDetailsState.company_country_id?.value
								: "---"
						}
						extraTextPosition="prepend"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "companyPhone", value: e.target.value },
									{
										required: true,
										phone: true,
									}
								),
							});
							setCompanyDetailsState({
								...companyDetailsState,
								company_phone: e.target.value,
							});
						}}
						color={errors?.companyPhone?.required ? "danger" : ""}
						errors={errors?.companyPhone}
					/>
				</div>
				<div className="col-4">
					<TextField
						label={userDetails.phoneNumber}
						placeholder={userDetails.phoneNumberPlaceholder}
						name="companyPhoneSecond"
						value={companyDetailsState.secondPhone}
						extraText={
							companyDetailsState.company_country_id?.value
								? "+" + companyDetailsState.company_country_id?.value
								: "---"
						}
						extraTextPosition="prepend"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "companyPhoneSecond", value: e.target.value },
									{
										required: true,
										phone: true,
									}
								),
							});
							setCompanyDetailsState({
								...companyDetailsState,
								secondPhone: e.target.value,
							});
						}}
						color={errors?.secondPhone?.required ? "danger" : ""}
						errors={errors?.secondPhone}
					/>
				</div>
			</div>
			{/*****  End Company Info  ******/}

			{/*****  Start Company Logo  ******/}
			<div className="bankConfigrationHeader p-3 text-visa-header font-weight-bold mt-3">
				<p className="m-0">Company Logo</p>
			</div>
			<div
				className={`our-border   py-5 ${
					errors?.logo?.required ? "border border-danger" : ""
				}`}
			>
				{companyDetailsState.displayImg ? (
					<figure className="d-flex  justify-content-center ">
						<img src={`${companyDetailsState.displayImg}`} className="col-2" />
					</figure>
				) : null}

				<div className=" border-0 text-center d-flex  justify-content-center">
					<button className="file-btn shape-border py-2">
						Add Photo
						<input
							type="file"
							className="custom-file"
							accept="image/png, image/gif, image/jpeg"
							onChange={(e) => {
								setCompanyDetailsState({
									...companyDetailsState,
									displayImg: URL.createObjectURL(e.target.files[0]),
									company_avatar: e.target.files[0],
								});
								setErrors({
									...errors,
									...validate(
										{ name: "logo", value: e.target.value },
										{
											required: true,
										}
									),
								});
							}}
						/>
					</button>
				</div>
			</div>

			<div className="d-flex  justify-content-end mt-3 ">
				<button
					className=" cancel-bg p-2 px-4 mx-3"
					onClick={() => {
						history.push(`/tourism-company`);
					}}
				>
					Back
				</button>
				<button
					className="btn companies-btn"
					onClick={() => {
						submit();
					}}
				>
					Add
				</button>
			</div>
		</div>
		//   <div className='px-3'>

		//   <div className="col-4">
		//     <SelectField
		//       hasLabel={true}
		//       placeholder={requests.Currency}
		//       name="status"
		//       label={requests.Currency}
		//       options={currencies}
		//       value={bankInfo.currency}
		//       onChange={(e) => {
		//         setBankInfo({
		//           ...bankInfo,
		//           currency: e
		//         })
		//         setErrors({
		//           ...errors,
		//           ...validate(
		//             { name: "currency", value: e.value },
		//             {
		//               required: true,
		//             }
		//           ),
		//         });

		//       }}
		//       errors={errors?.currency}
		//       color={errors?.currency?.required ? "danger" : ""}
		//     />
		//   </div>
		//   <div className='bankConfigrationHeader mx-1 p-3 text-visa-header font-weight-bold my-3'>
		//     <p className='m-0'>
		//       {requests.Countries}
		//     </p>
		//   </div>

		//   <div className="col-4">
		//     <SelectField
		//       hasLabel={true}
		//       placeholder={requests.Country}
		//       options={countries}
		//       value={null}
		//       name="status"
		//       label={requests.Country}
		//       onChange={(e) => {
		//         setselectedCountries([...selectedCountries, e])
		//         setErrors({
		//           ...errors,
		//           ...validate(
		//             { name: "Country", value: e.value },
		//             {
		//               required: true,
		//             }
		//           ),
		//         });
		//       }}
		//       errors={errors?.Country}
		//       color={errors?.Country?.required ? "danger" : ""}

		//     />
		//   </div>

		//   <div className='row px-3'>
		//     {selectedCountries.map((country) => {
		//       return <span className='border bg-gray p-2 mx-2 col-2 d-flex justify-content-between align-items-center my-2 ' >
		//         {country.label} <i class="fas fa-times text-gray mx-2 pointer" onClick={() => removeCountry(country.value)}></i>
		//       </span>

		//     })}
		//   </div>
		//   <div className='bankConfigrationHeader mx-1 p-3 text-visa-header font-weight-bold my-3'>
		//     <p className='m-0'>
		//       {requests.BankDetails}
		//     </p>
		//   </div>

		//   <div className='row p-0 m-0'>

		//     <div className='col-4'>
		//       <TextField
		//         type="text"
		//         placeholder={requests.BankName}
		//         hasLabel={true}
		//         label={requests.BankName}
		//         value={bankInfo.bank_name}
		//         onChange={(e) => {
		//           setBankInfo({
		//             ...bankInfo,
		//             bank_name: e.target.value
		//           })
		//           setErrors({
		//             ...errors,
		//             ...validate(
		//               { name: "bank_name", value: e.target.value },
		//               {
		//                 required: true,
		//               }
		//             ),
		//           });
		//         }}
		//         errors={errors?.bank_name}
		//         color={errors?.bank_name?.required ? "danger" : ""}
		//       />
		//     </div>

		//     <div className='col-4'>
		//       <TextField
		//         type="text"
		//         placeholder={requests.Beneficiary}
		//         hasLabel={true}
		//         label={requests.Beneficiary}
		//         value={bankInfo.beneficiary}
		//         onChange={(e) => {
		//           setBankInfo({
		//             ...bankInfo,
		//             beneficiary: e.target.value
		//           })
		//           setErrors({
		//             ...errors,
		//             ...validate(
		//               { name: "beneficiary", value: e.target.value },
		//               {
		//                 required: true,
		//               }
		//             ),
		//           });
		//         }}
		//         errors={errors?.beneficiary}
		//         color={errors?.beneficiary?.required ? "danger" : ""}
		//       />
		//     </div>
		//     <div className='col-4'>
		//       <TextField
		//         type="text"
		//         placeholder={requests.Branch}
		//         hasLabel={true}
		//         label={requests.Branch}
		//         value={bankInfo.branch}
		//         onChange={(e) => {
		//           setBankInfo({
		//             ...bankInfo,
		//             branch: e.target.value
		//           })
		//           setErrors({
		//             ...errors,
		//             ...validate(
		//               { name: "branch", value: e.target.value },
		//               {
		//                 required: true,
		//               }
		//             ),
		//           });
		//         }}
		//         errors={errors?.branch}
		//         color={errors?.branch?.required ? "danger" : ""}
		//       />
		//     </div>
		//     <div className='col-4'>
		//       <TextField
		//         type="text"
		//         placeholder={requests.IBAN}
		//         hasLabel={true}
		//         label={requests.IBAN}
		//         value={bankInfo.iban}
		//         onChange={(e) => {
		//           setBankInfo({
		//             ...bankInfo,
		//             iban: e.target.value
		//           })

		//           setErrors({
		//             ...errors,
		//             ...validate(
		//               { name: "iban", value: e.target.value },
		//               {
		//                 required: true,
		//               }
		//             ),
		//           });
		//         }}
		//         errors={errors?.iban}
		//         color={errors?.iban?.required ? "danger" : ""}
		//       />
		//     </div>
		//     <div className='col-4'>
		//       <TextField
		//         type="text"
		//         placeholder={requests.AccountNo}
		//         hasLabel={true}
		//         label={requests.AccountNo}
		//         value={bankInfo.account_no}
		//         onChange={(e) => {
		//           setBankInfo({
		//             ...bankInfo,
		//             account_no: e.target.value
		//           })
		//           setErrors({
		//             ...errors,
		//             ...validate(
		//               { name: "account_no", value: e.target.value },
		//               {
		//                 required: true,
		//               }
		//             ),
		//           });
		//         }}
		//         errors={errors?.account_no}
		//         color={errors?.account_no?.required ? "danger" : ""}
		//       />
		//     </div>

		//     <div className='col-4'>
		//       <TextField
		//         type="text"
		//         placeholder={requests.Swift}
		//         hasLabel={true}
		//         label={requests.Swift}
		//         value={bankInfo.swift}
		//         onChange={(e) => {
		//           setBankInfo({
		//             ...bankInfo,
		//             swift: e.target.value
		//           })
		//           setErrors({
		//             ...errors,
		//             ...validate(
		//               { name: "swift", value: e.target.value },
		//               {
		//                 required: true,
		//               }
		//             ),
		//           });
		//         }}
		//         errors={errors?.swift}
		//         color={errors?.swift?.required ? "danger" : ""}
		//       />
		//     </div>

		//   </div>

		//   <div className='bankConfigrationHeader mx-1 p-3 text-visa-header font-weight-bold my-3'>
		//     <p className='m-0'>
		//       {requests.Logo}
		//     </p>
		//   </div>

		//   <div className={`bg-gray  py-5 ${errors?.logo?.required ? "border border-danger" : ""}`}>

		//     {bankInfo.displayImg ? <figure className='d-flex  justify-content-center '>
		//       <img src={`${bankInfo.displayImg}`} className="col-2" />
		//     </figure> : null}

		//     <div className=" border-0 text-center d-flex  justify-content-center">
		//       <button className="file-btn shape-border py-2">
		//         {requests.AddPhoto}
		//         <input
		//           type="file"
		//           className="custom-file"
		//           accept="image/png, image/gif, image/jpeg"
		//           onChange={(e) => {
		//             setBankInfo({
		//               ...bankInfo,
		//               displayImg: URL.createObjectURL(e.target.files[0]),
		//               logo: e.target.files[0],
		//             });
		//             setErrors({
		//               ...errors,
		//               ...validate(
		//                 { name: "logo", value: e.target.value },
		//                 {
		//                   required: true,
		//                 }
		//               ),
		//             });

		//             if (status === "edit") {
		//               setIsImgChanged(true)
		//             }

		//           }}
		//         />
		//       </button>
		//     </div>
		//   </div>

		//   <div className="d-flex  justify-content-end mt-5">
		//     <button className=' cancel-bg p-2 px-4 text-white mx-3'
		//       onClick={() => {
		//         history.push(`/bank-configuration`)
		//       }}
		//     >Back</button>

		//     {status === "add" ? <button className='btn companies-btn'
		//       onClick={() => {
		//         addBank()
		//       }}>
		//       Add
		//     </button>
		//       : <button className='btn companies-btn'
		//         onClick={() => {
		//           editBank()
		//         }}>
		//         Edit </button>}

		//   </div>

		// </div>
	);
}
