import walletAdd from "assets/images/wallet/wallet-add.svg";
import { Modal, ModalBody } from "reactstrap";
import Locale from "translations";

export default function ViewTransactionPopup({ isOpen, toggle, popupInfo }) {
	const { wallet } = Locale;

	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
				<p className=" text-secondary font-wallet-popup">
					{wallet.ViewTransactions}
				</p>
				<i
					className="far fa-times-circle fa-lg text-secondary pointer"
					onClick={toggle}
				></i>
			</div>

			<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3">
				<img src={walletAdd} alt="" />
				<div className="row m-0 p-0 mt-5 justify-content-center  align-items-center text-dark">
					<p className="col-5  font-wallet-popup ">{wallet.receiptNumber} </p>
					<p className="col-5">
						{popupInfo?.transaction_number || popupInfo?.id?.transaction_number}
					</p>
					{/* <p className="col-5  font-wallet-popup ">{wallet.company_name}</p> */}

					{/* <p className="col-5   ">
						{popupInfo?.company_name ? popupInfo?.company_name : "-"}
					</p> */}
					<p className="col-5  font-wallet-popup">{wallet.requestDate}</p>
					<p className="col-5   ">
						{popupInfo?.created_at?.slice(0, 10) ||
							popupInfo?.id?.created_at?.slice(0, 10)}{" "}
						{popupInfo?.created_at?.slice(11, 16) ||
							popupInfo?.id?.created_at?.slice(0, 10)}
					</p>
					<p className="col-5  font-wallet-popup ">{wallet.amount}</p>
					<p className="col-5">
						{popupInfo?.amount || popupInfo?.id?.amount}{" "}
						{popupInfo?.deal_id || popupInfo?.currency}
					</p>
					{/* <p className="col-5  font-wallet-popup ">Deposit Photo</p>
					<figure className="col-5">
						-
					</figure> */}
				</div>
			</ModalBody>
		</Modal>
	);
}
