import Pagination from "components/shared/Pagination";
import { useGlobalDispatch } from "context/global";
import createDOMPurify from "dompurify";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import {
	cancelRoom,
	confirmBookingHotels,
	fetchVoucher,
	getAllSpecialRequests,
	getListWallets,
	updateBooking,
} from "services/Request";
import Locale from "translations";
import Passenger from "./passenger";
import PassengerFilter from "./PassengerFilter";

export default function Passangers({ passangerInfo }) {
	const dispatch = useGlobalDispatch();
	let history = useHistory();

	const data = passangerInfo?.payload?.rooms[0];
	console.log("data012", passangerInfo);
	const [filter, setFilter] = useState({
		departure_date: null,
		arrival_date: null,
		search: "",
		page: 1,
	});
	const [meta, setMeta] = useState();
	const [passengers, setPassengers] = useState([]);
	const { backOffice, booking, inventory } = Locale;
	// Handel Models
	const [cancelIsOpen, setcancelIsOpen] = useState(false);
	const [guestIsOpen, setGuestIsOpen] = useState(false);
	const [editIsOpen, setEditIsOpen] = useState(false);
	const [docIsOpen, setDocIsOpen] = useState(false);
	const [payIsOpen, setPayIsOpen] = useState(false);
	const DOMPurify = createDOMPurify(window);

	const [selectedGuests, setSelectedGuests] = useState(data?.passengers);
	const [listWallets, setListWallets] = useState([]);
	const [editState, setEditState] = useState({
		checkIn: passangerInfo?.start_date
			? moment(passangerInfo?.start_date)
			: moment(new Date()).add(1, "d"),
		checkOut: passangerInfo?.end_date
			? moment(passangerInfo.end_date)
			: moment(new Date()).add(2, "d"),
		adult: +passangerInfo?.adults,
		child: +passangerInfo?.children,
		roomType: null,
	});
	const [selected, setSelected] = useState({});
	const [childrenArray, setchildrenArray] = useState([]);
	const [swichToggle, setswichToggle] = useState(true);
	const [configData, setConfigData] = useState({});
	const [radio, setRadio] = useState();

	const handleSwitch = () => {
		setswichToggle(!swichToggle);
	};
	const listB = [
		{
			value: 0,
			label: "No Preference",
		},
		{
			value: 1,
			label: "King Size",
		},
		{
			value: 2,
			label: "Queen Size",
		},
		{
			value: 3,
			label: "Twin",
		},
	];
	useEffect(() => {
		setPassengers(passangerInfo?.payload.rooms);
	}, [data]);

	const toggleCancel = () => {
		setcancelIsOpen(!cancelIsOpen);
	};
	const toggleGuset = () => {
		setGuestIsOpen(!guestIsOpen);
	};
	const toggleEdit = () => {
		setEditIsOpen(!editIsOpen);
	};
	const toggleDoc = () => {
		setDocIsOpen(!docIsOpen);
	};
	const togglePay = () => {
		setPayIsOpen(!payIsOpen);
	};

	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};
	useEffect(() => {
		setchildrenArray(selected.childrenAges);
		setEditState({
			...editState,
			adult: selected?.adults,
			checkIn: selected?.from_date
				? moment(selected.from_date)
				: moment(new Date()).add(1, "d"),
			checkOut: selected?.to_date
				? moment(selected.to_date)
				: moment(new Date()).add(2, "d"),
			child: selected.children,
			childrenArray: selected.childrenAges,
			maxOccupancy: selected.maxOccupancy,
			adultOption: selected?.maxAdult
				? [...Array(+selected?.maxAdult).keys()].map((i, index) => ({
						value: index + 1,
						label: index + 1,
				  }))
				: [],
			childOption: selected?.maxChildren
				? [...Array(+selected?.maxChildren).keys()].map((i, index) => ({
						value: index + 1,
						label: index + 1,
				  }))
				: [],
			rateOption: selected?.room_rates
				? selected?.room_rates.map((res) => ({
						...res,
						label: res.mealTitle + "   (" + res.room_total_price + ")    ",
						value: res.roomConfigurationId,
				  }))
				: [],
			BeddingOption: listB,
			beddingPreferenceName: selected?.beddingPreference
				? listB.filter((res) => +res.id == +selected?.beddingPreference)[0]
						?.name
				: "",
			mealOptionId: selected?.roomConfigurationId,
			mealOptionName: selected?.room_rates
				? selected?.room_rates.filter(
						(res) => +res?.mealOptionId == +selected?.mealOptionId
				  )[0]?.mealTitle
				: "",
			allowsSpecialRequests: selected.allowsSpecialRequests
				? selected?.allowsSpecialRequests
				: true,
			specialRequests: selected?.specialRequests,
			beddingPreference: selected?.beddingPreference,
		});
	}, [selected]);
	const [SpecialRequests, setSpecialRequests] = useState([]);
	useEffect(async () => {
		const SpecialRequests = await getAllSpecialRequests();

		if (SpecialRequests?.status == 200) {
			setSpecialRequests(SpecialRequests.data);
		}
	}, []);
	const requsetEdit = async () => {
		const Data = {
			brn: passangerInfo?.brn,
			date_from: moment(editState.checkIn).format("YYYY-MM-DD"),
			date_to: moment(editState.checkOut).format("YYYY-MM-DD"),
			bookingCode: selected.bookingCode,
			mealOptionId: editState.mealOptionId?.toString(),
			provider_id: passangerInfo?.gds,
			company_id: passangerInfo?.company_id,
			number_of_adult: editState.adult,
			beddingPreference: editState.beddingPreference.toString()
				? editState.beddingPreference.toString()
				: "0",
			children:
				editState.childrenArray && editState.childrenArray.length > 0
					? editState.childrenArray
					: [],
			special_requests:
				editState.specialRequests && editState.specialRequests.length > 0
					? editState.specialRequests
					: [],
		};
		let book;
		book = await updateBooking(Data);
		if (book.status === 200) {
			//setRejectModal(false);
			//setModalEdit(false);
			toggleEdit();
			setConfigData(book.data);
			//setModalPay(true);
			togglePay();
			async function fetchSafaWallet() {
				setListWallets([]);
				const res = await getListWallets({
					company_id: passangerInfo?.company_id,
					currency: book.data.rate_types[0].currency,
				});

				if (res.status == 200) {
					setListWallets(res?.data?.data);
				}
			}
			fetchSafaWallet();
		} else {
			if (book.status == 400) {
				store.addNotification({
					title: "error!",
					message: "Error!",
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
			setEditIsOpen(false);
			//toggleRejectModal();
		}
	};
	const payAfterEdit = async () => {
		const Data = {
			brn: passangerInfo.brn,
			company_id: passangerInfo?.company_id,
			beddingPreference: editState.beddingPreference
				? editState.beddingPreference.toString()
				: "0",
			date_from: moment(editState.checkIn).format("YYYY-MM-DD"),
			date_to: moment(editState.checkOut).format("YYYY-MM-DD"),
			bookingCode: selected.bookingCode,
			mealOptionId: editState.mealOptionId.toString(),
			provider_id: 4,
			number_of_adult: editState.adult,
			children:
				editState.childrenArray && editState.childrenArray.length > 0
					? editState.childrenArray
					: [],
			special_requests:
				editState.specialRequests && editState.specialRequests.length > 0
					? editState.specialRequests
					: [],

			confirmData: {
				new_price: configData.rate_types[0].new_price,
				allocation_details: configData.rate_types[0].allocation_details,
				amend_charge: configData.rate_types[0].amend_charge,
			},
			payment_method: radio,
		};
		let book;
		book = await confirmBookingHotels(Data);

		if (book.status === 200) {
			store.addNotification({
				title: "Info!",
				message: "updated successfully!",
				type: "info",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			togglePay();
			// fetchSafaWallet();
			// fetchData();
			// togglePayModal();
		}
	};
	const [cancellationReason, setCancellationReason] = useState("");
	const [RoomsList, setRoomsList] = useState(passangerInfo?.payload?.rooms);
	const cancelBooking = async () => {
		let cancel;
		const Data = {
			brn: passangerInfo.brn,
			bookingCode: selected.bookingCode,
			cancellationReason: cancellationReason,
		};

		cancel = await cancelRoom(Data);

		if (cancel.status === 200) {
			toggleCancel();
			//fetchData();
			//fetchSafaWallet();
			store.addNotification({
				title: "Info!",
				message: "Cancal Reservation successfully!",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};
	const voucherData = async (romeKey) => {
		const res = await fetchVoucher(passangerInfo?.brn, romeKey);
		if (res.status == 200) {
			console.log("voucherData");
			dispatch({ type: "getVoucher", payload: res?.data?.data });
			history.push("/inventory-voucher");
		}
	};

	const setValueSpecialRequests = (checked, value) => {
		let x =
			editState.specialRequests && editState.specialRequests.length > 0
				? [...editState.specialRequests]
				: [];
		if (checked) {
			x.push({ req: value });
		} else {
			x = x.filter((res) => res.req != value);
		}
		setEditState({
			...editState,
			specialRequests: x,
		});
	};
	const setNewChildrenQuantity = (e) => {
		let x = [...Array(+e).keys()].map((i) => ({
			age: 0,
		}));
		setEditState({ ...editState, childrenArray: x, child: e });
	};
	const changeAge = (index, e) => {
		let x = editState.childrenArray;
		x[index] = { age: e.target.value };
		setEditState({ ...editState, childrenArray: x });
	};

	const allPassengersList = useMemo(() => {
		return passengers?.length > 0
			? passengers?.map((data, index) => {
					return (
						<Passenger
							index={index}
							passenger={data}
							passangerInfo={passangerInfo?.payload?.rooms[0]}
							toggleCancel={toggleCancel}
							toggleGuset={toggleGuset}
							toggleEdit={toggleEdit}
							toggleDoc={toggleDoc}
							setSelected={setSelected}
							voucherData={voucherData}
							status={passangerInfo?.reservation_status}
							passengers={passangerInfo}
						/>
					);
			  })
			: [];
	}, [passengers]);
	return (
		<>
			<div className="border mt-2">
				<div>
					<PassengerFilter filter={filter} setFilter={setFilter} />
				</div>

				<div className=" table-responsive">
					<table className="custom-table back-office-table table table-striped">
						<thead>
							<tr className="text-nowrap">
								<th>{booking.RoomNo}</th>
								<th>{booking.Arrival}</th>
								<th>{booking.Departure}</th>
								<th>{booking.PassengerName}</th>
								<th>{booking.RoomType}</th>
								<th>{booking.Adult}</th>
								<th>{booking.Child}</th>
								<th>{booking.BoardBasis}</th>
								<th>{booking.Price}</th>
								{/* <th>{booking.Actions}</th> */}
							</tr>
						</thead>

						<tbody>
							{allPassengersList.length > 0 ? (
								allPassengersList
							) : (
								<tr>
									<td colSpan="15">
										<div className="product-no-data">
											<i className="fas fa-info-circle fa-lg"></i>{" "}
											<h4>{backOffice.noResult}</h4>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
					<div className="px-2">
						<Pagination info={meta} goTo={goTo} />
					</div>
				</div>
			</div>
		</>
	);
}
