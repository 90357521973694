import React, { createContext, useContext, useReducer } from "react";
const BackOfficeContext = createContext();
const BackOfficeDispatchContext = createContext();
const initialState = {};

function backOfficeReducer(state, action) {
	switch (action.type) {
		case "teams": {
			return {
				...state,
				teams: action.payload,
			};
		}
		
		case "currentPage":
			return { ...state, currentPage: action.payload };

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useBackOfficeState() {
	const context = useContext(BackOfficeContext);
	if (context === undefined) {
		throw new Error(
			"useBackOfficeState must be used within a BackOfficeProvider"
		);
	}
	return context;
}

function useBackOfficeDispatch() {
	const context = useContext(BackOfficeDispatchContext);
	if (context === undefined) {
		throw new Error(
			"useBackOfficeDispatch must be used within a BackOfficeProvider"
		);
	}
	return context;
}

function BackOfficeProvider({ children }) {
	const [state, dispatch] = useReducer(backOfficeReducer, initialState);
	return (
		<BackOfficeContext.Provider value={state}>
			<BackOfficeDispatchContext.Provider value={dispatch}>
				{children}
			</BackOfficeDispatchContext.Provider>
		</BackOfficeContext.Provider>
	);
}

export { BackOfficeProvider, useBackOfficeState, useBackOfficeDispatch };
