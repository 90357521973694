import resetIcon from "assets/images/wallet/resetIcon.png";
import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useState } from "react";
import { fetchCitiesSearch } from "services/lookups";
import Locale from "translations";

export default function FilterReservation({ onReset, filters, setFilters }) {
	const { backOffice, booking } = Locale;
	// const { allCountries } = useGlobalState();

	const statusLookups = [
		{ value: "pending", label: "PENDING" },
		{ value: "approved", label: "APPROVED" },
		{ value: "rejected", label: "REJECTED" },
		{ value: "paid", label: "PAID" },
		{ value: "canceled", label: "CANCELED" },
		{ value: "expired", label: "EXPIRED" },
		{ value: "completed", label: "COMPLETED" },
	];

	function resetFilters() {
		setFilters({
			search: "",
			check_in: "",
			destination: "",
			status: "",
		});
	}

	const [listAuto, setListAuto] = useState([]);
	const getListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const countries = await fetchCitiesSearch(inputValue);
			let result = countries.map((item) => {
				return {
					...item,
					id: item.id,
					name: item.name,
					value: item.id,
					label: item.name,
				};
			});

			setListAuto(result);
		}
	};
	const handleChange = (e, flag = false) => {
		if (flag) {
			setFilters({
				...filters,
				page: 1,
				country: e["value"],
				goingTo: e.value,
			});
		} else
			setFilters({
				...filters,
				page: 1,
				destination: {
					label: e?.value?.name,
					value: e?.value?.id,
					country: e?.value?.country,
				},
			});
		setFilters({
			...filters,
			page: 1,
			destination: {
				label: e?.value?.name,
				value: e?.value?.id,
				country: e?.value?.country,
			},
		});
	};
	return (
		<div className="main-filter bg-white-blue py-2 mt-2">
			<div className="row align-items-center  w-100 no-gutter m-0">
				<div className="col-md-3">
					<div className="main-label">
						<TextField
							type="text"
							placeholder={"search"}
							label={"search"}
							value={filters.search}
							onChange={(e) => {
								setFilters({
									...filters,
									page: 1,
									search: e.target.value,
								});
							}}
						/>
						<i className="fas fa-search fa-fw"></i>
					</div>
				</div>

				<div className="col-md-2">
					<DatePickerField
						label={booking.CheckinDate}
						placeholder={booking.CheckinDate}
						date={filters.check_in}
						onChangeDate={(filterDate) => {
							setFilters({
								...filters,
								page: 1,
								check_in: filterDate,
							});
						}}
						isOutsideRange={(day) => {
							return false;
						}}
					/>
				</div>

				<div className="col-md-2 booking-autoComplete">
					<AutoCompleteField
						label={booking.Destination}
						placeholder={booking.Destination}
						hasLabel={true}
						flag={filters.goingTo?.country?.flag}
						listAuto={listAuto}
						setListAuto={setListAuto}
						getListAuto={getListAuto}
						isSearchable={true}
						value={filters.destination?.label ? filters.destination?.label : ""}
						onChange={(e) =>
							handleChange({ name: "destination", value: { name: e } }, true)
						}
						onSelectValue={(e) => {
							handleChange({ name: "destination", value: e }, true);
						}}
					/>
				</div>

				<div className="col-md-2">
					<SelectField
						label={backOffice.status}
						value={filters.status}
						placeholder={backOffice.status}
						name="country"
						options={statusLookups}
						onChange={(e) => {
							setFilters({
								...filters,
								page: 1,
								status: e,
							});
						}}
					/>
				</div>

				<div className="col-md" onClick={resetFilters}>
					<div
						onClick={onReset}
						className="d-flex align-items-center text-secondary pointer mt-4 reset-btn"
					>
						<img src={resetIcon} alt="" srcset="" />
						<p className="text-caption">{backOffice.resetFilter}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
