import axios from "axios";
import { cleanEmpty } from "services/general";
const backOfficeApi = process.env.REACT_APP_API_URL + "/api";

export const listBankConfigrations = async (params) => {
    return await axios
        .get(`${backOfficeApi}/bank-transfer-details`, {
            params: cleanEmpty(params),
        })
        .then((res) => res)
        .catch((err) => err.response);
};

export const addBankConfigration = async (data) => {
    return await axios
        .post(`${backOfficeApi}/bank-transfer-details/add`, data)
        .then((res) => res)
        .catch((err) => err.response);
};

export const editBankConfigration = async (data) => {
    return await axios
        .post(`${backOfficeApi}/bank-transfer-details/update/transfer`, data)
        .then((res) => res)
        .catch((err) => err.response);
};

export const viewBankConfigrations = async (id) => {
    return await axios
        .get(`${backOfficeApi}/bank-transfer-details/view/${id}`)
        .then((res) => res)
        .catch((err) => err.response);
};

export const fetchImagesBank = async (id) => {
    return await axios
        .get(`${backOfficeApi}/files/fetch/${id}`)
        .then((res) => res.config.url)
        .catch((err) => err.response);
};

