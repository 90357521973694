import "@fortawesome/fontawesome-free/css/all.min.css";
import { useGlobalState } from "context/global";
import useFetchLookups from 'hooks/useFetchLookups';
import moment from "moment";
import "moment/locale/ar";
import { Suspense } from "react";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { BrowserRouter as Router } from "react-router-dom";
import "scss/style.scss";
import Locale from "translations";
import Loader from "./components/Loader";
import AxiosConfiguration from "./helpers/axiosConfiguration";
import Routes from "./routes";


export default function App(props) {
  const { isAuth, locale, loading } = useGlobalState();
  Locale.setLanguage(locale);
  document
    .getElementsByTagName("html")[0]
    .setAttribute("dir", locale === "ar" ? "rtl" : "ltr");
  moment.locale("en");

  // Axios Configurations
  const lookups = useFetchLookups(isAuth);

  AxiosConfiguration();


  return (
    <>
      {loading && <Loader />}
      <div
        style={{
          direction: locale === "ar" ? "rtl" : "ltr",
          textAlign: locale === "ar" ? "right" : "left",
          fontFamily: locale === "ar" ? "Cairo" : "Roboto",
        }}
      >
        <ReactNotification />

        <Router>
          <Suspense fallback={<Loader />}>{Routes}</Suspense>
        </Router>

      </div>
    </>
  );
}
