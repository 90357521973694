import { useGlobalDispatch } from "context/global";
import { useHistory } from "react-router";
import Locale from "translations";
import Order from "./Order";
const locale = localStorage.getItem("currentLocale") || "en";

export default function OrdersTable({ Orders, goTo, meta, status }) {
	const dispatch = useGlobalDispatch();
	const history = useHistory();
	const { backOffice, translate_companies } = Locale;

	const calculateStep = (progress) => {
		// switch (Math.floor(progress)) {
		//   case 16:
		//     return "2";
		//   case 33:
		//     return "3";
		//   case 50:
		//     return "4";
		//   case 66:
		//     return "5";
		//   case 83:
		//     return "6";
		//   default:
		return "1";
		// }
	};

	const AllProducts =
		Orders &&
		Orders.length > 0 &&
		Orders.map((company) =>
			company.traveller_details.map((traveller) => {
				return (
					<Order company={company} traveller={traveller} key={company.id} />
				);
			})
		);

	return (
		<>
			<table className="custom-table back-office-table table table-striped">
				<thead>
					<tr>
						<th className="CompanyHead-title">
							{translate_companies.order_number}
						</th>
						<th className="CompanyHead-title">
							{translate_companies.type_examination}
						</th>
						<th className="CompanyHead-title">
							{translate_companies.customer_name}
						</th>
						<th className="CompanyHead-title">
							{translate_companies.medical_service_provider}
						</th>
						<th className="CompanyHead-title">
							{translate_companies.date_request}
						</th>
						<th className="CompanyHead-title">
							{translate_companies.examination_date}
						</th>
						<th className="CompanyHead-title">{translate_companies.cost}</th>
						{status === "checked" ? (
							<th className="CompanyHead-title">
								{translate_companies.time_taken}
							</th>
						) : null}
						<th className="CompanyHead-title">
							{translate_companies.payment_method}
						</th>
						<th className="CompanyHead-title">
							{translate_companies.company_ratio}
						</th>
					</tr>
				</thead>
				<tbody>
					{Orders?.length > 0 ? (
						AllProducts
					) : (
						<tr>
							<td colSpan="11">
								<div className="product-no-data">
									<i className="fas fa-info-circle fa-lg"></i>{" "}
									<h4>{backOffice.noResult}</h4>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
			{/* {Orders?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null} */}
		</>
	);
}
