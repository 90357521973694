import { store } from "react-notifications-component";
import { useParams } from 'react-router-dom';
import { deleteOffer } from "services/platform";
import Locale from "translations";
import PlatformOffers from './PlartformOffer';


export default function PlatfromSpecialOffer({ specialOfferList, setSpecialOfferList }) {
  const { backOffice } = Locale;
  const { id } = useParams();

  const changeValueOffer = (index, name, value, data) => {
    let list = specialOfferList;
    setSpecialOfferList([]);
    list[index] = { ...list[index], ...data, [name]: value };
    console.log("list[index]", list[index]);
    setTimeout(() => {
      setSpecialOfferList(list);
    }, 10);
  };



  const deleteOfferfn = async (e, index) => {
    if (id && e.id != null) {
      const res = await deleteOffer(id, e.id);
      if (res.status === 200) {
        // dispatch({ type: "progress", step: "2", current: "2" });
        // propertyDispatch({ type: "hotelMainDetails", payload: res.data });
        removeItemOffer(index);
        store.addNotification({
          title: "Done!",
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      }
    }
  };

  const removeItemOffer = async (index) => {
    let clonedCommissions = [...specialOfferList];
    clonedCommissions.splice(index, 1);
    setSpecialOfferList(clonedCommissions);
  };


  return (
    <div className="row custom--row">
      <div className="col">
        <div className="personal-data">
          <div className="col-md-12 head-manager p-0">
            <p>{backOffice.SpecialOffers}</p>
          </div>

          {specialOfferList?.length > 0 ? (
            <div className="col our-border px-4">
              <table className="custom-table back-office-table table table-striped">
                <thead>
                  <tr>
                    <th style={{ maxWidth: "320px", width: "320px" }}>
                      {backOffice.From}
                    </th>
                    <th style={{ maxWidth: "320px", width: "320px" }}>
                      {backOffice.To}
                    </th>
                    <th style={{ maxWidth: "320px", width: "320px" }}>
                      {backOffice.Offer}
                    </th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {specialOfferList
                    ? specialOfferList?.map((item, index) => (
                      <PlatformOffers
                        item={item}
                        deleteOfferfn={deleteOfferfn}
                        changeValueOffer={changeValueOffer}
                        index={index}
                      />
                    ))
                    : null}
                </tbody>
              </table>
            </div>
          ) : null}

          <a className="btn btn-lg text-success d-inline-block my-2 " href={() => false}
            onClick={() => {
              let x = { edit: true, form: new Date(), offer: "", id: null, to: new Date() };
              setSpecialOfferList([...specialOfferList, x]);
            }}
          >
            <i className="fas fa-plus-circle"></i>{" "}
            {backOffice.AddSpecialOffers}
          </a>
        </div>
      </div>
    </div>
  )
}

