import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import Locale from "translations";

export default function Filter({ filter, setFilter }) {
	const { backOffice } = Locale;
	const statusOptions = [
		{ value: "success", label: "Success" },
		{ value: "pending", label: "Pending" },
	];

	return (
		<>
			<div className="main-filter  our-border bg-white-blue py-2 mt-2 w-100">
				<div className="row align-items-center w-100 no-gutter m-0">
					<div className="col-3">
						<TextField
							type="text"
							placeholder={backOffice.search}
							label={backOffice.search}
							value={filter.search}
							onChange={(e) => {
								setFilter({
									...filter,
									search: e.target.value,
								});
							}}
						/>
					</div>

					<div className="col-3">
						<DatePickerField
							type="text"
							placeholder={"DD/MM/YYYY"}
							label={backOffice.date}
							date={filter.created_at}
							onDateChange={(e) => {
								setFilter({
									...filter,
									created_at: e,
								});
							}}
						/>
					</div>
					<div className="col-3">
						<SelectField
							label={backOffice.visaStatus}
							/* 							hasLabel={false}
							 */ placeholder={backOffice.visaStatus}
							name="status"
							options={statusOptions}
							value={filter.status}
							onChange={(e) => {
								setFilter({
									...filter,
									status: e,
								});
							}}
						/>
					</div>

					<div
						className="col-3"
						onClick={() =>
							setFilter({
								search: "",
								date: null,
								status: null,
							})
						}
					>
						<div className="d-flex align-items-center text-secondary pointer mt-4 reset-btn">
							<img src={resetIcon} alt="" srcset="" />

							<p className="text-caption">{backOffice.resetFilter}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
