function Travelers({ travelers }) {
	return (
		<div className="px-3 our-border mt-2">
			<table className="custom-table back-office-table table table-striped mb-0">
				<thead>
					<tr>
						<th>Passport No.</th>
						<th>Name</th>
						<th>Gender</th>
						<th>Birthdate</th>
						<th>Nationality</th>
						<th>Passport Expire Date</th>

						<th>Visa</th>
						<th>Action</th>
					</tr>
				</thead>

				<tbody>
					{travelers?.length > 0 ? (
						travelers.map((travel) => (
							<tr>
								<td>{travel.passport_number}</td>
								<td>{travel.name}</td>
								<td>{travel.gender}</td>
								<td>{travel.birth_date}</td>
								<td>{travel.nationality_name}</td>
								<td>{travel.passport_expiry}</td>
								<td>{travel.visa_status}</td>
								<td>{}</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="15">
								<div className="product-no-data">
									<i className="fas fa-info-circle fa-lg"></i>
									<h4>No Result</h4>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
}

export default Travelers;
