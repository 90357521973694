import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import Locale from "translations";

export default function SideFilter({
	toggleShow,
	show,
	filter,
	setFilter,
	countries,
	handleChange,
	listAuto,
	setListAuto,
	getListAuto,
}) {
	const { booking } = Locale;

	const statusOptions = [
		{ value: 1, label: "pending" },
		{ value: 2, label: "rejected" },
		{ value: 3, label: "approved" },
		{ value: 4, label: "canceled" },
		{ value: 5, label: "paid" },
		{ value: 6, label: "confirmed" },
		{ value: 7, label: "expired" },
	];
	return (
		<div className="w-100    pointer ">
			<div
				onClick={() => {
					toggleShow({ filter: !show.filter, colums: false });
				}}
				className={`border rounded filter-col  d-flex justify-content-between align-items-center p-2 ${
					show.filter ? "booking-active" : " txt-blue"
				}`}
			>
				<span className="">{booking.Filters}</span>
				<i class="fas fa-angle-down "></i>
			</div>

			{show.filter ? (
				<>
					<div className="booking-dropdown-filter border rounded p-2">
						<div className="col">
							<DatePickerField
								type="text"
								placeholder={booking.BookingDate}
								onOutsideClick={false}
								hasLabel={false}
								date={filter?.booking_date}
								onChangeDate={(e) => {
									setFilter({
										...filter,
										booking_date: e,
										page: null,
									});
								}}
							/>
						</div>

						<div className="col">
							<DatePickerField
								type="text"
								placeholder={booking.CheckinDate}
								onOutsideClick={false}
								hasLabel={false}
								date={filter?.start_date}
								onChangeDate={(e) => {
									setFilter({
										...filter,
										start_date: e,
										page: null,
									});
								}}
							/>
						</div>

						<div className="col">
							<DatePickerField
								type="text"
								placeholder={booking.CancellationDate}
								onOutsideClick={false}
								hasLabel={false}
								date={filter?.cancellation_date}
								onChangeDate={(e) => {
									setFilter({
										...filter,
										cancellation_date: e,
										page: null,
									});
								}}
							/>
						</div>

						<div className="col">
							<TextField
								type="text"
								placeholder={booking.Nights}
								hasLabel={false}
								value={filter.nights_count}
								onChange={(e) => {
									setFilter({
										...filter,
										nights_count: e.target.value,
										page: null,
									});
								}}
							/>
						</div>

						<div className="col">
							<TextField
								type="text"
								placeholder={booking.ReferenceAny}
								hasLabel={false}
								value={filter.reference_no}
								onChange={(e) => {
									setFilter({
										...filter,
										reference_no: e.target.value,
										page: null,
									});
								}}
							/>
						</div>
						<div className="col">
							<TextField
								type="text"
								placeholder={booking.BRN}
								hasLabel={false}
								value={filter.brn}
								onChange={(e) => {
									setFilter({
										...filter,
										brn: e.target.value,
										page: null,
									});
								}}
							/>
						</div>
						<div className="col">
							<TextField
								type="text"
								placeholder={booking.AgencyName}
								hasLabel={false}
								value={filter.search}
								onChange={(e) => {
									setFilter({
										...filter,
										search: e.target.value,
										page: null,
									});
								}}
							/>
						</div>

						<div className="col">
							<TextField
								type="text"
								placeholder={booking.HotelName}
								hasLabel={false}
								value={filter.hotel_name}
								onChange={(e) => {
									setFilter({
										...filter,
										hotel_name: e.target.value,
										page: null,
									});
								}}
							/>
						</div>
						<div className="col booking-autoComplete">
							{/* <SelectField
                        hasLabel={false}
                        placeholder={booking.Destination}
                        name="status"
                        options={countries}
                        value={filter.destination}
                        onChange={(e) => {
                            setFilter({
                                ...filter,
                                destination: e,
                                page: null
                            })
                        }}
                    /> */}
							<AutoCompleteField
								hasLabel={true}
								flag={filter.goingTo?.country?.flag}
								listAuto={listAuto}
								setListAuto={setListAuto}
								getListAuto={getListAuto}
								isSearchable={true}
								placeholder={booking.Destination}
								value={filter.destination}
								onChange={(e) =>
									handleChange(
										{ name: "destination", value: { name: e } },
										true
									)
								}
								onSelectValue={(e) => {
									handleChange({ name: "destination", value: e }, true);
								}}
							/>
						</div>
						{/* <div className="col">
                            <TextField
                                type="text"
                                placeholder={booking.LeaderName}
                                hasLabel={false}
                                value={filter.leader_name}
                                onChange={(e) => {
                                    setFilter({
                                        ...filter,
                                        leader_name: e.target.value,
                                        page: null
                                    })
                                }}
                            />
                        </div> */}

						<div className="col">
							<SelectField
								type="text"
								placeholder={booking.Status}
								hasLabel={false}
								options={statusOptions}
								value={filter.reservation_status}
								onChange={(e) => {
									setFilter({
										...filter,
										reservation_status: e,
										page: null,
									});
								}}
							/>
						</div>
					</div>
				</>
			) : null}
		</div>
	);
}
