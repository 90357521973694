import React from "react";
import TextField from "components/Form/TextField/TextField";
import DatePickerField from "components/shared/DatePickerField";
import Locale from "translations";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
const OwnerPersonalInfo = ({
  personal,
  index,
  errors,
  setErrors,
  propertyDetails,
  setPropertyDetails,
  removePersonalInfo,
  setPersonal
}) => {
  const { backOffice } = Locale;

  const handleChange = (e) => {
    if ("target" in e) {
      const value = e.target.value;
      const name = e.target.name;
      setPersonal(index, name, value);
    } else {
      const value = e.value;
      const name = e.name;
      setPersonal(index, name, value);
    }
  };

  return (
    <>
      <div className="row m-0 align-items-center">
        <div className="col-md-4">
          <TextField
            type="text"
            label={backOffice.firstName}
            placeholder={backOffice.firstNamePlaceholder}
            value={personal?.first_name}
            name="first_name"
            onChange={(e) => {
              handleChange(e);
              setErrors({
                ...errors,
                ...validate(
                  { name: `first_name${index}`, value: e.target.value },
                  {
                    required: true
                  }
                )
              });
              setPropertyDetails({
                ...propertyDetails,
                first_name: e.target.value
              });
            }}
            color={errors?.["first_name" + index]?.required ? "danger" : ""}
            errors={errors?.["first_name" + index]}
          />
        </div>

        <div className="col-md-4">
          <TextField
            type="text"
            label={backOffice.lastName}
            placeholder={backOffice.lastNamePlaceholder}
            value={personal?.last_name}
            name="last_name"
            onChange={(e) => {
              handleChange(e);
              setErrors({
                ...errors,
                ...validate(
                  { name: `last_name${index}`, value: e.target.value },
                  {
                    required: true
                  }
                )
              });
              setPropertyDetails({
                ...propertyDetails,
                lastName: e.target.value
              });
            }}
            color={errors?.["last_name" + index]?.required ? "danger" : ""}
            errors={errors?.["last_name" + index]}
          />
        </div>

        <div className="col-md-3">
          <TextField
            type="text"
            label={backOffice.email}
            placeholder={backOffice.email}
            value={personal?.email}
            name="email"
            onChange={(e) => {
              handleChange(e);
              setErrors({
                ...errors,
                ...validate(
                  { name: `email${index}`, value: e.target.value },
                  {
                    required: true
                  }
                )
              });
              setPropertyDetails({
                ...propertyDetails,
                lastName: e.target.value
              });
            }}
            color={errors?.["email" + index]?.required ? "danger" : ""}
            errors={errors?.["email" + index]}
          />
        </div>

        <div className="col-md-1 text-center">
          {index > 0 && (
            <a
              href={() => false}
              className="link-delete text-danger"
              onClick={() => removePersonalInfo(index)}
            >
              <i className="fas fa-trash"></i>
            </a>
          )}
        </div>
      </div>
    </>
  );
};

export default OwnerPersonalInfo;
