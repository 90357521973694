import { useEffect, useState } from "react";
import { fetchCountries } from "services/lookups";

export const LooksupCountries = () => {
  const [countries, setCountries] = useState([]);
  let locale = localStorage.getItem("currentLocale") || "en";

  useEffect(() => {
    async function fetchLookups() {
      const res = await fetchCountries();
      const format = res?.data?.map((t) => ({
        value: t.id,
        label: t.names[locale],
      }));
      setCountries(format);
    }
    fetchLookups();
  }, []);

  return countries;
};
export default LooksupCountries;
