import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from 'context/global';
import { useEffect, useState } from "react";
import Locale from "translations";

export default function ExchangeDetails({ rateRow, setRateRow, errors, setErrors }) {
  const { backOffice } = Locale;
  const { locale, allCurrencies } = useGlobalState();
  const [currencies, setCurrencies] = useState([]);
  useEffect(() => {
    async function currenciesLookup() {
      const format = allCurrencies?.map((t) => ({
        value: t.id,
        label: t.currency_code,
      }));
      setCurrencies(format);
    }
    currenciesLookup();
  }, [allCurrencies]);
  const add = () => {
    setRateRow([
      ...rateRow,
      {
        to: null,
        from: null,
        rate: "",
      },
    ]);
  };
  const deleteRow = (indexs) => {
    let cloneData = rateRow;
    let newData = cloneData.filter((res, index) => indexs != index);
    setRateRow(newData);
  };
  useEffect(() => {
    setRateRow(rateRow);
  });

  const handleChenage = (name, value, index) => {
    let newData = rateRow;
    newData[index][name] = value;
    setRateRow([...newData]);


  };


  return (
    <div className="container">
      {rateRow && rateRow.length > 0
        ? rateRow.map((item, index) => {
          return (
            <div className="visa-row ">
              <div className="col-md-10 m-auto">
                <div className="row visa-row-content flex-wrap">
                  <>
                    <div className="d-flex  flex-wrap col-md-3">
                      <SelectField
                        label={backOffice.From}
                        options={currencies}
                        name="from"
                        onChange={(e) => {
                          handleChenage("from", e, index);
                        }}
                        value={
                          typeof rateRow[index].from === "object"
                            ? rateRow[index].from
                            : {
                              value: rateRow[index].id,
                              label: rateRow[index].from,
                            }
                        }
                        color={errors?.from?.required && rateRow[index].from == null ? "danger" : ""}
                        errors={rateRow[index].from == null ? errors?.from : ""}
                      />
                    </div>
                    <div className="col-md-3">
                      <SelectField
                        label={backOffice.To}
                        options={currencies}
                        name="to"
                        onChange={(e) => {
                          handleChenage("to", e, index);
                        }}
                        value={
                          typeof rateRow[index].to === "object"
                            ? rateRow[index].to
                            : {
                              value: rateRow[index].id,
                              label: rateRow[index].to,
                            }
                        }
                        color={errors?.to?.required && rateRow[index].to == null ? "danger" : ""}
                        errors={rateRow[index].to == null ? errors?.to : ""}
                      />
                    </div>
                    <div className="col-md-3">
                      <TextField
                        type="text"
                        label={backOffice.rate}
                        placeholder={backOffice.rate}
                        name="rate"
                        onChange={(e) => {
                          handleChenage("rate", e.target.value, index);
                        }}
                        value={rateRow[index].rate}
                        color={errors?.rate?.required && rateRow[index].rate == "" ? "danger" : ""}
                        errors={rateRow[index].rate == "" ? errors?.rate : ""}
                      />
                    </div>

                    <div className="col-md-2 d-flex justify-content-center align-items-center">
                      <a
                        className="btn-link text-danger mx-1 w-100"
                        onClick={() => {
                          deleteRow(index);
                        }}
                      >
                        <i class="fas fa-trash-alt"></i> Remove
                      </a>
                    </div>
                  </>
                </div>
              </div>
            </div>
          );
        })
        : null}


      <button
        className="btn-link text-success btn-add-input col-md-3 m-auto"
        onClick={() => add()}
      >
        <i class="fas fa-plus-circle text-success"></i>Add
      </button>
    </div>
  );
}
