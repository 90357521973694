export default function sbsReducer(state, action) {
	switch (action.type) {
		case "setLocale":
			localStorage.setItem("currentLocale", action.payload);
			window.location.reload();
			return { ...state, locale: action.payload };

		case "setLoading":
			return { ...state, loading: action.payload };

		case "progress": {
			return {
				...state,
				progress: action.progress,
				step: action.step,
				current: action.current,
			};
		}
		case "company_name": {
			return {
				...state,
				companyName: action.payload,
			};
		}
		case "login":
			localStorage.setItem("isAuth", true);
			localStorage.setItem("token", action.token);
			localStorage.setItem("user_id", action.user_id);
			localStorage.setItem("permissions", JSON.stringify(action?.permissions));

			return {
				...state,
				isAuth: action.isAuth,
				token: action.token,
				refreshToken: action.refreshToken,
				permissions: action?.permissions,
			};

		case "logout":
			localStorage.removeItem("isAuth");
			localStorage.removeItem("token");
			localStorage.removeItem("permissions");
			return {
				...state,
				isAuth: false,
				token: null,
			};

		case "getPermissions":
			localStorage.setItem("role", action?.payload?.role);
			localStorage.setItem(
				"permissions",
				JSON.stringify(action?.payload?.permissions)
			);
			return {
				...state,
				permissions: action?.payload?.permissions,
				role: action?.payload?.role,
			};
		case "getVoucher":
			return { ...state, voucher: action?.payload };

		case "getCountries":
			return { ...state, allCountries: action?.payload };

		case "getCurrencies":
			return { ...state, allCurrencies: action?.payload };

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}
