import React, { useEffect, useMemo, useState } from "react";
import { getPermissions } from "services/managementTeam";
import PermissionGroup from "./PermissionGroup";

export default function ListPermissions({
  permissions,
  selectedPermissions,
  setSelectedPermissions,
}) {
  const handelCheck = (itemId, flag) => {
    if (flag) {
      setSelectedPermissions([...selectedPermissions, itemId]);
    } else {
      setSelectedPermissions([
        ...selectedPermissions.filter((id) => id != itemId),
      ]);
    }
  };

  const handelCheckAll = (collectionPermission, flag) => {
    if (flag) {
      setSelectedPermissions([
        ...new Set([...selectedPermissions, ...collectionPermission]),
      ]);
    } else {
      setSelectedPermissions(
        selectedPermissions.filter((per) => !collectionPermission.includes(per))
      );
    }
  };

  /* make loop to make permissions groups */

  console.log(selectedPermissions);

  const allPermissions = permissions
    ? Object.keys(permissions).map((permission, index) => {
        return (
          <PermissionGroup
            key={index}
            name={permission}
            permission={permissions[permission]}
            handelCheck={handelCheck}
            selectedPermissions={selectedPermissions}
            handelCheckAll={handelCheckAll}
          />
        );
      })
    : null;

  return <div className="row m-0">{allPermissions}</div>;
}
