import axios from "axios";
import { cleanEmpty } from "services/general";
const backOfficeApi = process.env.REACT_APP_API_URL + "/api";

export const listOnlineVisaRequests = async (params) => {
	return await axios
		.get(`${backOfficeApi}/online-visa/online-visa-requests`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewOnlineVisaRequest = async (id) => {
	return await axios
		.get(`${backOfficeApi}/online-visa/issued-request/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewOnlineVisaRequestWaiting = async (id) => {
	return await axios
		.get(`${backOfficeApi}/online-visa/online-visa-requests/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewOnlineVisaPassenger = async (id) => {
	return await axios
		.get(`${backOfficeApi}/online-visa/show-passenger/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const listSuppliersOnlineVisa = async () => {
	return await axios
		.get(`${backOfficeApi}/back-office/suppliers/dropdown?service_type=2`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const assginSuppliersOnlineVisa = async (data) => {
	return await axios
		.post(`${backOfficeApi}/online-visa/assign-supplier`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const listCycleTypes = async () => {
	return await axios
		.get(`${backOfficeApi}/transportation/list/cycle_types`)
		.then((res) => res)
		.catch((err) => err.response);
};

// api booking
export const listBooking = async (params) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/bookings`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewBookingPassinger = async (id) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/bookings/view/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const getInvouce = async (brn) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/bookings/get-invoice/${brn}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const updateBooking = async (data) => {
	return await axios
		.post(`${backOfficeApi}/marketplace/bookings/update-booking`, data)
		.catch((err) => err.response);
};

export const getAllSpecialRequests = async () => {
	return await axios
		.get(`${backOfficeApi}/marketplace/bookings/get_all_special_requests`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const cancelRoom = async (data) => {
	return await axios
		.post(`${backOfficeApi}/marketplace/bookings/cancel-room`, data)
		.catch((err) => err.response);
};

export const cancelHotelBooking = async (data) => {
	return await axios
		.post(`${backOfficeApi}/marketplace/bookings/cancel-booking`, data)
		.catch((err) => err.response);
};
export const fetchVoucher = async (brn, number) => {
	return await axios.get(
		`${backOfficeApi}/marketplace/bookings/get-voucher/${brn}/${number}`
	);
};
//list Wallets
export const getListWallets = async (data) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/bookings/list-wallets`, {
			params: cleanEmpty(data),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const confirmBookingHotels = async (data) => {
	return await axios
		.post(`${backOfficeApi}/marketplace/bookings/update-booking-confirm`, data)
		.catch((err) => err.response);
};

export const getListNotes = async (brn) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/bookings/notes/${brn}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const addNotes = async (data) => {
	return await axios
		.post(`${backOfficeApi}/marketplace/bookings/notes/add`, data)
		.catch((err) => err.response);
};
export const addRefund = async (data) => {
	return await axios
		.post(`${backOfficeApi}/marketplace/bookings/refund`, data)
		.catch((err) => err.response);
};

export const editConfirmation = async (data) => {
	return await axios
		.post(`${backOfficeApi}/marketplace/bookings/edit-confirmation-num`, data)
		.catch((err) => err.response);
};

//Ground Service
export const listGroundRequests = async (params) => {
	return await axios
		.get(`${backOfficeApi}/ground-work/ground-work-requests`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const assginSuppliersGround = async (data) => {
	return await axios
		.post(`${backOfficeApi}/ground-work/assign-supplier`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewGroundRequestWaiting = async (id) => {
	return await axios
		.get(`${backOfficeApi}/ground-work/ground-work-requests/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewGroundPassenger = async (id) => {
	return await axios
		.get(`${backOfficeApi}/ground-work/show-passenger/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchNotes = async (id) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/bookings/notes/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
//trabsportation
export const listTransportationRequests = async (params) => {
	return await axios
		.get(`${backOfficeApi}/transportation/requests`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewTransportationRequestWaiting = async (id) => {
	return await axios
		.get(`${backOfficeApi}/transportation/requests/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const assginSuppliersTransportation = async (id, data) => {
	return await axios
		.post(
			`${backOfficeApi}/transportation/requests/${id}/assign-supplier`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewTransportationPassenger = async (id_req, id_pass) => {
	return await axios
		.get(
			`${backOfficeApi}/transportation/requests/${id_req}/passenger/${id_pass}`
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const exportToExcel = async (id) => {
	return await axios
		.get(`${backOfficeApi}/transportation/export-request/${id}`, {
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const exportToExcelGround = async (id) => {
	return await axios
		.get(`${backOfficeApi}/ground-work/export-request/${id}`, {
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const addNotesGround = async (id, data) => {
	return await axios
		.post(`${backOfficeApi}/ground-work/add-note/${id}`, data)
		.catch((err) => err.response);
};

export const UpdatePaymentExpirationDate = async (data) => {
	return await axios
		.post(
			`${backOfficeApi}/marketplace/bookings/edit-payment-expiration-date`,
			data
		)
		.catch((err) => err.response);
};
export const addNoteTransportation = async (id, data) => {
	return await axios
		.post(`${backOfficeApi}/transportation/add-note/${id}`, data)
		.catch((err) => err.response);
};
export const fetchNotesTransportation = async (id) => {
	return await axios
		.get(`${backOfficeApi}/transportation/list-notes/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchHistoryBooking = async (id, params) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/bookings/history/${id}`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchBookingHistoryUsers = async (id) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/bookings/history/${id}/users`)
		.then((res) => res)
		.catch((err) => err.response);
};
