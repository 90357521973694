import React from "react";
import { useParams } from "react-router-dom";
import Locale from "translations";

export default function Order({ company, traveller }) {
  const { backOffice, translate_companies } = Locale;
  const { status } = useParams();
  return (
    <tr className="bg-white text-black" key={company.id}>
      <td>{traveller.ref} </td>
      <td>{company?.examination_data?.service}</td>
      <td>{traveller.full_name_ar}</td>

      <td>
        <p>{company?.provider_name}-</p>
        <p>{company?.examination_data?.type}</p>
      </td>
      <td>{traveller?.created_at}</td>
      <td>{traveller?.examination_datetime}</td>

      <td>
        {company?.examination_data?.fees} {translate_companies.pounds}
      </td>
      {status === "checked" ? (
        <td>
          {company?.examination_data?.time} {translate_companies.hour}
        </td>
      ) : null}
      <td>{traveller?.payment_method}</td>
      <td>
        {company?.examination_data?.safa_commission}{" "}
        {translate_companies.pounds}
      </td>
    </tr>
  );
}
