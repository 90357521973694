import { Box, Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
// import { useTranslation } from '../../../../hooks/useTranslation';
import ImageGallery from "./ImageGallery";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			<Box>{children}</Box>
		</div>
	);
}
function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

function ShowRoomDetails({ room }) {
	const [tabState, setTabState] = useState(0);
	// const t = useTranslation();

	const handleChange = (event, newValue) => {
		setTabState(newValue);
	};

	const roomImages = room.images.map((i) => ({
		src: i.url,
		alt: i.description,
	}));
	return (
		<div className="my-2 border-2 border-top ">
			<Box style={{ marginBottom: 16 }}>
				<Tabs
					value={tabState}
					onChange={handleChange}
					aria-label="basic tabs example"
				>
					<Tab label={"gallery"} {...a11yProps(0)} />
					<Tab label={"amenities"} {...a11yProps(1)} />
				</Tabs>
			</Box>
			<TabPanel value={tabState} index={0}>
				<ImageGallery images={roomImages} />
			</TabPanel>
			<TabPanel value={tabState} index={1}>
				<div className="row">
					{room.features.map((i, index) => (
						<div key={index} className="col-md-3 col-sm-4 ">
							<i class="fas fa-check p-1 b2c_primary_color" />
							{i.name}
						</div>
					))}
				</div>
			</TabPanel>
		</div>
	);
}

export default ShowRoomDetails;
