import { useGlobalState } from "context/global";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from "reactstrap";
import { allNotifications, readNotification } from "services/notifaction";
import io from "socket.io-client";
import Locale from "translations";
import iconNotification from "../../../../assets/images/customHeader/Notifications.svg";

// const socket = io("http://localhost:3000");

export const NotifactionHeader = () => {
	// const { userInfo } = useSBSState();
	const { notifaction } = Locale;
	const history = useHistory();
	const { user_id } = useGlobalState();

	const [isConnected, setIsConnected] = useState(false);
	const [notifyData, setNotifyData] = useState();
	const search = window.location.search;
	const name = new URLSearchParams(search);
	const notifactionData = async () => {
		const res = await allNotifications();

		setNotifyData(res?.data?.data);
	};

	useEffect(() => {
		let x = {};
		for (const [key, value] of name) {
			x = { ...x, [key]: value };
		}

		if (Notification.permission === "granted") {
			// alert("we have permission");
		} else if (Notification.permission !== "denied") {
			Notification.requestPermission().then((permission) => {
				console.log(permission);
			});
		}

		if (!("Notification" in window)) {
			console.log("Browser does not support desktop notification");
		} else {
			console.log("Notifications are supported");
		}

		if (!x.hasOwnProperty("token")) {
			notifactionData();
			const socket = io(process.env.REACT_APP_NOTIFICATION_API);
			console.log("done");
			socket.on("connect", () => {
				setIsConnected(true);

				socket.emit("new-user", "back-office", user_id);
			});

			socket.on("new-notification", (data) => {
				// notifactionData();
				async function showNotification() {
					const res = await allNotifications();
					setNotifyData(res?.data?.data);

					let icon =
						"https://homepages.cae.wisc.edu/~ece533/images/airplane.png";
					let body = res?.data?.data[0].title;
					var notification = new Notification("manager", { body, icon });
					notification.onclick = () => {
						notification.close();
						window.parent.focus();
					};
				}
				showNotification();
			});

			socket.on("disconnect", () => {
				setIsConnected(false);
			});

			socket.on("pong", () => {
				// setLastPong(new Date().toISOString());
			});

			return () => {
				socket.off("connect");
				socket.off("disconnect");
				socket.off("pong");
			};
		}
	}, []);
	/****Start Api Notifaction */

	const readNotify = async (id1) => {
		const res = await readNotification(id1);
		notifactionData();
	};

	let notificationLength = notifyData?.filter(
		(data) => data["read_at"] == null
	);

	let allNotifyData =
		notifyData?.length > 3 ? notifyData.slice(0, 3) : notifyData;

	const AllNotification =
		allNotifyData?.length > 0 ? (
			allNotifyData.map((data, index) => (
				<DropdownItem
					tag="button"
					className={`d-flex justify-content-start flex-wrap py-2 border-top DropdownItem font-weight-bold m- ${
						data.read_at == null ? "not-read" : "read"
					}`}
					onClick={() => {
						readNotify(data.id);
						// setTimeout(() => {
						// 	window.location.reload();
						// }, 550);
						if (data.item_type == "company") {
							history.push(`/tourism-company/profile/${data.item_id}`);
						} else if (data.item_type == "brn") {
							history.push(`/booking/view/${data.item_id}`);
						}
					}}
				>
					<h5
						className="w-100 mx-3 text-left mb-0 text-white"
						style={{ textAlign: "left" }}
					>
						{data?.title.length < 25
							? data?.title
							: data?.title.slice(0, 25) + "..."}
					</h5>

					<br />
					<p
						className="mx-3 mb-0 text-left text-white"
						style={{ textAlign: "left" }}
					>
						{data?.body.length < 25
							? data?.body
							: data?.body.slice(0, 25) + "..."}
						{/* {data.body} */}
					</p>
				</DropdownItem>
			))
		) : (
			<DropdownItem tag="button" className="d-flex justify-content-center py-2">
				<p>{notifaction?.noNotification}</p>
			</DropdownItem>
		);
	return (
		<>
			<UncontrolledDropdown setActiveFromChild className="notification ">
				<DropdownToggle className="bell-notifaction mx-2 ">
					<div className="custom-notification">
						<img src={iconNotification} alt="" />
					</div>

					{notificationLength && notificationLength?.length > 0 ? (
						<span>{notifyData?.length}</span>
					) : null}
					{/* {notificationLength && notificationLength?.length > 0 ? (
						<span>{notificationLength?.length}</span>
					) : null} */}
				</DropdownToggle>

				<DropdownMenu className="bg-notify no-padding notify-items notifaction-items-header">
					{AllNotification}
					<DropdownItem
						onClick={() => {
							history.push("/notifications");
						}}
						className="d-flex justify-content-center w-100 notify-btn notify-show-more py-2"
					>
						<h6>View All Notification</h6>
					</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>
		</>
	);
};
