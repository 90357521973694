import axios from "axios";
const notifactionURL = process.env.REACT_APP_API_URL + "/api/notifications";

export const allNotifications = async (page) => {
	return await axios
		.get(`${notifactionURL}?page= ${page}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const readNotification = async (id) => {
	return await axios
		.get(`${notifactionURL}/read/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
