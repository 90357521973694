import BackOfficeRoute from "components/BackOfficeRoute";
import { CompaniesLayoutRoute } from "components/CompaniesLayoutRoute";
import ContractRouter from "components/ContractRouter";
import { HotelLayoutRoute } from "components/HotelLayoutRoute";
import HotelProviderRouter from "components/HotelProviderRouter";
import AllNotifications from "components/Notifications";
import PlatformRoute from "components/PlatformRoute";
import PropertyRoute from "components/PropertyRoute";
import Calendar from "modules/backOffice/Calendar";
import ActivityLogs from "modules/backOffice/Components/activityLogs";
import BankConfigrations from "modules/backOffice/Components/Bank-configration";
import MakeBankConfigrations from "modules/backOffice/Components/Bank-configration/MakeBankConfigrations";
import Bookings from "modules/backOffice/Components/Bookings";
import BookingView from "modules/backOffice/Components/Bookings/booking-view";
import Voucher from "modules/backOffice/Components/Bookings/booking-view/Voucher";
// import ManagingVendor from "modules/backOffice/Components/Vendor/manageVendor/managing";
//import ManageVendor from "modules/backOffice/Components/Vendor/manageVendor/vendorCommission";
import ChangePassword from "modules/backOffice/Components/change-password/ChangePassword";
import EditCompany from "modules/backOffice/Components/Companies/EditCompany";
import ContractListDetails from "modules/backOffice/Components/ContractList/Details/Index";
import EditDetails from "modules/backOffice/Components/ContractList/editDetails";
import ContractDetails from "modules/backOffice/Components/ContractRequest/Details/Index";
import ExchangeRate from "modules/backOffice/Components/ExchangeRate";
import GroundConfiguration from "modules/backOffice/Components/GroundConfiguration";
import HotelDetails from "modules/backOffice/Components/HotelCommissions/HotelDetails/Index";
import AddInvitation from "modules/backOffice/Components/Invitations/AddInvitation";
import AddGroup from "modules/backOffice/Components/ManagementTeam/Groups/AddGroup";
import Groups from "modules/backOffice/Components/ManagementTeam/Groups/Groups";
import AddUser from "modules/backOffice/Components/ManagementTeam/Users/AddUser";
import Users from "modules/backOffice/Components/ManagementTeam/Users/Users";
import ManualSuppliers from "modules/backOffice/Components/manual-suppliers";
import AddSupplier from "modules/backOffice/Components/manual-suppliers/addSupplier";
import ListReservation from "modules/backOffice/Components/manual-suppliers/ListReservation/index";
import Payment from "modules/backOffice/Components/manual-suppliers/payment";
import AddOfflineReservation from "modules/backOffice/Components/OfflineReservation/addOfflineReservation";
import ListOfflineReservations from "modules/backOffice/Components/OfflineReservation/listOfflineReservation";
import PlatformDetails from "modules/backOffice/Components/PlatformCommessions/PlatformDetails/Index";
import Property from "modules/backOffice/Components/Property";
import OnlineVisaRequests from "modules/backOffice/Components/Requests/Visa";
import PassangerProfile from "modules/backOffice/Components/Requests/Visa/PassangerProfile";
import ViewRequest from "modules/backOffice/Components/Requests/Visa/ViewRequest";
import SharingListDetails from "modules/backOffice/Components/SharingList/Details/Index";
import SharingDetails from "modules/backOffice/Components/SharingRequest/Details/Index";
// import ManagingVendor from "modules/backOffice/Components/Vendor/manageVendor/managing";
//import ManageVendor from "modules/backOffice/Components/Vendor/manageVendor/vendorCommission";
import { OnlineVisaRoute } from "components/OnlineVisaRoute";
import AllBookings from "modules/backOffice/Components/B2C/AllBookings";
import AllTransactions from "modules/backOffice/Components/B2C/AllTransactions";
import B2CBookingDetails from "modules/backOffice/Components/B2C/BookingDetails";
import CommissionB2c from "modules/backOffice/Components/B2C/Commission";
import UserActivityLogs from "modules/backOffice/Components/B2C/users/ActivityLogs";
import B2cUsers from "modules/backOffice/Components/B2C/users/List";
import ViewUser from "modules/backOffice/Components/B2C/users/View";
import FinancialOffline from "modules/backOffice/Components/Financial/offlineSuppliers";
import FinancialOnline from "modules/backOffice/Components/Financial/onlineSuppliers";
import FinancialPlatforms from "modules/backOffice/Components/Financial/platforms";
import PopupAds from "modules/backOffice/Components/PopupAds";
import AddEditPopup from "modules/backOffice/Components/PopupAds/addEditPopup";
import PopupAdsCountries from "modules/backOffice/Components/PopupAds/popupAdsCountries";
import SBSReservationView from "modules/backOffice/Components/SBSReservations";
import SBSReservationsList from "modules/backOffice/Components/SBSReservationsList";
import MarketingOffers from "modules/backOffice/Components/SearchHotels";
import MarketingOffersList from "modules/backOffice/Components/SearchHotels/searchItemsTable";
import ViewMarketingOffer from "modules/backOffice/Components/SearchHotels/searchItemsTable/viewitem/ViewSearchHotel";
import OnlineSupReservation from "modules/backOffice/Components/Suppliers/ListReservation";
import OnlinePayment from "modules/backOffice/Components/Suppliers/onlinePayment";
import Suppliers from "modules/backOffice/Components/Suppliers/Suppliers";
import SuppliersEdit from "modules/backOffice/Components/Suppliers/SuppliersEdit";
import TourismCompanies from "modules/backOffice/Components/TourismCompanies";
import AddCompany from "modules/backOffice/Components/TourismCompanies/addCompany";
import Branches from "modules/backOffice/Components/TourismCompanies/Branches";
import Employees from "modules/backOffice/Components/TourismCompanies/Employees";
import AddEmployee from "modules/backOffice/Components/TourismCompanies/Employees/AddEmployee";
import GroupsTourism from "modules/backOffice/Components/TourismCompanies/Groups";
import AddGroupTourism from "modules/backOffice/Components/TourismCompanies/Groups/AddGroupTourism";
import MultiWallet from "modules/backOffice/Components/TourismCompanies/MultiWallet";
import ListWalletTransactions from "modules/backOffice/Components/TourismCompanies/MultiWallet/ListWalletTransactions";
import Profile from "modules/backOffice/Components/TourismCompanies/Profile";
import Reservations from "modules/backOffice/Components/TourismCompanies/Reservations/ListReservations";
import ViewReservations from "modules/backOffice/Components/TourismCompanies/Reservations/ViewReservations";
import TransportationConfiguration from "modules/backOffice/Components/TransportationConfiguration";
import TravelDetails from "modules/backOffice/Components/TravelManagement/Details/index";
import Vendor from "modules/backOffice/Components/Vendor";
import ManageVendor from "modules/backOffice/Components/Vendor/manageVendor";
import ReservationView from "modules/backOffice/Components/Vendor/manageVendor/vendorReservation/reservation-view";
import VendorTransactionsList from "modules/backOffice/Components/Vendor/manageVendor/vendorWallet/vendorTransactionsList";
import VisaConfiguration from "modules/backOffice/Components/VisaConfiguration";
import Wallet from "modules/backOffice/Components/wallet";
import Transactions from "modules/backOffice/Components/wallet/transactions";
import AddEditHotel from "modules/backOffice/Containers/AddEditHotel";
import AddEditPlatform from "modules/backOffice/Containers/AddEditPlatform";
import CompanyManagement from "modules/backOffice/Containers/Companies";
import ContractList from "modules/backOffice/Containers/ContractList";
import ContractRequest from "modules/backOffice/Containers/ContractRequest";
import Home from "modules/backOffice/Containers/Home";
import HotelCommissionsList from "modules/backOffice/Containers/HotelCommissionsList";
import Invitations from "modules/backOffice/Containers/Invitations";
import Orders from "modules/backOffice/Containers/Orders";
import PlatformCommissionList from "modules/backOffice/Containers/PlatformCommissionList";
import Seasons from "modules/backOffice/Containers/Seasons";
import SharingList from "modules/backOffice/Containers/SharingList";
import SharingRequest from "modules/backOffice/Containers/SharingRequest";
import TravelManagement from "modules/backOffice/Containers/TravelManagement";
import { lazy } from "react";
import { Route, Switch } from "react-router-dom";

// Lazy Components
const Error404 = lazy(() => import("./components/Errors/Error404/Error404"));
const Login = lazy(() => import("./modules/auth/pages/Login.js"));

// Application Routes
const Routes = (
	<Switch>
		{/* Login Module */}
		<Route path="/auth/login" exact component={Login} />
		<BackOfficeRoute exact path="/" component={Home} />
		<BackOfficeRoute exact path="/invitation" component={Invitations} />
		<BackOfficeRoute exact path="/notifications" component={AllNotifications} />
		<BackOfficeRoute path="/invitation/add" component={AddInvitation} />
		{/* <BackOfficeRoute
			path="/property-management"
			component={PropertyManagement}
			exact
		/>
		<PropertyRoute
			path="/property-management?page=:page"
			component={PropertyManagement}
		/>
		<PropertyRoute
			path="/property-management/:id"
			component={PropertyDetails}
		/> */}
		{/* <PropertyRoute exact path="/property" component={Property} /> */}
		{/* <PropertyRoute path="/property/:id" component={Property} /> */}
		{/* <BackOfficeRoute path="/seasons" component={Seasons} exact /> */}
		{/* <BackOfficeRoute path="/suppliers" component={Suppliers} exact /> */}
		{/* <BackOfficeRoute
			path="/suppliers/edit/:id"
			component={SuppliersEdit}
			exact
		/> */}

		<PropertyRoute exact path="/property" component={Property} />
		<PropertyRoute path="/property/:id" component={Property} />
		<BackOfficeRoute path="/seasons" component={Seasons} exact />
		<BackOfficeRoute path="/suppliers" component={Suppliers} exact />
		<BackOfficeRoute
			path="/suppliers/edit/:id"
			component={SuppliersEdit}
			exact
		/>
		<BackOfficeRoute
			path="/suppliers/:name/:id/reservation"
			component={OnlineSupReservation}
		/>
		<BackOfficeRoute
			path="/suppliers/payment/:id"
			component={OnlinePayment}
			exact
		/>
		<BackOfficeRoute
			path="/visa-configuration"
			component={VisaConfiguration}
			exact
		/>
		<BackOfficeRoute
			path="/bank-configuration"
			component={BankConfigrations}
			exact
		/>
		<BackOfficeRoute
			path="/bank-configuration/:status/:id"
			component={MakeBankConfigrations}
			exact
		/>
		<BackOfficeRoute
			path="/bank-configuration/:status"
			component={MakeBankConfigrations}
			exact
		/>
		{/* <BackOfficeRoute
			path="/visa-configuration"
			component={VisaConfiguration}
			exact
		/>
	
	 */}
		{/* Online Visa Route */}
		{/* <OnlineVisaRoute
		{/* Online Visa Route */}
		<OnlineVisaRoute
			exact
			path="/online-visa/requests/:status"
			component={OnlineVisaRequests}
		/>
		<BackOfficeRoute
			exact
			path="/online-visa/view/request/:status/:id"
			component={ViewRequest}
		/>
		<BackOfficeRoute
			exact
			path="/online-visa/view/passenger/:status/:request_id/:passenger_id"
			component={PassangerProfile}
		/>
		{/* 
		/>
		<OnlineVisaRoute
			exact
			path="/requests/transportation/:status"
			component={RequestsTransportation}
		/>
		<BackOfficeRoute
			exact
			path="/requests/transportation/:status/:id"
			component={ViewRequestTransportation}
		/>
		<BackOfficeRoute
			exact
			path="/requests/transportation/passenger/:status/:request_id/:passenger_id"
			component={PassangerProfileTransportation}
		/>
		<OnlineVisaRoute
			exact
			path="/requests/ground/:status"
			component={RequestsGround}
		/> */}
		{/* <BackOfficeRoute
		/>
		<BackOfficeRoute
			exact
			path="/requests/ground/:status/:id"
			component={ViewRequestGround}
		/>
		<BackOfficeRoute
			exact
			path="/requests/ground/passenger/:status/:request_id/:passenger_id"
			component={PassangerProfileGround}
		/> */}
		<BackOfficeRoute exact path="/bookings" component={Bookings} />
		<BackOfficeRoute exact path="/booking/view/:id" component={BookingView} />
		<BackOfficeRoute
			exact
			path="/manual-reservations/sbs-reservations"
			component={SBSReservationsList}
		/>
		<BackOfficeRoute
			exact
			path="/manual-reservations/offline-reservations"
			component={ListOfflineReservations}
		/>
		<BackOfficeRoute
			exact
			path="/manual-reservations/offline-reservations/add"
			component={AddOfflineReservation}
		/>
		<BackOfficeRoute exact path="/inventory-voucher" component={Voucher} />
		{/* SBS Group Reservations */}
		<BackOfficeRoute
			exact
			path="/manual-reservations/sbs-reservations/:id"
			component={SBSReservationView}
		/>
		<BackOfficeRoute
			exact
			path="/Offline-reservations/sbs-reservations/:id"
			component={SBSReservationView}
		/>
		{/* B2C */}
		<BackOfficeRoute
			path="/all-transactions"
			component={AllTransactions}
			exact
		/>
		<BackOfficeRoute
			path="/b2c-booking-details/:reservationId"
			component={B2CBookingDetails}
			exact
		/>
		<BackOfficeRoute
			path="/b2c-user-booking-details/:reservationId"
			component={B2CBookingDetails}
			exact
		/>
		<BackOfficeRoute path="/all-bookings" component={AllBookings} exact />
		<BackOfficeRoute path="/b2c-users" component={B2cUsers} exact />
		<BackOfficeRoute path="/b2c-users/:id" component={ViewUser} exact />
		<BackOfficeRoute
			path="/b2c-users/activity-log/:id"
			component={UserActivityLogs}
			exact
		/>
		<BackOfficeRoute path="/b2c-Commission" component={CommissionB2c} exact />
		{/* Tourism  Companies */}
		<BackOfficeRoute
			path="/tourism-company"
			component={TourismCompanies}
			exact
		/>
		<BackOfficeRoute
			path="/tourism-company/add-company"
			component={AddCompany}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/profile/:company_id"
			component={Profile}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/employees/:company_id"
			component={Employees}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/:status/employee/:company_id"
			component={AddEmployee}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/:status/employee"
			component={AddEmployee}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/:status/group/:company_id"
			component={AddGroupTourism}
			exact
		/>
		{/* <CompaniesLayoutRoute
      path="/tourism-company/:status/group"
      component={AddGroupTourism}
      exact
    />
 */}
		<CompaniesLayoutRoute
			path="/tourism-company/groups/:company_id"
			component={GroupsTourism}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/branches/:company_id"
			component={Branches}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/:company_id/wallet"
			component={MultiWallet}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/reservations/:company_id"
			component={Reservations}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/reservations/:company_id/reservation/:reservation_id"
			component={ViewReservations}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/:company_id/wallet/transactions/:wallet_id"
			component={ListWalletTransactions}
			exact
		/>
		{/* <CompaniesLayoutRoute
			path="/wallet/:type/add/:company_id"
			component={CreateCompanyRequest}
			exact
		/>
		<CompaniesLayoutRoute
			path="/wallet/safa-pay/:company_id"
			component={SaddRequest}
			exact
		/>
		<CompaniesLayoutRoute
			path="/wallet/request-view/:id"
			component={RequestVeiw}
			exact
		/>
		<CompaniesLayoutRoute
			path="/wallet/deals-view/:id"
			component={DealsVeiw}
			exact
		/> */}
		<CompaniesLayoutRoute
			path="/tourism-company/orders/:company_id/:status"
			component={Orders}
		/>
		<BackOfficeRoute
			path="/company-management"
			component={CompanyManagement}
			exact
		/>
		<BackOfficeRoute
			path="/companies/edit/:company_id"
			component={EditCompany}
		/>
		<BackOfficeRoute
			path="/company-management?page=:page"
			component={CompanyManagement}
		/>
		<BackOfficeRoute
			path="/contract-request"
			component={ContractRequest}
			exact
		/>
		<BackOfficeRoute
			path="/contract-request?page=:page"
			component={ContractRequest}
		/>
		<ContractRouter path="/contract-request/:id" component={ContractDetails} />
		<ContractRouter path="/contract-list" component={ContractList} exact />
		<ContractRouter
			path="/contract-list?page=:page"
			component={ContractRequest}
		/>
		<ContractRouter path="/contract-list/:id" component={ContractListDetails} />
		<ContractRouter
			path="/contract-list0/edit/:id"
			exact
			component={EditDetails}
		/>
		{/* sharing  */}
		<BackOfficeRoute path="/sharing-request" component={SharingRequest} exact />
		<BackOfficeRoute
			path="/sharing-request?page=:page"
			component={SharingRequest}
		/>
		<BackOfficeRoute path="/sharing-request/:id" component={SharingDetails} />
		<BackOfficeRoute path="/sharing-list" component={SharingList} exact />
		<BackOfficeRoute
			path="/sharing-list?page=:page"
			component={SharingRequest}
		/>
		<BackOfficeRoute path="/sharing-list/:id" component={SharingListDetails} />
		<BackOfficeRoute
			path="/travel-management"
			component={TravelManagement}
			exact
		/>
		<BackOfficeRoute path="/travel-management/:id" component={TravelDetails} />
		{/* Commissions Module */}
		<PlatformRoute
			path="/platform-commissions-list"
			component={PlatformCommissionList}
			exact
		/>
		<PlatformRoute path="/add-platform" component={AddEditPlatform} exact />
		<PlatformRoute path="/edit-platform/:id" component={AddEditPlatform} />
		<PlatformRoute path="/platform-details/:id" component={PlatformDetails} />
		<PropertyRoute
			path="/hotel-commissions-list"
			component={HotelCommissionsList}
			exact
		/>
		<HotelProviderRouter path="/add-hotel" component={AddEditHotel} exact />
		<HotelProviderRouter path="/edit-hotel/:id" component={AddEditHotel} />
		<HotelProviderRouter path="/hotel-details/:id" component={HotelDetails} />
		{/* ---------------------------------------------------------------------------------------------------------- */}
		<HotelLayoutRoute path="/calendar/:id" component={Calendar} />
		{/* team Mangment */}
		{/*   <BackOfficeRoute exact path="/management-team" component={ManagementTeam} /> */}
		<BackOfficeRoute exact path="/management-team/users" component={Users} />
		<BackOfficeRoute
			exact
			path="/management-team/:type/user"
			component={AddUser}
		/>
		<BackOfficeRoute
			exact
			path="/management-team/:type/user/:id"
			component={AddUser}
		/>
		<BackOfficeRoute exact path="/management-team/groups" component={Groups} />
		<BackOfficeRoute
			exact
			path="/management-team/:type/group"
			component={AddGroup}
		/>
		<BackOfficeRoute
			path="/Ground-configuration"
			component={GroundConfiguration}
			exact
		/>
		<BackOfficeRoute
			path="/Transportation-configuration"
			component={TransportationConfiguration}
			exact
		/>
		<BackOfficeRoute
			exact
			path="/management-team/:type/group/:id"
			component={AddGroup}
		/>
		{/* <BackOfficeRoute path="/notification" component={Notification} /> */}
		<BackOfficeRoute path="/exchange-rate" component={ExchangeRate} exact />
		<BackOfficeRoute path="/activityLogs" component={ActivityLogs} exact />
		<BackOfficeRoute path="/wallet" component={Wallet} exact />
		<BackOfficeRoute
			path="/wallet0/:wallet_id"
			component={Transactions}
			exact
		/>
		<BackOfficeRoute path="/vendor-commission" component={Vendor} exact />
		{/* <BackOfficeRoute path="/add-vendor" component={AddVendor} exact /> */}
		<BackOfficeRoute path="/manage-vendor/:id" component={ManageVendor} exact />
		<BackOfficeRoute
			path="/manage-vendor/:id/view/reservation/:brn"
			component={ReservationView}
			exact
		/>
		<BackOfficeRoute
			path="/manage-vendor/:id/wallet/transactions/:wallet_id"
			component={VendorTransactionsList}
			exact
		/>
		<BackOfficeRoute path="/change-password" component={ChangePassword} />
		<BackOfficeRoute
			path="/marketing-offers"
			component={MarketingOffers}
			exact
		/>
		<BackOfficeRoute
			path="/marketing-offers-list"
			component={MarketingOffersList}
		/>
		<BackOfficeRoute
			path="/marketing-offers/:id"
			component={ViewMarketingOffer}
			exact
		/>
		{/*************Manual Suppliers*************/}
		<BackOfficeRoute
			path="/manual-suppliers"
			component={ManualSuppliers}
			exact
		/>
		<PropertyRoute
			path="/manual-suppliers?page=:page"
			component={ManualSuppliers}
			exact
		/>
		<BackOfficeRoute
			path="/manual-suppliers/:type"
			component={AddSupplier}
			exact
		/>
		<BackOfficeRoute
			path="/manual-suppliers/:name/:id/reservation"
			component={ListReservation}
			exact
		/>
		<BackOfficeRoute
			path="/manual-suppliers/view/payment/:id"
			component={Payment}
			exact
		/>
		<BackOfficeRoute path="/popup-ads" component={PopupAdsCountries} exact />
		<BackOfficeRoute path="/view/popup-ads/:id" component={PopupAds} exact />
		<BackOfficeRoute
			path="/popup-ads/:id/:status"
			component={AddEditPopup}
			exact
		/>
		<BackOfficeRoute
			path="/popup-ads/:id/:status/:id_ads"
			component={AddEditPopup}
			exact
		/>
		<BackOfficeRoute
			path="/Financial-onlineSuppliers"
			component={FinancialOnline}
			exact
		/>
		<BackOfficeRoute
			path="/Financial-offlineSuppliers"
			component={FinancialOffline}
			exact
		/>
		<BackOfficeRoute
			path="/Financial-platforms"
			component={FinancialPlatforms}
			exact
		/>
		{/* 
    <BackOfficeRoute exact path="/team-management" component={TeamManagement} />
		<BackOfficeRoute path="/team-management/add-group" component={AddGroups} />
		<BackOfficeRoute path="/team-management/add-user" component={AddUser} />
		<BackOfficeRoute
			path="/team-management/change-password"
			component={ChangePassword}
		/> */}
		{/* <Route path="/modals" component={TeamManagementUI} /> */}
		{/* <PrivateRoute exact path="/property/basic" component={BasicInfo} /> */}
		{/* <PrivateRoute path="/progress-circular" component={ProgressCircular} /> */}
		{/* <PrivateRoute path="/statistics" component={index} /> */}
		{/* General Routes */}
		<Route path="*" component={Error404} />
	</Switch>
);

export default Routes;
