import PasswordField from "components/shared/PasswordField";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import { changeUserPassword } from "services/b2c";
import Locale from "translations";
import { CloseIcon } from "../../../Icons";

export default function ChangePassword({ closeModal, userId }) {
	const { backOffice } = Locale;
	let { id } = useParams();
	const [changePassword, setChangePassword] = useState({
		newPassword: "",
		passwordConfirmation: "",
	});

	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	// Check Errors
	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "newPassword", value: changePassword.newPassword },
				{ required: true, min: 8 }
			),
			...validate(
				{
					name: "passwordConfirmation",
					value: changePassword.passwordConfirmation,
				},
				{ required: true, confirm: true },
				changePassword.newPassword
			),
		});
	};

	const handleCreate = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	useEffect(() => {
		if (isFormValid(errors)) {
			const update = async () => {
				let date = {
					password: changePassword.newPassword,
					password_confirmation: changePassword.passwordConfirmation,
				};
				const res = await changeUserPassword(userId ?? id, date);
				//;
				if (res.status == 200 || res.status == 201) {
					closeModal();
					store.addNotification({
						title: "Info!",
						message: "change password Successfully",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 2000,
							onScreen: true,
							pauseOnHover: true,
						},
						// onRemoval: () => {
						// 	history.push("/");
						// },
					});
				}
			};

			update();
		}
	}, [isErrorLoaded]);
	return (
		<>
			<div className=" container bg-white rounded p-3">
				<div className="d-flex justify-content-between align-items-center">
					<h6 className="font-weight-bold">{backOffice.changePassword}</h6>
					<button className="bg-transparent" onClick={closeModal}>
						<CloseIcon />
					</button>
				</div>
				<div className="row px-3 ">
					<div className="col-md-12">
						<PasswordField
							placeholder={backOffice.newPasswordPlaceholder}
							label={backOffice.newPassword}
							name="newPassword"
							value={changePassword.newPassword}
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(
										{ name: "newPassword", value: e.target.value },
										{
											required: true,
											min: 8,
										}
									),
								});
								setChangePassword({
									...changePassword,
									newPassword: e.target.value,
								});
							}}
							min={8}
							color={
								errors?.newPassword?.required || errors?.newPassword?.min
									? "danger"
									: ""
							}
							errors={errors?.newPassword}
						/>
					</div>
					<div className="col-md-12">
						<PasswordField
							placeholder={backOffice.confirmPasswordPlaceholder}
							label={backOffice.confirmPassword}
							name="confirmationPassword"
							value={changePassword.passwordConfirmation}
							onChange={(e) => {
								setChangePassword({
									...changePassword,
									passwordConfirmation: e.target.value,
								});
								setErrors({
									...errors,
									...validate(
										{
											name: "passwordConfirmation",
											value: e.target.value,
										},
										{
											required: true,
											confirm: true,
										},
										changePassword.newPassword
									),
								});
							}}
							color={
								errors?.passwordConfirmation?.required ||
								errors?.passwordConfirmation?.confirm
									? "danger"
									: ""
							}
							errors={errors?.passwordConfirmation}
						/>
					</div>
					<div className="col-md-12 px-3">
						<button
							className="btn btn-success p-2  text-white  w-100"
							onClick={handleCreate}
						>
							{backOffice.changePassword}
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
