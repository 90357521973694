import { useState } from "react";
import { Link } from "react-router-dom";
import Locale from "translations";

export default function TravelTableContent({ productItem }) {
	const { backOffice } = Locale;

	const [status, setStatus] = useState(
		productItem.status === true ? true : false
	);
	const toggleButton = async () => {
		//Call Api
		setStatus(!status);
	};

	return (
		<>
			<tr key={productItem.id}>
				<td scope="row">{productItem.supplierName}</td>
				<td>{productItem.contractType}</td>
				<td>{productItem.country}</td>

				<td>{productItem.email} </td>
				<td>{productItem.supplierPhone} </td>
				<td>{productItem.supplierDate} </td>
				<td>
					<p className="text-success font-10">{productItem.supplierDate}</p>
					<p className="text-danger font-10">{productItem.supplierDate}</p>
				</td>

				<td>
					{status === true ? (
						<span className="text-success font-weight-bold">
							{backOffice.active}
						</span>
					) : (
						<span className="text-danger font-weight-bold">
							{backOffice.inActive}
						</span>
					)}{" "}
				</td>
				<td>
					<Link
						to={{
							pathname: `/travel-management/${productItem.id}`,
						}}
						className="d-inline-block"
					>
						<i className="icx icx-edit fa-lg"></i>
					</Link>
					<div onClick={toggleButton} className="d-inline-block">
						<div
							className={`link-company pointer icon-status ${
								status !== true ? "bg-danger" : "bg-success"
							}`}
						>
							<i
								className={`fas fa-fw ${
									status !== true ? "fa-stop" : "fa-play"
								}`}
							></i>
						</div>
					</div>
				</td>
			</tr>
		</>
	);
}
