import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import PaymentPopup from "./ui/PaymentPopup";
import ViewTransactionPopup from "./ui/ViewTransactionPopup";
import Wallet from "./Wallet";

export default function VendorTransactionsList() {
	const [popupInfo, setpopupInfo] = useState({});
	const [reload, setReload] = useState(true);
	const { wallet_id } = useParams();
	const [wallet, setWallet] = useState();
	const location = useLocation();

	// useEffect(() => {
	// 	const getWallet = async () => {
	// 		const res = await showWallets(wallet_id);
	// 		if (res?.status === 200) {
	// 			setWallet(res.data.data);
	// 		}
	// 	};
	// 	getWallet();
	// }, [reload]);

	const [isOpen, setIsOpen] = useState(false);
	const [isOpenView, setIsOpenView] = useState(false);

	const toggle = (id = null, currency = null, account = null, type = null) => {
		setpopupInfo({
			id,
			currency,
			account,
			type,
		});
		setIsOpen(!isOpen);
	};

	const toggleView = (id = null, deal_id = null, type = null) => {
		setpopupInfo({
			id,
			deal_id,
			type,
		});
		setIsOpenView(!isOpenView);
	};
	useEffect(() => {
		setWallet(location.state.wallet);
	}, []);

	console.log(wallet);
	const walletComponent = useMemo(
		() => (
			<Wallet
				Wallet={location.state.wallet}
				toggle={toggle}
				isOpenView={isOpenView}
				toggleView={toggleView}
				viewMore={false}
			/>
		),
		[wallet]
	);

	return (
		<div className="vendor-wallet-transaction">
			{walletComponent}
			{/* <Wallet
				Wallet={wallet}
				toggle={toggle}
				isOpenView={isOpenView}
				toggleView={toggleView}
				viewMore={false}
			/> */}
			<PaymentPopup
				isOpen={isOpen}
				popupInfo={popupInfo}
				toggle={toggle}
				setpopupInfo={setpopupInfo}
				setReload={setReload}
			/>

			<ViewTransactionPopup
				isOpen={isOpenView}
				toggle={toggleView}
				popupInfo={popupInfo}
			/>
		</div>
	);
}
