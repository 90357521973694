import Pagination from "components/shared/Pagination";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { listPlatformReservations } from "services/platform";
import Locale from "translations";
import HotelReservationsDetailsFilter from "./HotelReservationsDetailsFilter";

const ReservationsDetails = ({ hotelID }) => {
	let locale = localStorage.getItem("currentLocale") || "en";
	const history = useHistory();
	const { backOffice } = Locale;
	const { id } = useParams();
	const { search } = useLocation();
	const [_, pages] = search.split("=");
	const [ReservationList, setReservationList] = useState([]);
	const [meta, setMeta] = useState(null);

	const fetchData = async (id, filters = {}, page = pages) => {
		const res = await listPlatformReservations(id, filters, page);
		if (res?.status === 200) {
			setReservationList(res?.data?.reservations);
			setMeta(res?.data?.meta);
		}
	};

	useEffect(() => {
		if (hotelID !== undefined) {
			fetchData(hotelID);
		}
	}, [hotelID, pages]);

	const goTo = (page) => {
		fetchData(null, page);
		const params = new URLSearchParams();
		if (page) {
			params.append("page", page);
		} else {
			params.delete("page", page);
		}
		history.push({ search: params.toString() });
	};

	const allReservations = ReservationList?.map((item, index) => (
		<>
			<tr key={item?.id}>
				<td>{item?.name}*******</td>
				<td>{item?.ref_num}</td>
				<td>{item?.check_in}</td>
				<td>{item?.nights}</td>
				<td>{item?.pax}</td>
				<td>{item?.price}</td>
				<td
					className={
						item.status == "confirmed" ? "text-success" : "text-warning"
					}
				>
					<span className="text-capitalize">{item.status}</span>
				</td>
				<td className="text-orange">
					{item?.total_commission} {backOffice.SAR}({item?.base_commission}%)
				</td>
				<td className="text-orange">
					{item?.safa_value} {backOffice.SAR}({item?.safa_commission}%)
				</td>
				<td className="text-orange">
					{item?.platform_value} {backOffice.SAR} ({item?.platform_commission}%)
				</td>
			</tr>
		</>
	));

	return (
		<>
			<div className="property-details">
				<div className="row m-0">
					<div className="col-12 p-0">
						<HotelReservationsDetailsFilter
							ReservationList={ReservationList}
							setReservationList={setReservationList}
							pages={pages}
							hotelID={hotelID}
						/>
						<table className="custom-table back-office-table table table-striped">
							<thead>
								<tr id="tableHeaderRow">
									<th>{backOffice.hotelName}</th>
									<th>{backOffice.refNo}</th>
									<th>{backOffice.checkIn}</th>
									<th>{backOffice.nights}</th>
									<th>{backOffice.pax}</th>
									<th>{backOffice.price}</th>
									<th>{backOffice.status}</th>
									<th className="text-orange">{backOffice.commission}</th>
									<th className="text-orange">{backOffice.safaCommission}</th>
									<th className="text-orange">
										{backOffice.plateformCommission}
									</th>
								</tr>
							</thead>
							<tbody>
								{ReservationList ? (
									allReservations
								) : (
									<tr>
										<td
											colSpan={
												document.querySelector("#tableHeaderRow").childNodes
													.length
											}
										>
											<div className="product-no-data">
												<i className="fas fa-info-circle fa-lg"></i>{" "}
												<h4>{backOffice.noResult}</h4>
											</div>
										</td>
									</tr>
								)}
							</tbody>
						</table>
						{ReservationList?.length > 0 ? (
							<Pagination info={meta} goTo={goTo} />
						) : null}
					</div>
				</div>
			</div>
		</>
	);
};

export default ReservationsDetails;
