import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Locale from "translations";

export default function RequestBank(props) {
	const { payment } = Locale;
	const history = useHistory();
	return (
		<>
			<div className="add-credit-box py-5 my-5">
				<h6 className="current-head font-weight-bold">
					{payment.messages.requestToAddCredit}
				</h6>
				<div className="d-flex align-items-center">
					<button
						className="btn btn-success"
						onClick={() => history.push("/credit-card")}
					>
						<i className="fab fa-cc-mastercard"></i>
						<i className="fab fa-cc-visa px-1"></i>
						{payment.messages.creditCard}
					</button>
					<button
						className="btn btn-success"
						onClick={() => history.push("/bank-transfer")}
					>
						<i className="fas fa-landmark"></i> {payment.messages.bankTransfer}
					</button>
				</div>
			</div>
		</>
	);
}
