import axios from "axios";
import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";
import FilterTransaction from "./FilterTransaction";

export default function TableTransaction(props) {
	const { payment } = Locale;
	const [transactionList, setTransactionList] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	const { className } = props;

	const [modal, setModal] = useState(false);
	const [modalData, setModalData] = useState(null);

	const toggle = () => setModal(!modal);

	useEffect(() => {
		axios.get("../transactionPayment.json").then((res) => {
			setTransactionList(res.data.transactionsList);
		});
	}, []);

	//Remove Item
	const removeItem = (index) => {
		setTransactionList(transactionList.filter((k, i) => i !== index));
	};

	//Transaction Modal Items
	const showTransactions = (id) => {
		const transactionItems = transactionList.find((item) => item.id === id);
		setModalData(transactionItems);
	};

	//Filter Transaction
	const filterTransactionName = ({ TransactionID }) => {
		return (
			TransactionID.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
		);
	};

	const AllTransactions = transactionList
		.filter(filterTransactionName)
		.map((productItem, index) => {
			return (
				<tr key={productItem.id}>
					<td>{productItem.TransactionID}</td>
					<td>{productItem.Date}</td>
					<td>{productItem.Desc}</td>
					<td>{productItem.Amount} SAR</td>
					<td>{productItem.paymentMethod}</td>
					<td>{productItem.paymentType}</td>
					<td>{productItem.Destination}</td>
					<td>{productItem.Allotment}</td>
					<td>
						{productItem.Shared ? (
							<span className="btn-success">Success</span>
						) : (
							<span className="btn-danger">Failed</span>
						)}
					</td>
					<td className="actions">
						<span onClick={() => showTransactions(productItem.id)}>
							<i onClick={toggle} className="far fa-eye text-primary"></i>
						</span>
						<i className="fas fa-download fa-fw text-primary"></i>
						<i className="fas fa-print fa-fw text-primary"></i>

						{/* <i
              className="fas fa-trash-alt text-danger"
              onClick={() => removeItem(index)}
            ></i> */}
					</td>
				</tr>
			);
		});

	return (
		<>
			<div className="product-builder-list bg-transparent">
				<FilterTransaction onSearch={setSearchValue} value={searchValue} />

				<table className="custom-table table-striped table">
					<thead>
						<tr>
							<th>
								ID
								{/* {payment.messages.transactionId} */}
							</th>
							<th>{payment.messages.date}</th>
							<th>{payment.messages.description}</th>
							<th>{payment.messages.amount}</th>
							<th>{payment.messages.paymentMethod}</th>
							<th>{payment.messages.paymentType}</th>
							<th>{payment.messages.transctionType}</th>
							<th>{payment.messages.destination}</th>
							<th>{payment.messages.status}</th>
							<th width="14%">{payment.messages.tools}</th>
						</tr>
					</thead>
					<tbody>{AllTransactions}</tbody>
				</table>
			</div>

			<div>
				{modalData !== null ? (
					<Modal
						className="modal-transaction"
						isOpen={modal}
						toggle={toggle}
						className={className}
					>
						<ModalHeader toggle={toggle} className="justify-content-between">
							<h6 className="modal-title pull-left font-weight-bold">
								{payment.messages.transactions} ({modalData.TransactionID})
							</h6>
						</ModalHeader>
						<ModalBody>
							<div className="transaction-data d-flex justify-content-between text-caption">
								<ul className="font-weight-bold">
									<li>{payment.messages.transactionId}</li>
									<li>{payment.messages.date}</li>
									<li>{payment.messages.description}</li>
									<li>{payment.messages.amount}</li>
									<li>{payment.messages.paymentMethod}</li>
									<li>{payment.messages.paymentType}</li>
									<li>{payment.messages.transctionType}</li>
									<li>{payment.messages.destination}</li>
									<li>{payment.messages.status}</li>
								</ul>
								<ul className="list-unstyled">
									<li>{modalData.TransactionID}</li>
									<li>{modalData.Date}</li>
									<li>{modalData.Desc}</li>
									<li>{modalData.Amount}</li>
									<li>{modalData.paymentMethod}</li>
									<li>{modalData.paymentType}</li>
									<li>{modalData.Destination}</li>
									<li>{modalData.Allotment}</li>
									<li className="text-success">{modalData.Shared}</li>
								</ul>
							</div>
						</ModalBody>
					</Modal>
				) : null}
			</div>
		</>
	);
}
