import moment from "moment";
import { store } from "react-notifications-component";
import { Link } from "react-router-dom";
import { verifyRequestContract } from "services/property";

export default function ContractTableContent({ fetchData, productItem }) {
	const locale = localStorage.getItem("currentLocale") || "en";

	const verify = async (id, value) => {
		const res = await verifyRequestContract(id, { value: value });
		if (res.status === 200) {
			store.addNotification({
				title: "Done!",
				message: res.data,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			fetchData();
		}
	};

	return (
		<>
			<tr key={productItem.id}>
				<td scope="row">{productItem.supplier_name}</td>
				<td>{productItem.property_name}</td>
				<td>{productItem.country.names[locale]}</td>
				<td>{productItem.supplier_email} </td>
				<td>{productItem.supplier_phone} </td>
				<td>{moment(productItem.created_at).format("DD-MM-YYYY")} </td>
				<td>
					<p
						className={
							productItem.status === "Pending"
								? "text-warning"
								: productItem.status === "Verified"
								? "text-success"
								: "text-danger"
						}
					>
						{productItem.status}
					</p>
				</td>
				<td>
					<Link
						to={{
							pathname: `/contract-request/${productItem.id}`,
						}}
						className="d-inline-block"
					>
						<i className="icx icx-edit fa-lg"></i>
					</Link>
					{/* {productItem.status === "Pending" ? (
            <>
              <div className="d-inline-block">
                <div
                  onClick={() => verify(productItem.id, 2)}
                  className={`link-company pointer icon-status bg-success`}
                >
                  <i className="fas fa-fw fa-play"></i>
                </div>
              </div>

              <div className="d-inline-block mx-2">
                <div
                  onClick={() => verify(productItem.id, 3)}
                  className={`link-company pointer icon-status bg-danger`}
                >
                  <i className="fas fa-fw fa-stop"></i>
                </div>
              </div>
            </>
          ) : null} */}
				</td>
			</tr>
		</>
	);
}
