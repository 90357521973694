import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import { getNotification } from "helpers/makeNotifications";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { updateUser } from "services/b2c";
import { fetchCountries } from "services/lookups";
import Locale from "translations";

const statusOptions = [
	{ value: "active", label: "Active" },
	{ value: "inactive", label: "Inactive" },
];

function EditUser({ setEdit, details }) {
	const { userDetails, backOffice } = Locale;
	const { locale } = useGlobalState();
	const { allCountries } = useGlobalState();
	const country = allCountries?.find((i) => i.id == details?.country_id);

	const formatedCountry = {
		value: country?.id,
		label: country?.names?.en,
		code: country?.country_code,
	};

	const formatedStatus = statusOptions.find(
		(i) => i.value.toLocaleLowerCase() === details?.status
	);

	let { id } = useParams();
	const [userState, setUserState] = useState({
		full_name: details?.name,
		email: details?.email,
		country: formatedCountry,
		phone: details?.phone,
		status: formatedStatus,
	});
	const [errors, setErrors] = useState({});
	const [countries, setCountries] = useState([]);

	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "full_name", value: userState.full_name },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "email", value: userState.email },
				{ required: true, email: true }
			),
			...validate(
				{ name: "country", value: userState.country },
				{ required: true }
			),
			...validate(
				{ name: "phone", value: userState.phone },
				{ required: true, phone: true }
			),
			...validate(
				{ name: "status", value: userState.status },
				{ required: true }
			),
		});
	};

	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCountries();
			const format = res.data.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}

		fetchLookups();
	}, []);

	const editUser = async () => {
		console.log(userState);
		const res = await updateUser(id, {
			...userState,
			name: userState?.full_name,
			country_id: userState?.country?.value,
			phone: `${userState.phone}`,
			phone_code: `+${userState?.country?.value}`,
			status: `${userState?.status?.value}`,
		});
		if (res.status === 200 || res.status === 201) {
			getNotification(res.data.message);
			setEdit(false);
		}
	};

	useEffect(() => {
		if (isFormValid(errors)) {
			editUser();
		}
	}, [isErrorLoaded]);
	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	return (
		<div className="mx-5 px-5">
			{/*****  Start Personal Info  ******/}

			<div className="row">
				<div className="col-6">
					<TextField
						type="text"
						label={userDetails.fullName}
						placeholder={userDetails.fullNamePlaceholder}
						value={userState.full_name}
						name="full_name"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									min: 3,
								}),
							});
							setUserState({
								...userState,
								full_name: e.target.value,
							});
						}}
						min={3}
						color={
							errors?.full_name?.required || errors?.full_name?.min
								? "danger"
								: ""
						}
						errors={errors?.full_name}
					/>
				</div>
				<div className="col-6">
					<TextField
						label={"Email Address"}
						placeholder={userDetails.businessEmailPlaceholder}
						value={userState.email}
						name="email"
						disabled={true}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									email: true,
								}),
							});
							setUserState({
								...userState,
								email: e.target.value,
							});
						}}
						color={
							errors?.email?.required || errors?.email?.email ? "danger" : ""
						}
						errors={errors?.email}
					/>
				</div>
				<div className="col-6">
					<SelectField
						label={userDetails.country}
						value={userState.country}
						name="country"
						options={countries}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "country", value: e.value },
									{ required: true }
								),
							});
							setUserState({
								...userState,
								country: e,
								phone: "",
							});
						}}
						color={errors?.country?.required ? "danger" : ""}
						errors={errors?.country}
					/>
				</div>
				<div className="col-6">
					<TextField
						label={userDetails.phoneNumber}
						placeholder={userDetails.phoneNumberPlaceholder}
						name="phone"
						value={userState.phone}
						extraText={
							userState.country?.value ? "+" + userState.country?.value : "---"
						}
						extraTextPosition="prepend"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "phone", value: e.target.value },
									{
										required: true,
										phone: true,
									}
								),
							});
							setUserState({
								...userState,
								phone: e.target.value,
							});
						}}
						color={
							errors?.phone?.required || errors?.phone?.phone ? "danger" : ""
						}
						errors={errors?.phone}
					/>
				</div>
				<div className="col-6">
					<SelectField
						label={backOffice.status}
						value={userState.status}
						name="status"
						options={statusOptions}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate({ name: "status", value: 1 }, { required: true }),
							});
							setUserState({
								...userState,
								status: e,
							});
						}}
						color={errors?.status?.required ? "danger" : ""}
						errors={errors?.status}
					/>
				</div>
			</div>
			<div className="d-flex  justify-content-end mt-3 ">
				<button
					className=" cancel-bg p-2 px-4 mx-3"
					onClick={() => {
						setEdit(false);
					}}
				>
					Cancel
				</button>
				<button
					className="btn btn-success"
					onClick={() => {
						submit();
					}}
				>
					Save Changes
				</button>
			</div>
			{/*****  End Personal Info  ******/}
		</div>
	);
}

export default EditUser;
