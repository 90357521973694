import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useContractState } from "context/contractRequest";
import validate from "helpers/validate";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { fetchCities } from "services/lookups";
import Locale from "translations";

const PropertyDetails = ({ state, countries }) => {
  const { backOffice } = Locale;
  let locale = localStorage.getItem("currentLocale") || "en";
  const [propertyDetails, setPropertyDetails] = useState({
    classification_name: "",
    trade_name: "",
    address: "",
    unit_number: "",
    zip: "",
    country: "",
    city: "",
    spin: false,
  });


  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState({});
  const fetchData = useContractState();
  const { id } = useParams();
  let history = useHistory();

  const handleChange = (e) => {
    if ("target" in e) {
      const value = e.target.value;
      const name = e.target.name;
      setPropertyDetails({ ...propertyDetails, [name]: value });
    } else {
      const value = e.value;
      const name = e.name;
      if (name === "country") {
        setCities([]);
        setPropertyDetails({
          ...propertyDetails,
          city: "",
          [name]: value,
        });
      } else setPropertyDetails({ ...propertyDetails, [name]: value });
    }
  };

  const checkFormErrors = () => {
    setErrors({
      ...errors,
      ...validate(
        {
          name: "classification_name",
          value: propertyDetails.classification_name,
        },
        { required: true, min: 3 }
      ),
      ...validate(
        { name: "trade_name", value: propertyDetails.trade_name },
        { required: true }
      ),
      ...validate(
        { name: "address", value: propertyDetails.address },
        { required: true }
      ),
      ...validate(
        { name: "unit_number", value: propertyDetails.unit_number },
        {
          required: true,
        }
      ),
      ...validate(
        { name: "zip", value: propertyDetails.zip },
        { required: true, min: 5, max: 5 }
      ),
      ...validate(
        { name: "country", value: propertyDetails.country },
        { required: true }
      ),
      ...validate(
        { name: "city", value: propertyDetails.city },
        { required: true }
      ),
    });
  };

  // const checkAvailability = () => {
  //   setPropertyDetails({
  //     ...propertyDetails,
  //     spin: true,
  //   });
  //   checkFormErrors();
  //   if (!isErrorLoaded) {
  //     setIsErrorLoaded(true);
  //   } else {
  //     setIsErrorLoaded(false);
  //   }
  // };

  // Fetch Cities
  useEffect(() => {
    async function fetchLookups() {
      if (propertyDetails.country?.value) {
        const res = await fetchCities(propertyDetails.country?.value);
        const formatted = res?.data?.map((country) => ({
          value: country.id,
          label: country.names[locale],
        }));
        setCities(formatted);
      }
    }
    fetchLookups();
  }, [propertyDetails.country]);

  useEffect(() => {
    if (id && state) {
      setPropertyDetails({
        ...state?.property_details,
        country: {
          label: state.property_details?.country?.names[locale],
          value: state.property_details?.country.id,
        },
        city: {
          label: state?.property_details?.city?.names[locale],
          value: state?.property_details?.city?.id,
        },
      });
    }
  }, [id, state]);

  return (
    <>
      <div className="property-details">
        <div className="row m-0">
          <div className="col-12 pr-4 p-0">
            <TextField
              type="text"
              disabled={true}
              label={backOffice.hotelNamePerClass}
              placeholder={backOffice.hotelNamePerClass}
              value={propertyDetails.classification_name}
              name="classification_name"
              onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(e.target, {
                    required: true,
                    min: 3,
                  }),
                });
                setPropertyDetails({
                  ...propertyDetails,
                  classification_name: e.target.value,
                });
              }}
              min={3}
              color={
                errors?.classification_name?.required ||
                  errors?.classification_name?.min
                  ? "danger"
                  : ""
              }
              errors={errors?.classification_name}
            />

            <TextField
              type="text"
              label={backOffice.tradeName}
              disabled={true}
              placeholder={backOffice.tradeName}
              value={propertyDetails.trade_name}
              name="trade_name"
              onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(e.target, {
                    required: true,
                  }),
                });
                setPropertyDetails({
                  ...propertyDetails,
                  trade_name: e.target.value,
                });
              }}
              color={errors?.trade_name?.required ? "danger" : ""}
              errors={errors?.trade_name}
            />

            <TextField
              type="text"
              label={backOffice.address}
              disabled={true}
              placeholder={backOffice.address}
              value={propertyDetails.address}
              name="address"
              onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(e.target, {
                    required: true,
                  }),
                });
                setPropertyDetails({
                  ...propertyDetails,
                  address: e.target.value,
                });
              }}
              color={errors?.address?.required ? "danger" : ""}
              errors={errors?.address}
            />

            <div className="row m-0">
              <div className="col-md-6">
                <TextField
                  type="text"
                  label={backOffice.unitNumber}
                  disabled={true}
                  placeholder={backOffice.unitNumber}
                  value={propertyDetails.unit_number}
                  name="unit_number"
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      ...validate(e.target, {
                        required: true,
                      }),
                    });
                    setPropertyDetails({
                      ...propertyDetails,
                      unit_number: e.target.value,
                    });
                  }}
                  color={errors?.unit_number?.required ? "danger" : ""}
                  errors={errors?.unit_number}
                />
              </div>

              <div className="col-md-6">
                <TextField
                  type="text"
                  label={backOffice.zipCode}
                  disabled={true}
                  placeholder={backOffice.zipCode}
                  value={propertyDetails.zip}
                  name="zip"
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "zip", value: e.target.value },
                        {
                          required: true,
                          min: 5,
                          max: 5,
                        }
                      ),
                    });
                    setPropertyDetails({
                      ...propertyDetails,
                      zip: e.target.value,
                    });
                  }}
                  color={
                    errors?.zip?.required || errors.zip?.min || errors.zip?.max
                      ? "danger"
                      : ""
                  }
                  min={5}
                  max={5}
                  errors={errors?.zip}
                />
              </div>

              {/* Country */}
              <div className="col-6">
                <SelectField
                  label={backOffice.country}
                  placeholder={backOffice.country}
                  errors={errors.country}
                  disabled={true}
                  color={errors.country?.required ? "danger" : ""}
                  options={countries}
                  id="basic-countries"
                  onChange={(e) => {
                    handleChange({ name: "country", value: e });
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "country", value: e },
                        {
                          required: true,
                        }
                      ),
                    });
                  }}
                  value={propertyDetails.country}
                />
              </div>

              {/* City */}
              <div className="col-6">
                <SelectField
                  label={backOffice.city}
                  placeholder={backOffice.cityPlaceholder}
                  errors={errors.city}
                  disabled={true}
                  color={errors.city?.required ? "danger" : ""}
                  options={cities}
                  id="basic-cities"
                  onChange={(e) => {
                    handleChange({ name: "city", value: e });
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "city", value: e },
                        {
                          required: true,
                        }
                      ),
                    });
                  }}
                  value={propertyDetails.city}
                />
              </div>
            </div>

            {/* <div className="row flex-row-reverse px-2">
              <div className="col-md-2">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => checkAvailability()}
                  disabled={propertyDetails.spin}
                >
                  {propertyDetails.spin ? (
                    <Spinner color="light" size="sm" />
                  ) : null}{" "}
                  Save
                </button>
              </div>
              <div className="col-md-2">
                <button className="btn btn-secondary w-100">Back</button>
              </div>
            </div> */}
            <div className="row flex-row-reverse px-2">
              <div className="col-md-2">
                <button
                  className="btn btn-secondary w-100"
                  onClick={() => history.push("/contract-list?page=1")}
                >
                  {backOffice.back}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyDetails;
