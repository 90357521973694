import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import Locale from "translations";

export function Tax({ tax, index, deleteTax, setTax, currencys }) {
	const { polices } = Locale;

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setTax(index, name, value);
		} else {
			const value = e.value;
			const name = e.name;
			setTax(index, name, value);
		}
	};

	const handleChangeCurrency = (e, currency) => {
		let x;

		if (tax.rates == undefined) {
			x = [];
			x.push({
				rate: e.target.value,
				currency: currency,
			});
		} else {
			x = [...tax.rates];
			if (x.filter((res) => res.currency == currency).length > 0) {
				x = x.map((res) => {
					if (res.currency == currency) {
						return {
							rate: e.target.value,
							currency: currency,
						};
					} else {
						return res;
					}
				});
			} else {
				x.push({
					rate: e.target.value,
					currency: currency,
				});
			}
		}
		console.log(x);
		setTax(index, "rates", x);
	};

	console.log("tax?.currency", tax);
	return (
		<>
			<div className="col-4">
				<TextField
					label={polices.taxName}
					placeholder={polices.taxName}
					value={tax.name}
					name="name"
					id="tax-1"
					onChange={(e) => {
						handleChange(e);
					}}
				/>
			</div>
			<div className="col-2 ">
				<SelectField
					label={polices.type}
					placeholder={polices.type}
					value={tax.type}
					id="tax-3"
					onChange={(e) => {
						handleChange({ name: "type", value: e });
					}}
					options={[
						{ label: "percentage", value: "percentage" },
						{ label: "fixed", value: "fixed" },
					]}
				/>
			</div>
			{tax.type &&
				tax.type?.value == "fixed" &&
				currencys &&
				currencys.length > 0
				? currencys.map((res) => {
					return (
						<div className="col-md-2">
							<TextField
								label={polices.taxRate + " (" + res.label + ")"}
								placeholder={polices.taxRate + res.label}
								value={
									tax?.rates && tax?.rates?.length > 0
										? tax?.rates.filter(
											(resa) => resa.currency == res.label
										)[0]?.rate
										: ""
								}
								name="rate"
								id="tax-2"
								onChange={(e) => {
									handleChangeCurrency(e, res.label);
								}}
							/>
						</div>
					);
				})
				: ""}

			{tax.type && tax.type?.value == "percentage" ? (
				<div className="col-md-2">
					<TextField
						label={polices.taxRate}
						placeholder={polices.taxRate}
						value={tax.rate}
						name="rate"
						id="tax-2"
						onChange={(e) => {
							handleChange(e);
						}}
					/>
				</div>
			) : (
				""
			)}
			{index > 0 && (
				<div className="d-flex justify-content-center align-items-center delete-room">
					<i
						onClick={() => deleteTax(index)}
						className="fas fa-trash text-danger"
					></i>
				</div>
			)}
		</>
	);
}
