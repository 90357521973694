import moment from "moment";
import { useEffect, useState } from "react";
import { allTransaction } from "services/b2c";
import Locale from "translations";
import TransactionsList from "../TransactionsList";

export default function AllTransactions() {
	const { backOffice, payment } = Locale;
	const [transactions, setTransactions] = useState([]);
	const [meta, setMeta] = useState(null);
	const [filters, setFilter] = useState({
		search: "",
		status: null,
		created_at: null,
	});

	useEffect(() => {
		const fetchList = async () => {
			const res = await allTransaction({
				...filters,
				status: filters.status?.value,
				created_at: filters.created_at
					? moment(filters.created_at).format("YYYY-MM-DD")
					: null,
			});
			if (res?.status === 200) {
				setTransactions(res?.data?.data);
				setMeta(res?.data?.meta);
			}
		};
		fetchList();
	}, [filters]);

	console.log("filters1001", filters);
	return (
		<TransactionsList
			filters={filters}
			setFilter={setFilter}
			transactions={transactions}
			meta={meta}
		/>
	);
}
