import { useContractState } from "context/contractRequest";
import { useGlobalDispatch } from "context/global";
import { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useParams } from "react-router";
import Locale from "translations";
import CancelModal from "./CancelModal";
import Photo from "./Photo";

function Photos({ disabled, setDisabled, setFilePhoto, photosState }) {
	const { backOffice } = Locale;
	const { id } = useParams();
	const dispatch = useGlobalDispatch();
	const { ContractDetails } = useContractState();
	const slides = useRef(null);
	// local photos
	const [photos, setPhotos] = useState(
		photosState?.length > 0 ? photosState : []
	);
	const [gallery, setGallery] = useState(
		photosState?.length > 0 ? photosState : []
	);
	useEffect(() => {
		setPhotos([]);
		setGallery(photosState?.length > 0 ? photosState : []);
	}, [photosState]);

	console.log("photosState", photos);
	const [canceled, setCanceled] = useState(false);

	const handleUpload = (e) => {
		const files = e.target.files;
		const urls = [];
		for (let i = 0; i < files.length; i++) {
			const url = window.URL.createObjectURL(files[i]);
			urls.push({ id: i, image: url, uploaded: false });
		}
		// const url = window.URL.createObjectURL(files[0]);
		setGallery([...gallery, ...urls]);
		if (files && files != undefined) {
			setPhotos([...photos, ...files]);
		}
	};

	useEffect(() => {
		if (photos.length > 0) {
			setFilePhoto(photos[0]);
		}
	}, [photos]);

	useEffect(() => {
		if (id && ContractDetails?.property?.photos) {
			const formatted = ContractDetails.property.photos.map(
				(photo) =>
					(photo = {
						id: photo.id,
						image: photo.image,
						uploaded: true,
					})
			);
			setGallery(formatted);
		}
	}, [ContractDetails]);

	const isPhotosValid = () => {
		return true;
	};

	// const upload = async () => {
	//   if (isPhotosValid()) {
	//     if (photos.length > 0) {
	//       const formData = new FormData();
	//       formData.append(`file`, photos[0]);
	//       setFilePhoto(photos[0]);
	// for (let i = 0; i < photos.length; i++) {
	//   formData.append(`photos[${i}]`, photos[i]);
	// }
	// const res = await addPhotos(id, formData);
	// if (res?.status === 201) {
	//   setPhotos([]);
	//   setDisabled(false);
	//   const newGallery = Object.entries(res.data.photos).map(
	//     ([key, value]) => ({
	//       id: key,
	//       image: value,
	//       uploaded: true,
	//     })
	//   );
	//   setGallery(
	//     [...gallery, ...newGallery].filter((photo) => photo.uploaded)
	//   );
	// }
	//     }
	//   } else {
	//     store.addNotification({
	//       title: "info!",
	//       message: "Invalid",
	//       type: "danger",
	//       insert: "top",
	//       container: "top-right",
	//       animationIn: ["animated", "fadeIn"],
	//       animationOut: ["animated", "fadeOut"],
	//       dismiss: {
	//         duration: 3000,
	//         onScreen: true,
	//         pauseOnHover: true,
	//       },
	//     });
	//   }
	// };

	useEffect(() => {
		if (id && photos.length === 0) {
			setDisabled(false);
		} else if (photos.length > 0) {
			setDisabled(true);
			if (gallery.length > 3) {
				slides.current.goToSlide(gallery.length - 3);
			}
		}
	}, [photos]);

	// const submit = () => {
	// 	dispatch({ type: "progress", step: "4" });
	// };

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 5,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};

	return (
		<div>
			<CancelModal isOpen={canceled} toggle={setCanceled} />
			<div className="head-manager">
				<p>{backOffice.uploadContractFile}</p>
			</div>
			{gallery.length > 0 ? (
				<div className="p-3 text-center d-flex flex-column align-items-center position-relative upload-images">
					<h5 className="text-dark font-weight-bold">
						{backOffice.dragDropImg}
					</h5>
					<p className="gray py-1 mx-3">or</p>
					<div className="d-flex">
						<label
							onChange={handleUpload}
							htmlFor="uploadFile"
							className="text-balck px-4"
						>
							<i className="far fa-images px-2 fa-lg"></i>
							{backOffice.addPhoto}
						</label>
						<input
							multiple="multiple"
							onChange={handleUpload}
							type="file"
							id="uploadFile"
							accept="image/*"
							// className="d-none"
						/>
					</div>
				</div>
			) : (
				<div className="p-3 text-center  position-relative upload-images contract-upload">
					<h5 className="text-blue-black font-weight-bold">
						{backOffice.dragDropImg}
					</h5>
					<p className="py-1">{backOffice.or}</p>
					<label
						htmlFor="uploadFile"
						onChange={handleUpload}
						className="text-black px-4"
					>
						<i className="far fa-images px-2 fa-lg"></i>
						{backOffice.addPhoto}
					</label>
					<input
						multiple="multiple"
						onChange={handleUpload}
						type="file"
						id="uploadFile"
						accept="image/*"
					/>
				</div>
			)}

			<Carousel ref={slides} responsive={responsive} className="gallery">
				{gallery.length > 0 &&
					gallery.map((photo, i) => {
						return (
							<div className="col-md-12 my-4">
								<Photo
									key={i}
									photo={photo}
									gallery={gallery}
									setGallery={setGallery}
								/>
							</div>
						);
					})}
			</Carousel>

			{/* <div className="row my-5 mx-0 justify-content-between">
        <button
          className="btn btn-success mx-2"
          onClick={upload}
          id="cancel-photo"
          disabled={photos.length === 0}
        >
          {backOffice.upload}
        </button>
      </div> */}
		</div>
	);
}

export default Photos;
