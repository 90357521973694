import Model from "components/model";
import SelectField from "components/shared/SelectField";
import React, { useState } from "react";
import Locale from "translations";

export default function AddCustomRate({
	customRatemodal,
	setCustomRatemodal,
	countries,
	ratetoggle,
	addCustomRate,
}) {
	const { hotelLayout } = Locale;
	const [customRate, setCustomRate] = useState({
		countries: [],
	});
	const handleSubmit = () => {
		addCustomRate(customRate.countries, customRatemodal.ratePlanId);
		setCustomRatemodal({
			isOpen: !customRatemodal.isOpen,
		});
		setCustomRate({});
	};
	return (
		<>
			<Model
				isOpen={customRatemodal.isOpen}
				title={customRatemodal.title}
				ratetoggle={ratetoggle}
				hasFooter={false}
			>
				<div className="py-3 w-100">
					<div className="col-md-12 mb-4">
						<SelectField
							multi={true}
							label={hotelLayout.calendar.selectCountry}
							options={countries}
							placeholder={hotelLayout.calendar.selectCountry}
							id="services-languages"
							value={customRate.countries}
							onChange={(e) => {
								setCustomRate({ ...customRate, countries: e });
							}}
						/>
					</div>

					<div className="submit-btn d-flex flex-row-reverse">
						<button
							className="btn btn-primary mx-1 px-5"
							onClick={handleSubmit}
						>
							{hotelLayout.calendar.add}
						</button>
						<button className="btn btn-secondary mx-1" onClick={ratetoggle}>
							{hotelLayout.calendar.cancel}
						</button>
					</div>
				</div>
			</Model>
		</>
	);
}
