import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { listCompanies } from "services/companies";
import Locale from "translations";
import CompaniesFilter from "../Components/Companies/CompaniesFilter";
import CompaniesTable from "../Components/Companies/CompaniesTable";

export default function Companies() {
	const history = useHistory();
	const { backOffice } = Locale;
	const { search } = useLocation();
	const [_, pages] = search.split("=");
	const [companies, setCompanies] = useState([]);
	const [meta, setMeta] = useState(null);

	const fetchData = async (filters = null, page = pages) => {
		const res = await listCompanies(filters, page);
		if (res?.status === 200) {
			console.log(res.data.data);
			setCompanies(res.data.data);
			setMeta(res.data.meta);
		}
	};

	useEffect(() => {
		fetchData();
	}, [pages]);

	const goTo = (page) => {
		fetchData(null, page);
		const params = new URLSearchParams();
		if (page) {
			params.append("page", page);
		} else {
			params.delete("page", page);
		}
		history.push({ search: params.toString() });
	};

	return (
		<>
			<div className="d-flex justify-content-between align-items-center">
				<h6 className="h6 font-weight-bold text-black">
					{backOffice.companies}
				</h6>

				{/* <button className="btn text-white bg-blue-black rounded-0">
          Export
        </button> */}
			</div>
			<CompaniesFilter
				companies={companies}
				setCompanies={setCompanies}
				pages={pages}
			/>
			<CompaniesTable companies={companies} goTo={goTo} meta={meta} />
		</>
	);
}
