export const RemoveIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="13.86"
		height="13.86"
		viewBox="0 0 13.86 13.86"
	>
		<path
			id="Path_3453"
			data-name="Path 3453"
			d="M8.93,2a6.93,6.93,0,1,0,6.93,6.93A6.951,6.951,0,0,0,8.93,2Zm2.564,8.524a.686.686,0,0,1-.97.97L8.93,9.9,7.336,11.494a.686.686,0,0,1-.97-.97L7.96,8.93,6.366,7.336a.686.686,0,0,1,.97-.97L8.93,7.96l1.594-1.594a.686.686,0,1,1,.97.97L9.9,8.93Z"
			transform="translate(-2 -2)"
			fill="#ff6868"
		/>
	</svg>
);

export const FilterIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="12"
		height="12.003"
		viewBox="0 0 12 12.003"
	>
		<path
			id="Path_2749"
			data-name="Path 2749"
			d="M12.75,5.515a.749.749,0,0,1,.617.324l.04.065A4,4,0,0,1,10.2,12L10,12H7.555l.722.722a.75.75,0,0,1,.073.977l-.073.084a.75.75,0,0,1-.977.073l-.084-.073-2-2a.75.75,0,0,1-.073-.977l.073-.084,2-2a.75.75,0,0,1,1.133.977l-.073.084-.721.721H10a2.5,2.5,0,0,0,2.495-2.336L12.5,8a2.488,2.488,0,0,0-.391-1.344A.759.759,0,0,1,12,6.265.75.75,0,0,1,12.75,5.515Zm-5.03-3.3A.75.75,0,0,1,8.7,2.145l.084.073,2,2,.073.084a.751.751,0,0,1,.007.883l-.08.094-2,2L8.7,7.355a.751.751,0,0,1-.883.007l-.094-.08L7.647,7.2a.751.751,0,0,1-.007-.883l.08-.094L8.441,5.5H6A2.5,2.5,0,0,0,3.505,7.836L3.5,8a2.489,2.489,0,0,0,.288,1.167l.109.185a.75.75,0,0,1-1.283.777A4,4,0,0,1,5.8,4L6,4H8.44l-.72-.721-.073-.084A.75.75,0,0,1,7.72,2.218Z"
			transform="translate(-2 -1.998)"
			fill="#393d3b"
		/>
	</svg>
);
