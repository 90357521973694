import TextField from "components/shared/TextField";
import { getNotification } from "helpers/makeNotifications";
import { useParams } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import {
	addBalances,
	addBalancesCredit,
	payCreditByDebit,
} from "services/multiWallets";
import Locale from "translations";

export default function PaymentPopup({
	isOpen,
	popupInfo,
	setpopupInfo,
	toggle,
	setReload,
}) {
	const { wallet } = Locale;
	const { company_id } = useParams();

	const addBalance = async () => {
		let res;
		let data = {
			transaction_type: "add",
			amount: popupInfo.amount,
			payment_card_id: popupInfo.wallet_id,
			company_id,
			description: popupInfo.description,
		};
		if (popupInfo.type === "credit") {
			res = await addBalancesCredit(data);
		} else if (popupInfo.type === "payCredit") {
			res = await payCreditByDebit(company_id, {
				amount: popupInfo.amount,
				//  currency : popupInfo.wallet_id
			});
		} else {
			res = await addBalances(data);
		}
		if (res.status === 200) {
			setReload(res?.data?.transaction_?.payment_card_id);
			getNotification(res?.data?.message);
			toggle();
		}
	};
	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
				<p className=" text-secondary ">
					{popupInfo.type === "payCredit"
						? wallet.PayYourWallet
						: wallet.addBalance}
				</p>
				<i
					className="far fa-times-circle fa-lg text-secondary "
					onClick={toggle}
				></i>
			</div>

			<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3">
				<div className=" m-0 p-0 w-75">
					<div className="d-flex">
						<div className=" mx-1">
							<TextField
								type="text"
								placeholder={wallet.AccountNo}
								label={
									popupInfo.type === "payCredit"
										? wallet.CreditLeftToUse
										: wallet.AccountNo
								}
								hasLabel={true}
								name="Account"
								disabled={true}
								value={popupInfo.account}
							/>
						</div>
						<div className="mx-1">
							<TextField
								type="text"
								disabled={true}
								label={
									popupInfo.type === "payCredit"
										? wallet.TotalLimit
										: wallet.Currency
								}
								hasLabel={true}
								placeholder={wallet.Currency}
								name="Currency"
								value={popupInfo.currency}
							/>
						</div>
					</div>

					<div className="mx-1">
						<TextField
							type="number"
							placeholder={wallet.Amount}
							hasLabel={false}
							name="Amount"
							value={popupInfo.amount}
							onChange={(e) => {
								setpopupInfo({ ...popupInfo, amount: e.target.value });
							}}
						/>
					</div>
					<div className="mx-1">
						<TextField
							type="text"
							placeholder={"Description"}
							hasLabel={false}
							name="description"
							value={popupInfo.description}
							onChange={(e) => {
								setpopupInfo({ ...popupInfo, description: e.target.value });
							}}
						/>
					</div>
				</div>
			</ModalBody>
			<div className="w-100 pb-5 border-top-0  text-center    p-0 ">
				<button
					className="btn rounded companies-btn  w-75"
					onClick={addBalance}
				>
					{wallet.addBalance}
				</button>
			</div>
		</Modal>
	);
}
