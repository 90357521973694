import Pagination from "components/shared/Pagination";
import Locale from "translations";
import Filter from "./Filter";
import Transaction from "./Transaction";

export default function TransactionsList({
	transactions,
	setFilter,
	filters,
	meta,
}) {
	const { backOffice, payment } = Locale;

	const goTo = (page) => {
		setFilter((prev) => ({ ...prev, page: page }));
	};

	const AllTransactions =
		transactions.length > 0 &&
		transactions.map((transaction, index) => (
			<Transaction transaction={transaction} key={index} />
		));

	return (
		<>
			<div>
				<div className="d-flex">
					<Filter filter={filters} setFilter={setFilter} />
				</div>
				<div className="px-3 our-border border-top-0">
					<table className="custom-table back-office-table table table-striped mb-0">
						<thead>
							<tr>
								<th>{payment.messages.transactionId}</th>
								<th>{backOffice.reservationRefNo}</th>
								<th>{backOffice.date}</th>
								<th>{backOffice.amount}</th>
								<th>{backOffice.status}</th>
							</tr>
						</thead>

						<tbody>
							{transactions?.length > 0 ? (
								AllTransactions
							) : (
								<tr>
									<td colSpan="15">
										<div className="product-no-data">
											<i className="fas fa-info-circle fa-lg"></i>{" "}
											<h4>{backOffice.noResult}</h4>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
					<Pagination info={meta} goTo={goTo} items={10} />
				</div>
			</div>
		</>
	);
}
