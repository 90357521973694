import React from "react";

export default function BranchUser({ group }) {
 

  return (
    <tr>
      <td className="w-25">{group?.name}</td>
   {/*    <td className="w-25">{user.email}</td>
      <td className="w-25">{user.email}</td> */}
 {/*      <td className="">
        {user.status === "active" ? (
          <i class="far fa-check-circle icons-teamMange text-success"></i>
        ) : (
          <i class="far fa-times-circle icons-teamMange text-danger"></i>
        )}
      </td>

      <td className="d-flex">
        <i class="fas fa-edit"></i>
        <i class="fas fa-check text-success mx-2"></i>
        <i class="fas fa-trash text-danger"></i>
      </td> */}
    </tr>
  );
}
