import resetIcon from "assets/images/wallet/resetIcon.png";
import { useState } from "react";

// import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import Locale from "translations";
// import moment from "moment";
import RangeSlider from "components/shared/RangeSlider";

export default function PlatformCommissionsFilter({
	setHotelList,
	pages,
	platformID,
}) {
	const { backOffice } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";
	const [countries, setCountries] = useState([]);

	const [filterState, setFilterState] = useState({
		hotelName: null,
		country: "",
		commission: null,
	});

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setFilterState({ ...filterState, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			if (name === "country") {
				setFilterState({
					...filterState,
					city: "",
					[name]: value,
				});
			} else setFilterState({ ...filterState, [name]: value });
		}
	};

	//Fetch Countries
	// useEffect(() => {
	//   async function fetchLookups() {
	//     const res = await fetchCountries();
	//     const format = res?.map((t) => ({
	//       value: t.id,
	//       label: t.names.en,
	//       code: t.country_code,
	//     }));
	//     setCountries(format);
	//   }
	//   fetchLookups();
	// }, []);

	// useEffect(() => {
	//   async function fetchData(platformID) {
	//     const filteredData = {
	//       name: filterState.hotelName,
	//       country: filterState.country?.label,
	//       total_commission: filterState.commission
	//     };
	//     const res = await listPlatformHotels(platformID, cleanEmpty(filteredData), pages);
	//     setHotelList(res.data.hotels);
	//   }
	//   fetchData(platformID);
	// }, [
	//   platformID,
	//   filterState.hotelName,
	//   filterState.country,
	//   filterState.commission,
	// ]);

	const resetFilters = () => {
		setFilterState({
			hotelName: "",
			country: "",
			commission: "",
		});
	};

	return (
		<>
			<>
				<div className="main-filter bg-white-blue py-2 my-2">
					<div className="row align-items-center w-100 no-gutter m-0">
						<div className="col-md-2">
							<div className="main-label">
								<TextField
									type="text"
									placeholder={backOffice.hotelName}
									label={backOffice.hotelName}
									/* 									hasLabel={false}
									 */ value={filterState.hotelName}
									onChange={(e) => {
										setFilterState({
											...filterState,
											hotelName: e.target.value,
										});
									}}
								/>
							</div>
						</div>

						<div className="col-md-2">
							<SelectField
								/* 								hasLabel={false}
								 */ value={filterState.country}
								placeholder={backOffice.country}
								label={backOffice.country}
								name="country"
								options={countries}
								onChange={(e) => {
									handleChange({ name: "country", value: e });
								}}
							/>
						</div>

						<div className="col-md-2 mt-4">
							<RangeSlider
								min={0}
								max={1000}
								step={50}
								ruler={true}
								label={false}
								minValue={250}
								maxValue={750}
								onChange={(e) => {
									setFilterState({
										...filterState,
										commission: e.maxValue,
									});
								}}
							/>
						</div>

						<div className="col-md mt-4">
							<div
								onClick={resetFilters}
								className="d-flex align-items-center text-secondary pointer mt-4 reset-btn"
							>
								<img src={resetIcon} alt="" srcset="" />
								{/* 								<i className="fas fa-retweet position-static px-1 text-secondary text-caption"></i>
								 */}{" "}
								<p className="text-caption">{backOffice.resetFilter}</p>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	);
}
