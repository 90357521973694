import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { viewVendorReservation } from "services/vendor";
import Locale from "translations";
import Booking from "./Booking";
import Financial from "./Financial";
import Passangers from "./Passangers";
import Payments from "./Payments";
export default function ReservationView() {
	const { id, brn } = useParams();
	const [activeTab, setActiveTab] = useState(1);
	const { vendor, booking } = Locale;

	const [bookingPassengerData, setBookingPassengerData] = useState();

	useEffect(() => {
		const viewReservation = async () => {
			const res = await viewVendorReservation(id, brn);
			setBookingPassengerData(res?.data?.data);
		};
		viewReservation();
	}, []);

	return (
		<div className="vendor">
			<h3 className="txt-blue font-weight-bold col">{vendor.vendor}</h3>
			<div className="booking-view-header all-tabs mt-3 d-flex  justify-content-between  ">
				<div className="d-flex ">
					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                         ${activeTab === 1 ? "is-active" : ""}`}
						onClick={() => {
							setActiveTab(1);
						}}
					>
						{booking.booking}
					</button>

					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                         ${activeTab === 2 ? "is-active" : ""}`}
						onClick={() => {
							setActiveTab(2);
						}}
					>
						{booking.Passengers}
					</button>

					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                         ${activeTab === 3 ? "is-active" : ""}`}
						onClick={() => {
							setActiveTab(3);
						}}
					>
						{booking.Financial}
					</button>

					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                         ${activeTab === 4 ? "is-active" : ""}`}
						onClick={() => {
							setActiveTab(4);
						}}
					>
						{booking.Payments}
					</button>
				</div>
			</div>
			{activeTab === 1 ? (
				<Booking bookingInfo={bookingPassengerData} />
			) : activeTab === 2 ? (
				<Passangers passangerInfo={bookingPassengerData} />
			) : activeTab === 3 ? (
				<Financial finacial={bookingPassengerData} />
			) : activeTab === 4 ? (
				<Payments payment={bookingPassengerData} />
			) : null}
		</div>
	);
}
