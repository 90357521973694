import OfflineReservationRoom from './Room';

export default function OfflineReservationRooms({ roomLookups, roomsList, setRoomsList }) {
  const newRoom = {
    count: 1,
    room_type: roomLookups?.types?.length > 0 && roomLookups?.types[0],
    room_view: roomLookups?.views?.length > 0 && roomLookups?.views[0],
    number_of_adult: 1,
    number_of_childs: 1,
    childern: []
  }
  function addNewRoom() {
    setRoomsList([...roomsList, newRoom])
  }
  function removeRoom(index) {
    setRoomsList([...roomsList.slice(0, index), ...roomsList.slice(index + 1)]);
  }

  function updateRoomData(index, changedValue, newValue) {
    let rooms = [...roomsList];
    let room = { ...rooms[index] };
    if (changedValue === 'count') {
      room = { ...room, [`${changedValue}`]: newValue, number_of_childs: 1, };
    }
    room = { ...room, [`${changedValue}`]: newValue };
    rooms[index] = room;
    setRoomsList(rooms)
  }

  return (
    <>
      <div className="bankConfigrationHeader p-3 text-visa-header font-weight-bold mt-3">
        <p className="m-0">Rooms</p>
      </div>
      <div className="row pb-5 our-border">
        <div className="col-12 mt-2">
          {/* rooms */}
          {roomsList?.length > 0
            ?
            roomsList?.map((room, index) => {
              return (
                <OfflineReservationRoom
                  room={room}
                  setRoomsList={setRoomsList}
                  roomsList={roomsList}
                  roomLookups={roomLookups}
                  updateRoomData={updateRoomData}
                  addNewRoom={addNewRoom}
                  removeRoom={removeRoom}
                  index={index}
                  key={index} />
              )
            })

            :
            <div className="w-100 py-4 d-flex flex-column align-items-center justify-content-center">
              <p>No Room Added, Please Add Room</p>
              <button className="btn companies-btn"
                onClick={() => {
                  setRoomsList([newRoom])
                }}
                style={{ backgroundColor: "#00AAEB", boxShadow: "0px 3px 6px #00AAEB5F" }}
              >
                Add Room
              </button>
            </div>
          }
        </div>
      </div>
    </>
  )
}
