import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useContractState } from "context/contractRequest";
import validate from "helpers/validate";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchCities, fetchCountries } from "services/lookups";
import Locale from "translations";

const Configurations = ({ state }) => {
  const { backOffice } = Locale;
  let locale = localStorage.getItem("currentLocale") || "en";
  const [propertyDetails, setPropertyDetails] = useState({
    classification_name: "",
    trade_name: "",
    address: "",
    unit_number: "",
    zip: "",
    country: "",
    city: "",
    spin: false
  });

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState({});
  const fetchData = useContractState();
  const { id } = useParams();

  const handleChange = (e) => {
    if ("target" in e) {
      const value = e.target.value;
      const name = e.target.name;
      setPropertyDetails({ ...propertyDetails, [name]: value });
    } else {
      const value = e.value;
      const name = e.name;
      if (name === "country") {
        setCities([]);
        setPropertyDetails({
          ...propertyDetails,
          city: "",
          [name]: value
        });
      } else setPropertyDetails({ ...propertyDetails, [name]: value });
    }
  };

  const checkFormErrors = () => {
    setErrors({
      ...errors,
      ...validate(
        {
          name: "classification_name",
          value: propertyDetails.classification_name
        },
        { required: true, min: 3 }
      ),
      ...validate(
        { name: "trade_name", value: propertyDetails.trade_name },
        { required: true }
      ),
      ...validate(
        { name: "address", value: propertyDetails.address },
        { required: true }
      ),
      ...validate(
        { name: "unit_number", value: propertyDetails.unit_number },
        {
          required: true
        }
      ),
      ...validate(
        { name: "zip", value: propertyDetails.zip },
        { required: true, min: 5, max: 5 }
      ),
      ...validate(
        { name: "country", value: propertyDetails.country },
        { required: true }
      ),
      ...validate(
        { name: "city", value: propertyDetails.city },
        { required: true }
      )
    });
  };

  // const checkAvailability = () => {
  //   setPropertyDetails({
  //     ...propertyDetails,
  //     spin: true,
  //   });
  //   checkFormErrors();
  //   if (!isErrorLoaded) {
  //     setIsErrorLoaded(true);
  //   } else {
  //     setIsErrorLoaded(false);
  //   }
  // };

  //Fetch Countries
  useEffect(() => {
    async function fetchLookups() {
      const res = await fetchCountries();
      const format = res.map((t) => ({
        value: t.id,
        label: t.names[locale]
      }));
      setCountries(format);
    }
    fetchLookups();
  }, []);

  // Fetch Cities
  useEffect(() => {
    async function fetchLookups() {
      if (propertyDetails.country?.value) {
        const res = await fetchCities(propertyDetails.country?.value);
        const formatted = res.map((country) => ({
          value: country.id,
          label: country.names[locale]
        }));
        setCities(formatted);
      }
    }
    fetchLookups();
  }, [propertyDetails.country]);

  useEffect(() => {
    if (id && state) {
      setPropertyDetails({
        ...state?.property_details,
        country: {
          label: state.property_details?.country?.names[locale],
          value: state.property_details?.country.id
        },
        city: {
          label: state?.property_details?.city?.names[locale],
          value: state?.property_details?.city?.id
        }
      });
    }
  }, [id, state]);

  return (
    <>
      <div className="property-details">
        <div className="row m-0">
          <div className="col-10 p-0">
            <div className="row m-0">
              <div className="col-md-6 pl-0">
                <DatePickerField
                  label={backOffice.publishFrom}
                  //   date={moment(propertyDetails?.contractDurationFrom)}
                  date={moment(state?.share_request?.publish_from)}
                  onChangeDate={(date) =>
                    setPropertyDetails({
                      ...propertyDetails,
                      contractDurationFrom: date._d
                    })
                  }
                  isOutsideRange={(day) => {
                    return false;
                  }}
                />
              </div>
              <div className="col-md-6 pr-0">
                <DatePickerField
                  label={backOffice.publishTo}
                  //   date={moment(propertyDetails?.contractDurationTo)}
                  date={moment(state?.share_request?.publish_to)}
                  onChangeDate={(date) =>
                    setPropertyDetails({
                      ...propertyDetails,
                      contractDurationTo: date._d
                    })
                  }
                  isOutsideRange={(day) => {
                    return false;
                  }}
                  value={state?.share_request?.publish_to}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  type="text"
                  label={backOffice.bookingType}
                  placeholder={backOffice.bookingType}
                  value={propertyDetails.unit_number}
                  name="unit_number"
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      ...validate(e.target, {
                        required: true
                      })
                    });
                    setPropertyDetails({
                      ...propertyDetails,
                      unit_number: e.target.value
                    });
                  }}
                  color={errors?.unit_number?.required ? "danger" : ""}
                  errors={errors?.unit_number}
                />
              </div>

              <div className="col-md-6">
                <TextField
                  type="text"
                  label={backOffice.roomOrder}
                  placeholder={backOffice.roomOrder}
                  value={propertyDetails.zip}
                  name="zip"
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "zip", value: e.target.value },
                        {
                          required: true,
                          min: 5,
                          max: 5
                        }
                      )
                    });
                    setPropertyDetails({
                      ...propertyDetails,
                      zip: e.target.value
                    });
                  }}
                  color={
                    errors?.zip?.required || errors.zip?.min || errors.zip?.max
                      ? "danger"
                      : ""
                  }
                  min={5}
                  max={5}
                  errors={errors?.zip}
                />
              </div>

              <div className="col-12">
                <div className="header-box my-3">{backOffice.commission}</div>
              </div>

              {/* Country */}
              <div className="col-4">
                <TextField
                  label={backOffice.commissionType}
                  placeholder={backOffice.commissionType}
                  //   value={tax.name}
                  name="name"
                  id="tax-1"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </div>
              <div className="col-4 text-with-select">
                <TextField
                  label={backOffice.commission}
                  placeholder={backOffice.commission}
                  value={state?.contract?.commission}
                  name="rate"
                  id="tax-2"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <SelectField
                  //   disabled
                  label={backOffice.percentage}
                  placeholder={backOffice.percentage}
                  //   value={tax.type}
                  id="tax-3"
                  onChange={(e) => {
                    handleChange({ name: "type", value: e });
                  }}
                  options={[
                    { label: "percentage", value: "percentage" },
                    { label: "fixed", value: "fixed" }
                  ]}
                />
              </div>
            </div>

            {/* <div className="row flex-row-reverse px-2">
              <div className="col-md-2">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => checkAvailability()}
                  disabled={propertyDetails.spin}
                >
                  {propertyDetails.spin ? (
                    <Spinner color="light" size="sm" />
                  ) : null}{" "}
                  Save
                </button>
              </div>
              <div className="col-md-2">
                <button className="btn btn-secondary w-100">Back</button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Configurations;
