import TextAreaField from "components/shared/TextAreaField";
// import TextAreaField from "components/Form/TextAreaField/TextAreaField";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { addNotes } from "services/sbsReservations";

function Notes(props) {
	const { details, handleReservationState } = props;
	const { notes } = details;

	const [noteState, setNoteState] = useState("");
	const { id } = useParams();

	const handleNotesList = (response) => {
		let updatedNotesState = response.data.data.notes;

		handleReservationState({ name: "notes", value: updatedNotesState });
		setNoteState("");
	};

	const addNewNote = async () => {
		const res = await addNotes(id, {
			body: noteState,
		});

		if (res.status == 200) {
			handleNotesList(res);
		}
	};

	return (
		<div id="reserv-notes">
			{notes.map((i) => (
				<div key={i.created_at} className="w-100 border p-2 mt-2 notes_content">
					<p>{`${i.user_name} | ${i.created_at}`}</p>
					<p>{i.body}</p>
				</div>
			))}

			<TextAreaField
				// label={""}
				hasLabel={false}
				// customLabel={property.reject_modal_reasons_input}
				placeholder={"Add Notes"}
				value={noteState}
				rows={5}
				name="reasons"
				onChange={(e) => {
					setNoteState(e.target.value);
				}}
			/>
			<div className="w-100 text-end">
				<button
					className="btn btn-info p-2 my-2 rounded-0"
					onClick={addNewNote}
					disabled={details?.status?.id == 5}
				>
					Add New Note
				</button>
			</div>
		</div>
	);
}

export default Notes;
