import Header from "components/layout/Header";
import { HotelLayoutProvider } from "context/hotelLayout";
import { Redirect, Route } from "react-router-dom";

// React Component
export function HotelLayoutRoute({ component: Component, ...props }) {
	const isAuth = localStorage.getItem("isAuth");
	if (isAuth) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
					<>
						<Header />
						{/* <DashboardSubNav /> */}
						<div className="hotel-box">
							<div className="container">
								<div className="head">
									<h5 className="title">Dar Al Eiman Royal Hotel</h5>
									<div className="bookable">Bookable</div>
								</div>
							</div>
						</div>
						<HotelLayoutProvider>
							<Component {...matchProps} />
						</HotelLayoutProvider>
					</>
				)}
			/>
		);
	} else {
		return <Redirect to="/login" />;
	}
}
