import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Locale from "translations";

const SelectField = (props) => {
	const [isOpened, setIsOpened] = useState(false);
	const selectRef = useRef(null);
	const { commons } = Locale;

	const customStyles = {
		singleValue: (defaultStyles) => {
			return {
				...defaultStyles,
			};
		},

		placeholder: (defaultStyles) => {
			return {
				...defaultStyles,
			};
		},
	};

	const handleClickOutside = (e) => {
		if (selectRef.current && !selectRef.current.contains(e.target))
			setIsOpened(false);
		else setIsOpened(true);
	};

	useEffect(() => {
		window.addEventListener("click", handleClickOutside);
		return () => window.removeEventListener("click", handleClickOutside);
	}, []);

	if (props.extruded) {
		if (props.options) {
			props.options.forEach((element) => (element.disabled = false));
			props.options.forEach((element) => {
				props.extruded.forEach((ele) => {
					if (ele.value === element.id) {
						element.disabled = true;
					}
				});
			});
		}
	}

	const generateErrorsMsgs = (errors) => {
		let message = [];
		if (errors) {
			for (const [k, v] of Object.entries(errors)) {
				if (k === "required" && v) {
					message.push(commons.isRequired);
				} else if (k === "min" && v) {
					message.push(` should be at least ${props.min} digits`);
				} else if (k === "email" && v) {
					message.push(`should be valid`);
				}
			}
			if (message.length === 1) {
				return `${props.customLabel || props.label} ${message[0]}`;
			} else if (message.length > 1) {
				return `${props.customLabel || props.label} ${message.join(" & ")}`;
			} else {
				return;
			}
		}
	};

	return (
		<div className="w-100">
			<div
				className={`my-2 control-field + ${
					props.color ? " control-field--" + props.color : ""
				}
        
        ${props.multi ? "multi" : ""}`}
			>
				{/* Text Field Body */}
				<div
					className={`control-field__body ${
						props.disabled ? "isDisabled" : ""
					}`}
					onClick={() => setIsOpened(!isOpened)}
					ref={selectRef}
				>
					{/* Label */}
					{props.hasLabel ? (
						<label
							className={`control-field__label ${
								props.color ? " control-field__label--" + props.color : ""
							}`}
						>
							{props.label}
						</label>
					) : null}

					{/* Text Input */}

					<div className="row no-gutters">
						<Select
							className="control-field__select"
							id={props.id}
							styles={
								props.styles
									? { ...customStyles, ...props.styles }
									: { ...customStyles }
							}
							value={props.value}
							name={props.name}
							menuIsOpen={props.disabled ? false : isOpened}
							isMulti={props.multi}
							isSearchable={props.searchable}
							isDisabled={props.disabled}
							placeholder={props.placeholder}
							onInputChange={props.onInputChange}
							onChange={props.onChange}
							onClick={props.onClick}
							options={props.options || []}
							onBlur={props.onBlur}
							isClearable={false}
							onFocus={props.onFocus}
							isOptionDisabled={(option) => option.Disabled}
							defaultValue={props.defaultValue}
						/>
					</div>
				</div>
			</div>

			{/* Feedback */}
			<small
				className={`control-field__feedback control-field__feedback--${
					props.color
				} ${props.isInvalid ? "d-block" : "d-none"}`}
			>
				{props.feedbackMessage}
			</small>
			<small
				className={`control-field__feedback control-field__feedback--${props.color} d-block error-message`}
			>
				{generateErrorsMsgs(props.errors)}
			</small>
		</div>
	);
};

SelectField.defaultProps = {
	hasLabel: true,
};

export default SelectField;
