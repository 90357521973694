import axios from "axios";
const rateURL = process.env.REACT_APP_API_URL + "/api";

export const viewExchangeRate = async () => {
  return await axios
    .get(`${rateURL}/exchange-rate`)
    .then((res) => res)
    .catch((err) => err.response);
};
export const addExchangeRate = async (data) => {
  return await axios
    .post(`${rateURL}/exchange-rate/create-configs`, data)
    .then((res) => res)
    .catch((err) => err);
};

