import SelectField from "components/shared/SelectField";
import { useGlobalState } from "context/global";
import { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import { fetchType } from "services/visa";
import Locale from "translations";
import VisaRow from "./visaRow";

export default function AddVisa({
	country,
	destination,
	setDestination,
	indexConfiguration,
	setIndexConfiguration,

	inputsState,
	setInputsState,

	arrayCountry,
	setArrayCountry,
	viewConfiguration,
	setviewConfiguration,
	countries,
	addToggle,
}) {
	let locale = localStorage.getItem("currentLocale") || "en";
	const { allCurrencies } = useGlobalState();
	const { backOffice } = Locale;

	//state no main
	const [VisaRowState, setVisaRowState] = useState([]);
	const [visaIndexState, setVisaIndexState] = useState();

	const handleVisa = (e, data) => {
		console.log(viewConfiguration);
		let newData = viewConfiguration;
		let counrtyX = newData.filter((res) => res.country == data.country)[0];
		counrtyX.visa_types.push({
			id: e.value,
			name: e.label,
			residences: [{ residence: " ", currency: "" }],
		});
		setviewConfiguration([...newData]);
	};

	//Fetch currencies
	const [currencies, setCurrencies] = useState([]);
	useEffect(() => {
		async function currenciesLookup() {
			const format = allCurrencies?.map((t) => ({
				value: t.id,
				label: t.currency_code,
			}));
			setCurrencies(format);
		}
		currenciesLookup();
	}, [allCurrencies]);
	const handleDelete = (index, ind = 0) => {
		let clone = [...viewConfiguration];
		clone = clone.filter((res, index) => ind != index);
		setviewConfiguration(clone);
	};
	//Collapse
	const [isOpen, setIsOpen] = useState(true);
	const toggleButton = () => setIsOpen(!isOpen);

	//api
	const [visaType, setVisaType] = useState([]);
	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchType(
				viewConfiguration[0]?.country_code || country?.value
			);

			if (res?.status == 200) {
				const format = res?.data?.data?.map((t) => ({
					value: t.id,
					label: t.name,
				}));
				setVisaType(format);
			}
		}
		fetchLookups();
	}, []);
	const [alertToggle, setAlertToggle] = useState(false);

	return (
		<>
			{viewConfiguration && viewConfiguration?.length > 0 ? (
				<>
					{viewConfiguration?.map((configData, index) => (
						<div className="p-0 border mb-2">
							<div className=" header-add-visa mt-2 px-4 d-flex justify-content-between">
								<p className="country-visa">{configData?.country}</p>

								<div className="w-25 visa-select d-flex align-items-center">
									<SelectField
										label={backOffice.visa}
										placeholder={"visa Type"}
										options={visaType}
										id="basic-countries"
										onChange={(e) => {
											handleVisa(e, configData);
										}}
									/>

									<span
										className="btn-link text-danger mt-4 w-50 pointer"
										onClick={() => handleDelete(indexConfiguration, index)}
									>
										<i class="fas fa-trash"></i> {backOffice.Remove}
									</span>
								</div>
							</div>
							<Collapse isOpen={true}>
								<VisaRow
									index={index}
									country={configData?.country}
									//visa={item}
									handleDelete={handleDelete}
									destination={destination}
									setDestination={setDestination}
									indexConfiguration={index}
									setIndexConfiguration={setIndexConfiguration}
									setviewConfiguration={setviewConfiguration}
									viewConfiguration={viewConfiguration}
									inputsState={(inputsState = configData?.visa_types)}
									setInputsState={setInputsState}
									arrayCountry={arrayCountry}
									setArrayCountry={setArrayCountry}
									configData={configData?.visa_types}
									allConfigData={configData}
									setVisaRowState={setVisaRowState}
									visaIndexState={visaIndexState}
									VisaRowState={VisaRowState}
									countries={countries}
									currencies={currencies}
									addToggle={addToggle}
									alertToggle={alertToggle}
									setAlertToggle={setAlertToggle}
								/>
							</Collapse>
						</div>
					))}
				</>
			) : (
				""
			)}
		</>
	);
}
