import moment from "moment";

export function fetchCalendarFormat(service) {
	const { rooms, dateStart, dateEnd, id } = service;
	return {
		id: id,
		date_from: moment(dateStart).format("YYYY-MM-DD"),
		date_to: moment(dateEnd).format("YYYY-MM-DD"),
		rooms: rooms.map((language) => (language = language.value)),
	};
}

export function formatPluckRoomCalender(service) {
	const {
		days,
		room_id,
		ratePlan,
		customRate,
		ratePrice,
		roomsNumber,
		bulkValidFrom,
		bulkValidTo,
	} = service;
	return {
		room_id: room_id,
		days: days,
		property_rates:
			ratePlan?.length > 0 ? ratePlan.map((res) => (res = res.value)) : [],
		countries:
			customRate?.length > 0 ? customRate.map((res) => (res = res.value)) : [],
		price: ratePrice,
		count: roomsNumber,
		date_from: moment(bulkValidFrom).format("YYYY-MM-DD"),
		date_to: moment(bulkValidTo).format("YYYY-MM-DD"),
	};
}
