/**
 * @Component TextAreaField
 * @Description This component for any text input group like text, email, etc.
 * @Props
 *    -> name: Name for text area field
 *    -> label: Text label for text area field
 *    -> placeholder: Text area field placeholder
 *    -> className: HTML classes for text area field
 *    -> id: HTML ID
 *    -> rows: number of textarea rows
 *    -> color: Bootstrap color
 *    -> value: Text area value
 *    -> feedbackMessage: Feedback message for validation
 *    -> onBlur: Text area Function
 *    -> onChange: Text area Function
 *    -> isInvalid: [Bool] set field is valid or not
 */

function TextAreaField(props) {
	return (
		<>
			<div
				className={`my-2 input-group control-field ${
					props.color ? "control-field--" + props.color : ""
				}`}
			>
				{/* Text Area Field Body */}
				<div className="control-field__body">
					{/* Label */}
					{props.hasLabel && (
						<label
							className={`control-field__label ${
								props.color ? " control-field__label--" + props.color : ""
							}`}
						>
							{props.label}
						</label>
					)}

					{/* Text Area */}
					<div className="row no-gutters">
						<textarea
							name={props.name}
							className={`${props.className ?? ""} control-field__input w-100`}
							id={props.id}
							rows={props.rows}
							onChange={props.onChange}
							onBlur={props.onBlur}
							placeholder={props.placeholder}
							value={props.value}
							disabled={props.disabled}
						></textarea>
					</div>
				</div>
			</div>

			{/* Feedback */}
			<small
				className={`control-field__feedback control-field__feedback--${
					props.color
				} ${props.isInvalid ? "d-block" : "d-none"}`}
			>
				{props.feedbackMessage}
			</small>
		</>
	);
}

TextAreaField.defaultProps = {
	hasLabel: true,
};

export default TextAreaField;
