import Model from "components/model";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { rejectRequestContract } from "services/property";
import { store } from "react-notifications-component";

import TextAreaField from "components/shared/TextAreaField";
import Locale from "translations";
import validate, { isFormValid, validatePhone } from "helpers/validate";

function RejectModal({ isOpen, toggle, ID }) {
    const [reasons, setReasons] = useState('');
    const [errors, setErrors] = useState({});
    const [isErrorLoaded, setIsErrorLoaded] = useState(false);

    const history = useHistory();

    const { property} = Locale;

    useEffect(() => {
        if (isFormValid(errors)) {
            Rejected();
            console.log('Rejected');
        }
    }, [isErrorLoaded]);

    const handleReasonsChange = (e) => {
        const value = e.target.value;
        setReasons(value);
    }

    const checkFormErrors = () => {
        setErrors({
          ...errors,
          ...validate(
            { name: "reasons", value: reasons },
            { required: true, min: 8 }
          )
        });
      };

    const submit = () => {
        checkFormErrors();
        if (!isErrorLoaded) {
            setIsErrorLoaded(true);
          } else {
            setIsErrorLoaded(false);
          }
    }

    const Rejected = async () => {
        let data = {
            value: 3,
            reason: reasons
        }
        const res = await rejectRequestContract(ID, data);
        if (res.status === 200) {
            console.log(res);
            store.addNotification({
                title: "Done!",
                message: res.data.message,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true,
                  pauseOnHover: true
                }
              });
              toggle(false);
              history.push(`/contract-request?page=1`);
        }
    }

    return (
        <Model
            isOpen={isOpen}
            toggle={() => toggle(false)}
            type="ok"
            title="Are you sure you want to reject?"
            size="lg"
            submit={()=>submit()}
        >
        <h1 className="p-1">{property.rejectReasonsInputRequired}</h1> 
            <TextAreaField
                label={property.reject_modal_reasons_input}
                customLabel={property.reject_modal_reasons_input}
                placeholder={property.reject_modal_reasons_input}
                value={reasons}
                rows={5}
                name="reasons"
                onChange={(e) => {
                    handleReasonsChange(e);
                    setErrors({
                        ...errors,
                        ...validate(e.target, {
                            required: true,
                            min: 8
                        })
                    });
                }}
                min={8}
                color={errors.reasons?.required || errors.reasons?.min ? "danger" : ""}
                errors={errors.reasons}
            />
        </Model>
    );
}

export default RejectModal;
