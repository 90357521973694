import SelectField from "components/shared/SelectField";
import React, { useEffect, useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import Locale from "translations";

function Amenity({ amenity, allAmenities, setAllAmenities, roomTypes, id }) {
  const { amenities } = Locale;
  const [checked, setChecked] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [selected, setSelected] = useState("");

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked === true) {
      setAllAmenities([
        ...allAmenities,
        {
          id: Number(value),
          is_has_all_rooms: true,
        },
      ]);
      setChecked(true);
    } else {
      setChecked(false);
      let allAmenitiesClone = [...allAmenities];
      setAllAmenities(allAmenitiesClone.filter((e) => e.id !== Number(value)));
    }
  };

  const handleSelect = (values, id) => {
    const amenitiesClone = [...allAmenities];
    const idx = amenitiesClone.findIndex((item) => item.id === id);
    amenitiesClone[idx] = {
      id,
      rooms: values,
      is_has_all_rooms: selected === "all" ? true : false,
    };
    setAllAmenities(amenitiesClone);
  };

  useEffect(() => {
    if (allAmenities?.find((item) => item.id === amenity.id)) {
      setChecked(true);
    } else if (allAmenities.length === 0) {
      setChecked(false);
    }
  }, [amenity, allAmenities]);

  useEffect(() => {
    const current = allAmenities?.find((item) => item.id === amenity.id);
    if (current) {
      setRooms(current.rooms);
      if (current.is_has_all_rooms === false) {
        setChecked(true);
        setSelected("some");
      }
      //  else {
      // 	setChecked(true);
      // 	setSelected("all");
      // }
    }
  }, [allAmenities, amenity.id]);

  useEffect(() => {
    setRooms([]);
    const amenitiesClone = [...allAmenities];
    const current = amenitiesClone?.find((item) => item.id === amenity?.id);
    const idx = amenitiesClone.findIndex((item) => item.id === current?.id);
    if (selected === "all") {
      amenitiesClone[idx] = { id: current.id, is_has_all_rooms: true };
      setAllAmenities(amenitiesClone);
    } else {
      amenitiesClone[idx] = {
        id: current?.id,
        is_has_all_rooms: false,
        rooms: rooms,
      };
      setAllAmenities(amenitiesClone);
    }
  }, [selected]);

  //
  //
  return (
    <div className={`amenity ${checked ? "checked" : ""}`}>
      <div className="d-flex">
        <div className="form-group form-check amenity-check w-50">
          <input
            name={amenity.name}
            disabled={false}
            type="checkbox"
            className="form-check-input"
            onChange={handleCheckboxChange}
            value={amenity.id}
            checked={allAmenities?.find((item) => item.id === amenity.id)}
            id={amenity.id}
          />
          <label
            className="form-check-label text-capitalize pointer"
            htmlFor={amenity.id}
          >
            {amenity.name}
          </label>
        </div>
        {checked ? (
          <FormGroup tag="fieldset" className="name-check">
            <FormGroup check onClick={() => setSelected("all")}>
              <Label check className="pointer" htmlFor={`rooms--${amenity.id}`}>
                <input
                  type="radio"
                  disabled={false}
                  defaultChecked={
                    selected === "all" ||
                    allAmenities?.find(
                      (item) => item.id === amenity.id && item.is_has_all_rooms
                    )
                  }
                  name={`rooms-${amenity.id}`}
                  id={`rooms--${amenity.id}`}
                />{" "}
                All Rooms
              </Label>
            </FormGroup>
            <FormGroup onClick={() => setSelected("some")} check>
              <Label htmlFor={`rooms-${amenity.id}`} check className="pointer">
                <Input
                  type="radio"
                  disabled={false}
                  id={`rooms-${amenity.id}`}
                  defaultChecked={
                    selected === "some" ||
                    allAmenities?.find(
                      (item) =>
                        item.id === amenity.id &&
                        item.is_has_all_rooms === false
                    )
                  }
                  name={`rooms-${amenity.id}`}
                />
                Some Rooms
              </Label>
            </FormGroup>
          </FormGroup>
        ) : (
          ""
        )}
      </div>
      {checked && selected === "some" ? (
        <div className="col-6 p-0">
          <SelectField
            multi
            label={amenities.selectAmenity}
            disabled={false}
            options={roomTypes}
            placeholder={amenities.selectAmenity}
            value={rooms}
            onChange={(values) => handleSelect(values, amenity.id)}
            // id="amenity-roomType"
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Amenity;
