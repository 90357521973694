import Pagination from "components/shared/Pagination";
import moment from "moment";
import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { listOrders } from "services/orders";
import Locale from "translations";
import OrdersFilter from "../Components/TourismCompanies/Orders/OrdersFilter";
import OrdersTable from "../Components/TourismCompanies/Orders/OrdersTable";

export default function Orders() {
	const { backOffice } = Locale;

	const [Orders, setOrders] = useState([]);
	const [meta, setMeta] = useState(null);

	const { company_id, status } = useParams();

	const [filter, setFilter] = useState({
		traveller_name: "",
		check_date: "",
	});

	const fetchData = async () => {
		const res = await listOrders(
			{
				...filter,
				check_date: filter.check_date
					? moment(filter.check_date).format("DD-MM-YYYY")
					: "",
				status: status === "in-progress" ? "in-progress,paid" : status,
			},
			company_id
		);
		if (res?.status === 200) {
			setOrders(res.data.data);
			setMeta(res.data.meta);
		}
	};

	useEffect(() => {
		fetchData();
	}, [filter, status]);

	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};

	return (
		<>
			<div className="d-flex justify-content-between align-items-center">
				<h6 className="main-title ">{backOffice.orders}</h6>
				<div className="d-flex ">
					<NavLink
						exact={true}
						to={`/tourism-company/orders/${company_id}/in-progress`}
						activeClassName="is-active"
						className="p-2 border mx-1  rounded text-blue-black"
					>
						{backOffice.InProgress}
					</NavLink>

					<NavLink
						exact={true}
						to={`/tourism-company/orders/${company_id}/checked`}
						activeClassName="is-active"
						className="p-2 border rounded text-blue-black"
					>
						{backOffice.AreChecked}
					</NavLink>
				</div>
			</div>

			<OrdersFilter
				company_id={company_id}
				filter={filter}
				setFilter={setFilter}
			/>

			<OrdersTable Orders={Orders} status={status} />

			<Pagination info={meta} goTo={goTo} items={10} />
		</>
	);
}
