import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { getNotification } from "helpers/makeNotifications";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { addEmployee, editEmployee, groupsLookups } from "services/companies";
import Locale from "translations";
import LooksupBranches from "../lookups/fetchBranches";
import LooksupCountries from "../lookups/fetchCountries";

export default function AddEmployee() {
	const { Companies, backOffice } = Locale;
	const { status } = useParams();
	const [errors, setErrors] = useState({});
	const { company_id } = useParams();
	const [Groups, setGroups] = useState([]);
	let locale = localStorage.getItem("currentLocale") || "en";

	const Branches = LooksupBranches(company_id);
	const countries = LooksupCountries();
	const [user, setUser] = useState({
		full_name: "",
		user_id: "",
		position: "",
		phone: "",
		groups: null,
		branch_id: null,
		nationality: null,
		password: "",
		password_confirmation: "",
	});

	const EmployeeEdit = useLocation().state.employee;
	const history = useHistory();

	useEffect(() => {
		if (status === "edit") {
			setUser({
				full_name: EmployeeEdit.full_name,
				position: EmployeeEdit.position,
				user_id: EmployeeEdit.user_id,
				phone: `${EmployeeEdit.phone.phone_code} ${EmployeeEdit.phone.phone}`,
				nationality: {
					value: EmployeeEdit?.nationality?.id,
					label: EmployeeEdit?.nationality?.name,
				},
				groups: {
					value: EmployeeEdit?.group != null ? EmployeeEdit?.group[0]?.id : "",
					label:
						EmployeeEdit?.group != null ? EmployeeEdit?.group[0]?.name : "",
				},
				branch_id: {
					value: EmployeeEdit?.branch?.id,
					label: EmployeeEdit?.branch?.name,
				},
			});
		}
	}, [EmployeeEdit]);

	const checkFormErrors = () => {
		let SubmitError = {};
		Object.keys(user).forEach((key) => {
			SubmitError = {
				...SubmitError,
				...validate(
					{
						name: key,
						value: user[key],
					},
					{ required: true }
				),
			};
		});
		setErrors(SubmitError);
	};

	const createUser = async () => {
		let res;
		checkFormErrors();
		if (isFormValid(errors)) {
			if (status === "add") {
				res = await addEmployee(company_id, {
					...user,
					branch_id: user.branch_id.value,
					lang: locale,
					group_id: user.groups.value,
					nationality: user.nationality.value,
					phone: `+${user.nationality.value}${user.phone}`,
				});
			} else {
				res = await editEmployee(company_id, {
					...user,
					branch_id: user.branch_id.value,
					groups: [user.groups.value],
					lang: locale,
					nationality: user.nationality.value,
				});
			}
			if (res.status === 200 || res.status === 201) {
				getNotification(res?.data?.message);
				history.push(`/tourism-company/employees/${company_id}`);
			}
		}
	};

	useEffect(async () => {
		if (user?.branch_id?.value) {
			const res = await groupsLookups(company_id, {
				branch_id: user?.branch_id?.value,
			});
			const format = res?.data?.data.map((t) => ({
				value: t.id,
				label: t.name,
			}));
			setGroups(format);
		}
	}, [user?.branch_id?.value]);

	console.log(errors);
	return (
		<>
			<div className="my-2">
				<h3 className="font-weight-bold px-1 border-bottom py-2">
					{" "}
					{status === "add" ? Companies.addEmployee : Companies.editEmployee}
				</h3>
			</div>

			<div className="mt-2 our-border border-bottom-0">
				<h3 className="font-weight-bold px-2 mb-0 py-3">
					{" "}
					{Companies.basicInfo}
				</h3>
			</div>

			<div className="our-border pb-4">
				<div className="row   m-0 p-0">
					<div className="col-4">
						<TextField
							type="text"
							placeholder={"full name"}
							label={"full name"}
							hasLabel={true}
							name="full_name"
							value={user.full_name}
							errors={errors?.full_name}
							min={3}
							color={
								errors?.full_name?.required || errors?.full_name?.min
									? "danger"
									: ""
							}
							onChange={(e) => {
								setUser({ ...user, full_name: e.target.value });
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										min: 3,
									}),
								});
							}}
						/>
					</div>
					<div className="col-4">
						<TextField
							type="text"
							placeholder={"position"}
							label={"position"}
							hasLabel={true}
							name="position"
							value={user.position}
							errors={errors?.position}
							color={
								errors?.position?.required || errors?.position?.min
									? "danger"
									: ""
							}
							min={3}
							onChange={(e) => {
								setUser({ ...user, position: e.target.value });
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										min: 3,
									}),
								});
							}}
						/>
					</div>

					<div className="col-4">
						<TextField
							type="text"
							placeholder={Companies.email}
							label={Companies.email}
							hasLabel={true}
							name="user_id"
							disabled={status === "edit"}
							value={user.user_id}
							errors={errors?.user_id}
							color={
								errors?.user_id?.required || errors?.user_id?.email
									? "danger"
									: ""
							}
							onChange={(e) => {
								setUser({ ...user, user_id: e.target.value });
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										email: true,
									}),
								});
							}}
						/>
					</div>
				</div>
				<div className="row m-0 p-0">
					<div className="col-4">
						<SelectField
							hasLabel={true}
							placeholder={Companies.country}
							label={Companies.country}
							name="nationality"
							options={countries}
							value={user.nationality}
							errors={errors?.nationality}
							color={errors?.nationality?.required ? "danger" : ""}
							onChange={(e) => {
								const { label: value } = e;
								setUser({ ...user, nationality: e });
								setErrors({
									...errors,
									...validate(
										{ name: "nationality", value },
										{
											required: true,
										}
									),
								});
							}}
						/>
					</div>
					<div className="col-4">
						<TextField
							type="text"
							placeholder={Companies.phoneNumber}
							label={Companies.phoneNumber}
							hasLabel={true}
							name="phone"
							value={user.phone}
							errors={errors?.phone}
							min={11}
							color={
								errors?.phone?.required
									? "danger"
									: "" || errors?.phone?.min
									? "danger"
									: ""
							}
							onChange={(e) => {
								setUser({ ...user, phone: e.target.value });
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										min: 11,
									}),
								});
							}}
						/>
					</div>
					<div className="col-4">
						<SelectField
							hasLabel={true}
							placeholder={Companies.branch}
							label={Companies.branch}
							options={Branches}
							name="branch_id"
							value={user.branch_id}
							errors={errors?.branch_id}
							color={errors?.branch_id?.required ? "danger" : ""}
							onChange={(e) => {
								const { label: value } = e;
								setUser({ ...user, branch_id: e });
								setErrors({
									...errors,
									...validate(
										{ name: "branch_id", value },
										{
											required: true,
										}
									),
								});
							}}
						/>
					</div>
				</div>

				<div className="row m-0 p-0">
					<div className="col-4">
						<SelectField
							hasLabel={true}
							placeholder={Companies.group}
							label={Companies.group}
							options={Groups}
							name="groups"
							value={user.groups}
							errors={errors?.groups}
							color={errors?.groups?.required ? "danger" : ""}
							onChange={(e) => {
								const { label: value } = e;
								setUser({ ...user, groups: e });
								setErrors({
									...errors,
									...validate(
										{ name: "groups", value },
										{
											required: true,
										}
									),
								});
							}}
						/>
					</div>
					{/*     <div className="col-3 ">
          <TextField
            type="text"
            placeholder={backOffice.newPassword}
            hasLabel={false}
          />
        </div>
        <div className="col-3">
          <TextField
            type="text"
            placeholder={backOffice.confirmPassword}
            hasLabel={false}
          />
        </div> */}
				</div>
				{status === "add" ? <div className="row m-0 p-0"></div> : null}

				<div className="  w-75 d-flex justify-content-end">
					<button className="btn rounded companies-btn " onClick={createUser}>
						{status === "add" ? Companies.addEmployee : Companies.editEmployee}
					</button>
				</div>
			</div>
		</>
	);
}
