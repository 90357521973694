import Pagination from "components/shared/Pagination";
import Locale from "translations";
import PlatformCommissionsTableContent from "./PlatformCommissionsTableContent";

export default function PlatformCommissionsTable({
	PlatformList,
	fetchData,
	meta,
	goTo,
}) {
	const { backOffice } = Locale;

	const removeItem = (itemId) => {
		console.log(itemId);
	};

	const AllProducts = PlatformList?.map((productItem, index) => (
		<PlatformCommissionsTableContent
			fetchData={fetchData}
			productItem={productItem}
			index={index}
			key={index}
			removeItem={removeItem}
		/>
	));

	return (
		<>
			<div className="our-border px-4">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>{backOffice.platformName}</th>
							<th>{backOffice.country}</th>
							<th>{backOffice.contractStartDate}</th>
							<th>{backOffice.contractEndDate}</th>
							<th>{backOffice.mobileNumber}</th>
							<th>{backOffice.status}</th>
							<th>{backOffice.tools}</th>
						</tr>
					</thead>
					<tbody>
						{PlatformList?.length > 0 ? (
							AllProducts
						) : (
							<tr>
								<td colSpan="11">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				{PlatformList?.length > 0 ? (
					<Pagination info={meta} goTo={goTo} />
				) : null}
			</div>
		</>
	);
}
