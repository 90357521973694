import TextAreaField from "components/shared/TextAreaField";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { updateTerms } from "services/sbsReservations";

function TermsConditions(props) {
	const { details, handleReservationState } = props;
	const { terms } = details;
	const [termsState, setTermsState] = useState(terms);
	const { id } = useParams();
	const handleTermsState = () => {
		handleReservationState({ name: "terms", value: termsState });
	};

	const updateTermsAndConditions = async () => {
		const res = await updateTerms(id, {
			terms: termsState,
		});

		if (res.status == 200) {
			handleTermsState();
		} else {
			setTermsState(terms);
		}
	};

	return (
		<div id="reserv-terms-conditions">
			<div className="terms_conditions_header w-100 d-flex">
				<p>Terms & Conditions</p>
				<button
					className="btn btn-info p-2 rounded-0"
					onClick={updateTermsAndConditions}
					disabled={details?.status?.id >= 4}
				>
					Save Changes
				</button>
			</div>

			<div className="my-2 terms_conditions_filed">
				<TextAreaField
					hasLabel={false}
					placeholder={"Add Remarks"}
					value={termsState}
					rows={5}
					name="reasons"
					onChange={(e) => {
						setTermsState(e.target.value);
					}}
				/>
			</div>
		</div>
	);
}

export default TermsConditions;
