import resetIcon from "assets/images/wallet/resetIcon.png";

import DatePickerField from "components/shared/DatePickerField";
import TextField from "components/shared/TextField";
import Locale from "translations";
export default function OrdersFilter({ filter, setFilter }) {
	const { backOffice } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";

	/*   const handleChange = (e) => {
    if ("target" in e) {
      const value = e.target.value;
      const name = e.target.name;
      setFilterState({ ...filterState, [name]: value });
    } else {
      const value = e.value;
      const name = e.name;
      setFilterState({ ...filterState, [name]: value });
    }
  }; */

	/*   useEffect(() => {
    async function fetchData() {
      const data = {
        search: filterState.search,
        status: filterState.status?.value,
      };

      const res = await listOrders(data, company_id, pages);
      setOrders(res?.data.data);
    }
    fetchData();
  }, [filterState.search, filterState.status]); */

	/* const Status = [
    {
      value: 1,
      label: "IN_PROGRESS",
    },
    {
      value: 2,
      label: "COMPLETED ",
    },
    {
      value: 3,
      label: "IN_VERIFY",
    },
    {
      value: 4,
      label: "REJECTED ",
    },
    {
      value: 5,
      label: "ACTIVE",
    },
    {
      value: 6,
      label: "BOOKED",
    },
    {
      value: 7,
      label: "CLOSED",
    },
  ];
 */

	return (
		<>
			<div className="main-filter bg-white-blue py-2 my-2">
				<div className="row align-items-center w-100 no-gutter m-0">
					<div className="col-md-2">
						<div className="main-label">
							<TextField
								type="text"
								placeholder={backOffice.search}
								hasLabel={false}
								value={filter.traveller_name}
								onChange={(e) => {
									setFilter({
										...filter,
										traveller_name: e.target.value,
									});
								}}
							/>
							<i className="fas fa-search fa-fw"></i>
						</div>
					</div>

					<div className="col-md-2">
						<DatePickerField
							hasLabel={false}
							date={filter.check_date}
							placeholder={backOffice.date}
							onChangeDate={(e) => {
								setFilter({
									...filter,
									check_date: e,
								});
							}}
							isOutsideRange={(day) => {
								return false;
							}}
						/>
					</div>

					<div
						className="col-md"
						onClick={() => {
							setFilter({
								traveller_name: "",
								check_date: "",
							});
						}}
					>
						<div className="d-flex align-items-center text-secondary pointer mt-4 reset-btn">
							<img src={resetIcon} alt="" srcset="" />
							<p className="text-caption">{backOffice.resetFilter}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
