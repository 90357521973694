import axios from "axios";
const backOfficeApi = process.env.REACT_APP_API_URL + "/api";

export const showWallets = async (id) => {
	return await axios
		.get(`${backOfficeApi}/payment-cards/card/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listCurrencies = async () => {
	return await axios
		.get(`${backOfficeApi}/lookups/currencies`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const listMarketplaceCurrencies = async () => {
	return await axios
		.get(`${backOfficeApi}/marketplace/url-tokens/list-currencies`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listCompanyWallets = async (id) => {
	return await axios
		.get(`${backOfficeApi}/payment-cards/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listWalletsTransactions = async (params) => {
	return await axios
		.get(`${backOfficeApi}/payment-transactions`, {
			params: params,
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const listWalletsRequests = async (params) => {
	return await axios
		.get(`${backOfficeApi}/payment-transactions/requests/list`, {
			params: params,
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const showRequests = async (id, params) => {
	return await axios
		.get(`${backOfficeApi}/payment-transactions/requests/${id}/show`, {
			params: params,
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const showTransaction = async (id, params) => {
	return await axios
		.get(`${backOfficeApi}/payment-transactions/${id}`, {
			params: params,
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const acceptRequests = async (id, data) => {
	return await axios
		.post(`${backOfficeApi}/payment-transactions/requests/${id}/accept`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const rejectRequests = async (id, data) => {
	return await axios
		.post(`${backOfficeApi}/payment-transactions/requests/${id}/reject`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addBalances = async (data) => {
	return await axios
		.post(`${backOfficeApi}/payment-transactions/debit`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const addBalancesCredit = async (data) => {
	return await axios
		.post(`${backOfficeApi}/payment-transactions/credit`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const payCreditByDebit = async (id, data) => {
	return await axios
		.post(`${backOfficeApi}/payment-cards/${id}/pay-credit-by-debit`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addPaymentCard = async (data) => {
	return await axios
		.post(`${backOfficeApi}/payment-cards`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getReceipt = async (id) => {
	return await axios
		.get(`${backOfficeApi}/files/fetch/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
