import Locale from "translations";

export default function WalletTable(props) {
	const { backOffice } = Locale;

	const allThead =
		props.tbody?.length > 0 ? (
			props.thead.map((item, index) => {
				return (
					<th key={index} className="CompanyHead-title text-gray ">
						{item}
					</th>
				);
			})
		) : (
			<th className="CompanyHead-title text-gray text-center ">
				{backOffice.noResult}
			</th>
		);

	return (
		<table className="custom-table back-office-table table table-striped">
			<thead className="">
				<tr className="">{allThead}</tr>
			</thead>
			<tbody>{props.viewMore ? props.tbody?.slice(0, 5) : props.tbody}</tbody>
		</table>
	);
}
