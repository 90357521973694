import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import moment from "moment";
import React from "react";
import { useState } from "react";
import Switch from "react-switch";
import error from "../../../../../assets/images/booking/error.svg";
import sucess from "../../../../../assets/images/booking/sucess.svg";

export default function EditReservation({
  requsetEdit,
  editState,
  setEditState,
  setValueSpecialRequests,
  setNewChildrenQuantity,
  changeAge,
  swichToggle,
  handleSwitch,
  SpecialRequests
}) {
  console.log("editState101", editState);
  // const [swichToggle, setswichToggle] = useState(true);
  // const handleSwitch = () => {
  //     setswichToggle(!swichToggle);
  // };


  return (
    <div className="row p-3">
      <>
        <div className="col-6">
          <DatePickerField
            type="text"
            placeholder={"DD/MM/YYYY"}
            label={"Check-in"}
            date={editState.checkIn}
            onChangeDate={(filterDate) => {
              setEditState({
                ...editState,
                checkIn: filterDate,
                checkOut: moment(filterDate).add(1, "d"),
              });
            }}
          />
        </div>

        <div className="col-6">
          <DatePickerField
            type="text"
            hasLabel={true}
            label={"Check-out"}
            date={editState.checkOut}
            onChangeDate={(filterDate) => {
              setEditState({
                ...editState,
                checkOut: filterDate,
              });
            }}
          />
        </div>

        <div className="col-6">
          <SelectField
            hasLabel={true}
            name="status"
            label={"Adults"}
            options={editState.adultOption}
            value={{ label: editState?.adult, value: editState?.adult }}
            onChange={(e) => {
              
              setEditState({
                ...editState,
                adult: e.value,
              });
            }}
          />
        </div>

        <div className="col-6">
        <SelectField
            hasLabel={true}
            name="status"
            label={"Childern"}
            options={editState.childOption}
            value={{ label: editState.child, value: editState.child }}
            onChange={(e) => {
              setNewChildrenQuantity(e.value);
            }}
          />
        </div>

        {editState.childrenArray && editState.childrenArray.length > 0 ? (
          <>
            {editState.childrenArray.map((res, index) => {
              return (
                <div className="col-6">
                  <TextField
                    hasLabel={true}
                    name="status"
                    label={`Childern (${index + 1}) Age`}
                    value={res.age}
                    onChange={(e) => changeAge(index, e)}
                  />
                </div>
              );
            })}
          </>
        ) : null}

        <div className="col-6">
          <SelectField
            hasLabel={true}
            name="status"
            label={"Rate Basis"}
            options={editState.rateOption}
            value={{
              label: editState.mealOptionName,
              value: editState.mealOptionId,
            }}
            onChange={(e) => {
              setEditState({
                ...editState,
                mealOptionId: e.value,
                mealOptionName: e.label,
              });
            }}
          />
        </div>

        <div className="col-6">
          <SelectField
            hasLabel={true}
            name="status"
            label={"Bedding Preference"}
            options={editState.BeddingOption}
            value={{
                label: editState.beddingPreferenceName,
                value:  editState.beddingPreference,
              }}
            onChange={(e) => {
              setEditState({
                ...editState,
                beddingPreference: e.value,
                beddingPreferenceName: e.label,
              });
            }}
          />
        </div>

        <div className="d-flex my-2 col-12">
          <p>Special Request</p>
          <Switch
            className="react-switch mx-3 border-reduis-switch"
            //disabled
            checked={swichToggle}
            height={25}
            handleDiameter={25}
            width={55}
            borderRadius={4}
            onChange={handleSwitch}
            uncheckedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 15,
                  color: "#fff",
                  paddingRight: 2,
                }}
              >
                No
              </div>
            }
            checkedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 15,
                  color: "#fff",
                  paddingRight: 2,
                }}
              >
                Yes
              </div>
            }
          />
        </div>

        <div className="special-request-containt"  style={{columnCount:2}}>
       
          { editState.allowsSpecialRequests && SpecialRequests  && swichToggle
            ? SpecialRequests?.map((res, index) => {
                return (
                  <>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={res.code}
                        checked={
                          editState.specialRequests &&
                          editState.specialRequests.length > 0
                            ? editState.specialRequests.filter(
                                (reas) => reas.req == res.code
                              ).length > 0
                            : null
                        }
                        onChange={(e) => {
                          setValueSpecialRequests(e.target.checked, res.code);
                        }}
                        id={"flexCheckIndeterminate" + index}
                      />
                      <label
                        class="form-check-label"
                        for={`flexCheckIndeterminate` + index}
                      >
                        {res.text}
                      </label>
                    </div>
                  </>
                );
              })
            : ""}
        </div>

        <div className=" col-12  ">
          <button
            className="btn  companies-btn w-100 mt-4"
            onClick={requsetEdit}
          >
            SEARCH
          </button>
        </div>
      </>
    </div>
  );
}
