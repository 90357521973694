import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import Model from "components/model";
import DatePickerField from "components/shared/DatePickerField";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useEffect, useState } from "react";
import { uploadFile } from "services/sbsReservations";

///////////////////////////////// EditPaymentModal/////////////////////////

const paymentMethodList = [
	{ label: "Bank Transfer", value: "bank" },
	{ label: "Cash", value: "cash" },
];

export const EditPaymentModal = (props) => {
	const {
		modalState,
		toggleModel,
		details,
		paymentIndex,
		handleReservationState,
	} = props;
	const [selected, setSelected] = useState("edit");
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const currentPayment = details?.["payments"]?.[paymentIndex];

	const [paymentState, setPaymentState] = useState({
		option_date: new Date(),
		amount: 0,
		paymentMethod: "",
		bankReceipt: "",
	});

	const [errors, setErrors] = useState({});

	const editPayment = selected === "edit";
	const confirmPayment = selected === "confirm";
	const bankTransfer = paymentState.paymentMethod?.value === "bank";
	const cash = paymentState.paymentMethod?.value === "cash";
	const amountCondition = editPayment || cash;
	const bankTransferCondition = confirmPayment && bankTransfer;

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "option_date", value: paymentState.option_date },
				{ required: true }
			),
			...(amountCondition &&
				validate(
					{ name: "amount", value: paymentState.amount },
					{
						required: true,
						minNumber: 1,
					}
				)),
			...(confirmPayment &&
				validate(
					{ name: "paymentMethod", value: paymentState.paymentMethod },
					{ required: true }
				)),
			...(bankTransferCondition &&
				validate(
					{ name: "bankReceipt", value: paymentState.bankReceipt },
					{ required: true }
				)),
		});
	};
	const onEditPayment = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const uploadRecepit = async (file) => {
		let formData = new FormData();
		formData.append("file", file);
		let isImgSelected = "";
		if (file) {
			isImgSelected = await uploadFile(formData);
			return isImgSelected?.data?.uuid;
		}
	};

	useEffect(() => {
		const fechData = async () => {
			if (Object.values(errors).length > 1) {
				if (isFormValid(errors)) {
					if (editPayment) {
						let updatedPayments = [...details?.["payments"]];
						const updatepayment = { ...currentPayment };

						updatepayment.amount = paymentState.amount;
						updatepayment.option_date = moment(paymentState.option_date).format(
							"YYYY-MM-DD"
						);
						updatedPayments[paymentIndex] = updatepayment;
						handleReservationState({
							name: "payments",
							value: updatedPayments,
						});
						toggleModel();
					} else {
						const paymentMethod = paymentState.paymentMethod?.value;
						if (paymentMethod === "cash") {
							let updatedPayments = [...details?.["payments"]];
							const updatepayment = { ...currentPayment };

							updatepayment.payment_date = moment(
								paymentState.option_date
							).format("YYYY-MM-DD");
							updatepayment.payment_method = paymentState.paymentMethod.value;
							updatepayment.amount = paymentState.amount;
							updatepayment.status = "confirmed";
							updatedPayments[paymentIndex] = updatepayment;
							handleReservationState({
								name: "payments",
								value: updatedPayments,
							});
							toggleModel();
						} else if (paymentMethod === "bank") {
							let updatedPayments = [...details?.["payments"]];
							const updatepayment = { ...currentPayment };
							const uuid = await uploadRecepit(paymentState.bankReceipt);
							updatepayment.payment_date = moment(
								paymentState.option_date
							).format("YYYY-MM-DD");
							updatepayment.payment_method = paymentState.paymentMethod.value;
							updatepayment.status = "confirmed";
							updatepayment.receipt = uuid;
							updatedPayments[paymentIndex] = updatepayment;
							handleReservationState({
								name: "payments",
								value: updatedPayments,
							});
							toggleModel();
						}
					}
				}
			}
		};
		fechData();
		return () => {};
	}, [isErrorLoaded]);

	const resetForm = () => {
		setPaymentState({
			option_date: new Date(),
			amount: 0,
			paymentMethod: "",
			bankReceipt: "",
		});
		setErrors({});
	};

	return (
		<Model
			isOpen={modalState}
			// type={tentativeModal.type}
			title={"Edit Payment"}
			toggle={toggleModel}
			size="md"
			hasFooter={false}
		>
			<div className="w-100 px-5 reserv_modal row">
				<div className={"reserv_modal_total_price  my-3 w-100"}>
					<p>Total Price</p>
					<p>{`${currentPayment?.amount} ${details?.currency}`}</p>
				</div>

				{/*///////////// Radio Inputs ///////////////*/}
				<fieldset className="name-check d-flex justify-content-between form-group w-100">
					<div
						className="reserv_modal_radio form-check"
						onClick={() => {
							setSelected("edit");
							resetForm();
						}}
					>
						<label
							htmlFor={"edit-payment"}
							className={`pointer p-0 ${
								editPayment ? "pointer-checked" : ""
							} form-check-label`}
						>
							<input
								className="form-check-input"
								type="radio"
								disabled={false}
								checked={editPayment}
								name={"edit-payment"}
								id={"edit-payment"}
							/>
							Edit Payment
						</label>
					</div>
					<div
						className="reserv_modal_radio form-check"
						onClick={() => {
							setSelected("confirm");
							resetForm();
						}}
					>
						<label
							htmlFor={"confirm-payment"}
							className={`pointer p-0 ${
								confirmPayment ? "pointer-checked" : ""
							} form-check-label`}
						>
							<input
								className="form-check-input"
								type="radio"
								disabled={false}
								checked={confirmPayment}
								name={"confirm-payment"}
								id={"confirm-payment"}
							/>
							Confirm Payment
						</label>
					</div>
				</fieldset>

				{/*///////////// Form Fields ///////////////*/}

				<div className="m-0 modal-body px-0 reserv_modal_payment_fields">
					<div>
						<DatePickerField
							type="text"
							placeholder={"DD/MM/YYYY"}
							label={"Option Date"}
							date={moment(paymentState.option_date)}
							onChangeDate={(date) =>
								setPaymentState((current) => ({
									...current,
									option_date: date._d,
								}))
							}
							isOutsideRange={(day) => {
								return false;
							}}
						/>
					</div>
					{confirmPayment && (
						<div className="payment_field_model">
							<SelectField
								label={"Payment Method"}
								placeholder={paymentState?.paymentMethod?.label}
								value={paymentState?.paymentMethod?.label}
								name="paymentMethod"
								id="paymentMethod"
								options={paymentMethodList}
								onChange={(e) => {
									setPaymentState((current) => ({
										...current,
										paymentMethod: e,
										amount: 0,
										bankReceipt: "",
									}));
									console.log(e);
									let confirmErrors =
										e.value == "bank"
											? { amount: { required: false } }
											: { paymentMethod: { required: false } };
									setErrors({
										...errors,
										...confirmErrors,
										...validate(
											{ name: "paymentMethod", value: e },
											{ required: true }
										),
									});
								}}
								onBlur={() =>
									setErrors({
										...errors,
										...validate(
											{
												name: "paymentMethod",
												value: paymentState.paymentMethod,
											},
											{ required: true }
										),
									})
								}
								color={errors?.paymentMethod?.required ? "danger" : ""}
								errors={errors?.paymentMethod}
							/>
						</div>
					)}

					{amountCondition && (
						<div className="payment_field_model">
							<TextField
								type="text"
								label={"amount"}
								placeholder={"amount"}
								id={"room-amount"}
								name="amount"
								value={paymentState?.amount}
								extraTextPosition="append"
								extraText={details?.currency}
								onChange={(e) => {
									setPaymentState((current) => ({
										...current,
										amount: +e.target.value.replace(/[^0-9.]/, ""),
									}));

									setErrors({
										...errors,
										...validate(
											{ name: "amount", value: e.target },
											{ required: true, minNumber: 0 }
										),
									});
								}}
								color={
									errors?.amount?.required || errors?.amount?.minNumber
										? "danger"
										: ""
								}
								min={0}
								errors={errors?.amount}
							/>
						</div>
					)}

					{bankTransferCondition && (
						<div
							className={`payment_amount_model upload_photo ${
								errors.bankReceipt?.required ? "red-Border" : ""
							}`}
						>
							<h6 className="p-2">Upload Bank Receipt</h6>
							<div className="d-flex justify-content-center my-5">
								<label
									htmlFor="bank-receipt"
									className={`btn btn-primary px-4`}
								>
									<input
										type="file"
										id="bank-receipt"
										accept="image/*"
										onChange={(e) => {
											const file = e.target.files.item(0);
											setPaymentState((current) => ({
												...current,
												bankReceipt: file,
											}));
										}}
										style={{ display: "none" }}
									/>
									<span aria-label="upload">Add Photo</span>
								</label>
							</div>
						</div>
					)}
					{bankTransferCondition && errors.bankReceipt?.required && (
						<small className="control-field__feedback error-message d-block">
							Bank Receipt is required
						</small>
					)}
				</div>

				<button
					className="btn btn-res-primary w-100 mt-2"
					onClick={onEditPayment}
				>
					Edit
				</button>
			</div>
		</Model>
	);
};
