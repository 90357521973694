import React, { createContext, useContext, useReducer } from "react";
const PlatformContext = createContext();
const PlatformDispatchContext = createContext();
const initialState = {};

function PlatformReducer(state, action) {
  switch (action.type) {
    case "platformDetails": {
      return { ...state, PlatformDetails: action.payload };
    }

    case "progress": {
      return {
        ...state,
        progress: action.progress,
        step: action.step,
        current: action.current,
      };
    }

    case "platformDetailsList": {
      return { ...state, PlatformDetailsList: action.payload };
    }

    case "progressList": {
      return {
        ...state,
        progress: action.progress,
        step: action.step,
        current: action.current,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function usePlatformState() {
  const context = useContext(PlatformContext);
  if (context === undefined) {
    throw new Error("usePlatformState must be used within a PlatformProvider");
  }
  return context;
}

function usePlatformDispatch() {
  const context = useContext(PlatformDispatchContext);
  if (context === undefined) {
    throw new Error(
      "usePlatformDispatch must be used within a PlatformProvider"
    );
  }
  return context;
}

function PlatformProvider({ children }) {
  const [state, dispatch] = useReducer(PlatformReducer, initialState);
  return (
    <PlatformContext.Provider value={state}>
      <PlatformDispatchContext.Provider value={dispatch}>
        {children}
      </PlatformDispatchContext.Provider>
    </PlatformContext.Provider>
  );
}

export { PlatformProvider, usePlatformState, usePlatformDispatch };
