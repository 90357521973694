import hotelImg from "assets/images/Hotels.svg";
import Pagination from "components/shared/Pagination";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { listVendor } from "services/vendor";
import Locale from "translations";
import Filter from "./filter";

export default function Vendor() {
	const history = useHistory();
	const { backOffice, vendor: vendorLocale } = Locale;
	const [vendorList, setVendorList] = useState([]);
	const [meta, setMeta] = useState(null);
	const [filter, setFilter] = useState({
		search: undefined,
		name: undefined,
		page: 1,
	});
	const fetchData = async () => {
		const res = await listVendor(filter);

		if (res?.status === 200) {
			setVendorList(res.data.data);
			setMeta(res.data.meta);
		}
	};
	useEffect(() => {
		fetchData();
	}, [filter]);

	const AllVendors =
		vendorList.length > 0 ? (
			vendorList.map((vendor, index) => (
				<tr>
					<td width={"25%"}>{vendor.id}</td>
					<td width={"25%"}>{vendor.name}</td>
					<td className="img-service" width={"25%"}>
						{vendor?.services?.some((item) => item.name === "hotels") ? (
							<div className="mx-2">
								<img src={hotelImg} alt="" width={22} />
							</div>
						) : null}
					</td>
					<td width={"10%"}>
						<Link
							to={{
								pathname: `/manage-vendor/${vendor.id}`,
							}}
							className="d-inline-block action--btn font-bold"
						>
							<i class="fas fa-cog mx-1"></i>
							{vendorLocale.manage}
						</Link>
					</td>
				</tr>
			))
		) : (
			<tr>
				<td colSpan="4">
					<div className="product-no-data">
						<i className="fas fa-info-circle fa-lg"></i>{" "}
						<h4>{backOffice.noResult}</h4>
					</div>
				</td>
			</tr>
		);
	const goTo = (page) => {
		setFilter({ ...filter, page: +page });
	};

	return (
		<>
			<div className="d-flex justify-content-between align-items-center mb-2">
				<h6 className="main-title ">{"Vendor Commission"}</h6>
				{/* <Link className="btn btn-success" to={`/add-vendor`}>
					<i class="fas fa-plus-circle"></i> {backOffice.add}
				</Link> */}
			</div>
			<Filter filter={filter} setFilter={setFilter} />
			<div className=" table-responsive px-4 our-border">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>Id</th>
							<th>{vendorLocale.name}</th>
							<th>{vendorLocale.services}</th>
							<th>{vendorLocale.tools}</th>
						</tr>
					</thead>

					<tbody>{AllVendors}</tbody>
				</table>
				<div className="px-2">
					<Pagination info={meta} goTo={goTo} />
				</div>
			</div>
		</>
	);
}
