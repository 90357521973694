import Pagination from "components/shared/Pagination";
import Locale from "translations";
import SeasonsTableContent from "./TableContent";

export default function SuppliersTable({
	setSuppliersList,
	suppliersList,
	meta,
	goTo,
	filter,
}) {
	const { backOffice } = Locale;
	const All = suppliersList?.map((supplierItem, index) => (
		<SeasonsTableContent
			filter={filter}
			supplierItem={supplierItem}
			setSuppliersList={setSuppliersList}
			index={index}
			key={index}
		/>
	));

	return (
		<>
			<div className="our-border px-4">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>{backOffice.supplierName}</th>
							<th>{backOffice.country}</th>
							<th>{backOffice.service}</th>
							<th>{backOffice.joinDate}</th>
							<th>{backOffice.status}</th>
							<th>Spacial Offer</th>
							<th>{backOffice.tools}</th>
						</tr>
					</thead>
					<tbody>
						{suppliersList?.length > 0 ? (
							All
						) : (
							<tr>
								<td colSpan="7">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				{suppliersList?.length > 0 ? (
					<Pagination info={meta} goTo={goTo} />
				) : null}
			</div>
		</>
	);
}
