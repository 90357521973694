import libphonenumber from "google-libphonenumber";
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export const isFormValid = (errors) => {
	let errorsList = [];
	if (errors != undefined && Object.values(errors).length > 0) {
		Object.values(errors).map((value, i) => {
			errorsList = [...errorsList, ...Object.values(value)];
		});
		return errorsList.every((value) => !value);
	} else {
		return false;
	}
};

export const validatePhone = (phone, code) => {
	try {
		if (phone !== null) {
			return phoneUtil.isValidNumberForRegion(
				phoneUtil.parse(phone.toString(), code),
				code
			);
		} else {
			return true;
		}
	} catch (err) {
		return false;
	}
};

export default function validate(target, rules, confirm) {
	const { name, value } = target;
	const errors = { [name]: {} };

	if (rules.required) {
		if (value === "" || value == undefined || value == null || value == false) {
			errors[name]["required"] = true;
		} else {
			errors[name]["required"] = false;
		}
	}

	if (rules.min) {
		if (value != undefined && value.length >= rules.min) {
			errors[name]["min"] = false;
		} else {
			errors[name]["min"] = true;
		}
	}

	if (rules.max) {
		if (value != undefined && value.length <= rules.max) {
			errors[name]["max"] = false;
		} else {
			errors[name]["max"] = true;
		}
	}

	if (rules.maxNumber) {
		if (value != undefined && +value <= rules.maxNumber) {
			errors[name]["maxNumber"] = false;
		} else {
			errors[name]["maxNumber"] = true;
		}
	}

	if (rules.minNumber) {
		if (value != undefined && +value >= rules.minNumber) {
			errors[name]["minNumber"] = false;
		} else {
			errors[name]["minNumber"] = true;
		}
	}

	if (rules.email) {
		const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

		if (emailRegex.test(value)) {
			errors[name]["email"] = false;
		} else {
			errors[name]["email"] = true;
		}
	}

	if (rules.number) {
		const numberRegex = /^((?!501|504)[0-9]*)$/;

		if (numberRegex.test(value)) {
			errors[name]["number"] = false;
		} else {
			errors[name]["number"] = true;
		}
	}
	if (rules.password) {
		const passwordRegex = /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/g;

		if (passwordRegex.test(value)) {
			errors[name]["password"] = false;
		} else {
			errors[name]["password"] = true;
		}
	}
	if (rules.floatNumber) {
		//const floatNumberRegex = /^[+-]?\d+(\.\d+)?$/;
		const floatNumberRegex = /^[0-9]*(\.[0-9]*)?$/;

		if (floatNumberRegex.test(value)) {
			errors[name]["floatNumber"] = false;
		} else {
			errors[name]["floatNumber"] = true;
		}
	}

	if (rules.confirm) {
		if (value.toLowerCase() === confirm.toLowerCase()) {
			errors[name]["confirm"] = false;
		} else {
			errors[name]["confirm"] = true;
		}
	}

	if (rules.phone) {
		if (value) {
			errors[name]["phone"] = false;
		} else {
			errors[name]["phone"] = true;
		}
	}

	if (rules.date) {
		if (value) {
			errors[name]["date"] = false;
		} else {
			errors[name]["date"] = true;
		}
	}

	if (rules.notNumber) {
		const notNumberRegex = /^[a-zA-Zء-ي_ ]*$/;
		if (notNumberRegex.test(value)) {
			errors[name]["notNumber"] = false;
		} else {
			errors[name]["notNumber"] = true;
		}
	}

	return errors;
}
