import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import moment from "moment";
import { useEffect, useState } from "react";
import { isInclusivelyBeforeDay } from "react-dates";
import Select from "react-select";
import Locale from "translations";
import WarningModal from "./warningModal";

export default function VisaRow({
	index,
	country: countryBase,
	visa,
	handleDelete,
	destination,
	setDestination,
	indexConfiguration,
	setviewConfiguration,
	viewConfiguration,
	setIndexConfiguration,
	inputsState,
	setInputsState,
	arrayCountry,
	setArrayCountry,
	configData,
	allConfigData,
	visaLabel,
	currencies,
	countries,
	addToggle,
	alertToggle,
	setAlertToggle,
}) {
	console.log("countryBasecountryBase", countryBase);
	const { backOffice } = Locale;
	console.log("inputsStateinputsState", inputsState);

	const [allInputs, setAllInputs] = useState([]);
	const transportationType = [
		{
			value: "maritime",
			label: "maritime",
		},
		{
			value: "air",
			label: "air",
		},
		{
			value: "land",
			label: "land",
		},
	];
	const [inputsStates, setInputsStates] = useState([]);
	const [VisaLabel, setVisaLabel] = useState();
	useEffect(() => {
		setAllInputs([...inputsState]);
		setVisaLabel(visaLabel);
		//setVisaRowState([])
	}, []);
	console.log("VisaLabel", VisaLabel);

	const add = () => {
		setInputsStates([...inputsStates, {}]);
	};
	const addEdit = (ind) => {
		let newData = viewConfiguration;
		let counrtyX = newData.filter((res) => res.country == countryBase)[0];
		counrtyX.visa_types[ind].residences.push({
			residence: " ",
			currency: "",
		});
		setviewConfiguration([...newData]);
	};

	const handleChenageinArray = (
		name,
		value,
		index,
		ind = 0,
		selectKeyId = null,
		selectKeyLabel = null
	) => {
		let input = inputsState[index];
		let newData = viewConfiguration;
		let counrtyX = newData.filter((res) => res.country == countryBase)[0];
		counrtyX.visa_types[index].residences[ind][name] = value;
		setviewConfiguration([...newData]);
	};
	const handleCopy = (index, ind) => {
		let allConfigurationClone = viewConfiguration;
		let selectedConfig = allConfigurationClone.filter(
			(res) => res.country == countryBase
		)[0];
		let configureRowCopied = selectedConfig.visa_types[ind]?.residences;

		configureRowCopied?.push({
			...configureRowCopied[index],
			id: configureRowCopied?.length + 1,
		});
		setviewConfiguration([...allConfigurationClone]);
	};

	useEffect(() => {
		if (!arrayCountry.includes(countryBase?.value)) {
			setArrayCountry([...arrayCountry, countryBase?.value]);
		}
	}, []);

	const deleteVise = (ind, indexs) => {
		let newData = viewConfiguration;
		let counrtyX = newData.filter((res) => res.country == countryBase)[0];
		counrtyX.visa_types[ind].residences = counrtyX.visa_types[
			ind
		].residences.filter((res, index) => indexs != index);
		if (counrtyX.visa_types[ind].residences.length == 0) {
			//newData = newData.filter((res) => res.country != countryBase);
			counrtyX.visa_types = counrtyX.visa_types.filter(
				(res, index) => res?.residences?.length != 0
			);
			if (counrtyX.visa_types?.length == 0) {
				newData = newData.filter((res) => res.country != countryBase);
			}
			console.log(counrtyX);
		}
		setviewConfiguration([...newData]);
	};
	useEffect(() => {
		let allConfigurationClone = viewConfiguration;
		let selectedConfig = allConfigurationClone.filter(
			(res) => res.country == countryBase
		);
		console.log(selectedConfig);

		if (selectedConfig?.length > 1) {
			setAlertToggle(true);
			selectedConfig = selectedConfig[0];
			allConfigurationClone.pop(selectedConfig[1]);
			console.log(allConfigurationClone);
		}
		setviewConfiguration([...allConfigurationClone]);
	}, [addToggle]);

	console.log("newData1010", alertToggle);

	return (
		<>
			{!visa && inputsState && inputsState?.length > 0
				? inputsState.map((inputsStateData, ind) => {
						return (
							<div className="visa-row">
								{inputsStateData.residences.map((configDataItem, index) => (
									<div className="col-md-12 m-auto">
										<div className="row visa-row-content d-flex flex-nowrap">
											<div
												className={`btn-visa col-md-2 ${
													index != 0 ? "dis-hidden" : ""
												}`}
											>
												<button className="btn px-5 py-2 w-100">
													{inputsStateData?.name}
												</button>
											</div>

											<div className="config-row d-flex flex-wrap bg-gray-white-100 mb-2">
												<div className="col-md-2">
													<SelectField
														label={backOffice.residence}
														placeholder={backOffice.residence}
														options={countries}
														id="basic-countries"
														name="residences"
														onChange={(e) => {
															handleChenageinArray(
																"residence",
																e,
																ind,
																index,
																"country_id",
																"name"
															);
														}}
														value={
															!configDataItem?.residence
																? {
																		value: configDataItem?.residence_id,
																		label: configDataItem?.residence_name,
																  }
																: configDataItem?.residence
														}
													/>
												</div>
												<div className="col-md-4 mt-3">
													<span>{backOffice.nationality}</span>
													<Select
														label={backOffice.nationality}
														placeholder={backOffice.nationality}
														name="nationalities"
														isMulti
														value={
															configDataItem?.nationalities?.length > 0 &&
															configDataItem?.nationalities[0]?.country_id
																? configDataItem?.nationalities?.map((item) => {
																		return {
																			value: item.country_id,
																			label: item.name,
																		};
																  })
																: configDataItem?.nationalities?.map((item) => {
																		return {
																			value: item?.value,
																			label: item?.label,
																		};
																  })
														}
														onChange={(e) => {
															handleChenageinArray(
																"nationalities",
																e,
																ind,
																index
															);
														}}
														options={countries}
													/>
													{/* <SelectField
														label={backOffice.nationality}
														placeholder={backOffice.nationality}
														options={countries}
														name="nationalities"
														multi={true}
														onChange={(e) => {
															handleChenageinArray(
																"nationalities",
																e,
																ind,
																index
															);
														}}
														value={
															configDataItem?.nationalities?.length > 0 &&
															configDataItem?.nationalities[0]?.country_id
																? configDataItem?.nationalities?.map((item) => {
																		return {
																			value: item.country_id,
																			label: item.name,
																		};
																  })
																: configDataItem?.nationalities?.map((item) => {
																		return {
																			value: item?.value,
																			label: item?.label,
																		};
																  })
														}
													/> */}
												</div>
												<div className="col-md-2 visa-transTypeLabel">
													<SelectField
														label={backOffice.transportation_type}
														placeholder={backOffice.transportation_type}
														options={transportationType}
														id="basic-countries"
														name="transportation_type"
														onChange={(e) => {
															handleChenageinArray(
																"transportation_type",
																e,
																ind,
																index,
																"transportation_type",
																"name"
															);
														}}
														value={
															configDataItem?.transportation_type &&
															typeof configDataItem?.transportation_type ==
																"string"
																? {
																		value: configDataItem?.transportation_type,
																		label: configDataItem?.transportation_type,
																  }
																: configDataItem?.transportation_type
														}
													/>
													{console.log(!configDataItem?.transportation_type)}
												</div>
												<div className="col-md-2">
													<TextField
														type="text"
														label={backOffice.price}
														placeholder={backOffice.price}
														name="price"
														onChange={(e) => {
															handleChenageinArray(
																"price",
																e.target.value,
																ind,
																index
															);
														}}
														value={configDataItem?.price}
													/>
												</div>
												<div className="col-md-2">
													<SelectField
														label={backOffice.currency}
														placeholder={backOffice.currency}
														options={currencies}
														id="basic-countries"
														name="currency"
														onChange={(e) => {
															handleChenageinArray(
																"currency",
																e,
																ind,
																index,
																"id",
																"currency_code"
															);
														}}
														value={
															configDataItem?.currency &&
															!configDataItem?.currency.hasOwnProperty("label")
																? {
																		value: configDataItem?.currency,
																		label: configDataItem?.currency,
																  }
																: configDataItem.currency
														}
													/>
												</div>
												{/*******Start New Fields *********** */}

												<div className="col-md-2  dataPicker-configRow">
													<DatePickerField
														hasLabel={true}
														label={"Start Date"}
														placeholder={"Start Date"}
														date={
															configDataItem?.start_date
																? moment(configDataItem?.start_date)
																: configDataItem?.start_date
														}
														name="start_date"
														onChangeDate={(e) => {
															handleChenageinArray("start_date", e, ind, index);
														}}
														// isOutsideRange={(day) =>
														// 	isInclusivelyBeforeDay(day, moment())
														// }
														isOutsideRange={(day) =>
															day.isBefore(moment().add(-1, "days"))
														}
													/>
												</div>
												<div className="col-md-2 dataPicker-configRow">
													<DatePickerField
														hasLabel={true}
														label={"End Date"}
														placeholder={"End Date"}
														date={
															configDataItem?.end_date
																? moment(configDataItem?.end_date)
																: configDataItem?.end_date
														}
														name="end_date"
														onChangeDate={(e) => {
															handleChenageinArray("end_date", e, ind, index);
														}}
														isOutsideRange={(day) =>
															isInclusivelyBeforeDay(day, moment())
														}
														value
													/>
												</div>
												<div className="col-md-2">
													<TextField
														type="text"
														label={"Expire Days"}
														placeholder={"Expire Days"}
														name="expiration_days"
														onChange={(e) => {
															handleChenageinArray(
																"expiration_days",
																e.target.value,
																ind,
																index
															);
														}}
														value={configDataItem?.expiration_days}
													/>
												</div>
												{/*******End New Fields *********** */}
												{/**col-md-2  end=>center */}
												<div className="col-md-6 d-flex justify-content-end align-items-center mt-3">
													<button
														className="btn copyIcon-configRow"
														onClick={() => handleCopy(index, ind)}
													>
														<i class="fas fa-copy"></i> Duplicate
													</button>
													<a
														className="btn-link text-danger mx-1"
														onClick={() => {
															deleteVise(ind, index);
														}}
														href={() => false}
													>
														<i class="fas fa-trash"></i> {backOffice.Remove}
													</a>
												</div>
											</div>
										</div>
									</div>
								))}
								{inputsStateData.residences?.length == 0 ? (
									""
								) : (
									<button
										className="btn-link text-success btn-add-input"
										onClick={() => addEdit(ind)}
									>
										<i class="fas fa-plus-circle text-success"></i>
										{backOffice.add}
									</button>
								)}
							</div>
						);
				  })
				: ""}
			{alertToggle ? (
				<WarningModal isOpen={alertToggle} toggle={setAlertToggle} />
			) : (
				""
			)}
		</>
	);
}
