import { useEffect, useState } from "react";
import {
	fetchCountries,
	fetchMealPlans,
	fetchRoomTypes,
	fetchRoomViews,
} from "services/lookups";
import { fetchOfflineSupplier } from "services/sbsReservations";
import { formatArray } from "./helper";

const locale = localStorage.getItem("currentLocale") || "en";
const formatCountry = (array) =>
	array.map((t) => ({
		value: t.country_code,
		label: t.names[locale],
	}));

export const useDropdownDate = ({ model }) => {
	const { name } = model;
	const [dropdownData, setdropdownData] = useState({
		roomTypes: [],
		roomViews: [],
		suppliers: [],
		mealPlans: [],
		countries: [],
		hotels: [],
	});

	useEffect(() => {
		const fetchRoomsLookups = async () => {
			const [types, views] = await Promise.all([
				fetchRoomTypes(locale),
				fetchRoomViews(locale),
			]);

			setdropdownData((current) => ({
				...current,
				roomTypes: formatArray(types.data.data),
				roomViews: formatArray(views.data.data),
			}));
		};

		const fetchSuppliers = async () => {
			const [suppliers, mealPlans, countries] = await Promise.all([
				fetchOfflineSupplier(),
				fetchMealPlans(),
				fetchCountries(),
			]);
			setdropdownData((current) => ({
				...current,
				suppliers: formatArray(suppliers.data.data),
				mealPlans: formatArray(mealPlans.data.data),
				countries: formatCountry(countries.data),
			}));
		};

		name === "rooms" && fetchRoomsLookups();

		if (name === "info") {
			fetchSuppliers();
		}
	}, []);

	return dropdownData;
};
