import { useGlobalDispatch } from 'context/global';
import { useEffect } from "react";
import { fetchCountries } from 'services/lookups';
import { fetchCurrencies } from 'services/visa';

export default function useFetchLookups(isAuth) {
  const dispatch = useGlobalDispatch();

  useEffect(() => {
    if (isAuth) {
      fetchCountries().then(res => {
        dispatch({ type: "getCountries", payload: res.data })
      })
      fetchCurrencies().then(res => {
        dispatch({ type: "getCurrencies", payload: res.data.data })
      })
    }
  }, [isAuth]);
}
