import axios from "axios";
import { cleanEmpty } from "services/general";
const backOfficeApi = process.env.REACT_APP_API_URL + "/api";

export const listB2cUsers = async (params) => {
	return await axios
		.get(`${backOfficeApi}/b2c/user-management/users`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const listB2cReservations = async (params) => {
	return await axios
		.get(`${backOfficeApi}/umrah-planner/reservations`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const b2cReservationsView = async (id) => {
	return await axios
		.get(`${backOfficeApi}/umrah-planner/reservations/view/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const userReservations = async (id, params) => {
	return await axios
		.get(`${backOfficeApi}/umrah-planner/users/${id}/reservations`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const changeUserPassword = async (id, params) => {
	return await axios
		.put(`${backOfficeApi}/b2c/user-management/users/${id}/change-password`, {
			...cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateUser = async (id, params) => {
	return await axios
		.put(`${backOfficeApi}/b2c/user-management/users/${id}`, {
			...cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const b2cUserView = async (id) => {
	return await axios
		.get(`${backOfficeApi}/b2c/user-management/users/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteB2cUserView = async (id) => {
	return await axios
		.delete(`${backOfficeApi}/b2c/user-management/users/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const allTransaction = async (params) => {
	return await axios
		.get(`${backOfficeApi}/b2c/transactions`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const getUserActivityLogs = async (id, filter) => {
	return await axios
		.get(`${backOfficeApi}/b2c/user-management/users/${id}/activity-logs`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
