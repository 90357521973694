import axios from "axios";
import { cleanEmpty } from "services/general";
const seasonsURL = process.env.REACT_APP_API_URL + "/api/commission-management/season";


export const listSeasons = async (params, page = 1) => {
  
  return await axios
    .get(`${seasonsURL}?page=${page}`, { params })
    .then((res) => res)
    .catch((err) => err.response);
};





export const viewSeason = async (id) => {
  return await axios
    .get(`${seasonsURL}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

// add and edit
export const add_Season = async (data) => {
  return await axios
    .post(`${seasonsURL}`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const editSeason = async (id, data) => {
  return await axios
    .put(`${seasonsURL}/${id}`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const deleteSeason = async (seasonId) => {
  return await axios
    .delete(`${seasonsURL}/${seasonId}`)
    .then((res) => res)
    .catch((err) => err.response);
};