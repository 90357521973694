import TextField from "components/Form/TextField/TextField";
import SelectField from "components/shared/SelectField";
import validate from "helpers/validate";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Locale from "translations";

export default function EditCompany() {
  const { company_id } = useParams();
  const { translate_companies } = Locale;
  const [meta, setMeta] = useState(null);
  const [errors, setErrors] = useState({});

  const [company, setCompany] = useState({
    company_name: "",
  });
  console.log(errors);
  return (
    <div className="container">
      <div className="row">
        <div className="col-4">
          <TextField
            type="text"
            placeholder={translate_companies.companyNamePlaceholder}
            label={translate_companies.companyName}
            name="company_name"
            value={company.company_name}
            errors={errors?.company_name}
            color={errors?.company_name?.required ? "danger" : ""}
            onChange={(e) => {
              setCompany({ ...company, company_name: e.target.value });
              setErrors({
                ...errors,
                ...validate(e.target, {
                  required: true,
                }),
              });
            }}
          />
        </div>
        <div className="col-4">
          <TextField
            type="text"
            placeholder={translate_companies.companyEmailPlaceholder}
            label={translate_companies.companyEmail}
            value={""}
          />
        </div>
        <div className="col-4 ">
          <TextField
            type="text"
            placeholder={translate_companies.phoneNumberPlaceholder}
            label={translate_companies.phoneNumber}
            value={""}
          />
        </div>
        <div className="col-4 ">
          <TextField
            type="text"
            placeholder={translate_companies.extraPhoneNumberPlaceholder}
            label={translate_companies.extraPhoneNumber}
            value={""}
          />
        </div>
        <div className="col-4 ">
          <TextField
            type="text"
            placeholder={translate_companies.addressPlaceholder}
            label={translate_companies.address}
            value={""}
          />
        </div>

        <div className="col-4 ">
          <SelectField
            label={translate_companies.countries}
            value={""}
            placeholder={translate_companies.countryPlaceholder}
            name="status"
          />
        </div>

        <div className="col-4 ">
          <SelectField
            label={translate_companies.cities}
            value={""}
            placeholder={translate_companies.cityPlaceholder}
            name="status"
          />
        </div>
      </div>
    </div>
  );
}
