import ConfirmModal from "components/Modals/ConfirmModal";
import Pagination from "components/shared/Pagination";
import { useState } from "react";
import { store } from "react-notifications-component";
import { deleteUser, updateStatus } from "services/managementTeam";
import Locale from "translations";
import User from "./User";

export default function ListUsers({ users, setdeleting, meta, goTo }) {
	const { backOffice, teamManagement, confirmation } = Locale;
	const [confirmIsOpen, setConfirmIsOpen] = useState(false);
	const [userdet, setuserdet] = useState({});
	const [dateContent, setDateContent] = useState(false);

	const toggleConfirm = (id = null, status = null) => {
		setConfirmIsOpen(!confirmIsOpen);
		setuserdet({ id, status });
	};
	const removeUser = async (id) => {
		const res = await deleteUser(id);
		if (res.status === 200) {
			store.addNotification({
				title: "Info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			setConfirmIsOpen(!confirmIsOpen);
			setdeleting((prev) => !prev);
		}
	};

	const changeStatus = async (id, status) => {
		const res = await updateStatus(id, { status: status });
		if (res.status === 200) {
			store.addNotification({
				title: "Info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});

			toggleConfirm();
			setdeleting((prev) => !prev);
		}
	};

	const allUsers = users?.map((user) => (
		<User
			user={user}
			key={user.id}
			removeUser={removeUser}
			confirmIsOpen={confirmIsOpen}
			setConfirmIsOpen={setConfirmIsOpen}
			toggleConfirm={toggleConfirm}
		/>
	));

	return (
		<>
			<div className=" table-responsive  our-border border-top-0 px-3">
				<table className="table table-striped">
					<thead>
						<tr>
							<th className="CompanyHead-title">{teamManagement.userName}</th>
							<th className="CompanyHead-title">{backOffice.email}</th>
							<th className="CompanyHead-title">{backOffice.groupName}</th>
							<th className="CompanyHead-title">{backOffice.status}</th>
							<th className="CompanyHead-title">{backOffice.process}</th>
						</tr>
					</thead>
					<tbody>
						{allUsers?.length > 0 ? (
							allUsers
						) : (
							<tr>
								<td colSpan="11">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				<div className="px-2">
					<Pagination info={meta} goTo={goTo} />
				</div>
			</div>

			<ConfirmModal
				IsOpen={confirmIsOpen}
				toggle={toggleConfirm}
				message={
					userdet.status == "active"
						? confirmation.inactiveUser
						: confirmation.activeUser
				}
				onConfirm={() => changeStatus(userdet?.id, userdet?.status)}
				type="comfirm"
			/>
		</>
	);
}
