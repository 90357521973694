import { useGlobalState } from "context/global";
import Locale from "translations";

function Profile({ profileDetails }) {
	const { userDetails, backOffice, commons } = Locale;
	const { allCountries } = useGlobalState();
	const country = allCountries?.find((i) => i.id == profileDetails?.country_id)
		?.names?.en;
	return (
		<div className="mt-3 ">
			<div className="border bg-white">
				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView">{userDetails.fullName}</p>
					<p className="m-0 p-2 col-4 border-booking">{profileDetails?.name}</p>
					<p className="m-0 p-2 col-2 bg-bookingView">{backOffice.country}</p>
					<p className="m-0 p-2 col-4 border-booking">{country}</p>
				</div>

				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView"> {backOffice.email}</p>
					<p className="m-0 p-2 col-4 border-booking">
						{profileDetails?.email}
					</p>
					<p className="m-0 p-2 col-2 bg-bookingView">
						{userDetails.phoneNumber}
					</p>
					<p className="m-0 p-2 col-4 border-booking">
						{profileDetails?.phone}
					</p>
				</div>

				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView">{commons.registerDate}</p>
					<p className="m-0 p-2 col-4 border-booking">
						{profileDetails?.created_at}
					</p>
					<p className="m-0 p-2 col-2 bg-bookingView">{backOffice.status}</p>
					<p
						className={`m-0 p-2 col-4 border-booking ${
							"user?.status" === "active" || true ? "active" : "text-danger"
						}`}
					>
						{profileDetails?.status}
					</p>
				</div>
			</div>
		</div>
	);
}

export default Profile;
