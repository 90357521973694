import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import moment from "moment";
import { useState } from "react";
import { store } from "react-notifications-component";
import { addRefund } from "services/Request";
import Locale from "translations";
import CustomModal from "./Modal";

export default function Payments({ payment }) {
	const { backOffice, booking } = Locale;
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const toggleModel = () => {
		setModalIsOpen(!modalIsOpen);
	};
	const status = [
		{ label: "Total", value: 1 },
		{ label: "Percentage", value: 2 },
	];
	const [refund, setRefund] = useState({
		status: "",
		input: "",
	});

	const handleRefund = async () => {
		const res = await addRefund({
			brn: payment?.brn,
			refund_percentage:
				refund?.status?.label == "Percentage" ? refund?.input : "100",
			type: refund?.status?.label,
		});

		if (res.status == 200) {
			store.addNotification({
				title: "Info!",
				message: res?.data?.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					setRefund({
						status: "",
						input: "",
					});
					setModalIsOpen(false);
				},
			});
		}
	};
	const ToDay = moment().format("yyyy-MM-DD");
	return (
		<div>
			<div className=" table-responsive">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr className="text-nowrap">
							<th>{booking.Payment}</th>
							<th>{booking.Date}</th>
							<th>{booking.Amount}</th>
							<th>{booking.RefNo}</th>
							<th>{booking.Refunds}</th>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td>{payment?.payment_method}</td>
							<td>{payment?.created_at}</td>
							<td>
								{payment?.total_price} {payment?.currency}
							</td>
							<td>{payment?.provider_reference}</td>
							<td>
								{payment.reservation_status != "canceled" &&
								payment.payment_status == "confirmed" &&
								payment?.start_date > ToDay ? (
									<button
										type="button"
										class="btn btn-primary"
										onClick={toggleModel}
									>
										{booking.Refunds}
									</button>
								) : (
									<p className="text-danger">-</p>
								)}
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<CustomModal
				modalIsOpen={modalIsOpen}
				header={booking.Refunds}
				toggle={toggleModel}
				size={"md"}
				centered={true}
			>
				<div>
					<SelectField
						hasLabel={false}
						name="status"
						options={status}
						value={refund?.status}
						onChange={(e) => {
							setRefund({
								...refund,
								status: e,
							});
						}}
					/>
				</div>
				{refund?.status?.label == "Percentage" ? (
					<div className="my-3">
						<TextField
							type="number"
							placeholder={booking.Percentage}
							hasLabel={false}
							value={refund?.input}
							onChange={(e) => {
								setRefund({
									...refund,
									input: e.target.value,
								});
							}}
						/>
					</div>
				) : null}

				<div className="d-flex  justify-content-end mt-2">
					<button
						className="btn companies-btn w-100"
						disabled={refund?.status == ""}
						onClick={handleRefund}
					>
						{booking.Refunds}
					</button>
				</div>
			</CustomModal>
		</div>
	);
}
