import Pagination from "components/shared/Pagination";
import Locale from "translations";
import PropertyTableContent from "./ContractTableContent";
export default function ContractTable({
	ProductList,
	fetchData,
	meta,
	goTo,
	setMeta,
	setProductList,
}) {
	const { backOffice } = Locale;

	const AllProducts = ProductList?.map((productItem, index) => (
		<PropertyTableContent
			fetchData={fetchData}
			productItem={productItem}
			index={index}
			setProductList={setProductList}
			setMeta={setMeta}
			key={index}
		/>
	));

	return (
		<>
			<div className="our-border px-4">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>{backOffice.supplierName}</th>
							<th>{backOffice.hotelName}</th>
							<th>{backOffice.country}</th>
							<th>{backOffice.supplierEmail}</th>
							<th>{backOffice.supplierPhone}</th>
							<th>{backOffice.createdAt}</th>
							<th>{backOffice.status}</th>
							<th>{backOffice.tools}</th>
						</tr>
					</thead>
					<tbody>
						{ProductList?.length > 0 ? (
							AllProducts
						) : (
							<tr>
								<td colSpan="11">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				{ProductList?.length > 0 ? (
					<Pagination info={meta} goTo={goTo} />
				) : null}
			</div>
		</>
	);
}
