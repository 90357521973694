import { Link } from "react-router-dom";

export default function BookItem({
	data,
	checkedColums,
	toggleExtend,
	setSelectItem,
}) {
	return (
		<tr>
			{/* {checkedColums.includes("referral_code") ? <td>{data?.referral_code}</td> : null} */}
			{checkedColums.includes("brn") ? <td>{data.brn}</td> : null}
			{checkedColums.includes("agency") ? <td>{data.agency}</td> : null}
			{checkedColums.includes("hotel_name") ? <td>{data.hotel_name}</td> : null}
			{checkedColums.includes("destination") ? (
				<td>{data.destination}</td>
			) : null}
			{checkedColums.includes("rooms_number") ? (
				<td>{data.rooms_number}</td>
			) : null}
			{checkedColums.includes("created_at") ? <td>{data.created_at}</td> : null}
			{checkedColums.includes("start_date") ? <td>{data.start_date}</td> : null}
			{checkedColums.includes("nights_count") ? (
				<td>{data.nights_count}</td>
			) : null}

			{checkedColums.includes("paid_later") ? (
				<td>{data.paid_later == 0 ? "Disabled" : "Enabled"}</td>
			) : null}
			{checkedColums.includes("reservation_status") ? (
				<td>{data.reservation_status} </td>
			) : null}
			{checkedColums.includes("total_price") ? (
				<td>{data.total_price}</td>
			) : null}
			{checkedColums.includes("total_refund") ? (
				<td>{data.total_refund}</td>
			) : null}
			{checkedColums.includes("total_paid") ? <td>{data.total_paid}</td> : null}

			{checkedColums.includes("pax") ? <td>{data.pax}</td> : null}

			{checkedColums.length >= 1 ? (
				<td className="d-flex">
					{/* <Link to={"booking/view"}>
          <i class="fas fa-chevron-right"></i>
        </Link> */}

					{data?.can_extend_date &&
					data?.reservation_status != "canceled" &&
					data?.reservation_status != "paid" ? (
						<button
							class="btn  btn-primary mx-2"
							onClick={() => {
								setSelectItem({ brn: data.brn });
								toggleExtend();
							}}
						>
							<i class="fas fa-edit"></i>
						</button>
					) : null}
					<Link
						to={{
							pathname: `/booking/view/${data.reservation_num}`,
							state: {
								BookingInfo: data,
							},
						}}
					>
						<i class="fas fa-chevron-right"></i>
					</Link>
				</td>
			) : null}
		</tr>
	);
}
