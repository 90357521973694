import SelectField from "components/shared/SelectField";
import React, { useEffect, useRef, useState } from "react";
import { store } from "react-notifications-component";
import { Collapse } from "reactstrap";
import { fetchCountries } from "services/lookups";
import Locale from "translations";
import AddCustomRate from "./AddCustomRate";
import BulkEdit from "./BullkEdit";
import Cell from "./Cell";
import { addPriceRangeDays } from "services/calendar";
import moment from "moment";
export default function CalendarRoom(props) {
  const { hotelLayout } = Locale;

  const [calendar, setCalendar] = useState({ ratePlan: [] });
  const [modal, setModal] = useState({
    isOpen: false,
    type: "edit",
    title: ""
  });

  const { filters, getViewCalendar } = props;
  //Collapse
  const [isOpen, setIsOpen] = useState(true);
  const toggleButton = () => setIsOpen(!isOpen);
  const [RangeDays, setRangeDays] = useState({ days: [] });

  const toggle = (roomId) => {
    setModal({
      isOpen: !modal.isOpen,
      title: hotelLayout.calendar.bulkEdit,
      id: roomId
    });
  };

  const [customRatemodal, setCustomRatemodal] = useState({
    isOpen: false,
    type: "edit",
    title: ""
  });

  const ratesplans = props.room.standalone_property_rates.map(
    (ratePlan) =>
      (ratePlan = {
        label: ratePlan.name,
        value: ratePlan.id,
        showCountries: true,
        countries: ratePlan?.property_rate_price_countries.map(
          (res) =>
            (res = {
              label: res.country.name["en"],
              value: res.country.id
            })
        )
      })
  );

  const ratetoggle = (ratePlanId) => {
    setCustomRatemodal({
      isOpen: !customRatemodal.isOpen,
      title: hotelLayout.calendar.bulkEdit,
      ratePlanId: ratePlanId
    });
  };

  // cells
  const [selected, setSelected] = React.useState();
  const [started, setStarted] = React.useState("");
  const [handle, setHandle] = React.useState();
  const [handleFirstDay, setHandleFirstDay] = React.useState();

  const handleMouseUp = (e) => {
    if (e.type === "mouseup" && selected && started) {
      setStarted(false);
    }
  };

  const handleMouseDown = (e) => {
    if (e.type === "mousedown" && selected && started) {
      setStarted(false);
    }
  };

  useEffect(() => {
    if (typeof document.onselectstart != "undefined") {
      document.onselectstart = new Function("return false");
    } else {
      document.onmousedown = new Function("return false");
      document.onmouseup = new Function("return true");
    }
  }, []);
  const [lookups, setLookups] = useState({
    countries: []
  });
  const locale = localStorage.getItem("currentLocale") || "en";

  useEffect(() => {
    async function fetchLookups() {
      const res = await fetchCountries();
      const format = res.map((t) => ({
        value: t.id,
        label: t.names[locale],
        code: t.country_code
      }));
      setLookups({
        ...lookups,
        countries: format
      });
    }

    fetchLookups();
  }, []);

  const addCustomRate = (country, retePlanId) => {
    let ratePlan = [...calendar.ratePlan];
    ratePlan.forEach((element) => {
      if (element.value === retePlanId) {
        element.countries = [...element.countries, ...country];
      }
    });
    setCalendar({ ...calendar, ratePlan: ratePlan });
  };

  const ChangePriceRangeDays = async () => {
    setStarted(false);
    let date_from = RangeDays.days[0];
    let date_to = RangeDays.days[RangeDays.days.length - 1];
    let response;
    if (moment(date_to).isAfter(date_from)) {
      response = await addPriceRangeDays(props.id, {
        room_id: props.room.id,
        count: RangeDays.count,
        date_from: date_from,
        date_to: date_to
      });
    } else {
      response = await addPriceRangeDays(props.id, {
        room_id: props.room.id,
        count: RangeDays.count,
        date_from: date_to,
        date_to: date_from
      });
    }

    setRangeDays({ days: [] });

    if (response.message) {
      getViewCalendar({ ...filters, id: props.id });
      store.addNotification({
        title: "Info!",
        message: response.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true
        }
      });
    }
  };

  useEffect(() => {
    if (RangeDays.days.length > 0) {
      const timeoutId = setTimeout(() => ChangePriceRangeDays(), 800);
      return () => clearTimeout(timeoutId);
    }
  }, [RangeDays]);

  const [heightRate, setHeightRate] = useState(0);
  const selectRef = useRef(null);

  useEffect(() => {
    if (selectRef?.current?.offsetHeight !== null) {
      setHeightRate(selectRef.current.offsetHeight);
    }
  }, [selectRef?.current?.offsetHeight, calendar?.ratePlan?.length]);

  const showCountries = (rate) => {
    let ratePlan = [...calendar.ratePlan];
    ratePlan.forEach((element) => {
      if (element.value === rate.value) {
        element.showCountries = !element.showCountries;
      }
    });
    setCalendar({ ...calendar, ratePlan: ratePlan });
  };

  return (
    <>
      <BulkEdit
        ratesPlans={ratesplans}
        modal={modal}
        setModal={setModal}
        toggle={toggle}
        idRoom={props.room.id}
        getViewCalendar={getViewCalendar}
        filtersData={filters}
        countries={lookups.countries}
        size="lg"
      />

      <AddCustomRate
        customRatemodal={customRatemodal}
        setCustomRatemodal={setCustomRatemodal}
        ratetoggle={ratetoggle}
        addCustomRate={addCustomRate}
        countries={lookups.countries}
      />

      <div className="calendar-room">
        <div className="calendar-head">
          <h6>
            {props.room.custom_name !== null
              ? props.room.custom_name
              : props.room.name.name}
          </h6>
          <button
            className="btm btn-primary border-0 py-2 rounded font-12"
            onClick={toggle}
          >
            {hotelLayout.calendar.bulkEdit}
          </button>
        </div>
        <div className="calendar-parent">
          <ul className="calendar-title">
            <li></li>
            <li>{hotelLayout.calendar.roomStatus}</li>
            <li>{hotelLayout.calendar.roomToSell}</li>
            <li>{hotelLayout.calendar.netBooked}</li>
            <li className="calendar-select" ref={selectRef}>
              <SelectField
                label={hotelLayout.calendar.ratePlan}
                value={calendar.ratePlan}
                name="ratePlan"
                id="ratePlan"
                multi={true}
                options={ratesplans}
                onChange={(e) => {
                  setCalendar({ ...calendar, ratePlan: e });
                }}
              />
            </li>
            {calendar.ratePlan && calendar.ratePlan?.length > 0
              ? calendar.ratePlan.map((ratePlan) => (
                  <li className="shadow-sm product-build__product-collpase branch-collapse p-0">
                    <div className="button-collapse bg-white border-0">
                      <div className="p-2 d-flex justify-content-between align-items-center">
                        <div>
                          <p className="font-12" onClick={toggleButton}>
                            {ratePlan.label}
                          </p>
                          <a
                            href={() => false}
                            className="text-primary font-12"
                            onClick={() => ratetoggle(ratePlan.value)}
                          >
                            {hotelLayout.calendar.addCustomPrice}{" "}
                          </a>
                        </div>

                        {ratePlan.countries && ratePlan.countries.length > 0 ? (
                          <i
                            onClick={() => showCountries(ratePlan)}
                            className={`fas fa-fw pointer text-gray-300  ${
                              ratePlan.showCountries !== true
                                ? "fa-chevron-right "
                                : "fa-chevron-down"
                            } `}
                          ></i>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <Collapse isOpen={ratePlan.showCountries}>
                      {ratePlan.countries && ratePlan.countries.length > 0
                        ? ratePlan.countries.map((res) => (
                            <li className="child-country">- {res.label}</li>
                          ))
                        : ""}
                    </Collapse>
                  </li>
                ))
              : ""}
          </ul>

          <div
            onMouseUp={handleMouseUp}
            onMouseDown={handleMouseDown}
            className="day-parent"
          >
            {props.days.map((cell, index) => {
              return (
                <Cell
                  heightRate={heightRate}
                  ratePlans={calendar.ratePlan}
                  day={cell}
                  handle={handle}
                  setHandle={setHandle}
                  selected={selected}
                  setSelected={setSelected}
                  started={started}
                  setStarted={setStarted}
                  setHandleFirstDay={setHandleFirstDay}
                  handleFirstDay={handleFirstDay}
                  setRangeDays={setRangeDays}
                  RangeDays={RangeDays}
                  id={index}
                  hotelId={props.id}
                  key={index + 100}
                  room={props.room}
                  room_availability_days={props.room.room_availability_days}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
