import axios from "axios";
import { cleanEmpty } from "services/general";
const hotelURL = process.env.REACT_APP_API_URL + "/api/commission-management/hotel";


export const listHotels = async (params, page = 1) => {
  return await axios
    .get(`${hotelURL}?page=${page}`, { params })
    .then((res) => res)
    .catch((err) => err.response);
};

export const viewHotel = async (id) => {
  return await axios
    .get(`${hotelURL}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

// list hotelURL Reservations
// export const listHotelReservations = async (id, params, page = 1) => {
//   return await axios
//     .get(`${hotelURL}/${id}/reservation?page=${page}`, { params })
//     .then((res) => res)
//     .catch((err) => err.response);
// };

// add and edit
export const addHotel = async (data) => {
  return await axios
    .post(`${hotelURL}`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const editHotel = async (id, data) => {
  return await axios
    .put(`${hotelURL}/${id}`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const deleteHotelCommission = async (hotelId, commissionId) => {
  return await axios
    .delete(`${hotelURL}/${hotelId}/commission/${commissionId}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const editHotelCommission = async (hotelId, commissionId, data) => {
  return await axios
    .put(`${hotelURL}/${hotelId}/commission/${commissionId}`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const Add_Commission = async (hotelId, data) => {
  return await axios
    .post(`${hotelURL}/${hotelId}/commission`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};


export const AddOffer = async (hotelId, data) => {
  return await axios
    .post(`${hotelURL}/${hotelId}/offer`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const EditOffer = async (hotelId,offerId, data) => {
  return await axios
    .put(`${hotelURL}/${hotelId}/offer/${offerId}`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const deleteOffer = async (hotelId,offerId) => {
  return await axios
    .delete(`${hotelURL}/${hotelId}/offer/${offerId}`)
    .then((res) => res)
    .catch((err) => err.response);
};

// list Hotrl Reservations
export const listHotelReservations = async (id, filter) => {
  return await axios
    .get(`${hotelURL}/${id}/reservation`, {
			params: cleanEmpty(filter),
		})
    .then((res) => res)
    .catch((err) => err.response);
};