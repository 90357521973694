import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { userReservations } from "services/b2c";
import ReservationsList from "../../ReservationsList";
export default function UserReservations() {
	const [reservations, setReservations] = useState([]);
	const [meta, setMeta] = useState(null);
	const { id } = useParams();
	const [filters, setFilter] = useState({
		search: "",
		trip_type: null,
		visa_status: null,
		booking_date: null,
		departure_date: null,
	});
	useEffect(() => {
		const fetchList = async () => {
			const res = await userReservations(id, {
				...filters,
				trip_type: filters.trip_type?.value,
				visa_status: filters.visa_status?.value,
				booking_date: filters.booking_date
					? moment(filters.booking_date).format("YYYY-MM-DD")
					: null,
				departure_date: filters.departure_date
					? moment(filters.departure_date).format("YYYY-MM-DD")
					: null,
			});
			if (res?.status === 200) {
				setReservations(res?.data?.data);
				setMeta(res?.data?.meta);
			}
		};
		fetchList();
	}, [filters]);

	return (
		<ReservationsList
			filters={filters}
			meta={meta}
			reservations={reservations}
			setFilter={setFilter}
		/>
	);
}
