import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import validate from "helpers/validate";
import { Fragment } from "react";
import { RemoveIcon } from "../Main/DetailsIcons";
import { useDropdownDate } from "../Main/dropdownData";
import { roomsTotals } from "../Main/helper";

function Rooms(props) {
	const { details, roomsHandler, errors, setErrors, edit } = props;
	const groupStatusId = details?.status?.id;
	const pendingStatus = groupStatusId === 2;
	const tentativeStatus = groupStatusId === 3 || groupStatusId === 4;
	const reservationStatusCondition = pendingStatus || tentativeStatus;
	const rooms = details["rooms"];
	const { roomTypes, roomViews } = useDropdownDate({
		model: { name: "rooms" },
	});

	const handleRoomsState = ({ name, value, index, multiValue }) => {
		let updatedRoomState = [...rooms];
		let cuurentRoomState = {
			...updatedRoomState[index],

			...(multiValue ? value : { [name]: value }),
		};
		updatedRoomState[index] = cuurentRoomState;

		roomsHandler({ name: "rooms", value: updatedRoomState });
	};

	const handleRoomsError = ({ validateValue, index }) => {
		let updatedRoomsErrors = [...errors.rooms];
		let cuurentRoomError = {
			...updatedRoomsErrors[index],
			...validate(validateValue[0], {
				...validateValue[1],
			}),
		};
		updatedRoomsErrors[index] = cuurentRoomError;

		const updateErrors = { ...errors, rooms: updatedRoomsErrors };

		setErrors(updateErrors);
	};

	const removeRoom = (index) => {
		let updatedRoomState = [...rooms];
		updatedRoomState.splice(index, 1);
		roomsHandler({ name: "rooms", value: updatedRoomState });
	};

	const addNewRoom = () => {
		let updatedRoomState = [...rooms];

		const newRoom = {
			id: null,
			key: window.self.crypto.randomUUID(),
			room_count: 0,
			room_view: "",
			room_type: "",
			number_of_adult: 0,
			children: 0,
			net_rate: 0,
			selling_rate: 0,
			total_selling_price: 0,
		};
		updatedRoomState.push(newRoom);
		roomsHandler({ name: "rooms", value: updatedRoomState });
	};

	const { totalRooms, totalSellingPrice } = roomsTotals(details);

	return (
		<div className="reserv_details_rooms mt-3">
			<div className="reserv_details_rooms_header">
				<p>Rooms</p>
				{groupStatusId != 1 && groupStatusId && (
					<Fragment>
						<label>Total Rooms: {`${totalRooms}`}</label>
						<label>
							Total Selling Price:{" "}
							{`${totalSellingPrice} ${details?.["currency"]}`}
						</label>
					</Fragment>
				)}
			</div>
			<div className="reserv_details_rooms_form">
				{rooms.map((room, index) => (
					<Room
						key={room.id ?? room?.key}
						errors={errors.rooms}
						index={index}
						handleRoomsState={handleRoomsState}
						handleRoomsError={handleRoomsError}
						roomState={rooms}
						removeRoom={removeRoom}
						roomTypes={roomTypes}
						roomViews={roomViews}
						update={reservationStatusCondition || groupStatusId == 5}
						edit={edit}
						details={details}
					/>
				))}
				{edit && (
					<a
						className="btn btn-md d-inline-block add_new_room"
						href={() => false}
						onClick={addNewRoom}
					>
						<i className="fas fa-plus-circle"></i>
						<span>Add New Room</span>
					</a>
				)}
			</div>
		</div>
	);
}

export default Rooms;

const Room = (props) => {
	const {
		errors,
		handleRoomsError,
		index,
		handleRoomsState,
		roomState,
		removeRoom,
		roomTypes,
		roomViews,
		update,
		edit,
		details,
	} = props;
	const groupStatusId = details?.status?.id;
	const { nights } = roomsTotals(details);

	const pendingStatus = groupStatusId === 2;

	const indexedError = errors?.[index];
	const indexedRoomState = roomState?.[index];
	const roomsCount = roomState.length;

	return (
		<div className="row m-0 modal-body">
			<div className={`col-md-1  ${edit ? "" : "read-only"}`}>
				<TextField
					type="text"
					label={"count"}
					placeholder={"count"}
					id={"room-count"}
					value={indexedRoomState?.room_count}
					name="room_count"
					onChange={(e) => {
						const value = {
							room_count: +e.target.value.replace(/[^0-9.]/, ""),
							total_selling_price:
								+e.target.value.replace(/[^0-9.]/, "") *
								indexedRoomState["selling_rate"] *
								nights,
						};
						handleRoomsState({
							multiValue: true,
							value,
							index,
						});
						handleRoomsError({
							index,
							validateValue: [
								e.target,
								{
									required: true,
									minNumber: 1,
								},
							],
						});
					}}
					color={
						indexedError?.room_count?.required ||
						indexedError?.room_count?.minNumber
							? "danger"
							: ""
					}
					min={1}
					errors={indexedError?.room_count}
					readOnly={!edit}
				/>
			</div>
			<div className={`col-md-1  ${edit ? "" : "read-only"}`}>
				<SelectField
					label={"type"}
					placeholder={indexedRoomState?.room_type?.label}
					value={indexedRoomState?.room_type?.label}
					name="room_type"
					id="room-type"
					// multi={true}
					options={roomTypes}
					disabled={!edit}
					onChange={(e) => {
						handleRoomsState({ name: "room_type", value: e, index });
						handleRoomsError({
							index,
							validateValue: [
								{ name: "room_type", value: e.value },
								{
									required: true,
								},
							],
						});
					}}
					onBlur={() =>
						handleRoomsError({
							index,
							validateValue: [
								{ name: "room_type", value: indexedRoomState?.room_type },
								{
									required: true,
								},
							],
						})
					}
					color={indexedError?.room_type?.required ? "danger" : ""}
					errors={indexedError?.room_type}
				/>
			</div>
			<div className={`col-md-2  ${edit ? "" : "read-only"}`}>
				<SelectField
					label={"view"}
					placeholder={"view"}
					value={indexedRoomState?.room_view?.label}
					name="room_view"
					id="room-view"
					options={roomViews}
					disabled={!edit}
					onChange={(e) => {
						handleRoomsState({ name: "room_view", value: e, index });
						handleRoomsError({
							index,
							validateValue: [
								{ name: "room_view", value: e.value },
								{
									required: true,
								},
							],
						});
					}}
					onBlur={() =>
						handleRoomsError({
							index,
							validateValue: [
								{ name: "room_view", value: indexedRoomState?.room_view },
								{
									required: true,
								},
							],
						})
					}
					color={indexedError?.room_view?.required ? "danger" : ""}
					errors={indexedError?.room_view}
				/>
			</div>
			<div className={`col-md-1  ${edit ? "" : "read-only"}`}>
				<TextField
					type="text"
					label={"Adult"}
					placeholder={"Adult"}
					id={"room-adult"}
					name="number_of_adult"
					value={indexedRoomState?.number_of_adult}
					onChange={(e) => {
						handleRoomsState({
							name: "number_of_adult",
							value: +e.target.value.replace(/[^0-9.]/, ""),
							index,
						});
						handleRoomsError({
							index,
							validateValue: [
								e.target,
								{
									required: true,
									minNumber: 1,
								},
							],
						});
					}}
					color={
						indexedError?.number_of_adult?.required ||
						indexedError?.number_of_adult?.minNumber
							? "danger"
							: ""
					}
					min={1}
					errors={indexedError?.number_of_adult}
					readOnly={!edit}
				/>
			</div>
			<div className={`col-md-1  ${edit ? "" : "read-only"}`}>
				<TextField
					type="text"
					label={"children"}
					placeholder={"children"}
					id={"room-child"}
					name="children"
					value={indexedRoomState?.children}
					onChange={(e) => {
						handleRoomsState({
							name: "children",
							value: +e.target.value.replace(/[^0-9.]/, ""),
							index,
						});
						handleRoomsError({
							index,
							validateValue: [
								e.target,
								{
									required: true,
								},
							],
						});
					}}
					color={
						indexedError?.children?.required ||
						indexedError?.children?.minNumber
							? "danger"
							: ""
					}
					min={0}
					errors={indexedError?.children}
					readOnly={!edit}
				/>
			</div>
			{update && (
				<Fragment>
					<div className={`col-md-2 `}>
						<TextField
							type="text"
							label={"Net Rate"}
							placeholder={"Net Rate"}
							id={"room-net_rate"}
							name="net_rate"
							value={indexedRoomState?.net_rate}
							extraTextPosition="append"
							extraText={details?.["currency"]}
							onChange={(e) => {
								const value = {
									net_rate: +e.target.value.replace(/[^0-9.]/, ""),
								};
								handleRoomsState({
									multiValue: true,
									value,
									index,
								});

								handleRoomsError({
									index,
									validateValue: [
										e.target,
										{
											required: true,
											minNumber: 0,
										},
									],
								});
							}}
							color={
								indexedError?.net_rate?.required ||
								indexedError?.net_rate?.minNumber
									? "danger"
									: ""
							}
							min={0}
							errors={indexedError?.net_rate}
							readOnly={!edit && !pendingStatus}
						/>
					</div>
					<div className="col-md-2">
						<TextField
							type="text"
							label={"Selling Rate"}
							placeholder={"Selling Rate"}
							id={"room-selling_rate"}
							name="selling_rate"
							value={indexedRoomState?.selling_rate}
							extraTextPosition="append"
							extraText={details?.["currency"]}
							onChange={(e) => {
								const value = {
									selling_rate: +e.target.value.replace(/[^0-9.]/, ""),
									total_selling_price:
										+e.target.value.replace(/[^0-9.]/, "") *
										indexedRoomState["room_count"] *
										nights,
								};
								handleRoomsState({
									multiValue: true,
									value,
									index,
								});
								handleRoomsError({
									index,
									validateValue: [
										e.target,
										{
											required: true,
											minNumber: 0,
										},
									],
								});
							}}
							color={
								indexedError?.selling_rate?.required ||
								indexedError?.selling_rate?.minNumber
									? "danger"
									: ""
							}
							min={0}
							errors={indexedError?.selling_rate}
							readOnly={!edit && !pendingStatus}
						/>
					</div>
					<div className="col-md-2">
						<TextField
							type="text"
							label={"Total Selling Price"}
							placeholder={"Total Selling Price"}
							id={"room-total_selling_price"}
							name="total_selling_price"
							disabled
							value={indexedRoomState?.total_selling_price}
							onChange={(e) => {
								handleRoomsState({
									name: "total_selling_price",
									value: +e.target.value.replace(/[^0-9.]/, ""),
									index,
								});
								handleRoomsError({
									index,
									validateValue: [
										e.target,
										{
											required: true,
											minNumber: 0,
										},
									],
								});
							}}
							color={
								indexedError?.total_selling_price?.required ||
								indexedError?.total_selling_price?.minNumber
									? "danger"
									: ""
							}
							min={0}
							errors={indexedError?.total_selling_price}
							readOnly={!edit && !pendingStatus}
						/>

						{roomsCount > 1 && update && edit && (
							<button
								type="button"
								onClick={() => removeRoom(index)}
								className="remove_room_icon"
							>
								<RemoveIcon />
							</button>
						)}
					</div>
				</Fragment>
			)}
		</div>
	);
};
