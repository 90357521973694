import axios from "axios";
import { cleanEmpty } from "services/general";
const suppliersURL = process.env.REACT_APP_API_URL + "/api/back-office";


export const listSuppliers = async (filter) => {
	return await axios
		.get(`${suppliersURL}/suppliers`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchSupplierReservations = async (id, filter) => {
	return await axios
		.get(`${suppliersURL}/suppliers/${id}/reservation`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const changeOfferStatus = async (id, offer) => {
	return await axios
		.put(`${suppliersURL}/suppliers/` + id, { is_offer: offer })
		.then((res) => res)
		.catch((err) => err.response);
};
