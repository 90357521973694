import DateFnsUtils from "@date-io/date-fns";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider
} from "@material-ui/pickers";
import resetIcon from "assets/images/wallet/resetIcon.png";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from 'context/global';
import "date-fns";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { cleanEmpty } from "services/general";
import { listHotels } from "services/hotels";
import Locale from "translations";

export default function HotelCommissionsFilter({
	setHotelList,
	setMeta,
	pages,
}) {
	const { backOffice } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";
	const { allCountries } = useGlobalState();
	const firstRender = useRef(false);

	const [filterState, setFilterState] = useState({
		hotelName: null,
		date: null,
		country: "",
		status: "",
	});
	const [countries, setCountries] = useState([]);
	const [cities, setCities] = useState([]);

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setFilterState({ ...filterState, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			if (name === "country") {
				setCities([]);
				setFilterState({
					...filterState,
					city: "",
					[name]: value,
				});
			} else setFilterState({ ...filterState, [name]: value });
		}
	};

	//Fetch Countries
	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);

	useEffect(() => {
		if (firstRender.current) {
			async function fetchData() {
				const data = {
					name: filterState.hotelName,
					contract_start_date: filterState.date
						? moment(filterState.date).format("YYYY-MM-DD")
						: null,
					country_id: filterState.country?.value,
					is_active: filterState.status?.value,
				};
				const res = await listHotels(cleanEmpty(data), pages, pages);
				setHotelList(res?.data.hotels);
				setMeta(res?.data.meta);
			}
			fetchData();
		}

		firstRender.current = true;
	}, [
		filterState.hotelName,
		filterState.date,
		filterState.country_id,
		filterState.status,
	]);

	const Status = [
		{
			value: 0,
			label: "Inactive",
		},
		{
			value: 1,
			label: "Active",
		},
	];

	const resetFilters = () => {
		setFilterState({
			hotelName: null,
			date: null,
			country: "",
			status: "",
		});
	};

	const [selectedDate, setSelectedDate] = useState(new Date());

	const handleDateChange = (date) => {
		setSelectedDate(date);
		setFilterState({
			...filterState,
			date: date,
		});
	};

	return (
		<>
			<>
				<div className="main-filter  py-2 my-2">
					<div className="row align-items-center w-100 no-gutter m-0">
						<div className="col-md-2">
							<div className="main-label">
								<TextField
									type="text"
									placeholder={backOffice.hotelName}
									label={backOffice.hotelName}
									/* 									hasLabel={false}
									 */ value={filterState.hotelName}
									onChange={(e) => {
										setFilterState({
											...filterState,
											hotelName: e.target.value,
										});
									}}
								/>
							</div>
						</div>

						<div className="col-md-3">
							<span className="d-block mt-1 mb-1">{backOffice.date}</span>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									className="DatePicker"
									variant="outlined"
									inputVariant="outlined"
									id="date-picker-dialog"
									placeholder={backOffice.contractStartDate}
									format="dd/MM/yyyy"
									value={selectedDate}
									onChange={handleDateChange}
								/>
							</MuiPickersUtilsProvider>
							{/* <DatePickerField
                hasLabel={false}
                date={filterState.date}
                placeholder={backOffice.contractStartDate}
                onChangeDate={(filterDate) => {
                  setFilterState({
                    ...filterState,
                    date: filterDate,
                  });
                }}
                isOutsideRange={(day) => {
                  return false;
                }}
              /> */}
						</div>

						<div className="col-md-2">
							<SelectField
								/* 								hasLabel={false}
								 */ value={filterState.country}
								placeholder={backOffice.country}
								label={backOffice.country}
								name="country"
								options={countries}
								onChange={(e) => {
									handleChange({ name: "country", value: e });
								}}
							/>
						</div>

						<div className="col-md-2">
							<SelectField
								/* 								hasLabel={false}
								 */ value={filterState.status}
								placeholder={backOffice.status}
								label={backOffice.status}
								name="status"
								options={Status}
								onChange={(e) => {
									setFilterState({
										...filterState,
										status: e,
									});
								}}
							/>
						</div>

						<div className="col-md">
							<div
								onClick={resetFilters}
								className="d-flex align-items-center text-secondary pointer mt-4 reset-btn"
							>
								<img src={resetIcon} alt="" srcset="" />
								{/* 								<i className="fas fa-retweet position-static px-1 text-secondary text-caption"></i>
								 */}{" "}
								<p className="text-caption">{backOffice.resetFilter}</p>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	);
}
