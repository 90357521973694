import { useGlobalState } from "context/global";
import BackOfficeLayout from "modules/backOffice/Components/backOfficeLayout";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { BackOfficeProvider } from "../context/backOffice";
import { ContractProvider } from "../context/contractRequest";
import { PlatformProvider } from "../context/platformRequest";
import { PropertyProvider } from "context/property";
import { HotelProvider } from "context/hotelRequest";
// React Component
export default function HotelProviderRouter({ component: Component, ...props }) {
  const { isAuth } = useGlobalState();

  if (isAuth) {
    return (
      <Route
        {...props}
        render={(matchProps) => (
          <PropertyProvider>
          <BackOfficeProvider>
           
                <HotelProvider> 
            <BackOfficeLayout>
              <Component {...matchProps} />
            </BackOfficeLayout>
           </HotelProvider>
        </BackOfficeProvider>
        </PropertyProvider>
        )}
      />
    );
  } else {
    return <Redirect to="/auth/login" />;
  }
}
