import resetIcon from "assets/images/wallet/resetIcon.png";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useEffect, useState } from "react";
import { listCompanies } from "services/companies";
import Locale from "translations";
export default function CompaniesFilter({
	onReset,
	companies,
	setCompanies,
	pages,
}) {
	const { backOffice } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";

	const [filterState, setFilterState] = useState({
		name: "",
		status: "",
	});

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setFilterState({ ...filterState, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			setFilterState({ ...filterState, [name]: value });
		}
	};

	useEffect(() => {
		async function fetchData() {
			const data = {
				name: filterState.name,
				status: filterState.status?.value,
			};

			const res = await listCompanies(data, pages);
			setCompanies(res?.data.data);
		}
		fetchData();
	}, [filterState.name, filterState.status]);

	const Status = [
		{
			value: 1,
			label: "ACTIVE",
		},
		{
			value: 0,
			label: "DEACTIVE",
		},
	];

	const resetFilters = () => {
		setFilterState({
			name: "",
			status: "",
		});
	};

	return (
		<>
			<div className="main-filter  py-2 my-2">
				<div className="row align-items-center w-100 no-gutter m-0">
					<div className="col-md-2">
						<div className="main-label">
							<TextField
								type="text"
								placeholder={backOffice.search}
								hasLabel={false}
								value={filterState.name}
								onChange={(e) => {
									setFilterState({
										...filterState,
										name: e.target.value,
									});
								}}
							/>
							<i className="fas fa-search fa-fw"></i>
						</div>
					</div>

					<div className="col-md-2">
						<SelectField
							hasLabel={false}
							value={filterState.status}
							placeholder={backOffice.status}
							name="status"
							options={Status}
							onChange={(e) => {
								setFilterState({
									...filterState,
									status: e,
								});
							}}
						/>
					</div>

					<div className="col-md" onClick={resetFilters}>
						<div
							onClick={onReset}
							className="d-flex align-items-center text-secondary pointer mt-4 reset-btn"
						>
							<img src={resetIcon} alt="" srcset="" />
							<p className="text-caption">{backOffice.resetFilter}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
