import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
function WarningModal({ isOpen, toggle }) {
	return (
		<Modal isOpen={isOpen} size={"md"}>
			<ModalHeader className="font-weight-bold" toggle={() => toggle(false)}>
				Warning Info!
			</ModalHeader>
			<ModalBody className="py-0">
				<div className="mx-0">
					<p
						className="my-3 text-center font-weight-bolder"
						style={{ fontSize: "20px" }}
					>
						This country already exists !
					</p>
				</div>
			</ModalBody>
			<ModalFooter>
				<button className="btn btn-primary" onClick={() => toggle(false)}>
					OK
				</button>
			</ModalFooter>
		</Modal>
	);
}

export default WarningModal;
