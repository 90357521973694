import moment from "moment";
import { store } from "react-notifications-component";
import { Link } from "react-router-dom";
import { verifyContract } from "services/property";
import Locale from "translations";

export default function PlatformCommissionsTableContent({
	fetchData,
	productItem,
	index,
	removeItem,
	country,
}) {
	const { backOffice } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";

	const verify = async (id, value) => {
		const res = await verifyContract(id, { value: value });
		if (res.status === 200) {
			store.addNotification({
				title: "Done!",
				message: res.data,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			fetchData();
		}
	};

	return (
		<>
			<tr key={productItem.id}>
				<td scope="row">{productItem["name_" + locale]}</td>
				<td>{productItem?.country["name_" + locale]}</td>
				<td>
					{moment(productItem?.contract_start_date).format("DD-MM-YYYY")}{" "}
				</td>
				<td>{moment(productItem?.contract_end_date).format("DD-MM-YYYY")} </td>
				<td>{productItem.mobile} </td>
				<td>
					<p
						className={
							productItem.is_active == 1 ? "text-success" : "text-danger"
						}
					>
						{productItem.is_active == 1
							? backOffice.active
							: backOffice.inActive}
					</p>
				</td>
				<td>
					<Link
						to={{
							pathname: `/platform-details/${productItem.id}`,
						}}
						className="d-inline-block action--btn"
					>
						<i className="fas fa-eye fa-lg"></i> {backOffice.view}
					</Link>
					<Link
						to={{
							pathname: `/edit-platform/${productItem.id}`,
						}}
						className="d-inline-block action--btn"
					>
						<i className="fas fa-edit fa-lg"></i> {backOffice.edit}
					</Link>
					{/* <Link
            to={{
              pathname: `/platform-details/${productItem.id}`,
            }}
            className="d-inline-block action--btn"
          >
            <i className="fas fa-money-check-alt fa-lg"></i> {backOffice.rounds}
          </Link> */}
					{/* <a
            href={() => false}
            className="d-inline-block text-danger"
            onClick={() => removeItem(productItem.id)}
          >
            <i className="fas fa-trash fa-lg"></i> {backOffice.delete}
          </a> */}
				</td>
			</tr>
		</>
	);
}
