import { useState } from "react";
import { Collapse } from "reactstrap";
import Locale from "translations";
import BranchUser from "./BranchUser";

export default function Branch({ branch, toggleEdit, removeBranch }) {
	const [isOpen, setIsOpen] = useState(false);
	const { backOffice, teamManagement, Companies } = Locale;
	const toggle = () => setIsOpen(!isOpen);
	let currentLocale = localStorage.getItem("currentLocale") ?? "en";

	const allUser = branch?.groups?.map((group) => {
		return <BranchUser group={group} />;
	});

	return (
		<div className="mt-3">
			<div className="row  d-flex justify-content-between align-items-center  m-0 border-0   collapse-border">
				<div className="d-flex  flex-row align-items-center ">
					<button className="collapse-btn  px-3 py-2" onClick={toggle}>
						{isOpen ? (
							<i class="fas fa-minus  text-white"></i>
						) : (
							<i class="fas fa-plus text-white"></i>
						)}
					</button>
					<p className="col-3 gName-f">{branch.name}</p>
				</div>
				<p className="col-3 d-flex align-items-center our-fs-14">
					<span className="member-num  mx-1">{branch.employee_count}</span>
					{Companies.employees}
				</p>
				<p className="col-1 d-flex align-items-center">
					<i
						class="fas fa-edit mx-2 pointer"
						onClick={() => {
							toggleEdit(branch);
						}}
					></i>

					<i
						class="fas fa-trash text-danger pointer"
						onClick={() => {
							removeBranch(branch.id);
						}}
					></i>
				</p>
			</div>

			<Collapse isOpen={isOpen}>
				<table className="custom-table back-office-table table collapse-table-border table-striped">
					<thead>
						{allUser?.length > 0 ? (
							<tr>
								<th className="CompanyHead-title w-25 ">
									{teamManagement.userName}
								</th>
								{/* <th className="CompanyHead-title w-25 ">{backOffice.email}</th>
                <th className="CompanyHead-title w-25 "> {Companies.group}</th>
                <th className="CompanyHead-title w-25 ">{backOffice.status}</th>
                <th className="CompanyHead-title w-25 ">{Companies.Control}</th> */}
							</tr>
						) : (
							<th className="CompanyHead-title text-center">
								{backOffice.noUsers}{" "}
								<i class="fas fa-exclamation-circle text-warning"></i>
							</th>
						)}
					</thead>
					<tbody>{allUser?.length > 0 ? allUser : null}</tbody>
				</table>
			</Collapse>
		</div>
	);
}
