import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useContractDispatch, useContractState } from "context/contractRequest";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import PropertyDetails from "./PropertyDetails/index";
import OwnersDetails from "./OwnersDetails/index";
import ManagersDetails from "./ManagerDetails/index";
import ContractDetails from "modules/backOffice/Components/ContractRequest/Details/ContractDetails/index";
import Locale from "translations";
import { viewContract, viewSharing } from "services/property";
export default function Details() {
  const { backOffice } = Locale;
  const { step, current } = useContractState();
  const dispatchContract = useContractDispatch();
  const [activeTab, setActiveTab] = useState(step);
  const [state, setstate] = useState({});
  const { id } = useParams();

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      dispatchContract({ type: "progress", current: tab, step: step });
    }
  };

  const fetchData = async (id) => {
    const res = await viewSharing(id);
    if (res.status === 200) {
      // dispatch({ type: "ContractDetails", payload: res.data });
      setstate(res.data);
    }
  };

  useEffect(() => {
    if (id !== undefined) {
      fetchData(id);
      dispatchContract({
        type: "progress",
        progress: 0,
        step: "1",
        current: "1"
      });
    } else {
      dispatchContract({
        type: "progress",
        progress: 0,
        step: "1",
        current: "1"
      });
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (current) {
      setActiveTab(current);
    } else {
      setActiveTab(step);
    }
  }, [current, step]);

  return (
    <div className="property contract-details">
      <div className=" text-black mb-3">
        <div className="">
          <div className="row m-0">
            <h1>{state.property_details?.classification_name}</h1>
          </div>
        </div>
      </div>
      <div className="">
        <div className="text-black">
          <div className="d-flex route-block">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${activeTab === "1" ? "is-active" : ""} ${
                    +step > 1 ? "done" : ""
                  }`}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  {backOffice.propertyDetails}
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={`${activeTab === "2" ? "is-active" : ""}  ${
                    +step > 2 ? "done " : ""
                  } `}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  {backOffice.ownerDetails}
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={`${activeTab === "3" ? "is-active" : ""}  ${
                    +step > 3 ? "done " : ""
                  } `}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  {backOffice.managerDetails}
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={`${activeTab === "4" ? "is-active" : ""} ${
                    +step > 4 ? "done " : ""
                  }`}
                  onClick={() => {
                    toggle("4");
                  }}
                >
                  {backOffice.contractDetails}
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <PropertyDetails state={state} />
          </TabPane>
          <TabPane tabId="2">
            <OwnersDetails state={state} />
          </TabPane>
          <TabPane tabId="3">
            <ManagersDetails state={state} />
          </TabPane>
          <TabPane tabId="4">
            <ContractDetails />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
}
