import { Link } from "react-router-dom";
import Switch from "react-switch";
import Locale from "translations";

export default function User({
	user,
	removeUser,
	changeStatus,
	confirmIsOpen,
	setConfirmIsOpen,
	toggleConfirm,
}) {
	const { backOffice, userDetails, confirmation } = Locale;

	return (
		<>
			<tr>
				<td>{user.user_name}</td>
				<td>{user.email}</td>
				<td>
					{typeof user.roles == "string"
						? user.roles
						: user.roles?.display_name}
				</td>
				<td>
					{user.status === "active" ? (
						<i class="far fa-check-circle icons-teamMange text-success"></i>
					) : (
						<i class="far fa-times-circle icons-teamMange text-danger"></i>
					)}
				</td>
				<td>
					<Link
						type="button"
						class=""
						to={`/management-team/edit/user/${user.id}`}
					>
						<i class="fas fa-edit  text-blue-black icons-teamMange "></i>
					</Link>
					{/* {user.status === "active" ? (
          <i
            role={"button"}
            class="fas fa-stop-circle  icons-teamMange mx-2 text-danger"
            onClick={() => {
              changeStatus(user.id, "inactive");
            }}
          ></i>
        ) : (
          <i
            role={"button"}
            class="fas fa-stop-circle  icons-teamMange mx-2 text-success"
            onClick={() => {
              changeStatus(user.id, "active");
            }}
          ></i>
        )} */}
					{user?.status === "inactive" ? (
						<>
							<Switch
								height={24}
								width={45}
								handleDiameter={20}
								className="mx-2 align-middle"
								onChange={() => toggleConfirm(user.id, "active")}
								checked={false}
							/>
						</>
					) : (
						<Switch
							height={24}
							width={45}
							handleDiameter={20}
							className="mx-2 align-middle"
							onChange={() => toggleConfirm(user.id, "inactive")}
							checked={true}
							onColor="#1777fb"
						/>
					)}
					{/* 
        <i
          role={"button"}
          class="fas fa-trash icons-teamMange mx-2 text-danger"
          onClick={() => {
            //removeUser(user.id);
            toggleConfirm()
          }}
        ></i> */}
				</td>
			</tr>

			{/* <ConfirmModal IsOpen={confirmIsOpen} toggle={toggleConfirm}
      message={confirmation.confirmDeleteUser} onConfirm={()=> removeUser(user.id)} /> */}
		</>
	);
}
