import { Link, useParams } from "react-router-dom";

export default function Reservation({ reservation }) {
	const { company_id } = useParams();

	return (
		<tr>
			<td className="col-1"> {reservation?.brn}</td>
			<td className=" w-15">{reservation?.hotel_name}</td>
			{/* <td>{reservation?.room_type}</td> */}
			{/* <td>
        <p className="rounded bg-success text-white m-0 text-center p-1">
          {reservation?.availablity} / {reservation?.total_availablity}{" "}
        </p>
      </td> */}
			<td>{reservation?.created_at}</td>
			<td>{reservation?.start_date}</td>
			<td>{reservation?.nights_count}</td>
			<td>{reservation?.total_price}</td>
			{/* <td>{reservation?.room_type}</td> */}
			<td>{reservation?.rooms_number}</td>
			<td>
				<p
					className={` p-1 font-weight-bold text-white rounded text-center ${
						reservation?.reservation_status === "paid"
							? "bg-success"
							: reservation?.reservation_status === "pending"
							? "bg-warning"
							: "bg-danger"
					}`}
				>
					{reservation?.reservation_status}
				</p>
			</td>
			<td>
				{true ? (
					<Link
						to={`/tourism-company/reservations/${company_id}/reservation/${reservation.reservation_num}`}
					>
						<i className="fas fa-chevron-right text-success"></i>
					</Link>
				) : (
					<Link
						to={{
							pathname: `/tourism-company/UmrahHolidays/${company_id}/reservation/${reservation.reservation_num}`,
						}}
					>
						<i className="fas fa-chevron-right text-success"></i>
					</Link>
				)}
			</td>
		</tr>
	);
}
