import resetIcon from "assets/images/wallet/resetIcon.png";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useHistory } from "react-router-dom";
import Locale from "translations";

export default function Filter({ filter, setFilter }) {
	const { backOffice, translate_companies, wallet } = Locale;
	const statues = [
		{ value: "active", label: backOffice.active },
		{ value: "inactive", label: backOffice.inActive },
	];
	const history = useHistory();
	console.log(filter);
	return (
		<>
			<div className="main-filter bg-teamMange_filter py-2 our-border ">
				<div className="row align-items-center w-100 no-gutter m-0">
					<div className="col-md-2">
						<div className="main-label">
							<TextField
								type="text"
								placeholder={backOffice.search}
								label="Search"
								/* 								hasLabel={false}
								 */ value={filter.q}
								onChange={(e) => {
									setFilter({
										...filter,
										q: e.target.value,
									});
								}}
							/>
							{/* 							<i className="fas fa-search fa-fw"></i>
							 */}{" "}
						</div>
					</div>

					{history.location.pathname == "/management-team/users" ? (
						<div className="col-md-3">
							<SelectField
								/* 								hasLabel={false}
								 */ value={filter.status}
								placeholder={translate_companies.status}
								label="Status"
								name="status"
								options={statues}
								onChange={(e) => {
									setFilter({
										...filter,
										status: e,
									});
								}}
							/>
						</div>
					) : null}

					<div
						className="col-md"
						onClick={() => {
							setFilter({
								q: "",
								status: "",
							});
						}}
					>
						<div className="mt-4  d-flex align-items-center text-secondary pointer reset-btn">
							{/* 							<i className="fas fa-retweet position-static px-1 text-secondary text-caption"></i>
							 */}
							<img src={resetIcon} className="me-1" alt="" srcset="" />
							<p className="text-caption">{backOffice.resetFilter}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
