import Pagination from "components/shared/Pagination";
import { useEffect, useState } from "react";
import { ListNotifications, readNotification } from "services/notifications";
import Notification from "./Notification";

export default function AllNotifications() {
	const [notifications, setNotifications] = useState([]);
	const [meta, setMeta] = useState({});

	useEffect(() => {
		const fetchNotifications = async () => {
			const res = await ListNotifications();
			if (res.status === 200) {
				setNotifications(res.data.data);
				setMeta(res.data.meta);
			}
		};
		fetchNotifications();
	}, []);

	const goTo = async (page) => {
		const res = await ListNotifications(page);
		if (res.status === 200) {
			setNotifications(res.data.data);
			setMeta(res.data.meta);
		}
	};

	const readed = async (id) => {
		const res = await readNotification(id);
		const fetchNotifications = async () => {
			const res = await ListNotifications();
			if (res.status === 200) {
				setNotifications(res.data.data);
				setMeta(res.data.meta);
			}
		};
		fetchNotifications();
	};

	const AllNotifications = notifications?.map((notification) => (
		<Notification notification={notification} readed={readed} />
	));

	return (
		<div className="d-flex flex-column  align-items-center ">
			<div className="w-50  p-4">{AllNotifications}</div>

			<div className="px-2">
				<Pagination info={meta} goTo={goTo} />
			</div>
		</div>
	);
}
