import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import { cleanEmpty } from "services/general";
import { createPaymentWallet } from "services/manualSupplier";
import FinancialPaymentType from "./financialPaymentType";

const FinancialPayment = ({ setReload, currency }) => {
	const { id } = useParams();

	const [payment, setPayment] = useState({
		amount: "",
		type: null,
		transaction_type: null,
		notes: "",
		transfer: {
			bankName: "",
			accountName: "",
			accountNo: "",
			transactionNo: "",
		},
		cheque: {
			chequeNo: "",
			date: "",
		},
	});
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const transactionTypeOption = [
		{ label: "cash", value: 1 },
		{ label: "transfer", value: 2 },
		{ label: "cheque", value: 3 },
	];
	const typeOptions = [
		{
			value: 1,
			label: "add",
		},
		{
			value: 2,
			label: "deduct",
		},
	];
	const checkFormErrors = () => {
		let Error = null;

		if (payment?.transaction_type?.label == "Transfer") {
			let newError = {
				...validate(
					{ name: "bankName", value: payment.bankName },
					{ required: true }
				),
				...validate(
					{ name: "accountName", value: payment.accountName },
					{ required: true }
				),
				...validate(
					{ name: "accountNo", value: payment.accountNo },
					{ required: true, email: true }
				),
				...validate(
					{ name: "transactionNo", value: payment.transactionNo },
					{ required: true }
				),
			};
			Error = newError;
		} else if (payment?.transaction_type?.label == "Cheque") {
			let newError = {
				...validate(
					{ name: "bankName", value: payment.bankName },
					{ required: true }
				),
				...validate(
					{ name: "accountName", value: payment.accountName },
					{ required: true }
				),
				...validate(
					{ name: "chequeNo", value: payment.chequeNo },
					{ required: true }
				),
				...validate({ name: "date", value: payment.date }, { required: true }),
			};
			Error = newError;
		}
		setErrors({
			...errors,
			...validate(
				{ name: "amount", value: payment.amount },
				{ required: true, number: true }
			),
			...validate({ name: "type", value: payment.type }, { required: true }),
			...validate(
				{ name: "transaction_type", value: payment.transaction_type },
				{ required: true }
			),
			...validate({ name: "notes", value: payment.notes }, { required: true }),

			...Error,
		});
	};

	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	useEffect(() => {
		if (isFormValid(errors)) {
			const CreatePayment = async () => {
				let data = {
					amount: +payment?.amount,
					type: payment?.type?.label,
					transaction_type: payment?.transaction_type?.label,
					note: payment?.notes,
					currency: currency,

					bank_name: payment?.transfer?.bankName,
					account_holder_name: payment?.transfer?.accountName,
					account_number: payment?.transfer?.accountNo
						? payment?.transfer?.accountNo
						: payment?.cheque?.chequeNo,
					transaction_number: payment?.transfer?.transactionNo
						? payment?.transfer?.transactionNo
						: payment?.cheque?.chequeNo,
					cheque_date: payment?.cheque?.date
						? moment(payment?.cheque?.date).format("YYYY-MM-DD")
						: "",
				};
				debugger;
				const res = await createPaymentWallet(id, cleanEmpty(data));
				if (res.status == 200 || res.status == 201) {
					store.addNotification({
						title: "Info!",
						message: "Create New Payment Successfully",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 2000,
							onScreen: true,
							pauseOnHover: true,
						},
						onRemoval: () => {
							setReload((prev) => !prev);
							setPayment({
								amount: "",
								type: null,
								transaction_type: null,
								notes: "",
								transfer: {
									bankName: "",
									accountName: "",
									accountNo: "",
									transactionNo: "",
								},
								cheque: {
									chequeNo: "",
									date: "",
								},
							});
						},
					});
				}
			};
			CreatePayment();
		}
	}, [isErrorLoaded]);
	console.log("payment5555", payment);
	return (
		<div className="financial-payment">
			<div className=" border rounded p-0">
				<div className="d-flex justify-content-between pt-3 pb-1 px-3  border-bottom">
					<h6 className="font-weight-bold">Financial payment</h6>
				</div>
				<div className="controls align-items-center  ">
					<div className="row p-3">
						<div className="col-3 ">
							<TextField
								type="text"
								label={"Amount"}
								placeholder={"Enter Amount"}
								name="amount"
								value={payment.amount}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(e.target, {
											required: true,
											number: true,
										}),
									});
									setPayment({ ...payment, amount: e.target.value });
								}}
								color={
									errors?.amount?.required || errors?.amount?.number
										? "danger"
										: ""
								}
								errors={errors?.amount}
							/>
						</div>
						<div className="col-3">
							<SelectField
								label={"Type"}
								name="type"
								options={typeOptions}
								value={payment.type}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(
											{ name: "type", value: e.value },
											{
												required: true,
											}
										),
									});
									setPayment({ ...payment, type: e });
								}}
								color={errors?.type?.required ? "danger" : ""}
								errors={errors?.type}
							/>
						</div>
						<div className="col-3">
							<SelectField
								label={"Transaction Type"}
								name="transaction_type"
								options={transactionTypeOption}
								value={payment.transaction_type}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(
											{ name: "transaction_type", value: e.value },
											{
												required: true,
											}
										),
									});
									setPayment({
										...payment,
										transaction_type: e,
									});
								}}
								color={errors?.transaction_type?.required ? "danger" : ""}
								errors={errors?.transaction_type}
							/>
						</div>

						<div className="col-3 ">
							<TextField
								type="text"
								label={"Notes"}
								placeholder={"Enter Notes"}
								name="notes"
								value={payment.notes}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(e.target, {
											required: true,
										}),
									});
									setPayment({ ...payment, notes: e.target.value });
								}}
								color={errors?.notes?.required ? "danger" : ""}
								errors={errors?.notes}
							/>
						</div>
					</div>
					{payment.transaction_type?.value == 2 ||
					payment.transaction_type?.value == 3 ? (
						<FinancialPaymentType
							payment={payment}
							setPayment={setPayment}
							setErrors={setErrors}
							errors={errors}
						/>
					) : (
						""
					)}
					<div className="d-flex justify-content-end mb-3">
						<button className="btn btn-info px-5 mx-3" onClick={submit}>
							Add
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FinancialPayment;
