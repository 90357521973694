import React from "react";
import Locale from "translations";

export default function Home() {
	const { backOffice } = Locale;

	return (
		<>
			<h3>{backOffice.mainMenu}</h3>
		</>
	);
}
