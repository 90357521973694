import { Typography } from "@material-ui/core";
import moment from "moment";

const formatDate = (date) => moment(date).format("YYYY-MM-DD hh:mm A");
function CancellationPolicies(props) {
	const { cancellationPolicy } = props;
	// const t = useTranslation();
	return (
		<div className={`cancelation_Policies`}>
			<Typography
				className={`cancelation_title}`}
				style={{ flexGrow: 1, fontSize: 14 }}
			>
				Dates you can cancel your booking in .
			</Typography>

			<div className="d-flex justify-content-between mt-2">
				{cancellationPolicy?.isRefundable ? (
					cancellationPolicy?.rules.map((i, index) => {
						const lastElement = cancellationPolicy.rules.length - 1 === index;
						return (
							<>
								{lastElement && (
									<div key={i.fromDateTime} className={`dates`}>
										{formatDate(i.toDateTime)}
									</div>
								)}
								<div key={i.toDateTime} className={`dates`}>
									{index === 0
										? formatDate(i.fromDateTime)
										: formatDate(i.toDateTime)}
								</div>
							</>
						);
					})
				) : cancellationPolicy?.rules?.length > 0 ? (
					<>
						<div className={`dates`}>
							{formatDate(cancellationPolicy?.rules[0]?.fromDateTime)}
						</div>
						<div className={`dates`}>
							{formatDate(
								cancellationPolicy?.rules[cancellationPolicy?.rules?.length - 1]
									?.toDateTime
							)}
						</div>
					</>
				) : null}
			</div>

			<div className="d-flex py-2 px-4 ">
				{cancellationPolicy?.isRefundable ? (
					cancellationPolicy?.rules.map((i, index) => {
						const chargeType = i?.charge?.chargeType === "percentage";
						const chargeValue = i?.charge?.chargeValue;
						const chargeAmount = i?.charge?.chargeAmount;
						const isNonRefundable = chargeType && chargeValue === 100;

						return (
							<div
								key={i.fromDateTime}
								className={`line ${index === 0 ? "first_line" : "next_line"} ${
									isNonRefundable ? "non_refund_color" : "refund_color"
								}`}
							>
								{isNonRefundable ? "Non Refundable" : chargeAmount}
							</div>
						);
					})
				) : (
					<div className={`line non_refund_color first_line w-100`}>
						Non Refundable
					</div>
				)}
			</div>
		</div>
	);
}

export default CancellationPolicies;
