import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AddEditSeason from "../Components/Seasons/AddEditSeason/AddEditSeason";
import SeasonsFilter from "../Components/Seasons/SeasonsList/SeasonsFilter";
import SeasonsTable from "../Components/Seasons/SeasonsList/SeasonsTable";

import Model from "components/model";
import { listSeasons } from "services/seasons";

import Locale from "translations";

export default function Seasons() {
	const history = useHistory();
	const { backOffice } = Locale;
	const { search } = useLocation();
	const [_, pages] = search.split("=");
	const [SeasonList, setSeasonList] = useState([]);
	const [meta, setMeta] = useState(null);

	const fetchData = async (filters = null, page = pages) => {
		const res = await listSeasons(filters, page);
		if (res?.status === 200) {
			setSeasonList(res?.data?.seasons);
			setMeta(res?.data?.meta);
		}
	};

	useEffect(() => {
		fetchData();
	}, [pages]);

	const goTo = (page) => {
		fetchData(null, page);
		const params = new URLSearchParams();
		if (page) {
			params.append("page", page);
		} else {
			params.delete("page", page);
		}
		history.push({ search: params.toString() });
	};

	const [modal, setModal] = useState({
		isOpen: false,
		type: "",
		title: "",
	});

	const [modelSeasons, setModelSeasons] = useState(null);
	const [seasonsSelect, setSeasonsSelect] = useState(null);
	const [seasonsSelectIndex, setSeasonsSelectIndex] = useState(null);

	const openSeasonsModalHandler = (Model, Seasons, Index) => {
		setModal({
			isOpen: !modal.isOpen,
			type: "modal",
			title: "Add Season",
		});

		setModelSeasons(Model);
		setSeasonsSelect(Seasons);
		setSeasonsSelectIndex(Index);
	};

	const addSeason = () => {
		setModal({
			isOpen: !modal.isOpen,
			type: "modal",
			title: "Add Season",
		});
	};

	return (
		<>
			<div className="d-flex justify-content-between align-items-center mb-2">
				<h6 className=" main-title">{backOffice.seasons}</h6>
				<div
					className="btn btn-success Button-Pointer"
					onClick={(Model, Seasons, Index) =>
						openSeasonsModalHandler("add", null, null)
					}
				>
					<i className="fas fa-plus-circle"></i>
					{backOffice.addNewSeason}
				</div>
			</div>
			<SeasonsFilter
				SeasonList={SeasonList}
				setSeasonList={setSeasonList}
				setMeta={setMeta}
				pages={pages}
			/>
			<SeasonsTable
				SeasonList={SeasonList}
				setSeasonList={setSeasonList}
				fetchData={fetchData}
				goTo={goTo}
				meta={meta}
			/>

			<Model
				isOpen={modal.isOpen}
				type={modal.type}
				title={backOffice.addSeason}
				toggle={openSeasonsModalHandler}
				size="lg"
				hasFooter={false}
			>
				<div className="w-100 p-4">
					<AddEditSeason
						SeasonsSelect={seasonsSelect}
						setSeasonList={setSeasonList}
						addSeason={addSeason}
						SeasonID={null}
						ModelSeasons={modelSeasons}
						SeasonsSelectIndex={seasonsSelectIndex}
					/>
				</div>
			</Model>
		</>
	);
}
