import ConfirmModal from "components/Modals/ConfirmModal";
import TextField from "components/shared/TextField";
import { getNotification } from "helpers/makeNotifications";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import {
	changeStatusEmployee,
	deleteEmployee,
	listEmployees,
} from "services/companies";
import Locale from "translations";
import LooksupBranches from "../lookups/fetchBranches";
import LooksupGroups from "../lookups/fetchGroups";
import Employee from "./Employee";
import Filter from "./Filter";

export default function Employees() {
	const { backOffice, Companies, confirmation } = Locale;
	const { company_id } = useParams();

	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => {
		setIsOpen(!isOpen);
	};
	const [empoleeyId, setempoleeyId] = useState({});
	const [empolstatus, setempolstatus] = useState({});
	const [isOpenModel, setIsOpenmodel] = useState(false);
	const empolyeeStatus = (id = null, status = null) => {
		setIsOpenmodel(!isOpenModel);
		setempoleeyId({ id });
		setempolstatus(status);
	};
	const toggleModel = () => {
		setIsOpenmodel(!isOpenModel);
	};

	const [Employees, setEmployees] = useState([]);
	const Groups = LooksupGroups(company_id);
	const Branches = LooksupBranches(company_id);

	const [meta, setMeta] = useState(null);

	const [filters, setFilter] = useState({
		name: "",
		email: null,
		branch_id: null,
		group_id: null,
	});

	useEffect(async () => {
		const res = await listEmployees(company_id, {
			...filters,
			status: filters.status?.value,
			group_id: filters.group_id?.value,
			branch_id: filters.branch_id?.value,
		});
		if (res?.status === 200) {
			setEmployees(res?.data?.data);
			setMeta(res?.data?.meta);
		}
	}, [filters]);

	const changeStatus = async (id) => {
		const res = await changeStatusEmployee(company_id, { user_id: id });
		if (res.status === 200 || res.status === 201) {
			getNotification(res?.data?.message);
			setFilter({ ...filters });
			setIsOpenmodel(!isOpenModel);
		}
	};

	const removeEmployee = async (id) => {
		const res = await deleteEmployee(company_id, id);
		if (res.status === 200 || res.status === 201) {
			getNotification(res?.data?.message);
			setFilter({ ...filters });
		}
	};

	const goTo = (page) => {
		setFilter({ ...filters, page: page });
	};

	const AllEmployees =
		Employees.length > 0 ? (
			Employees.map((employee, index) => (
				<Employee
					empolyeeStatus={empolyeeStatus}
					employee={employee}
					key={Employee.id}
					index={index}
					toggle={toggle}
					changeStatus={changeStatus}
					removeEmployee={removeEmployee}
				/>
			))
		) : (
			<tr>
				<td colSpan="11">
					<div className="product-no-data">
						<i className="fas fa-info-circle fa-lg"></i>{" "}
						<h4>{backOffice.noResult}</h4>
					</div>
				</td>
			</tr>
		);
	return (
		<div>
			<Filter
				filter={filters}
				setFilter={setFilter}
				Groups={Groups}
				Branches={Branches}
			/>
			<div className="our-border px-4">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>#</th>
							<th>{Companies.name}</th>
							<th>{Companies.email}</th>
							<th>{Companies.groups}</th>
							<th>{Companies.branch}</th>
							<th>{backOffice.status}</th>
							<th>{Companies.Control}</th>
						</tr>
					</thead>

					<tbody>
						{Employees?.length > 0 ? (
							AllEmployees
						) : (
							<tr>
								<td colSpan="11">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			{/* change status model		 */}
			<ConfirmModal
				IsOpen={isOpenModel}
				toggle={toggleModel}
				message={
					empolstatus == "active"
						? confirmation.activeEmplooye
						: confirmation.inactiveEmplooye
				}
				onConfirm={() => changeStatus(empoleeyId?.id)}
				type="comfirm"
			/>
			{/* Change password popup */}
			<div>
				<Modal isOpen={isOpen} toggle={toggle}>
					<div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
						<p className=" text-secondary ">{backOffice.changePassword}</p>
						<i className="far fa-times-circle fa-lg text-secondary "></i>
					</div>

					<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3">
						<div className=" w-90">
							<TextField
								type="text"
								hasLabel={true}
								placeholder={backOffice.newPassword}
							/>
						</div>

						<div className="w-90">
							{" "}
							<TextField
								type="text"
								placeholder={backOffice.confirmPassword}
								hasLabel={true}
							/>
						</div>
					</ModalBody>
					<div className="w-100 pb-5 border-top-0  text-center    p-0 ">
						<button className="btn rounded companies-btn  w-90">
							{backOffice.changePassword}
						</button>
					</div>
				</Modal>
			</div>
		</div>
	);
}
