import Pagination from "components/shared/Pagination";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";

import { store } from "react-notifications-component";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import { changeStatusOffers, fetchSearchHotels } from "services/wallet";
import Locale from "translations";

const MarketingOffersList = () => {
	const { backOffice, marketPlace } = Locale;
	const SBSURL = process.env.REACT_APP_SBS_FRONT_URL;
	const [searchHotelList, setSearchHotelList] = useState([]);
	const [isopen, setIsopen] = useState(false);
	const [counter, setcounter] = useState();
	const [meta, setMeta] = useState(null);
	const [filter, setFilter] = useState({
		expiration_date: undefined,
		page: 1,
	});
	const [Reload, setReload] = useState(false);
	const [isCopy, setIsCopy] = useState();
	const toggle = () => {
		setIsopen(!isopen);
	};
	const copyText = (text) => {
		navigator.clipboard.writeText(text);
		setIsCopy(text);
	};
	useEffect(() => {
		async function fetchSearchList() {
			const res = await fetchSearchHotels({
				...filter,
				expiration_date: filter.expiration_date
					? moment(filter.expiration_date).format("YYYY-MM-DD")
					: null,
			});
			setSearchHotelList(res?.data?.data);
			setMeta(res?.data?.meta);
		}

		fetchSearchList();
	}, [filter, Reload]);
	const changeStatus = async (id, status) => {
		const res = await changeStatusOffers(id, {
			status,
		});
		if (res.status === 200) {
			store.addNotification({
				title: "Change Status",
				message: res?.data?.message
					? res?.data?.message
					: "Changed Status Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					setReload((prev) => !prev);
				},
			});
		}
	};

	const AllSearch =
		searchHotelList?.length > 0 ? (
			searchHotelList?.map((res, index) => (
				<tr>
					<td>{res?.title}</td>
					<td width={"20%"} className="text-primary pointer">
						{/* {backOfficeURL.concat(res?.url?.slice(0, 25)) + "..."} */}
						<a
							href={
								SBSURL +
								"/market-view?token=" +
								res.token +
								`${res.url?.includes("is_offer=1") ? "&offer=1" : ""}`
							}
							target="_blank"
							rel="noopener noreferrer"
						>
							{res?.token?.slice(0, 20)}
						</a>
					</td>
					<td>{res.is_offer == 1 ? "Offers" : "Hotels"}</td>
					<td>
						{/* {res.country?.length > 0
							? res.country[0]?.name + "..."
							: res.country?.[0]?.name} */}
						{res.country?.length > 0 &&
							res?.country?.map((item) => item.name + " ")}
					</td>
					<td>{res.user_info?.user_name}</td>
					<td>{res.expiration_date}</td>

					<td>
						<button
							className={`btn  primary ${
								isCopy?.includes(res.token) ? "btn-primary" : "btn-light"
							} pointer`}
							onClick={() =>
								copyText(
									SBSURL +
										"/market-view?token=" +
										res.token +
										`${res.url?.includes("is_offer=1") ? "&offer=1" : ""}`
								)
							}
						>
							<i class="far fa-copy"></i>{" "}
							{isCopy?.includes(res.token) ? "Copied" : "Copy"}
						</button>
						<a
							className="ms-3"
							onClick={() => {
								toggle();
								setcounter(res.counter);
							}}
						>
							<i class="far fa-chart-bar"></i>
						</a>

						{res?.status != "active" ? (
							<Switch
								height={24}
								width={45}
								handleDiameter={20}
								className="mx-2 align-middle"
								onChange={() => {
									changeStatus(res.id, "active");
								}}
								checked={false}
							/>
						) : (
							<Switch
								height={24}
								width={45}
								handleDiameter={20}
								className="mx-2 align-middle"
								onColor="#1777fb"
								onChange={() => {
									changeStatus(res.id, "inactive");
								}}
								checked={true}
							/>
						)}
					</td>

					<td>
						<Link to={`/marketing-offers/${res.token}`}>
							<i class="fas fa-angle-right"></i>
						</Link>
					</td>
				</tr>
			))
		) : (
			<tr>
				<td colSpan="10">
					<div className="product-no-data">
						<i className="fas fa-info-circle fa-lg"></i>{" "}
						<h4>{backOffice.noResult}</h4>
					</div>
				</td>
			</tr>
		);
	const goTo = (page) => {
		setFilter({ ...filter, page: +page });
	};
	return (
		<>
			<div className="d-flex justify-content-between my-2">
				<h5>Marketing Offers List</h5>
				<Link to="/marketing-offers" className="btn btn-primary ">
					Create Offer
				</Link>
			</div>
			<div className=" table-responsive px-4 our-border">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>{marketPlace.Title}</th>
							<th>{marketPlace.url}</th>
							<th>{marketPlace.searchType}</th>
							<th>{marketPlace.country}</th>
							<th>{marketPlace.Admin}</th>
							<th>{marketPlace.expirationDate}</th>
							<th>{marketPlace.tools}</th>
						</tr>
					</thead>

					<tbody>{AllSearch}</tbody>
				</table>
				<div className="px-2">
					<Pagination info={meta} goTo={goTo} />
				</div>
			</div>
			<Modal isOpen={isopen} toggle={toggle}>
				<div className="lan d-flex justify-content-between align-items-baseline p-2 border-bottom vendor-wallet-view-popup ">
					<p className="  h4  font-weight-bold">{marketPlace.Statistics}</p>
					<i
						className="far fa-times-circle fa-lg  pointer"
						onClick={toggle}
					></i>
				</div>
				<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3">
					<div className="row m-0 p-0 mt-4 justify-content-center  align-items-center">
						<p className="col-12  h6   font-weight-bold ">
							{`This link opened ${counter} times`}
						</p>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default MarketingOffersList;
