import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";
import TextField from "components/shared/TextField";
import Locale from "translations";

export default function Filter({ filter, setFilter }) {
	const { Companies, backOffice } = Locale;
	const statues = [
		{ value: "active", label: backOffice.active },
		{ value: "inactive", label: backOffice.inActive },
	];
	console.log("filter", filter);
	return (
		<div className="main-filter bg-white-blue py-2 my-2">
			<div className="row align-items-center w-100 no-gutter m-0">
				<div className="col-2">
					<TextField
						type="text"
						placeholder={Companies.search}
						hasLabel={false}
						value={filter.name}
						onChange={(e) => {
							setFilter({
								...filter,
								name: e.target.value,
							});
						}}
					/>
				</div>

				<div className="col-2">
					<DatePickerField
						hasLabel={false}
						date={filter.date}
						placeholder={backOffice.checkIn}
						onChangeDate={(filterDate) => {
							setFilter({
								...filter,
								date: filterDate,
							});
						}}
						isOutsideRange={(day) => {
							return false;
						}}
					/>
					{/* <SelectField
            hasLabel={false}
            placeholder={backOffice.status}
            name="status"
            value={filter.status}
            options={statues}
            onChange={(e) => {
              setFilter({
                ...filter,
                status: e,
              });
            }}
          /> */}
				</div>
				<div
					className="col-2 "
					onClick={() => {
						setFilter({
							name: "",
							date: null,
						});
					}}
				>
					<div className="d-flex align-items-center text-secondary pointer mt-4 reset-btn">
						{/* 						<i className="fas fa-retweet position-static px-1 text-secondary text-caption"></i>
						 */}{" "}
						<img src={resetIcon} alt="" srcset="" />
						<p className="text-caption">{backOffice.resetFilter}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
