import successImage from "assets/images/backOffice/success.svg";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import Model from "components/model";
import libphonenumber from "google-libphonenumber";
import validate from "helpers/validate";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Locale from "translations";

export default function AddInvitation() {
  const [modal, setModal] = useState({
    isOpen: false,
    type: "",
  });

  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  // const [countries] = useFetchCountries();
  const [countries] = [{}];

  const { backOffice } = Locale;
  let history = useHistory();

  const backInvitation = () => {
    history.push(`../invitation`);
  };

  const [addInvitationState, setAddInvitationState] = useState({
    companyName: "",
    commissionerName: "",
    email: "",
    country: "",
    mobileNumber: "",
    countryPhoneCode: "",
    countryCode: null,
    season: "",
    month: "",
    expectedCounts: "",
  });

  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const validatePhone = (mobileNumber) => {
    try {
      return phoneUtil.isValidNumberForRegion(
        phoneUtil.parse(
          mobileNumber.toString(),
          addInvitationState.countryCode
        ),
        addInvitationState.countryCode
      );
    } catch (err) {
      return false;
    }
  };

  // Use Effect for [Country]
  useEffect(() => {
    const Data = countries?.filter(
      (obj) => obj.id == addInvitationState.country?.value
    );
    setAddInvitationState({
      ...addInvitationState,
      countryPhoneCode: Data.length > 0 ? Data[0]["phone_code"] : "-",
      countryCode: Data.length > 0 ? Data[0]["code"] : null,
    });
  }, [countries, addInvitationState.country?.value]);

  const checkFormErrors = () => {
    setErrors({
      ...errors,
      ...validate(
        { name: "companyName", value: addInvitationState.companyName },
        { required: true, min: 3 }
      ),
      ...validate(
        {
          name: "commissionerName",
          value: addInvitationState.commissionerName,
        },
        { required: true, min: 3 }
      ),
      ...validate(
        { name: "country", value: addInvitationState.country },
        { required: true }
      ),
      ...validate(
        { name: "mobileNumber", value: addInvitationState.mobileNumber },
        { required: true, phone: true }
      ),
      ...validate(
        { name: "email", value: addInvitationState.email },
        { required: true, email: true }
      ),
      ...validate(
        { name: "season", value: addInvitationState.season },
        { required: true }
      ),
      ...validate(
        { name: "month", value: addInvitationState.month },
        { required: true }
      ),
      ...validate(
        { name: "expectedCounts", value: addInvitationState.expectedCounts },
        { required: true }
      ),
    });
  };

  const handleCreate = async () => {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
    alert(`
		companyName => ${addInvitationState.companyName}
		commissionerName => ${addInvitationState.commissionerName}
		email => ${addInvitationState.email}
		country: => ${addInvitationState.country?.label}
		mobileNumber => ${addInvitationState.mobileNumber}
		season: => ${addInvitationState.season?.label}
		month => ${addInvitationState.month?.label}
		expectedCounts => ${addInvitationState.expectedCounts}
		`);

    setModal({
      isOpen: !modal.isOpen,
    });
  };

  const toggle = () => {
    if (!modal.isOpen) setErrors({});
    setModal({ isOpen: !modal.isOpen });
  };

  return (
    <>
      <Model
        isOpen={modal.isOpen}
        title={modal.title}
        size="lg"
        handleCreate={handleCreate}
        toggle={toggle}
        hasFooter={false}
      >
        <div className="d-flex flex-column m-auto text-center py-3">
          <img
            className="img-fluid py-3 px-2 w-50 m-auto"
            src={successImage}
            alt="successImage"
          />
          <h4 className="font-weight-bold py-1 text-title3">
            {backOffice.invitationLinkSuccess}
          </h4>
          <p className="font-weight-bold py-1">shorturl.at/enup8</p>
          <button className="btn text-white my-1 bg-blue-black">
            {backOffice.copyLink}
          </button>
          <Link className="text-blue-black" to="../invitation">
            {backOffice.backInvitation}
          </Link>
        </div>
      </Model>

      <div className="add-invitation">
        <h5 className="mx-1">{backOffice.companyDate}</h5>
        <div className="row m-0">
          <div className="col-md-6">
            <TextField
              type="text"
              label={backOffice.companyName}
              placeholder={backOffice.companyNamePlaceholder}
              value={addInvitationState.companyName}
              name="companyName"
              onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(e.target, {
                    required: true,
                    min: 3,
                  }),
                });
                setAddInvitationState({
                  ...addInvitationState,
                  companyName: e.target.value,
                });
              }}
              min={3}
              color={
                errors?.companyName?.required || errors?.companyName?.min
                  ? "danger"
                  : ""
              }
              errors={errors?.companyName}
            />
          </div>
          <div className="col-md-6">
            <TextField
              type="text"
              label={backOffice.CommissionerName}
              placeholder={backOffice.CommissionerNamePlaceholder}
              value={addInvitationState.commissionerName}
              name="commissionerName"
              onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(e.target, {
                    required: true,
                    min: 3,
                  }),
                });
                setAddInvitationState({
                  ...addInvitationState,
                  commissionerName: e.target.value,
                });
              }}
              min={3}
              color={
                errors?.commissionerName?.required ||
                  errors?.commissionerName?.min
                  ? "danger"
                  : ""
              }
              errors={errors?.commissionerName}
            />
          </div>
          <div className="col-md-6">
            <TextField
              type="text"
              label={backOffice.email}
              placeholder={backOffice.emailPlaceholder}
              value={addInvitationState.email}
              name="email"
              onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(e.target, {
                    required: true,
                    min: 3,
                  }),
                });
                setAddInvitationState({
                  ...addInvitationState,
                  email: e.target.value,
                });
              }}
              min={3}
              color={
                errors?.email?.required || errors?.email?.min ? "danger" : ""
              }
              errors={errors?.email}
            />
          </div>
          <div className="col-md-6">
            <SelectField
              label={backOffice.country}
              value={addInvitationState.country?.label}
              placeholder={backOffice.countryPlaceholder}
              name="country"
              options={countries}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "country", value: e.value },
                    { required: true }
                  ),
                });
                setAddInvitationState({
                  ...addInvitationState,
                  country: e,
                  mobileNumber: "",
                });
              }}
              onBlur={() =>
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "country", value: addInvitationState.country },
                    { required: true }
                  ),
                })
              }
              color={errors?.country?.required ? "danger" : ""}
              errors={errors?.country}
            />
          </div>
          {/* Phone Number */}
          <div className="col-md-6">
            <TextField
              label={backOffice.mobileNumber}
              placeholder={backOffice.mobileNumberPlaceholder}
              name="phone"
              value={addInvitationState.mobileNumber}
              extraText={addInvitationState.countryPhoneCode ?? "---"}
              extraTextPosition="prepend"
              onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(
                    {
                      name: "mobileNumber",
                      value: validatePhone(e.target.value),
                    },
                    {
                      required: true,
                      mobileNumber: true,
                    }
                  ),
                });
                setAddInvitationState({
                  ...addInvitationState,
                  countryPhoneCode: addInvitationState.countryPhoneCode,
                  countryCode: addInvitationState.countryCode,
                  mobileNumber: e.target.value,
                });
              }}
              color={
                errors?.mobileNumber?.required ||
                  errors?.mobileNumber?.mobileNumber
                  ? "danger"
                  : ""
              }
              errors={errors?.mobileNumber}
            />
          </div>
        </div>

        <h5 className="pt-2 font-weight-bold invitation-head">
          {backOffice.requiredInfo}
        </h5>

        {/* Start Working */}
        <div className="d-flex align-items-center py-1">
          <div className="head w-25">
            <h6>1- {backOffice.startWorking}: </h6>
          </div>
          <div className="d-flex w-75">
            <div className="w-50 px-2">
              <SelectField
                label={backOffice.season}
                placeholder={backOffice.seasonPlaceholder}
                value={addInvitationState.season?.label}
                name="season"
                options={countries}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "season", value: e.value },
                      { required: true }
                    ),
                  });
                  setAddInvitationState({
                    ...addInvitationState,
                    season: e,
                  });
                }}
                onBlur={() =>
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "season", value: addInvitationState.season },
                      { required: true }
                    ),
                  })
                }
                color={errors?.season?.required ? "danger" : ""}
                errors={errors?.season}
              />
            </div>
            <div className="w-50">
              <SelectField
                label={backOffice.month}
                placeholder={backOffice.monthPlaceholder}
                value={addInvitationState.month?.label}
                name="month"
                options={countries}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "month", value: e.value },
                      { required: true }
                    ),
                  });
                  setAddInvitationState({
                    ...addInvitationState,
                    month: e,
                  });
                }}
                onBlur={() =>
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "month", value: addInvitationState.month },
                      { required: true }
                    ),
                  })
                }
                color={errors?.month?.required ? "danger" : ""}
                errors={errors?.month}
              />
            </div>
          </div>
        </div>

        {/* Expected Numbers */}
        <div className="d-flex align-items-center py-1">
          <div className="head w-25">
            <h6>{backOffice.expectedNumbers}: </h6>
          </div>
          <div className="d-flex w-75">
            <div className="w-50 px-2">
              <TextField
                type="text"
                label="Counts"
                placeholder={backOffice.countsPlaceholder}
                value={addInvitationState.expectedCounts}
                name="expectedCounts"
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    ...validate(e.target, {
                      required: true,
                    }),
                  });
                  setAddInvitationState({
                    ...addInvitationState,
                    expectedCounts: e.target.value
                      .replace(/[^\d]/g, "")
                      .replace(/[^\w\s]/gi, "")
                      .replace(/^0/, ""),
                  });
                }}
                color={errors?.expectedCounts?.required ? "danger" : ""}
                errors={errors?.expectedCounts}
              />
            </div>
          </div>
        </div>

        {/* Program Level */}
        <div className="d-flex align-items-center py-1">
          <div className="head w-25">
            <h6>{backOffice.programLevel}: </h6>
          </div>
          <div className="d-flex w-75">
            <div className="px-2">
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input permChecks "
                  id="checkbox-1"
                  name="classification[]"
                  type="checkbox"
                  value="1"
                />
                <label
                  className="custom-control-label pointer"
                  htmlFor="checkbox-1"
                >
                  {backOffice.economic}
                </label>
              </div>
            </div>

            <div className="px-2">
              <div className="custom-control custom-checkbox ">
                <input
                  className="custom-control-input permChecks"
                  id="checkbox-2"
                  name="classification[]"
                  type="checkbox"
                  value="2"
                />
                <label
                  className="custom-control-label pointer"
                  htmlFor="checkbox-2"
                >
                  4 {backOffice.stars}
                </label>
              </div>
            </div>

            <div className="px-2">
              <div className="custom-control custom-checkbox ">
                <input
                  className="custom-control-input permChecks"
                  id="checkbox-3"
                  name="classification[]"
                  type="checkbox"
                  value="3"
                />
                <label
                  className="custom-control-label pointer"
                  htmlFor="checkbox-3"
                >
                  5 {backOffice.stars}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex py-2 my-2">
          <button
            className="btn text-white bg-blue-black rounded-0 px-3 py-2"
            onClick={handleCreate}
          >
            {backOffice.createInvitationLink}
          </button>
          <button
            className="btn text-white bg-blue-black rounded-0 mx-2 px-5 py-2"
            onClick={backInvitation}
          >
            {backOffice.cancle}
          </button>
        </div>
      </div>
    </>
  );
}
