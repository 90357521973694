import Locale from "translations";
import ContractFilter from "../Components/TravelManagement/TravelFilter";
import ContractTable from "../Components/TravelManagement/TravelTable";

export default function TravelManagement() {
	const { backOffice } = Locale;

	return (
		<>
			<div className="d-flex justify-content-between align-items-center">
				<h6 className=" main-title ">{backOffice.travelManagement}</h6>

				<button className="btn text-white bg-blue-black rounded-0">
					Export
				</button>
			</div>
			<ContractFilter />
			<ContractTable />
		</>
	);
}
