import Pagination from "components/shared/Pagination";
import Locale from "translations";
import PropertyTableContent from "./SharingTableContent";
export default function SharingTable({ ProductList, fetchData, meta, goTo }) {
	const { backOffice } = Locale;

	const AllProducts = ProductList?.map((productItem, index) => (
		//   const AllProducts = [
		//     {
		//       id: 1,
		//       supplier_name: "amgad1",
		//       hotel_name: "my hotel4444",
		//       country: {
		//         ar: "السعودية",
		//         en: "Saudi Arabia"
		//       },
		//       city: {
		//         ar: "مكة المكرمة",
		//         en: "Makkah"
		//       },
		//       publish_from: "2021-06-13",
		//       publish_to: "2022-06-13",
		//       created_at: "2021-07-04",
		//       status: "Pending"
		//     }
		//   ].map((productItem, index) => (
		<PropertyTableContent
			fetchData={fetchData}
			productItem={productItem}
			index={index}
			key={index}
		/>
	));

	return (
		<>
			<table className="custom-table back-office-table table table-striped">
				<thead>
					<tr>
						<th>{backOffice.supplierName}</th>
						<th>{backOffice.hotelName}</th>
						<th>{backOffice.country}</th>
						<th>{backOffice.city}</th>
						<th>{backOffice.publishFrom}</th>
						<th>{backOffice.publishTo}</th>
						<th>{backOffice.status}</th>
						<th>{backOffice.tools}</th>
					</tr>
				</thead>
				<tbody>
					{/* {ProductList?.length > 0 ? ( */}
					{AllProducts?.length > 0 ? (
						AllProducts
					) : (
						<tr>
							<td colSpan="11">
								<div className="product-no-data">
									<i className="fas fa-info-circle fa-lg"></i>{" "}
									<h4>{backOffice.noResult}</h4>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
			{ProductList?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}
		</>
	);
}
