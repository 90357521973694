import React, { useEffect, useState } from "react";
import Locale from "translations";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate from "helpers/validate";
const locale = localStorage.getItem("currentLocale");

function Room({ lookups, index, room, setRoom, deleteRoom }) {
  const { rooms } = Locale;
  const [showCustomName, setShowCustomName] = useState(false);
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const checkFormErrors = () => {
    setErrors({
      ...errors,
      ...validate({ name: "view", value: room.view }, { required: true }),
      ...validate(
        { name: "count", value: room.count },
        { required: true, number: true }
      ),
      ...validate(
        { name: "smokingPolicy", value: room.smokingPolicy },
        { required: true }
      )
  
    });
  };

  const handleChange = (e) => {
    if ("target" in e) {
      const value = e.target.value;
      const name = e.target.name;
      setRoom(index, name, value);
    } else {
      const value = e.value;
      const name = e.name;
      setRoom(index, name, value);
    }
  };

  //

  useEffect(() => {
    if (room.customName !== "") {
      setShowCustomName(true);
    }
  }, [room.customName]);
  console.log("room", room);
  return (
    <>
      <td>
        <SelectField
          //   disabled
          label={rooms.selectType}
          disabled={false}
          placeholder={rooms.selectType}
          options={lookups.types}
          hasLabel={false}
          value={room.type}
          name="type"
          id="room-type"
          onChange={(e) => {
            handleChange({ name: "type", value: e });
            setErrors({
              ...errors,
              ...validate({ name: "type", value: e }, { required: true }),
            });
          }}
          color={errors.type?.required ? "danger" : ""}
          errors={errors.type}
        />
      </td>
      <td>
        <TextField
          //   disabled
          label={rooms.selectName}
          disabled={false}
          placeholder={rooms.selectName}
          //onChange={(e) => {}}

          hasLabel={false}
          value={room.customName}
          name="customName"
          id="room-name"
          onChange={(e) => {
            handleChange({ name: "customName", value: e.target.value });
          }}
          color={errors.name?.required ? "danger" : ""}
          errors={errors.name}
        />
      </td>
      <td>
        <SelectField
          //   disabled
          label={rooms.selectView}
          disabled={false}
          placeholder={rooms.selectView}
          //onChange={(e) => {}}
          options={lookups.views}
          hasLabel={false}
          value={room.view}
          name="view"
          id="room-view"
          onChange={(e) => {
            handleChange({ name: "view", value: e });
            setErrors({
              ...errors,
              ...validate({ name: "view", value: e }, { required: true }),
            });
          }}
          color={errors.view?.required ? "danger" : ""}
          errors={errors.view}
        />
      </td>
      <td>
        <SelectField
          //   disabled
          label={rooms.selectSmokingPolicy}
          disabled={false}
          placeholder={rooms.selectSmokingPolicy}
          //onChange={(e) => {}}
          options={[
            { label: "Smoking", value: "Smoking" },
            { label: "Non Smoking", value: "Non Smoking" },
          ]}
          hasLabel={false}
          value={room.smokingPolicy}
          name="smokingPolicy"
          id="room-smokingPolicy"
          onChange={(e) => {
            handleChange({ name: "smokingPolicy", value: e });
            setErrors({
              ...errors,
              ...validate(
                { name: "smokingPolicy", value: e },
                { required: true }
              ),
            });
          }}
          color={errors.smokingPolicy?.required ? "danger" : ""}
          errors={errors.smokingPolicy}
        />
      </td>
      <td>
        <TextField
          label={rooms.count}
          placeholder={rooms.count}
          disabled={false}
          value={room.count}
          name="count"
          id="room-count"
          onChange={(e) => {
            handleChange(e);
            setErrors({
              ...errors,
              ...validate(
                { name: "count", value: e.target.value },
                { required: true, number: true }
              ),
            });
          }}
          color={errors.count?.required || errors.count?.number ? "danger" : ""}
          errors={errors.count}
          hasLabel={false}
        />
      </td>
      <td className="fixRoom">
        <div className="w-100">
          <TextField
            label={rooms.MaxAdults}
            placeholder={rooms.MaxAdults}
            value={room.max_adults}
            name="max_adults"
            id="room-count"
            onChange={(e) => {
              handleChange(e);
              setErrors({
                ...errors,
                ...validate(
                  { name: "max_adults", value: e.target.value },
                  { required: true, number: true }
                ),
              });
            }}
            color={
              errors.max_adults?.required || errors.max_adults?.number
                ? "danger"
                : ""
            }
            errors={errors.max_adults}
            hasLabel={false}
          />
        </div>
      </td>
      <td className="fixRoom">
        <div className="w-100">
          {" "}
          <TextField
            label={rooms.MaxChildrens}
            placeholder={rooms.MaxChildrens}
            value={room.max_children}
            name="max_children"
            id="room-count"
            onChange={(e) => {
              handleChange(e);
            }}
            color={
              errors.max_children?.required || errors.max_children?.number
                ? "danger"
                : ""
            }
            errors={errors.max_children}
            hasLabel={false}
          />
        </div>
      </td>
      <td className="fixRoom">
        <div className="w-100">
          {" "}
          <TextField
            label={rooms.MaxAdultWithChild}
            placeholder={rooms.MaxAdultWithChild}
            value={room.max_adults_with_children}
            name="max_adults_with_children"
            id="room-count"
            onChange={(e) => {
              handleChange(e);
              setErrors({
                ...errors,
                ...validate(
                  { name: "max_adults_with_children", value: e.target.value },
                  { required: true, number: true }
                ),
              });
            }}
            color={
              errors.max_adults_with_children?.required ||
              errors.max_adults_with_children?.number
                ? "danger"
                : ""
            }
            errors={errors.max_adults_with_children}
            hasLabel={false}
          />
        </div>
      </td>
      {/*Delete Room */}

      {index > 0 && (
        <div className="d-flex justify-content-center align-items-center delete-room">
          <i
            onClick={() => deleteRoom(index)}
            className="fas fa-trash text-danger"
            id="delete-customName"
          ></i>
        </div>
      )}
    </>
  );
}

export default Room;
