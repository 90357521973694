import PlatformAddContent from "modules/backOffice/Components/PlatformCommessions/AddEditCommission";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import Locale from "translations";

export default function AddEditPlatform() {
	const history = useHistory();
	const { backOffice } = Locale;
	const { id } = useParams();

	return (
		<>
			<div className="d-flex justify-content-between align-items-center">
				<h6 className="h4 font-weight-bold text-black">
					{id ? backOffice.editPlatform : backOffice.addNewPlatform}
				</h6>
			</div>
			<PlatformAddContent isEditMode={id ? true : false} />
		</>
	);
}
