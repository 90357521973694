import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import InvitationTable from "../Components/Invitations/InvitaionTable";
import InvitationFilter from "../Components/Invitations/InvitationFilter";
import Locale from "translations";

export default function Invitations() {
	const { backOffice } = Locale;
	let history = useHistory();
	let { url } = useRouteMatch();

	const AddInvitation = () => {
		history.push(`${url}/add`);
	};

	return (
		<>
			<div className="d-flex justify-content-between align-items-center">
				<h6 className="font-weight-bold text-blue-black">
					{backOffice.allInvitations}
				</h6>
				<h6 className="font-weight-bold text-blue-black">
					{backOffice.allInvitations}: 320
				</h6>
				<button
					className="btn text-white bg-blue-black rounded-0"
					onClick={AddInvitation}
				>
					{backOffice.addNewInvitation}
				</button>
			</div>
			<InvitationFilter />
			<InvitationTable />
		</>
	);
}
