import dumyImg from "assets/images/default-img.jpg";
import TextField from "components/shared/TextField";
import validate from "helpers/validate";
import { useState } from "react";
import { Collapse } from "reactstrap";
export default function ItemDestination(props) {
	const {
		item,
		index,
		setPopupAds,
		popupAds,
		setErrors,
		errors,
		handleItems,
		addOne,
		deleteOne,
		handleImageItem,
		deleteImageItem,
	} = props;
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);
	const backOfficeApi = process.env.REACT_APP_API_URL + "/api/files/fetch/";

	return (
		<div className="item-destination ">
			<div className="py-2 mx-3">
				<div
					className={`row  m-0  align-items-center collapse-bg ${
						errors[`destinationName${index}`]?.required ||
						errors[`priceStartFrom${index}`]?.required ||
						errors[`adsLink${index}`]?.required ||
						errors[`image${index}`]?.required
							? "border border-danger"
							: ""
					}`}
				>
					<button className="collapse-btn  px-3 py-2" onClick={() => toggle()}>
						{isOpen ? (
							<i class="fas fa-minus  text-white"></i>
						) : (
							<i class="fas fa-plus text-white"></i>
						)}
					</button>

					<div
						className="d-flex justify-content-between"
						style={{ width: "96%" }}
					>
						<p className="col-3 gName-f  font-weight-bold  m-0">
							{item.destinationName ? item.destinationName : "-"}
						</p>
						{index != 0 ? (
							<a
								class=" text-danger d-flex justify-content-end align-items-center  px-4"
								onClick={() => deleteOne(index)}
							>
								<i class="fa fa-trash mx-2"></i>
								Delete
							</a>
						) : null}
					</div>
				</div>
				<Collapse isOpen={isOpen}>
					{/**backOfficeApi + item.image */}
					<div
						className="row pb-3 px-5 our-border py-0  bg-white"
						style={{ marginLeft: "44px" }}
					>
						<div className="col-md-4 ">
							<figure
								className={`w-75 mb-0 mt-2 ${
									errors[`image${index}`]?.required
										? "border border-danger"
										: ""
								}
							 `}
							>
								<img
									src={
										item?.displayImg
											? item?.displayImg
											: item?.image
											? backOfficeApi + item?.image
											: dumyImg
									}
									//src={item?.image ? backOfficeApi + item?.image : dumyImg}
									alt=""
									className="w-100"
									width="330"
									height="170"
								/>
							</figure>
							{!item.image && !item.displayImg ? (
								<div className=" shape-border mt-1 w-75 border-0">
									<button className="file-btn text-success w-100 mt-2">
										<i class="fas fa-cloud-upload-alt"></i> Update Image
										<input
											type="file"
											className="custom-file"
											accept="image/png, image/gif, image/jpeg"
											onChange={(e) => {
												//handleItems(e.target.files[0], index, "image");
												handleItems(
													URL.createObjectURL(e.target.files[0]),
													index,
													"displayImg"
												);

												handleImageItem(e.target.files[0], index, "image");
											}}
										/>
									</button>
								</div>
							) : (
								<button
									className=" text-danger delete-file-buton mt-1 w-75 mt-2"
									onClick={() => deleteImageItem(index, "image")}
								>
									<i class="fas fa-trash"></i> Delete Image
								</button>
							)}
						</div>
						<div className="col-md-8">
							<div className="row">
								<div className="col-6">
									<TextField
										type="text"
										label={"Destination Name"}
										placeholder={"Enter Destination Name"}
										value={item.destinationName}
										name={`destinationName${index}`}
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
												}),
											});
											handleItems(e.target.value, index, "destinationName");
										}}
										color={
											errors[`destinationName${index}`]?.required
												? "danger"
												: ""
										}
										errors={errors[`destinationName${index}`]}
									/>
								</div>
								<div className="col-6  price_start_ads_field">
									<TextField
										type="number"
										label={"Price Start From"}
										placeholder={"Enter Price Start From"}
										value={item.priceStartFrom}
										name={`priceStartFrom${index}`}
										extraTextPosition="append"
										extraText={"SAR / Night"}
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
													floatNumber: true,
												}),
											});
											handleItems(e.target.value, index, "priceStartFrom");
										}}
										color={
											errors[`priceStartFrom${index}`]?.required ||
											errors[`priceStartFrom${index}`]?.floatNumber
												? "danger"
												: ""
										}
										errors={errors[`priceStartFrom${index}`]}
									/>
								</div>
								<div className="col-12">
									<TextField
										type="text"
										label={"Ad Link"}
										placeholder={"Enter Ad Link"}
										value={item.adsLink}
										name={`adsLink${index}`}
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
												}),
											});
											handleItems(e.target.value, index, "adsLink");
										}}
										color={errors[`adsLink${index}`]?.required ? "danger" : ""}
										errors={errors[`adsLink${index}`]}
									/>
								</div>
							</div>
						</div>
					</div>
				</Collapse>
			</div>
		</div>
	);
}
