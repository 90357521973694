import Pagination from "components/shared/Pagination";
import { useState } from "react";
import Locale from "translations";

export default function Financial({ finacial }) {
	const [meta, setMeta] = useState();
	const [page, setPage] = useState(0);
	const { backOffice, booking } = Locale;
	const goTo = (page) => {
		setPage(page);
	};

	return (
		<div className="mt-2">
			<div className=" table-responsive">
				<table className="custom-table back-office-table table table-striped border">
					<thead>
						<tr className="text-nowrap">
							<th>{booking.Provider}</th>
							<th>{booking.Providerreference}</th>
							{/* <th >{booking.Servicesummary}</th> */}
							<th>{booking.Providercurrency}</th>
							<th>{booking.Providernetprice}</th>
							<th>{booking.ProviderFee}</th>
							<th>{booking.Extramarkup}</th>
							<th>{booking.Totalpriceforuser}</th>

							<th>{"Safa Amount"}</th>
							<th>{"Vendor Amount"}</th>
						</tr>
					</thead>

					<tbody>
						{finacial !== undefined ? (
							<tr>
								<td>{finacial?.provider_name}</td>
								<td>{finacial?.provider_reference}</td>
								{/* <td>{finacial?.service}</td> */}
								<td>{finacial?.currency}</td>
								<td>
									{finacial?.provider_price.toFixed(2)} {finacial?.currency}
								</td>
								<td>
									{finacial?.provider_fee} {finacial?.currency}
								</td>
								<td>
									{finacial?.extra_mark_up} {finacial?.currency}
								</td>
								<td>
									{finacial?.total_price} {finacial?.currency}
								</td>

								<td>
									{finacial?.safa_amount?.toFixed(2)} {finacial?.currency}
								</td>
								<td>
									{finacial?.vendor_amount?.toFixed(2)} {finacial?.currency}
								</td>
							</tr>
						) : (
							<tr>
								<td colSpan="15">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				<div className="px-2">
					<Pagination info={meta} goTo={goTo} />
				</div>
			</div>
		</div>
	);
}
