import axios from "axios";
import { cleanEmpty } from "./general";
const backOfficeApi = process.env.REACT_APP_API_URL + "/api";

export const fetchAllAds = async (id, params = {}, page = 1) => {
	return await axios
		.get(
			`${backOfficeApi}/marketplace/ads/countries/get-all-ad-info/${id}?page=${page}`,
			{
				params: cleanEmpty(params),
			}
		)
		.then((res) => res)
		.catch((res) => res);
};
export const publishAds = async (data) => {
	return await axios
		.post(`${backOfficeApi}/marketplace/ads/countries/publish-ad-info`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listCountries = async (params) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/ads/countries`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const createCountry = async (data) => {
	return await axios
		.post(`${backOfficeApi}/marketplace/ads/countries/create`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const createAds = async (data) => {
	return await axios
		.post(`${backOfficeApi}/marketplace/ads/countries/create-ad-info`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateAds = async (id, data) => {
	return await axios
		.post(`${backOfficeApi}/marketplace/ads/countries/edit-ad-info/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewOneAd = async (id) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/ads/countries/view-ad-info/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteAds = async (id_country, id_ads) => {
	return await axios
		.delete(
			`${backOfficeApi}/marketplace/ads/countries/${id_country}/delete-ad-info/${id_ads}`
		)
		.then((res) => res)
		.catch((err) => err.response);
};
