import { useGlobalDispatch, useGlobalState } from "context/global";
import { usePropertyDispatch, usePropertyState } from "context/property";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { getProperty, verifyProperty } from "services/property";
import Locale from "translations";
import Amenities from "./Amenities";
import BasicInfo from "./BasicInfo";
import Photos from "./Photos";
import Polices from "./Polices";
import Rooms from "./Rooms";
import Services from "./Services";
import "./style.scss";

function Property() {
  const { state } = useLocation();

  const { step, current } = useGlobalState();

  const { id } = useParams();

  const dispatch = usePropertyDispatch();

  const dispatchGlobal = useGlobalDispatch();

  const { propertyDetails } = usePropertyState();

  const { property } = Locale;
  const [activeTab, setActiveTab] = useState(step);
  const [statusNum, setStatusNum] = useState(null);
  const history = useHistory();

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      dispatchGlobal({ type: "progress", current: tab, step: step });
    }
  };

  const calculateStep = (progress) => {
    // switch (Math.floor(progress)) {
    //   case 16:
    //     return "2";
    //   case 33:
    //     return "3";
    //   case 50:
    //     return "4";
    //   case 66:
    //     return "5";
    //   case 83:
    //     return "6";
    //   default:
    return "1";
    // }
  };

  const verify = async (value) => {
    const res = await verifyProperty(id, { value: value });
    if (res.status === 200) {
      store.addNotification({
        title: "Done!",
        message: "res.data",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true,
          pauseOnHover: true
        },
        onRemoval: () => {
          history.push(`/property-management?page=1`);
        },
      });
    }
  };

  useEffect(() => {
    async function fetchProperty() {
      const res = await getProperty(id);
      if (res.status === 200) {
        dispatch({ type: "propertyDetails", payload: res.data });
        dispatchGlobal({
          type: "progress",
          progress: Math.floor(res.data.registeration_progress),
          step: calculateStep(res.data.registeration_progress),
          current: calculateStep(res.data.registeration_progress)
        });
        setStatusNum(res.data.status_num);
      }
    }
    if (id) {
      fetchProperty();
    } else {
      dispatchGlobal({
        type: "progress",
        progress: 0,
        step: "1",
        current: "1"
      });
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (current) {
      setActiveTab(current);
    } else {
      setActiveTab(step);
    }
  }, [current, step]);

  //
  return (
    <div className="property">
      <div className="text-white mb-3 title-hotel mx-5">
        <div className="container">
          <div className="row justify-content-between ">
            <p className="pl-5 h5 font-weight-bold">{propertyDetails?.property?.name}</p>
            <p className={
              propertyDetails?.status === "Active" || (propertyDetails?.share_status == true && propertyDetails?.status == "Shared")
                ? "text-success"
                : propertyDetails?.status === "In Verify"
                  ? "text-warning"
                  : propertyDetails?.status === "In Progress"
                    ? "text-info "
                    : "text-danger s"
            }><span className="text-muted ">{'Current Status: '}</span>{propertyDetails?.share_status == true && propertyDetails?.status == "Shared" ? "Shared" : "Not Shared"}</p>
          </div>
        </div>
      </div>
      <div className="mx-5">
        <div className="text-black">
          <div className="d-flex route-block">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${activeTab === "1" ? "is-active" : ""} ${+step > 1 ? "done" : ""
                    }`}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  {property.basicInfo}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === "2" ? "is-active" : ""}  ${+step > 2 ? "done " : ""
                    } `}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  {property.services}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === "3" ? "is-active" : ""}  ${+step > 3 ? "done " : ""
                    } `}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  {property.rooms}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === "4" ? "is-active" : ""} ${+step > 4 ? "done " : ""
                    }`}
                  onClick={() => {
                    toggle("4");
                  }}
                >
                  {property.amenities}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === "5" ? "is-active" : ""} ${+step > 5 ? "done " : ""
                    }`}
                  onClick={() => {
                    toggle("5");
                  }}
                >
                  {property.photos}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === "6" ? "is-active" : ""} ${+step > 6 ? "done " : ""
                    }`}
                  onClick={() => {
                    toggle("6");
                  }}
                >
                  {property.polices}
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </div>
      <div className="room-row mt-3">
        {/* <fieldset disabled={true}> */}
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <BasicInfo statusNum={statusNum} />
          </TabPane>
          <TabPane tabId="2">
            <Services statusNum={statusNum} />
          </TabPane>
          <TabPane tabId="3">
            <Rooms statusNum={statusNum} />
          </TabPane>
          <TabPane tabId="4">
            <Amenities statusNum={statusNum} />
          </TabPane>
          <TabPane tabId="5">
            <Photos statusNum={statusNum} />
          </TabPane>
          <TabPane tabId="6">
            <Polices statusNum={statusNum} />
          </TabPane>
        </TabContent>
        {/* </fieldset> */}
        {propertyDetails?.status === "Pending to verify" ? (
          <div className="row my-5 mx-0 justify-content-end">
            <button
              className="btn btn-success px-5"
              onClick={() => verify(5)}
              id="verify"
            >
              Verify
            </button>
            {/* <button
              className="btn btn-danger px-5 mx-3"
               onClick={() => verify(4)}
              id="verify"
            >
              Reject
            </button> */}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Property;
