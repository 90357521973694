import walletAdd from "assets/images/wallet/wallet-add.svg";
import { Modal, ModalBody } from "reactstrap";
import Locale from "translations";

export default function ViewTransactionPopup({ isOpen, toggle, popupInfo }) {
	const { wallet } = Locale;

	console.log("popupInfo101", popupInfo);
	// useEffect(() => {
	// 	let res;
	// 	if (isOpen) {
	// 		const fetchInfo = async () => {
	// 			if (popupInfo?.type === "request") {
	// 				res = await showRequests(popupInfo.deal_id, {
	// 					company_id,
	// 					payment_card_id: popupInfo.wallet_id,
	// 				});
	// 			} else {
	// 				res = await showTransaction(popupInfo.deal_id, {
	// 					company_id,
	// 					payment_card_id: popupInfo.wallet_id,
	// 				});
	// 			}
	// 			if (res?.status === 200) {
	// 				const resReceipt = await getReceipt(res?.data?.data?.photo);
	// 				setDeal({ ...res.data.data, resReceipt: resReceipt.config.url });
	// 			}
	// 		};
	// 		fetchInfo();
	// 	}
	// }, [isOpen]);

	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<div className="lan d-flex justify-content-between align-items-baseline p-2 border-bottom vendor-wallet-view-popup ">
				<p className=" text-secondary font-weight-bold">
					{wallet.ViewTransactions}
				</p>
				<i
					className="far fa-times-circle fa-lg text-secondary pointer"
					onClick={toggle}
				></i>
			</div>

			<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3">
				<img src={walletAdd} alt="" />
				<div className="row m-0 p-0 mt-4 justify-content-center  align-items-center">
					<p className="col-5  font-weight-bold ">{wallet.receiptNumber} </p>
					<p className="col-5">
						{popupInfo?.transaction_number || popupInfo?.id?.transaction_number}
					</p>
					{/* <p className="col-5  font-weight-bold ">{wallet.company_name}</p> */}

					{/* <p className="col-5   ">
						{popupInfo?.company_name ? popupInfo?.company_name : "-"}
					</p> */}
					<p className="col-5  font-weight-bold ">{wallet.requestDate}</p>
					<p className="col-5   ">
						{popupInfo?.created_at?.slice(0, 10) ||
							popupInfo?.id?.created_at?.slice(0, 10)}{" "}
						{popupInfo?.created_at?.slice(11, 16) ||
							popupInfo?.id?.created_at?.slice(0, 10)}
					</p>
					<p className="col-5  font-weight-bold ">{wallet.amount}</p>
					<p className="col-5">
						{popupInfo?.amount || popupInfo?.id?.amount}{" "}
						{popupInfo?.deal_id || popupInfo?.currency}
					</p>
					{/* <p className="col-5  font-weight-bold ">Deposit Photo</p>
					<figure className="col-5">
						-
					</figure> */}
				</div>
			</ModalBody>
		</Modal>
	);
}
