import Pagination from "components/shared/Pagination";
import { useGlobalDispatch } from "context/global";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import Locale from "translations";
const locale = localStorage.getItem("currentLocale") || "en";

export default function CompaniesTable({ companies, goTo, meta }) {
	const dispatch = useGlobalDispatch();
	const history = useHistory();
	const { backOffice, translate_companies } = Locale;

	const calculateStep = (progress) => {
		// switch (Math.floor(progress)) {
		//   case 16:
		//     return "2";
		//   case 33:
		//     return "3";
		//   case 50:
		//     return "4";
		//   case 66:
		//     return "5";
		//   case 83:
		//     return "6";
		//   default:
		return "1";
		// }
	};

	const AllProducts = companies?.map((company) => (
		<tr className="bg-white text-black" key={company.id}>
			<td>{company.name}</td>
			<td>{company.created_at}</td>
			<td
				className={
					company.status.name === "active"
						? "text-success"
						: company.status.name === "In Verify"
						? "text-warning"
						: company.status.name === "In Progress"
						? "text-info"
						: "text-danger"
				}
			>
				{company.status.name}
			</td>

			<td className="d-flex justify-content-start">
				<NavLink
					exact={true}
					to={`/companies/edit/${company.id}`}
					activeClassName="is-active"
					className="p-2 text-primary  btn-link CompanyActions-link"
				>
					<i class="fas fa-edit mx-1"></i>
					{translate_companies.edit}
				</NavLink>

				<NavLink
					exact={true}
					to={`/companies/${company.id}/wallet`}
					activeClassName="is-active"
					className="p-2 text-primary  btn-link CompanyActions-link"
				>
					<i class="fas fa-wallet mx-1"></i>
					{translate_companies.wallet}
				</NavLink>

				<NavLink
					exact={true}
					to={`/companies/orders/${company.id}/in-progress`}
					activeClassName="is-active"
					className=" p-2 text-primary btn-link CompanyActions-link"
				>
					<i className="fas fa-th-list"></i>
					{backOffice.orders}
				</NavLink>
			</td>
		</tr>
	));

	return (
		<>
			<table className="custom-table back-office-table table table-striped">
				<thead>
					<tr>
						<th>{backOffice.companyName}</th>
						<th>{backOffice.createdDate}</th>
						<th>{backOffice.status}</th>
						<th>{backOffice.tools}</th>
					</tr>
				</thead>
				<tbody>
					{companies?.length > 0 ? (
						AllProducts
					) : (
						<tr>
							<td colSpan="11">
								<div className="product-no-data">
									<i className="fas fa-info-circle fa-lg"></i>{" "}
									<h4>{backOffice.noResult}</h4>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
			{companies?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}
		</>
	);
}
