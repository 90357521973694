import Model from "components/model";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { deleteCommission } from "services/platform";
import Locale from "translations";
import PlatformCommissionInfo from "./PlatformCommissionInfo";
import PlatformCommissionSelector from "./PlatformCommissionSelector";

export default function Commission({
	setCommissionsList,
	commissionsList,
	errors,
	setErrors,
	countries,
	season,
}) {
	const { backOffice } = Locale;
	const { id } = useParams();
	let locale = localStorage.getItem("currentLocale") || "en";

	const [modelCommissions, setModelCommissions] = useState(null);
	const [commissionsSelect, setCommissionsSelect] = useState(null);
	const [commissionsSelectIndex, setCommissionsSelectIndex] = useState(null);
	const [selectorsSelected, setSelectorsSelected] = useState(null);
	const [viewSelectorsModal, setViewSelectorsModal] = useState({
		isOpen: false,
		type: "",
		title: "",
	});
	const [modal, setModal] = useState({
		isOpen: false,
		type: "",
		title: "",
	});

	const openViewSelectorsModalHandler = (Selectors) => {
		setViewSelectorsModal({
			isOpen: !viewSelectorsModal.isOpen,
			type: "modal",
			title: "View Selectors",
		});
		setSelectorsSelected(Selectors);
	};

	const openCommissionModalHandler = (Model, Commissions, Index) => {
		setModal({
			isOpen: !modal.isOpen,
			type: "modal",
			title: "Add Commission",
		});
		setModelCommissions(Model);
		setCommissionsSelect(Commissions);
		setCommissionsSelectIndex(Index);
	};

	const addCommission = () => {
		setModal({
			isOpen: !modal.isOpen,
			type: "modal",
			title: "Add Commission",
		});
	};

	const removeItem = async (item, index) => {
		if (id && item.id) {
			const res = await deleteCommission(id, item.id);
			console.log(res);
			if (res.status === 200) {
				let clonedCommissions = [...commissionsList];
				clonedCommissions.splice(index, 1);
				setCommissionsList(clonedCommissions);
				//setCommissionsList([...CommissionsList]);
			}
		} else {
			let clonedCommissions = [...commissionsList];
			clonedCommissions.splice(index, 1);
			setCommissionsList(clonedCommissions);
		}
	};

	return (
		<div className="row custom--row">
			<div className="col">
				<div className="personal-data">
					<div className="col-md-12 head-manager p-0">
						<p>{backOffice.commissions}</p>
					</div>

					{commissionsList.length > 0 ? (
						<div className="col our-border px-4">
							<table className="custom-table back-office-table table table-striped">
								<thead>
									<tr>
										<th>{backOffice.name}</th>
										<th>{backOffice.type}</th>
										<th>{backOffice.selectors}</th>
										<th>{backOffice.commission}</th>
										<th>{backOffice.safaCommission}</th>
										<th>{backOffice.platformCommission}</th>
										<th>&nbsp;</th>
									</tr>
								</thead>
								<tbody>
									{commissionsList.map((item, index) => (
										<tr key={item.id}>
											<td>{id ? item["name_" + locale] : item?.name?.en} </td>
											<td>{item.type}</td>
											<td>
												<button
													className="btn btn-link"
													id={"selector" + index}
													onClick={(Selectors) =>
														openViewSelectorsModalHandler(item.selectors)
													}
												>
													<i className="fas fa-eye"></i>{" "}
													{Object.keys(item.selectors).length}{" "}
													{backOffice.selectors}
												</button>
											</td>
											<td>
												{item.base_commission}{" "}
												{item.type == "base" ? "%" : backOffice.SAR}
											</td>
											<td>{item.safa_commission}%</td>
											<td>{item.platform_commission}%</td>
											<td>
												<button
													className="btn btn-link mr-2"
													onClick={(Model, Commissions, Index) =>
														openCommissionModalHandler("edit", item, index)
													}
													title={backOffice.edit}
												>
													<i className="fas fa-edit"></i>{" "}
												</button>
												<a
													href={() => false}
													className="d-inline-block text-danger"
													onClick={() => removeItem(item, index)}
												>
													<i className="fas fa-trash"></i>
												</a>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : null}

					<a
						className="btn btn-lg text-success d-inline-block my-2"
						href={() => false}
						onClick={() => openCommissionModalHandler("add", null, null)}
					>
						<i className="fas fa-plus-circle"></i> {backOffice.addCommission}
					</a>
				</div>
			</div>
			{/* edit or add modal */}
			<Model
				isOpen={modal.isOpen}
				type={modal.type}
				title={backOffice.addCommission}
				toggle={openCommissionModalHandler}
				size="lg"
				hasFooter={false}
			>
				<div className="w-100 p-4">
					<PlatformCommissionInfo
						CommissionsList={commissionsList}
						SetCommissionsList={setCommissionsList}
						CommissionsSelect={commissionsSelect}
						CommissionsSelectIndex={commissionsSelectIndex}
						errors={errors}
						PlatformID={id ? id : null}
						ModelCommissions={modelCommissions}
						setErrors={setErrors}
						addCommission={addCommission}
						countries={countries}
						AllSeasons={season}
					/>
				</div>
			</Model>
			{/* view selector modal */}
			<Model
				isOpen={viewSelectorsModal.isOpen}
				type={viewSelectorsModal.type}
				title={backOffice.selectors}
				toggle={openViewSelectorsModalHandler}
				size="lg"
				hasFooter={false}
			>
				<div className="w-100 p-4">
					<PlatformCommissionSelector
						selectorsSelected={selectorsSelected}
						countries={countries}
						season={season}
					/>
				</div>
			</Model>
		</div>
	);
}
