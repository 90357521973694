import SafaLogo from "assets/images/safa_logo.png";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
// import UserSettings from "components/UserSetting";
import { useGlobalState } from "context/global";
// import Dashboard from "modules/dashboard/Dashboard/Dashboard";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

export default function Header(props) {
  const history = useHistory();
  const [show, setShow] = useState(false);

  const { isAuth } = useGlobalState();

  const showNav = {
    position: "fixed",
    width: "100%",
    zIndex: "9999999",
  };

  return (
    <>
      <div className="navbar bg-white shadow-sm" style={show ? showNav : null}>
        <Link
          className="navbar-brand"
          onClick={() => {
            show && setShow(false);
            history.push("/");
          }}
          // to="/"
        >
          <img className="img-fluid" src={SafaLogo} alt="Logo" width="70%" />
        </Link>

        {/* <div className="dropdowns d-flex align-items-center">
					{isAuth ? <UserSettings /> : null}
					<LanguageSwitcher className="bg-white" />
				</div> */}
      </div>
    </>
  );
}
