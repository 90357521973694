import { useEffect, useState } from "react";
import { listGroups } from "services/companies";

export const LooksupGroups = (id) => {
  const [Groups, setGroups] = useState([]);

  useEffect(() => {
    async function fetchLookups() {
      const res = await listGroups(id ,{});
      const formatted = res.data.data.map((group) => ({
        value: group.id,
        label: group.name,
      }));
      setGroups(formatted);
    }
    fetchLookups();
  }, []);

  return Groups;
};
export default LooksupGroups;
