import Pagination from "components/shared/Pagination";
import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FetchTransaction } from "services/wallet";
import Locale from "translations";

export default function OneWallet({
	Wallet,
	toggle,
	isOpenView,
	toggleView,
	viewMore,
	reload,
}) {
	const { wallet } = Locale;

	const [transactions, setTransactions] = useState([]);
	const [meta, setMeta] = useState(null);
	const { id } = useParams();
	const [filter, setFilter] = useState({
		search: undefined,
		name: undefined,
		page: 1,
	});
	useEffect(() => {
		const getDeals = async () => {
			const res = await FetchTransaction(Wallet.id, { ...filter });
			if (res?.status === 200) {
				setTransactions(res.data.data);
				setMeta(res?.data);
			}
		};
		getDeals();
	}, [filter.page, reload]);

	const goTo = (page) => {
		setFilter({ ...filter, page: +page });
	};

	const allTransactions = useMemo(() => {
		return transactions?.length > 0
			? transactions.map((transaction) => (
					<tr>
						<td width={"20%"}>{transaction.transaction_number}</td>
						<td>{transaction.transaction_type}</td>
						<td>{transaction.action_type}</td>
						<td>
							{transaction.current_balance} {Wallet.currency}
						</td>
						<td>
							{transaction.prev_balance}
							{Wallet.currency}
						</td>
						<td>
							{transaction.amount} {Wallet.currency}
						</td>
						<td>
							<i
								class="fas fa-eye pointer text-primary"
								onClick={() => toggleView(transaction, Wallet.currency)}
							></i>
						</td>
					</tr>

					// <Transaction
					// 	key={transaction.id}
					// 	transaction={transaction}
					// 	isOpenView={isOpenView}
					// 	toggleView={toggleView}
					// 	currency={Wallet?.currency}
					// />
			  ))
			: null;
	}, [transactions]);

	return (
		<div
			className={` ${Wallet?.type === "credit" ? "mt-4" : "mt-4"} ${
				viewMore ? "collapse-border" : ""
			}`}
		>
			<div className="row p-0 m-0 justify-content-between bg-white">
				<div className="col-2 bg-wallet-name font-weight-bolder d-flex align-items-center justify-content-center ">
					{Wallet?.type === "credit"
						? "Safa Wallet"
						: Wallet?.currency + " Wallet"}
				</div>

				{/* <div className="col-2 p-2">
					<p className="m-0 font-weight-bolder"> {wallet.AccountNo}</p>
					<p className="m-0"> {Wallet.ref_num ? Wallet.ref_num : "-"}</p>
				</div> */}

				<div className="col-2 p-2">
					<p className="m-0 font-weight-bolder">
						{Wallet?.type === "credit" ? wallet.CreditLeftToUse : wallet.amount}
					</p>
					<p className="m-0">
						{Wallet?.amount} {Wallet?.currency}
					</p>
				</div>

				{Wallet?.type === "credit" ? (
					<div className="col-2 p-2">
						<p className="m-0 font-weight-bolder">{wallet.TotalLimit}</p>
						<p className="m-0">
							{Wallet.limit} {Wallet?.currency}
						</p>
					</div>
				) : null}

				<div
					className={`${
						Wallet?.type === "credit" ? "col-3" : "col-2"
					} d-flex align-items-center`}
				>
					{/* {Wallet?.type === "credit" ? null : (
						<i class="fas fa-trash-alt mx-3 trash"></i>
					)} */}
				</div>
			</div>

			<div className="border-top bg-white">
				<div className=" table-responsive px-4 our-border">
					<table className="custom-table back-office-table table ">
						<thead>
							<tr>
								<th>{wallet.TransactionsID}</th>
								<th>{wallet.Type}</th>
								<th>{wallet.Method}</th>
								<th>{wallet.currentBalance}</th>
								<th>{wallet.previousBalance}</th>
								<th>{wallet.amount}</th>
								<th></th>
							</tr>
						</thead>

						<tbody>
							{viewMore ? allTransactions?.slice(0, 5) : allTransactions}
						</tbody>
					</table>
					{/* <div className="px-2">
						<Pagination info={meta} goTo={goTo} />
					</div> */}
				</div>
				{viewMore && transactions?.length > 5 ? (
					<div className="d-flex justify-content-end">
						<div className="col-2 my-2">
							<Link
								type="button"
								class="btn btn-success w-75"
								to={{
									pathname: `/wallet0/${Wallet.id}`,
									state: { wallet: Wallet },
								}}
							>
								{wallet.ViewMore}
							</Link>
						</div>
					</div>
				) : null}
			</div>
			{viewMore === false ? (
				<Pagination info={meta} goTo={goTo} items={10} />
			) : null}
		</div>
	);
}
