import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import Locale from "translations";
import AddEditHotelContent from "../Components/HotelCommissions/AddEditCommission";

export default function AddEditHotel() {
	const history = useHistory();
	const { backOffice } = Locale;
	const { id } = useParams();

	return (
		<>
			<div className="d-flex justify-content-between align-items-center">
				<h6 className=" font-weight-bold  h4">
					{id ? backOffice.editHotel : backOffice.addNewHotel}
				</h6>
			</div>
			<AddEditHotelContent isEditMode={id ? true : false} />
		</>
	);
}
