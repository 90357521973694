import Model from "components/model";
import { usePropertyState } from "context/property";
import React, { useEffect, useState } from "react";
import { Button, ModalFooter } from "react-bootstrap";
import { store } from "react-notifications-component";
import { useParams } from "react-router";
import { DeletePhoto, deletePhoto, EditPhoto, editPhoto } from "services/property";

function Photo({ photo, gallery, setGallery }) {
  const { id } = useParams();
  // const dispatch = useGlobalDispatch();
  const { propertyDetails } = usePropertyState();

  // const [photo, setPhoto] = useState([]);
  const [checked, setChecked] = useState(false);
  const [rooms, setRooms] = useState([]);

  const [modal, setModal] = useState({
    isOpen: false,
    type: "",
    title: ""
  });

  const toggle = () => {
    setModal({
      isOpen: !modal.isOpen,
      type: "gallery",
      title: "Edit Photo"
    });
  };

  const removePhoto = async (photoId) => {
    if (photo.uploaded) {
      const res = await DeletePhoto(id, {
        photo_id: photoId,
        _method: "delete"
      });
      if (res.status === 200) {
        setGallery(gallery.filter((img) => img.id !== photoId));
        store.addNotification({
          title: "info!",
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true
          }
        });
      }
    } else {
      setGallery([...gallery].filter((photo) => photo.id !== photoId));
    }
  };

  const handleCheckbox = (event) => {
    if (event.target.checked === true) {
      setRooms([...rooms, Number(event.target.value)]);
    } else {
      setRooms([...rooms.filter((e) => e !== Number(event.target.value))]);
    }
  };

  const editRoomPhotos = async () => {
    const data = {
      photo_id: photo.id,
      rooms,
      _method: "put"
    };
    if (rooms.length > 0) {
    const res = await EditPhoto(id, data);
    if (res.status === 200 || res.status === 201) {
      store.addNotification({
        title: "info!",
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true
        }
      });
    }
  }
  };

  // useEffect(() => {
  //   if (rooms.length > 0) {
  //     editRoomPhotos();
  //   }
  // }, [rooms]);

  //

  return (
    <div>
      <Model
        isOpen={modal.isOpen}
        toggle={() => toggle(photo)}
        type={modal.type}
        title={modal.title}
        size="lg"
        className="customModal"
        hasFooter={false}
      >
        <div className="d-flex justify-content-between w-100">
          <div className="data-gallery col-md-4">
            <div className="form-group form-check">
              <input
                type="checkbox"
                disabled={false}
                className="form-check-input"
                onChange={(e) => {
                  setChecked(!checked);
                }}
                checked={checked ? true : false}
                id="included"
              />
              <label
                className="form-check-label text-caption pointer text-blue-black font-weight-bold"
                for="included"
              >
                Include in your photo gallery
              </label>
              <p className="text-caption3">
                This will Also Be Included in Your Property Page
              </p>
            </div>

            <div>
              <p className="text-capitalize text-caption font-weight-bold">
                Indicate Of This Is A Room Photo:
              </p>
              {propertyDetails?.property?.rooms?.map((room, i) => {
                return (
                  <div className="form-group form-check" key={room.id}>
                    <input
                      type="checkbox"
                      disabled={false}
                      className="form-check-input"
                      onChange={handleCheckbox}
                      value={room.id}
                      defaultChecked={rooms.includes(room.id) ? true : false}
                      id="room1"
                    />
                    <label
                      className="form-check-label text-caption3 pointer"
                      htmlFor="room1"
                    >
                      {room.custom_name || room.name.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="photo-gallery text-center col-md-8">
            <img src={photo.image} alt="gallery" className="img-fluid" />
            {/* <div
              className="py-2 text-center bg-trash"
              onClick={() => removePhoto(photo.id)}
            >
              <i className="fa fa-trash text-white pointer"></i>
            </div> */}
          </div>
         
        </div>
        <ModalFooter>
            <Button
              color="primary"
             onClick={()=>{
              editRoomPhotos();
              setTimeout(() => {
                toggle();                
              }, 1500);
            }}
              //disabled={props.disabled}
            >
              Edit
            </Button>
            <Button color="secondary" onClick={toggle}>
              cancel
            </Button>
          </ModalFooter>
      </Model>

      <div className="gallery-item">
        <img src={photo.image} alt="gallery" className="img-fluid" />
        <ul className="bg-white d-flex justify-content-center py-1">
          {photo.uploaded && (
            <li
              className="mx-2 text-blue-black pointer"
              onClick={toggle}
              id="edit-photo"
            >
              <i className="fa fa-eye fa-fw"></i>
              View
            </li>
          )}
          <li
            className="mx-2 text-danger pointer"
            onClick={() => removePhoto(photo.id)}
            id="delete-photo"
          >
            <i className="fa fa-trash fa-fw"></i>
            Delete
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Photo;
