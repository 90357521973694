import resetIcon from "assets/images/wallet/resetIcon.png";
import TextField from "components/Form/TextField/TextField";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import { useState } from "react";
import Locale from "translations";
export default function InvitationFilter(props) {
	const { backOffice } = Locale;

	const [filterState, setFilterState] = useState({
		search: "",
		date: undefined,
		country: "",
	});

	const resetFilters = () => {
		setFilterState({
			search: "",
			date: "",
			status: "",
		});
	};

	const Status = [
		{
			value: 1,
			label: "IN_PROGRESS",
		},
		{
			value: 2,
			label: "COMPLETED ",
		},
		{
			value: 3,
			label: "IN_VERIFY",
		},
		{
			value: 4,
			label: "REJECTED ",
		},
		{
			value: 5,
			label: "ACTIVE",
		},
		{
			value: 6,
			label: "BOOKED",
		},
		{
			value: 7,
			label: "CLOSED",
		},
	];

	return (
		<>
			<>
				<div className="main-filter bg-white-blue py-2 my-2">
					<div className="row align-items-center w-100 no-gutter m-0">
						<div className="col-md-2">
							<div className="main-label">
								<TextField
									type="text"
									placeholder={backOffice.search}
									hasLabel={false}
									value={filterState.search}
									onChange={(e) => {
										setFilterState({
											...filterState,
											search: e.target.value,
										});
									}}
								/>
								<i className="fas fa-search fa-fw"></i>
							</div>
						</div>

						<div className="col-md-2">
							<DatePickerField
								hasLabel={false}
								date={filterState.date}
								placeholder={backOffice.date}
								onChangeDate={(filterDate) => {
									setFilterState({
										...filterState,
										date: filterDate,
									});
								}}
								isOutsideRange={(day) => {
									return false;
								}}
							/>
						</div>

						<div className="col-md-2">
							<SelectField
								hasLabel={false}
								placeholder={backOffice.status}
								name="country"
								options={Status}
								onChange={(e) => {
									setFilterState({
										...filterState,
										status: e,
									});
								}}
								value={filterState.status}
							/>
						</div>

						<div className="col-md" onClick={resetFilters}>
							<div
								onClick={props.onReset}
								className="d-flex align-items-center text-secondary pointer mt-4 reset-btn"
							>
								<img src={resetIcon} alt="" srcset="" />
								{/*                 <i className="fas fa-retweet position-static px-1 text-secondary text-caption"></i>
								 */}{" "}
								<p className="text-caption">{backOffice.resetFilter}</p>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	);
}
