import noHotel from "assets/images/placeHoleder.png";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Locale from "translations";

import "react-image-gallery/styles/css/image-gallery.css";
import Carousel from "react-spring-3d-carousel";

export default function BookingDetails({ details }) {
	const { company_id, reservation_id } = useParams();

	const { marketPlace, backOffice } = Locale;
	const state = { tab: "hotel" };
	const roomType = (status) => {
		switch (status) {
			case 1:
				return backOffice.single;
			case 2:
				return backOffice.dauble;
			case 3:
				return backOffice.triple;
			case 4:
				return backOffice.quadruple;
			default:
				return "Rooms";
		}
	};
	const [Silders, setSilders] = useState([]);
	const [active, setActive] = useState(0);

	useEffect(() => {
		let imagesList =
			details.hotel_data.images && details.hotel_data.images.length > 0
				? details.hotel_data.images.map((res, index) => {
						return {
							key: index + "uuid.v4(2)",
							content: (
								<img
									className="bg-white"
									src={res}
									alt={index + 1}
									onError={(e) => {
										e.target.onerror = null;
										e.target.src = noHotel;
									}}
								/>
							),
							onClick: () => setActive(index),
						};
				  })
				: null;
		setSilders(imagesList);
	}, []);
	return (
		<>
			<section className="marketplace-details px-2">
				<div className="">
					{/* <!-- start transport slider --> */}
					<div className="transport-slider-container px-0">
						<div className="slider-headers mb-2">
							<h5 className="font-weight-bold text-headline">
								{state.tab === "hotel"
									? details.hotel_data.name
									: details.transporter_data.name}
							</h5>
							<div className="d-flex">
								<p>
									{state.tab === "hotel"
										? details.hotel_data.address
										: details.transporter_data.address}
								</p>
								<span className="px-2">|</span>
								<p>
									{state.tab === "hotel"
										? details.hotel_data.distance + " m from Haram"
										: null}
								</p>
							</div>

							<ul className="list-unstyled d-flex">
								{state.tab === "hotel"
									? [...Array(details.hotel_data.rating).keys()].map((i) => (
											<li>
												<i
													key={i}
													className="fa fa-star"
													aria-hidden="true"
												></i>
											</li>
									  ))
									: null}
							</ul>
						</div>

						{state.tab === "hotel" && details.hotel_data.images.length > 0 ? (
							<div
								style={{
									height: "300px",
									width: "100%",
									overflow: "hidden",
									background: "#fff",
								}}
							>
								<Carousel
									slides={Silders}
									offsetRadius={6}
									goToSlide={active}
								/>
							</div>
						) : null}
						<div className="booking-box">
							<div className="hotel-meals booking-header  p-2 m-0  box-container">
								<h5 className=" font-weight-bold m-0">
									{backOffice.bookingDetails}
								</h5>
							</div>
							<div className="hotel-meals mt-0  p-2 rounded box-container">
								<Row className="m-0 ">
									<Col className="mb-2" sm="3">
										<p className=" text-caption">
											{state.tab === "hotel"
												? `${backOffice.hotelName}`
												: `${backOffice.transportName}`}
										</p>
										<p className="font-weight-bold text-primary text-headline">
											{state.tab === "hotel"
												? details.hotel_name
												: details.transporter_company_name}
										</p>
									</Col>
									{/*Transport*/}
									{state.tab === "transport" ? (
										<Col className="mb-2" sm="2">
											<p className="booking-label text-caption">
												{backOffice.transportType}
											</p>
											<p className="font-weight-bold text-headline">
												{details.trans_type}
											</p>
										</Col>
									) : null}

									{/*Hotel*/}
									{state.tab === "hotel" ? (
										<Col className="mb-2" sm="2">
											<p className="booking-label text-caption">
												{backOffice.roomType}
											</p>
											<p className="font-weight-bold text-headline">
												{state.tab === "hotel"
													? details.room_type
													: details.trans_type}
											</p>
										</Col>
									) : null}

									<Col className="mb-2" sm="2">
										<p className="booking-label text-caption">
											{state.tab === "hotel"
												? backOffice.checkIn
												: backOffice.bookingDate}
										</p>
										<p className="font-weight-bold text-headline">
											{state.tab === "hotel"
												? details.start_date
												: details.created_at}
										</p>
									</Col>
									<Col className="mb-2" sm="2">
										<p className="booking-label text-caption">
											{state.tab === "hotel"
												? backOffice.checkOut
												: backOffice.tripDate}
										</p>
										<p className="font-weight-bold text-headline">
											{state.tab === "hotel"
												? details.end_date
												: details.flight_date}
										</p>
									</Col>
									<Col className="mb-2" sm="2">
										<p className="booking-label text-caption">
											{state.tab === "hotel"
												? backOffice.noOfNights
												: backOffice.busCount}
										</p>
										<p className="font-weight-bold text-headline">
											{state.tab === "hotel"
												? details.nights_count
												: details.trans_count}
										</p>
									</Col>

									<Col className="mb-2" sm="2">
										<p className="booking-label text-caption">
											{backOffice.brn}
										</p>
										<p className="font-weight-bold text-headline">
											{details.brn}
										</p>
									</Col>
									<Col className="mb-2" sm="3">
										<p className="booking-label text-caption">
											{"Reservation Status"}
										</p>
										<p className="font-weight-bold text-headline">
											{details.reservation_status}
										</p>
									</Col>
								</Row>
							</div>
						</div>
					</div>
					{/* <!-- end transport slider --> */}
					{/* <!-- Start OverView --> */}
					<div className="overview booking-box box-container my-2">
						<div className="booking-header">
							<h5 className="px-2  py-2  font-weight-bold  mt-2 mb-2">
								{backOffice.overview}
							</h5>
						</div>
						<p className="mb-1 p-2 bg-white">
							{state.tab === "hotel"
								? details.hotel_data.description
								: details.transporter_data.description}
						</p>
					</div>
					{/* <!-- Start OverView --> */}
					{/* <!--Start hotel Facilities--> */}
					{state.tab === "hotel" ? (
						<div className="hotel-facilities box-container booking-box">
							<div className="booking-header">
								<h5 className="px-2 py-2   font-weight-bold  mt-2 mb-2">
									{backOffice.hotelFacility}
								</h5>
							</div>

							<ul>
								{state.tab === "hotel" ? (
									details.hotel_data.facilities &&
									details.hotel_data.facilities.length > 0 ? (
										details.hotel_data.facilities.map((facility, i) => {
											return (
												<li key={i}>
													<i className="fas fa-check-circle px-1 text-success"></i>
													{facility}
												</li>
											);
										})
									) : (
										<div className="w-100 no-data semiHeight d-flex justify-content-center align-items-center">
											<span>
												{"No Facilities"}
												<i
													className="icx icx-eye-slash "
													aria-hidden="true"
												></i>
											</span>
										</div>
									)
								) : null}
							</ul>
						</div>
					) : null}
					{/* <!--End hotel Facilities--> */}
					{/* <!--Start Payment--> */}
					{state.tab === "hotel" &&
					details.hotel_data.terms_conditions !== null ? (
						<div className="payment mt-4 box-container booking-box">
							<div className="booking-header">
								<h5 className="px-2 py-2 rounded font-weight-bold  mt-2 mb-2">
									Terms And Conditions
								</h5>
							</div>
							<p className="mb-1">{details.hotel_data.terms_conditions}</p>
						</div>
					) : null}

					{/* <!--End Payment--> */}

					<div className="rooms booking-box my-1">
						<div className="booking-header">
							<h6 className="px-2 py-2  rounded font-weight-bold  mt-2 mb-2">
								{backOffice.Rooms}
							</h6>
						</div>
						<div className="rooms-content  box-container">
							<div className="row align-items-center w-100 no-gutter m-0">
								<div className="col-md-2">
									<div class="d-flex align-items-center  bg-room border-radius-sm  py-1 px-2 ">
										<i class="fas fa-bed fa-fw add-bed font-gray-300 "></i>
										<div className="mx-2">
											<h6>{backOffice.ROOMTYPE} </h6>
											<p>{roomType(details?.room_type)}</p>
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<div class="d-flex flex-column  border-radius-sm bg-room  py-1 px-2 mt-2 mb-2">
										<h6>{backOffice.ROOMCOUNT} </h6>
										<p>{details?.rooms_number}</p>
									</div>
								</div>
								<div className="col-md-3">
									<div class="d-flex justify-content-between align-items-center  bg-room border-radius-sm  py-1 px-2 ">
										<div>
											<h6>{backOffice.NIGHTPRICE} </h6>
											<p>
												{Math.floor(
													details?.total_price / details?.nights_count
												)}
											</p>
										</div>
										<small>{backOffice.SAR}</small>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="facilities booking-box my-1">
						<div className="booking-header">
							<h6 className="px-2 py-2  rounded font-weight-bold  mt-2 mb-2">
								{backOffice.Facilities}
							</h6>
						</div>
						<div className="facilities-content  box-container">
							<ul>
								{details?.hotel_data?.facilities &&
								details?.hotel_data?.facilities?.length > 0 ? (
									details?.hotel_data?.facilities.map((item, index) => {
										return (
											<li key={index}>
												<i className="fas fa-check px-1 text-success"></i>
												{item}
											</li>
										);
									})
								) : (
									// <td colSpan="9">
									<div className="w-100 no-data semiHeight d-flex justify-content-center align-items-center">
										<h4>
											{"No Facilities"}
											<i className="icx icx-eye-slash " aria-hidden="true"></i>
										</h4>
									</div>
									// </td>
								)}
							</ul>
						</div>
					</div>
					{/* <!-- Start Payment policies --> */}
					<div className="payment-polices">
						<div className="row m-0">
							{state.tab === "hotel" &&
							details.hotel_data.payment_policy != null ? (
								<div className="col-md-6">
									<h5 className="my-2 text-headline font-weight-bold">
										{backOffice.paymentPolicy}
									</h5>
									<div className="payment-policy">
										<i className="fas fa-money-check fa-fw fa-lg"></i>
										<ul>
											<li>{details.hotel_data.payment_policy}</li>
										</ul>
									</div>
								</div>
							) : null}
							{state.tab === "hotel" &&
							details.hotel_data.cancelation_policy != null ? (
								<div className="col-md-6">
									<h5 className="my-2 text-headline font-weight-bold">
										{backOffice.cancellationPolicy}
									</h5>
									<div className="cancellation-policy">
										<i className="fas fa-info-circle fa-fw fa-lg"></i>
										<ul>
											<li>{details.hotel_data.cancelation_policy !== ""}</li>
										</ul>
									</div>
								</div>
							) : null}
						</div>
					</div>
					{/* <!-- End Payment policies --> */}
					<Link
						to={`/tourism-company/reservations/${company_id}`}
						className="btn btn-primary px-5 my-2"
					>
						{backOffice.back}
					</Link>
				</div>
			</section>
		</>
	);
}
