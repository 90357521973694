import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import TextField from "components/shared/TextField";
import moment from 'moment';
import { useState } from 'react';
import { store } from 'react-notifications-component';
import { useParams } from 'react-router-dom';
import { AddOffer, EditOffer } from "services/platform";
import Locale from "translations";

export default function PlatformOffers({ item, index, changeValueOffer, deleteOfferfn }) {
  const { backOffice } = Locale;
  const [SpecialOffer, setSpecialOffer] = useState({ ...item })
  const { id } = useParams();

  const addOfferItem = async (e, index) => {
    if (id && e.id == null) {
      const res = await AddOffer(id, {
        from_date: moment(e.form).format("YYYY-MM-DD"),
        to_date: moment(e.to).format("YYYY-MM-DD"),
        value: e.offer,
      });
      if (res.status === 200) {
        changeValueOffer(index, "edit", false, { ...e });
        store.addNotification({
          title: "Done!",
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      }
    } else {
      const res = await EditOffer(id, e.id, {
        from_date: moment(e.form).format("YYYY-MM-DD"),
        to_date: moment(e.to).format("YYYY-MM-DD"),
        value: e.offer,
      });
      if (res.status === 200) {
        changeValueOffer(index, "edit", false, { ...SpecialOffer });
        store.addNotification({
          title: "Done!",
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      }
    }
  };

  return (
    <tr key={index}>
      <td style={{ maxWidth: "320px", width: "320px" }}>
        {item.edit ? (
          <>
            <span className="text-black d-block mt-2">{backOffice.From}</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className="my-2 DatePicker-Form maxW320"
                variant="outlined"
                inputVariant="outlined"
                id="date-picker-dialog"
                format="dd/MM/yyyy"
                value={SpecialOffer.form}
                onChange={(e) => {
                  setSpecialOffer({
                    ...SpecialOffer,
                    form: new Date(e),
                  });
                }}
              />
            </MuiPickersUtilsProvider>
          </>
        ) : (
          moment(SpecialOffer.form).format("YYYY-MM-DD")
        )}
      </td>

      <td>
        {item.edit ? (
          <>
            <span className="text-black d-block mt-2">{backOffice.To}</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className="my-2 DatePicker-Form maxW320"
                variant="outlined"
                inputVariant="outlined"
                id="date-picker-dialog"
                format="dd/MM/yyyy"
                value={SpecialOffer.to}
                onChange={(e) => {
                  setSpecialOffer({
                    ...SpecialOffer,
                    to: new Date(e),
                  });
                }}
              />
            </MuiPickersUtilsProvider>
          </>
        ) : (
          moment(SpecialOffer.to).format("YYYY-MM-DD")
        )}
      </td>

      <td>
        {item.edit ? (
          <TextField
            type="text"
            label={backOffice.Offer}
            placeholder={backOffice.Offer}
            name="hotelCountry"
            value={SpecialOffer.offer}
            onChange={(e) => {
              setSpecialOffer({
                ...SpecialOffer,
                offer: e.target.value,
              });
            }}
          />
        ) : (
          SpecialOffer.offer
        )}
      </td>

      <td>
        {!item.edit ? (
          <>
            <button
              className="btn btn-link mr-2"
              onClick={() =>
                changeValueOffer(index, "edit", !item.edit, {
                  ...SpecialOffer,
                })
              }
              title={backOffice.edit}
            >
              <i className="fas fa-edit"></i>{" "}
            </button>
            <a
              href={() => false}
              className="d-inline-block text-danger"
              onClick={() => {
                deleteOfferfn(item, index);
              }}
            >
              <i className="fas fa-trash"></i>
            </a>
          </>
        ) : (
          <a href={() => false} className="d-inline-block text-success"
            onClick={() => {
              addOfferItem({ ...SpecialOffer }, index);
            }}
          >
            <i className="fas fa-check"></i>
          </a>
        )}
      </td>
    </tr>
  )
}
