import { useGlobalState } from 'context/global';
import moment from "moment";
import { useEffect, useState } from "react";
import { listSuppliers } from "services/suppliers";
import Locale from "translations";
import SuppliersFilter from "./SuppliersList/Filter";
import SuppliersTable from "./SuppliersList/SuppliersTable";

export default function Suppliers() {
	const { backOffice } = Locale;
	let locale = localStorage.getItem("currentLocale") || "en";
	const { allCountries } = useGlobalState();
	const [suppliersList, setSuppliersList] = useState();
	{
		/**
[
		{
			id:1,name:"Almuahadin Alealamia",country:"Saudi Arabia",service:["visa","hotel"],date:"10.Jan.2020",status:"active"
		},
		{
			id:2,name:"Almuahadin Alealamia",country:"Egypt",service:["visa"],date:"10.Jan.2020",status:"inactive"
		}
	]
*/
	}
	const [meta, setMeta] = useState(null);
	const [filter, setFilter] = useState({
		search: "",
		join_date: "",
		country_id: null,
		status: "",
		page: 1,
	});
	const [countries, setCountries] = useState([]);

	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);

	const fetchData = async () => {
		const res = await listSuppliers({
			...filter,
			join_date: filter.join_date
				? moment(filter.join_date).format("YYYY-MM-DD")
				: "",
			country_id: filter.country_id?.value,
			status: filter.status?.label,
			page:
				filter.join_date !== "" ||
					filter.country_id !== null ||
					filter.status !== "" ||
					filter.search !== ""
					? 1
					: filter.page,
		});

		if (res?.status === 200) {
			setSuppliersList(res?.data?.data);
			setMeta(res?.data?.meta);
		}
	};

	useEffect(() => {
		fetchData();
	}, [filter]);

	const goTo = (page) => {
		setFilter({ ...filter, page: +page });
		console.log("setFilter", filter);
	};
	return (
		<div className="suppliers">
			<div className="d-flex justify-content-between align-items-center mb-2">
				<h6 className="font-weight-bold 4h text-blue-black">
					{backOffice.suppliers}
				</h6>
			</div>
			<SuppliersFilter
				filter={filter}
				setFilter={setFilter}
				countries={countries}
			/>
			<SuppliersTable
				filter={filter}
				suppliersList={suppliersList}
				setSuppliersList={setSuppliersList}
				fetchData={fetchData}
				goTo={goTo}
				meta={meta}
			/>
		</div>
	);
}
