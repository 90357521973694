import FinancialFilter from './FinancialFilter'
import Tablelist from './tablelist'

function FinancialOffline() {
  return (<>
    <h1>FinancialOffline</h1>
    <FinancialFilter/>
    <Tablelist/>

    </>)
}

export default FinancialOffline