import { useParams } from 'react-router-dom';
import Locale from 'translations';

export default function HotelCommissionSelector({ selectorsSelected, countries, season }) {
  const { backOffice } = Locale;
  const { id } = useParams();
  return (
    <div className="row custom--row">
      {selectorsSelected?.country !== "" && selectorsSelected?.country ? (
        <div className="col-12 mb-3">
          <div className="item--display light-bg p-3">
            <div className="item--key">{backOffice.country}</div>
            <div className="item--value">
              {selectorsSelected?.country === "all"
                ? "All"
                : selectorsSelected?.country?.map((item) => {
                  if (id) {
                    let countryObject = countries.find(
                      (country) => country.value === item.country_id
                    );
                    return countryObject.label + " , ";
                  } else {
                    let countryObject = countries.find(
                      (country) => country.value === item
                    );
                    return countryObject.label + " , ";
                  }
                })}
            </div>
          </div>
        </div>
      ) : null}

      {selectorsSelected?.season !== "" && selectorsSelected?.season ? (
        <div className="col-12 mb-3">
          <div className="item--display light-bg p-3">
            <div className="item--key">{backOffice.season}</div>
            <div className="item--value">
              {selectorsSelected?.season === "all"
                ? "All"
                : selectorsSelected?.season?.map((item) => {
                  if (id) {
                    let seasonObject = season.find(
                      (itemSeason) => itemSeason.value === item.id
                    );
                    return seasonObject.label + " , ";
                  } else {
                    let seasonObject = season.find(
                      (itemSeason) => itemSeason.value === item
                    );
                    return seasonObject.label + " , ";
                  }
                })}
            </div>
          </div>
        </div>
      ) : null}

      {selectorsSelected?.hotel !== "" && selectorsSelected?.hotel ? (
        <div className="col-12">
          <div className="item--display light-bg p-3">
            <div className="item--key">{backOffice.hotelLevel}</div>
            <div className="item--value">
              {selectorsSelected?.hotel === "all"
                ? "All"
                : selectorsSelected?.hotel?.map((item) => {
                  if (id) {
                    return item?.name?.en + " , ";
                  } else {
                    return item + " , ";
                  }
                })}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
