import { useEffect, useMemo, useState } from "react";
import { FetchAllSudanWallets } from "services/wallet";
import Locale from "translations";
import OneWallet from "./oneWallet";
import ViewTransactionPopup from "./ui/ViewTransactionPopup";

export default function Wallet() {
	const [listWallets, setListWallets] = useState([]);
	const [popupInfo, setpopupInfo] = useState({});
	const [reload, setReload] = useState(true);
	const { wallet } = Locale;

	const [isOpen, setIsOpen] = useState(false);
	const [isOpenView, setIsOpenView] = useState(false);

	const toggleView = (transaction = null, currency = null) => {
		setpopupInfo({ ...transaction, currency });
		setIsOpenView(!isOpenView);
	};

	useEffect(() => {
		const getWallets = async () => {
			const res = await FetchAllSudanWallets();
			if (res?.status === 200) {
				setListWallets(res.data.data);
			}
		};
		getWallets();
	}, [reload]);

	const allWallets = useMemo(() => {
		return listWallets?.length > 0
			? listWallets.map((wallet) => (
					<OneWallet
						key={wallet.id}
						Wallet={wallet}
						isOpenView={isOpenView}
						toggleView={toggleView}
						viewMore={true}
						reload={reload}
					/>
			  ))
			: null;
	}, [listWallets, reload]);

	console.log("popupInfo101", popupInfo);
	return (
		<div className="vendor-wallet">
			<h3>My Wallet</h3>
			{allWallets}

			<ViewTransactionPopup
				isOpen={isOpenView}
				toggle={toggleView}
				popupInfo={popupInfo}
			/>
		</div>
	);
}
