import { useGlobalState } from "context/global";
import { Link } from "react-router-dom";

export default function Transaction({ transaction }) {
	const { locale } = useGlobalState();
	return (
		<tr>
			<td>{transaction.transaction_num}</td>
			<td style={{ textDecoration: "underline", cursor: "pointer" }}>
				<Link
					to={{
						pathname: `/b2c-user-booking-details/${transaction.id}`,
						state: {
							traveller_name: transaction?.traveller_name,
						},
					}}
				>
					{transaction?.payable?.reference_id}
				</Link>
			</td>
			<td>{transaction.created_at}</td>
			<td>
				{transaction.amount} {transaction.currency}
			</td>
			<td
				className={`${
					transaction?.status === "success" ? "text-success" : "text-danger"
				}`}
			>
				{transaction?.status}
			</td>
		</tr>
	);
}
