import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { getNotification } from "helpers/makeNotifications";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { addCommission, updateCommission } from "services/vendor";
import Locale from "translations";

export default function AddCommissionModal({
	isOpen,
	setIsOpen,
	popupInfo,
	toggle,
	setReload,
	providerLookups,
}) {
	const { wallet, vendor } = Locale;

	const [commissionState, setCommissionState] = useState({});

	useEffect(() => {
		if (popupInfo?.modal_type != "add") {
			setCommissionState({
				...popupInfo,
				service: {
					value: popupInfo?.service?.id,
					label: popupInfo?.service?.name,
				},
				provider: {
					value: popupInfo?.provider?.id,
					label: popupInfo?.provider?.name,
				},
			});
		} else {
			setCommissionState({});
		}
	}, [popupInfo]);
	console.log("commissionState10", popupInfo);
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "commission", value: commissionState.amount },
				{ required: true }
			),
			...validate(
				{ name: "safa_amount", value: commissionState.safa_amount },
				{ required: true }
			),
			...validate(
				{ name: "Provider_amout", value: commissionState.vendor_amount },
				{ required: true }
			),
			...validate(
				{ name: "service", value: commissionState.service },
				{ required: true }
			),
			...validate(
				{ name: "provider", value: commissionState.provider },
				{ required: true }
			),
		});
	};
	const handleCommission = async () => {
		const dataFormate = {
			...commissionState,
			provider_id: commissionState?.provider?.value,
			provider_name: commissionState?.provider?.label,
			service_id: commissionState?.service?.value,
			vendor_id: "1",
		};
		let res;
		if (popupInfo?.modal_type == "add") {
			res = await addCommission(dataFormate);
		} else {
			res = await updateCommission(commissionState?.id, dataFormate);
		}
		if (res.status === 200 || res.status === 201) {
			getNotification(res?.data?.message);
			toggle();
			setReload((prev) => !prev);
		}
	};
	useEffect(() => {
		if (isFormValid(errors)) {
			handleCommission();
		}
	}, [isErrorLoaded]);

	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	return (
		<Modal isOpen={isOpen} toggle={toggle} className="commission-modal">
			<div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
				<p className=" text-secondary ">
					{popupInfo?.modal_type == "add"
						? "Add Commission"
						: "Edit Commission"}
				</p>
				<i
					className="far fa-times-circle fa-lg text-secondary "
					onClick={() => setIsOpen((prev) => !prev)}
				></i>
			</div>

			<ModalBody className="  d-flex flex-column justify-content-center align-items-center p-0 py-3">
				<div className="m-0 p-0 w-100  px-5">
					<div className="row align-items-center w-100 no-gutter m-0">
						<div className="col-6">
							<SelectField
								label={vendor.Service}
								placeholder={vendor.Service}
								name="service"
								options={[{ value: 0, label: "hotels" }]}
								onChange={(e) => {
									setCommissionState({
										...commissionState,
										service: e,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "service", value: e },
											{
												required: true,
											}
										),
									});
								}}
								value={commissionState?.service}
								color={errors?.service?.required ? "danger" : ""}
								errors={errors?.service}
							/>
						</div>
						<div className="col-6">
							<SelectField
								label={vendor.Provider}
								placeholder={vendor.Provider}
								name="provider"
								options={providerLookups}
								onChange={(e) => {
									setCommissionState({
										...commissionState,
										provider: e,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "provider", value: e },
											{
												required: true,
											}
										),
									});
								}}
								value={commissionState?.provider}
								color={errors?.provider?.required ? "danger" : ""}
								errors={errors?.provider}
							/>
						</div>

						<div className="col-4">
							<TextField
								type="number"
								label={vendor.commission}
								placeholder={vendor.commission}
								name="commission"
								value={commissionState.amount}
								onChange={(e) => {
									setCommissionState({
										...commissionState,
										amount: e.target.value,
									});

									setErrors({
										...errors,
										...validate(
											{ name: "commission", value: e.target.value },
											{
												required: true,
											}
										),
									});
								}}
								extraTextPosition="append"
								extraText="%"
								color={errors?.commission?.required ? "danger" : ""}
								errors={errors?.commission}
							/>
						</div>
						<div className="col-4">
							<TextField
								type="number"
								label={vendor.safaAmount}
								placeholder={vendor.safaAmount}
								name="safa_amount"
								value={commissionState.safa_amount}
								onChange={(e) => {
									setCommissionState({
										...commissionState,
										safa_amount: e.target.value,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "safa_amount", value: e.target.value },
											{
												required: true,
											}
										),
									});
								}}
								extraTextPosition="append"
								extraText="%"
								color={
									errors?.safa_amount?.required ||
									(+commissionState.vendor_amount +
										+commissionState.safa_amount !=
										100 &&
										commissionState.vendor_amount != undefined)
										? "danger"
										: ""
								}
								errors={errors?.safa_amount}
							/>
						</div>
						<div className="col-4">
							<TextField
								type="number"
								label={vendor.vendorAmount}
								placeholder={vendor.vendorAmount}
								name="Provider_amout"
								value={commissionState.vendor_amount}
								onChange={(e) => {
									setCommissionState({
										...commissionState,
										vendor_amount: e.target.value,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "Provider_amout", value: e.target.value },
											{
												required: true,
											}
										),
									});
								}}
								extraTextPosition="append"
								extraText="%"
								color={
									errors?.Provider_amout?.required ||
									(+commissionState.vendor_amount +
										+commissionState.safa_amount !=
										100 &&
										commissionState.vendor_amount != undefined)
										? "danger"
										: ""
								}
								errors={errors?.Provider_amout}
							/>
						</div>
					</div>
					<div className="text-danger d-flex justify-content-end">
						{+commissionState.vendor_amount + +commissionState.safa_amount !=
							100 && commissionState.vendor_amount != undefined ? (
							<>{vendor.providerEqual100}</>
						) : (
							""
						)}
					</div>
				</div>
			</ModalBody>
			<div className="w-100 px-5 pb-4  border-top-0  text-center d-flex justify-content-end ">
				<button
					className="btn rounded companies-btn w-25"
					onClick={() => submit()}
					disabled={
						+commissionState.vendor_amount + +commissionState.safa_amount != 100
					}
				>
					{popupInfo?.modal_type == "add"
						? "Add Commission"
						: "Edit Commission"}
				</button>
			</div>
		</Modal>
	);
}
