import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import Locale from "translations";

export default function Filter({ filter, setFilter }) {
	const { backOffice } = Locale;
	const visaٍٍٍStatusOptions = [
		{ value: "pending", label: "Pending" },
		{ value: "rejected", label: "Rejected" },
		{ value: "issued", label: "Issued" },
	];
	const tripType = [{ value: "umrah", label: "Umrah" }];

	const handleOnChange = ({ name, value }) => {
		setFilter({
			...filter,
			page: 1,
			[name]: value,
		});
	};

	return (
		<>
			<div className="main-filter  our-border bg-white-blue py-2 mt-2 w-100">
				<div className="row align-items-center w-100 no-gutter m-0">
					<div className="col-2">
						<TextField
							type="text"
							placeholder={backOffice.search}
							label={backOffice.search}
							value={filter.search}
							onChange={(e) => {
								handleOnChange({
									name: "search",
									value: e.target.value,
								});
							}}
						/>
					</div>

					<div className="col-2">
						<DatePickerField
							type="text"
							placeholder={"DD/MM/YYYY"}
							label={backOffice.departureDate}
							date={filter.departure_date}
							onDateChange={(e) => {
								handleOnChange({
									name: "departure_date",
									value: e,
								});
							}}
						/>
					</div>
					<div className="col-2">
						<DatePickerField
							type="text"
							placeholder={"DD/MM/YYYY"}
							label={backOffice.bookingDate}
							date={filter.booking_date}
							onDateChange={(e) => {
								handleOnChange({
									name: "booking_date",
									value: e,
								});
							}}
						/>
					</div>

					<div className="col-2">
						<SelectField
							label={backOffice.tripType}
							/* 							hasLabel={false}
							 */ placeholder={backOffice.tripType}
							name="trip_type"
							options={tripType}
							value={filter.trip_type}
							onChange={(e) => {
								handleOnChange({
									name: "trip_type",
									value: e,
								});
							}}
						/>
					</div>

					<div className="col-2">
						<SelectField
							label={backOffice.visaStatus}
							/* 							hasLabel={false}
							 */ placeholder={backOffice.visaStatus}
							name="status"
							options={visaٍٍٍStatusOptions}
							value={filter.visa_status}
							onChange={(e) => {
								handleOnChange({
									name: "visa_status",
									value: e,
								});
							}}
						/>
					</div>

					<div
						className="col-2"
						onClick={() =>
							setFilter({
								search: "",
								departure_date: null,
								booking_date: null,
								trip_type: null,
								visa_status: null,
							})
						}
					>
						<div className="d-flex align-items-center text-secondary pointer mt-4 reset-btn">
							<img src={resetIcon} alt="" srcset="" />

							<p className="text-caption">{backOffice.resetFilter}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
