import Model from "components/model";
import React from "react";
import { useHistory } from "react-router";
function CancelModal({ isOpen, toggle }) {
  const history = useHistory();
  return (
    <Model
      isOpen={isOpen}
      toggle={() => toggle(false)}
      type="ok"
      title="Are you sure you want to cancel?"
      size="lg"
      submit={() => history.push("/")}
    >
      <p className="my-3">Go back to home</p>
    </Model>
  );
}

export default CancelModal;
