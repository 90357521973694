import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchCurrencyList, fetchWalletList } from "services/manualSupplier";
import FinancialPayment from "./financialPayment";
import FinancialPaymentTable from "./financialPaymentTable";
import PaymentCard from "./paymentCard";

const Payment = () => {
	const { id } = useParams();
	const [currencies, setCurrencies] = useState([]);
	const [payment, setpayment] = useState([]);
	const [paymentCard, setpaymentCard] = useState();
	const [meta, setMeta] = useState(null);
	const [Reload, setReload] = useState(false);
	const [filter, setFilter] = useState({
		search: "",
		date: "",
		type: null,
		transaction_type: null,
		currency: currencies?.length == 0 ? "" : currencies[0],
		page: 1,
	});
	const goTo = (page) => {
		setFilter({ ...filter, page: +page });
	};
	useEffect(() => {
		const currencyList = async () => {
			const res = await fetchCurrencyList(id);
			if (res.status == 200) {
				if (res.data.data?.length == 0) {
					setCurrencies([""]);
				} else {
					setCurrencies(res.data.data);
				}
			}
		};
		currencyList();
	}, []);

	useEffect(() => {
		setFilter({
			...filter,
			currency: currencies?.length == 0 ? "" : currencies[0],
		});
		console.log("filter10001", filter);
	}, [currencies]);

	useEffect(() => {
		const paymentList = async () => {
			const res = await fetchWalletList(id, {
				...filter,
				date: filter.date ? moment(filter.date).format("YYYY-MM-DD") : "",
				type: filter.type?.label,
				transaction_type: filter.transaction_type?.label,
				currency: filter.currency,
			});
			if (res.status == 200) {
				setpayment(res.data.data);
				setpaymentCard(res.data);
				setMeta(res.data.meta);
			}
		};
		paymentList();
	}, [filter, Reload]);
	return (
		<div className="manual-suppliers-payment">
			<div className="title mb-2">
				<h5>
					Supplier Payment{" "}
					{/* <span className="text-dark-yellow">(Emaar El Diyafa Hotels)</span> */}
				</h5>
			</div>
			{/**********Start Tabs********* */}

			<div class="card-body tabs-currencies">
				<ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
					{currencies?.map((item, index) => (
						<li
							class="nav-item"
							onClick={() => setFilter({ ...filter, currency: item })}
						>
							<a
								class={`nav-link  ${index == 0 ? "active" : ""}`}
								data-bs-toggle="tab"
								href={`#${item}`}
								role="tab"
								aria-selected="true"
							>
								<span class="d-none d-sm-block">{item}</span>
							</a>
						</li>
					))}
				</ul>

				<div class="tab-content p-3 text-muted">
					{currencies?.map((item, index) => (
						<div
							class={`tab-pane ${index == 0 ? "active" : ""}`}
							active
							id={`${item}`}
							role="tabpanel"
						>
							<>
								<div className="payment_cards d-flex gap-3 ">
									<PaymentCard
										value={paymentCard?.total_amount}
										title="Amount Due"
										className="pay-gray"
										currency={filter?.currency}
									/>
									<PaymentCard
										value={paymentCard?.total_payed}
										title="Paid"
										className="pay-success"
										currency={filter?.currency}
									/>
									<PaymentCard
										value={paymentCard?.total_amount - paymentCard?.total_payed}
										title="Unpaid "
										className="pay-danger"
										currency={filter?.currency}
									/>
								</div>
								<FinancialPayment
									setReload={setReload}
									currency={filter?.currency}
								/>
								<FinancialPaymentTable
									filter={filter}
									setFilter={setFilter}
									payment={payment}
									meta={meta}
									goTo={goTo}
									currency={filter?.currency}
								/>
							</>
						</div>
					))}
				</div>
			</div>

			{/**********End Tabs********* */}
		</div>
	);
};

export default Payment;
