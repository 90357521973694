import PermissionGroup from "./PermissionGroup";

export default function ListPermission({
  permissions,
  selectedPermissions,
  setSelectedPermissions,
}) {
  const handelCheck = (itemId, flag) => {
    if (flag) {
      setSelectedPermissions([...selectedPermissions, itemId]);
    } else {
      setSelectedPermissions([
        ...selectedPermissions.filter((id) => id != itemId),
      ]);
    }
  };

  const handelCheckAll = (collectionPermission, flag) => {
    if (flag) {
      setSelectedPermissions([
        ...new Set([...selectedPermissions, ...collectionPermission]),
      ]);
    } else {
      setSelectedPermissions(
        selectedPermissions.filter((per) => !collectionPermission.includes(per))
      );
    }
  };

  const allPermissions =
    permissions.length > 0
      ? permissions?.map((permission, index) => {
          return (
            <PermissionGroup
              key={index}
              permission={permission}
              handelCheck={handelCheck}
              selectedPermissions={selectedPermissions}
              handelCheckAll={handelCheckAll}
            />
          );
        })
      : null;

  return <div className="row m-0">{allPermissions}</div>;
}
