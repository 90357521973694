import createDOMPurify from "dompurify";
import moment from "moment";
import { useEffect, useState } from "react";

import { store } from "react-notifications-component";
import { cancelRoomReservations } from "services/companiesReservations";

import Locale from "translations";

export default function InventoryTab({ details, fetchData }) {
	console.log("detailsdetailsdetails", details);
	const { backOffice, teamManagement } = Locale;
	// const { state } = useLocation();
	const state = { tab: "hotel" };
	const [RoomsList, setRoomsList] = useState([]);
	const [modalEdit, setModalEdit] = useState(false);

	const toggleModalEdit = () => setModalEdit(!modalEdit);

	useEffect(() => {
		setRoomsList(details.payload.rooms);
	}, []);

	const cancelRoom = async (brn, code) => {
		const data = await cancelRoomReservations({
			brn: details.brn,
			bookingCode: code,
		});
		if (data.status == 200) {
			fetchData();
			store.addNotification({
				title: "Info!",
				message: "done",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	const DOMPurify = createDOMPurify(window);
	const [editState, setEditState] = useState({
		checkIn: details?.start_date
			? moment(details.start_date)
			: moment(new Date()).add(1, "d"),
		checkOut: details?.end_date
			? moment(details.end_date)
			: moment(new Date()).add(2, "d"),
		adult: +details.adults,
		child: 0,
		roomType: null,
	});

	const print = (e) => {
		const popupWin = window.open(
			"",
			"_blank",
			"top=0,left=0,height=auto,width=auto"
		);
		popupWin.document.open();
		// console.log();
		popupWin.document.write(`
				<html>
					<head>
				<title>Print tab</title>

					</head>
				<body style="width:100%;">
				${e}
				<script>

				setTimeout(() => {
					console.log("Delayed for 1 second.");
					window.print();

					document.addEventListener('keydown', logKey);

				function logKey(e) {
					console.log("Delayed for 1 second." ,e);
				}
				}, "1000")

				</script>
				</body>
				</html>`);

		// popupWin.close();
		// popupWin.document.close();
	};

	const roomType = (status) => {
		switch (status) {
			case 1:
				return "single";
			case 2:
				return "dauble";
			case 3:
				return "triple";
			case 4:
				return "quadruple";
			default:
				return "Rooms";
		}
	};

	const AllRooms =
		RoomsList?.length > 0 ? (
			RoomsList.map((productItem, index) => (
				<tr>
					<td>{index + 1}</td>
					<td>{productItem.name}</td>
					<td>{roomType(productItem.room_type)}</td>
					<td>{productItem.from_date}</td>
					<td>{productItem.to_date}</td>
					<td>
						<p className="bg-light p-1 rounded text-center">
							<i class="fas fa-child mx-1 "></i>
							{productItem.adults}{" "}
						</p>
					</td>
					<td>
						<p className="bg-light p-1 rounded text-center">
							<i class="fas fa-child mx-1 "></i>
							{productItem.children}
						</p>
					</td>
					<td>{productItem.maxOccupancy}</td>
					<td>{productItem.price}</td>
					<td>
						<a
							href={() => false}
							onClick={() => print(productItem.voucher)}
							className="link_action"
						>
							<i class="fas fa-cloud-download-alt"></i>
							Voucher
						</a>

						{!productItem.is_cancelled ? (
							<button
								className="btn btn-danger mx-2"
								onClick={() =>
									cancelRoom(productItem.brn, productItem.bookingCode)
								}
							>
								Cancel
							</button>
						) : (
							<span className="text-danger p-2 h6 font-weight-bold">
								Canceled
							</span>
						)}
					</td>
				</tr>
			))
		) : (
			<tr>
				<td colSpan="9">
					<div className="product-build__product-no-data fullHeight">
						<i className="icx icx-eye-slash" aria-hidden="true"></i>
						<h4>{"No Result "}</h4>
					</div>
				</td>
			</tr>
		);
	return (
		<>
			<table className="custom-table table table-striped custom-padding-15">
				<thead>
					<tr>
						<th>#</th>
						<th>{backOffice.roomName}</th>
						<th>{backOffice.roomType}</th>
						<th>{backOffice.checkIn}</th>
						<th>{backOffice.checkOut}</th>
						<th>{backOffice.adult}</th>
						<th>{backOffice.child}</th>
						<th>{backOffice.maxCapacity}</th>
						<th>{backOffice.Price}</th>
						<th>{backOffice.Tools}</th>
					</tr>
				</thead>
				<tbody>{AllRooms}</tbody>
			</table>

			{/* <Models /> */}
		</>
	);
}
