import SelectField from "components/shared/SelectField";
import TextAreaField from "components/shared/TextAreaField";
import TextField from "components/shared/TextField";
import { useGlobalDispatch, useGlobalState } from "context/global";
import { usePropertyDispatch, usePropertyState } from "context/property";
import { formatGetBasicInfo, formatPostBasicInfo } from "data/property";
import validate, { isFormValid, validatePhone } from "helpers/validate";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useParams } from "react-router-dom";
import { fetchAreas, fetchCities } from "services/lookups";
import { addBasicInfo, EditBasicInfo } from "services/property";
import Locale from "translations";
import CancelModal from "./CancelModal";

function BasicInfo(statusNum) {
	const { id } = useParams();
	const { allCountries } = useGlobalState();
	const { propertyDetails } = usePropertyState();
	const dispatch = useGlobalDispatch();
	const propertyDispatch = usePropertyDispatch();
	const history = useHistory();
	const locale = localStorage.getItem("currentLocale") || "en";
	const { property, commons, supplierDetails } = Locale;
	const [toggleValue, setToggleValue] = useState("english");
	const [canceled, setCanceled] = useState(false);
	const [info, setInfo] = useState({
		name: "",
		rating: "",
		description: "",
		country: "",
		city: "",
		area: "",
		address: "",
		postalCode: "",
		phone: "",
		secondPhone: "",
		email: "",
		officialEmail: "",
		latitude: "",
		longitude: "",
		lang: "en",
		distance: "",
		name_ar: "",
	});

	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [countries, setCountries] = useState([]);
	const [cities, setCities] = useState([]);
	const [areas, setAreas] = useState([]);
	const [isSameEmail, setIsSameEmail] = useState(false);

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "name", value: info.name },
				{ required: true, min: 3 }
			),
			...validate({ name: "rating", value: info.rating }, { required: true }),
			...validate(
				{ name: "address", value: info.address },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "email", value: info.email },
				{ required: true, email: true }
			),
			...validate(
				{ name: "officialEmail", value: info.officialEmail },
				{ required: true, email: true }
			),
			...validate({ name: "country", value: info.country }, { required: true }),
			...validate({ name: "city", value: info.city }, { required: true }),
			...validate({ name: "area", value: info.area }, { required: true }),
			...validate(
				{
					name: "phone",
					value: validatePhone(
						info.phone,
						countries.find((country) => country.value === info.country.value)
							?.code
					),
				},
				{ required: true, phone: true }
			),
			...validate(
				{
					name: "secondPhone",
					value: validatePhone(
						info.secondPhone,
						countries.find((country) => country.value === info.country.value)
							?.code
					),
				},
				{
					phone: validatePhone(
						info.secondPhone,
						countries.find((country) => country.value === info.country.value)
							?.code
					),
				}
			),
			...validate(
				{ name: "latitude", value: info.latitude },
				{ required: true }
			),
			...validate(
				{ name: "longitude", value: info.longitude },
				{ required: true }
			),
			...validate(
				{ name: "postalCode", value: info.postalCode },
				{ required: true, number: true }
			),
			...validate(
				{ name: "description", value: info.description },
				{ required: true }
			),
		});
	};

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setInfo({ ...info, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			if (name === "country") {
				setCities([]);
				setInfo({
					...info,
					city: "",
					area: "",
					postalCode: "",
					phone: "",
					secondPhone: "",
					[name]: value,
				});
			} else setInfo({ ...info, [name]: value });
		}
	};

	const handleNameChange = (e) => {
		const re = {
			en: /^[A-Za-z][A-Za-z0-9 ]*$/,
			ar: /^[\u0621-\u064A0-9 ]+$/,
		};

		const value = e.target.value;
		const name = e.target.name;
		if (value === "" || re[info.lang].test(value)) {
			if (toggleValue == "english") {
				setInfo({ ...info, [name]: value });
			} else {
				setInfo({ ...info, name_ar: value });
			}
		}
	};

	// Fetch Countries
	useEffect(() => {
		async function countries() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names["en"],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countries();
	}, [allCountries]);
	// Fetch Cities
	useEffect(() => {
		async function fetchLookups() {
			if (info.country?.value) {
				const res = await fetchCities(info.country?.value);
				const formatted = res?.data.map((country) => ({
					value: country.id,
					label: country.names[locale],
				}));
				setCities(formatted);
			}
		}
		fetchLookups();
	}, [info.country]);

	// Fetch Areas
	useEffect(() => {
		async function fetchLookups() {
			if (info.city?.value) {
				const res = await fetchAreas(info.city?.value);

				const formatted = res?.data.map((city) => ({
					value: city.id,
					label: city.names != null ? city.names["ar"] : "",
				}));
				setAreas(formatted);
			}
		}
		fetchLookups();
	}, [info.city]);

	// is same email
	useEffect(() => {
		if (isSameEmail) {
			setInfo({ ...info, officialEmail: info.email });
		} else if (info.email === "" && info.officialEmail === "") {
			setIsSameEmail(false);
		} else {
			setInfo({ ...info, officialEmail: info.officialEmail });
		}
	}, [isSameEmail, info.email]);

	useEffect(async () => {
		if (id && propertyDetails) {
			//
			const formatted = formatGetBasicInfo(propertyDetails);
			setInfo(formatted);
		}
	}, [propertyDetails]);
	//

	useEffect(async () => {
		if (isFormValid(errors)) {
			if (id && propertyDetails?.property?.name) {
				const data = formatPostBasicInfo(info);
				const res = await EditBasicInfo(id, data);

				if (res.status == 200) {
					propertyDispatch({ type: "propertyDetails", payload: res.data });
					dispatch({ type: "progress", step: "2", current: "2" });
					store.addNotification({
						title: "Basic Info",
						message: "Edit Basic Info Succussfully",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			} else {
				const data = formatPostBasicInfo(info);
				const res = await addBasicInfo(data);
				if (res.status === 201) {
					history.push(`/property/${res.data.id}`);
					dispatch({
						type: "progress",
						step: "2",
						current: "2",
					});
					propertyDispatch({ type: "propertyDetails", payload: res.data });
				}
			}
		}
	}, [isErrorLoaded]);

	const submit = () => {
		checkFormErrors();
		// if (info.country?.value === 966) {
		//   setErrors({
		//     ...errors,
		//     ...validate(
		//       { name: "distance", value: info.distance },
		//       { required: true }
		//       //, number: true
		//     ),
		//   });
		// }
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const reset = () => {
		setCanceled(true);
	};

	return (
		<>
			<CancelModal isOpen={canceled} toggle={setCanceled} />
			<div className="content-box">
				{/* <FormGroup tag="fieldset" className="col-12 name-check">
          <FormGroup check>
            <Label htmlFor="basic-search" check>
              <Input type="radio" name="propertyName" id="basic-search" />{" "}
              Search
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label htmlFor="basic-insert" check>
              <Input type="radio" name="propertyName" id="basic-insert" />{" "}
              Insert
            </Label>
          </FormGroup>
        </FormGroup> */}
				{/* name */}

				<div className="row m-0">
					<div className="col-7">
						<div className="toggle">
							<button
								id="basic-arabic"
								className={`${toggleValue === "arabic" ? "on" : ""}`}
								onClick={() => {
									setToggleValue("arabic");
									// setInfo({ ...info, name_ar: info?.name_ar, lang: "ar" });
								}}
							>
								Arabic
							</button>
							<button
								id="basic-english"
								className={`${toggleValue === "english" ? "on" : ""}`}
								onClick={() => {
									setToggleValue("english");
									// setInfo({ ...info, name_en: info?.name_en, lang: "en" });
								}}
							>
								English
							</button>
						</div>
						<TextField
							label={property.name}
							customLabel={property.customName}
							placeholder={property.namePlaceholder}
							disabled={false}
							value={toggleValue == "english" ? info.name : info.name_ar}
							name="name"
							id="basic-info"
							onChange={(e) => {
								handleNameChange(e);
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										min: 3,
									}),
								});
							}}
							min={3}
							color={errors.name?.required || errors.name?.min ? "danger" : ""}
							errors={errors.name}
						/>
					</div>
					{/* rating */}
					<div className="col-5">
						<SelectField
							label={property.rating}
							value={info.rating}
							disabled={false}
							options={[
								{ label: "1", value: 1 },
								{ label: "2", value: 2 },
								{ label: "3", value: 3 },
								{ label: "4", value: 4 },
								{ label: "5", value: 5 },
							]}
							placeholder={property.ratingPlaceholder}
							name="rating"
							id="basic-rating"
							onChange={(e) => {
								handleChange({ name: "rating", value: e });
								setErrors({
									...errors,
									...validate({ name: "rating", value: e }, { required: true }),
								});
							}}
							color={errors.rating?.required ? "danger" : ""}
							errors={errors.rating}
						/>
					</div>
					{/* description */}
					<div className="col-12">
						<TextAreaField
							label={property.description}
							placeholder={property.descriptionPlaceholder}
							rows={5}
							disabled={false}
							value={info.description}
							name="description"
							id="basic-description"
							onChange={(e) => {
								handleChange(e);
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
									}),
								});
							}}
							color={errors.description?.required ? "danger" : ""}
						/>
					</div>
				</div>
			</div>
			<div className="header-box my-3">{property.located}</div>
			<div className="row m-0">
				{/* Country */}
				<div className="col-6">
					<SelectField
						// disabled
						label={commons.country}
						disabled={false}
						placeholder={commons.countryPlaceholder}
						errors={errors.country}
						color={errors.country?.required ? "danger" : ""}
						options={countries}
						id="basic-countries"
						onChange={(e) => {
							handleChange({ name: "country", value: e });
							setErrors({
								...errors,
								...validate(
									{ name: "country", value: e },
									{
										required: true,
									}
								),
							});
						}}
						value={info.country}
					/>
				</div>
				{/* City */}
				<div className="col-6">
					<SelectField
						// disabled
						label={commons.city}
						disabled={false}
						placeholder={commons.cityPlaceholder}
						errors={errors.city}
						color={errors.city?.required ? "danger" : ""}
						options={cities}
						id="basic-cities"
						onChange={(e) => {
							handleChange({ name: "city", value: e });
							setErrors({
								...errors,
								...validate(
									{ name: "city", value: e },
									{
										required: true,
									}
								),
							});
						}}
						value={info.city}
					/>
				</div>
				{/* Area */}
				<div className="col-6">
					<SelectField
						// disabled
						label={commons.area}
						disabled={false}
						errors={errors.area}
						color={errors.area?.required ? "danger" : ""}
						options={areas}
						id="basic-areas"
						onChange={(e) => {
							handleChange({ name: "area", value: e });
							setErrors({
								...errors,
								...validate(
									{ name: "area", value: e },
									{
										required: true,
									}
								),
							});
						}}
						value={info.area}
					/>
				</div>
				{/* address */}
				<div className="col-6">
					<TextField
						label={commons.address}
						disabled={false}
						placeholder={commons.addressPlaceholder}
						value={info.address}
						name="address"
						id="basic-address"
						onChange={(e) => {
							handleChange(e);
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									min: 3,
								}),
							});
						}}
						min={3}
						color={
							errors.address?.required || errors.address?.min ? "danger" : ""
						}
						errors={errors.address}
					/>
				</div>
				{/* distance */}
				{info.country?.value === 966 && (
					<div className="col-6">
						<TextField
							label={commons.distance}
							disabled={false}
							placeholder={commons.distancePlaceholder}
							value={info.distance}
							name="distance"
							id="basic-distance"
							onChange={(e) => {
								handleChange(e);
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										//number: true
									}),
								});
							}}
							color={
								errors.distance?.required || errors.distance?.number
									? "danger"
									: ""
							}
							errors={errors.distance}
						/>
					</div>
				)}

				{/* postalCode */}
				<div className="col-6">
					<TextField
						label={commons.postalCode}
						disabled={false}
						placeholder={commons.postalCodePlaceholder}
						value={info.postalCode}
						name="postalCode"
						id="basic-postalCode"
						onChange={(e) => {
							handleChange(e);
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									number: true,
								}),
							});
						}}
						min={3}
						color={
							errors.postalCode?.required || errors.postalCode?.number
								? "danger"
								: ""
						}
						errors={errors.postalCode}
					/>
				</div>
				{/* Phone Number */}
				<div className="col-md-6 pl-md-2">
					<TextField
						label={supplierDetails.phoneNumber}
						disabled={false}
						placeholder={supplierDetails.phoneNumberPlaceholder}
						name="phone"
						id="basic-phone"
						value={info.phone}
						extraText={info.country.value ? "+" + info.country.value : "---"}
						extraTextPosition="prepend"
						onChange={(e) => {
							handleChange(e);
							setErrors({
								...errors,
								...validate(
									{
										name: "phone",
										value: validatePhone(
											e.target.value,
											countries.find(
												(country) => country.value === info.country.value
											).code
										),
									},
									{
										required: true,
										phone: true,
									}
								),
							});
						}}
						//value={info.phone}
						errors={errors.phone}
						color={
							errors?.phone?.required || errors?.phone?.phone ? "danger" : ""
						}
					/>
				</div>
				{/* Phone Number */}
				<div className="col-md-6 pl-md-2">
					<TextField
						label={supplierDetails.phoneNumber}
						disabled={false}
						placeholder={supplierDetails.phoneNumberPlaceholder}
						name="secondPhone"
						id="basic-secondPhone"
						value={info.secondPhone}
						extraText={info.country.value ? "+" + info.country.value : "---"}
						extraTextPosition="prepend"
						onChange={(e) => {
							handleChange(e);
							setErrors({
								...errors,
								...validate(
									{
										name: "secondPhone",
										value: validatePhone(
											e.target.value,
											countries.find(
												(country) => country.value === info.country.value
											).code
										),
									},
									{
										phone: true,
									}
								),
							});
						}}
						errors={errors.secondPhone}
						color={
							errors.secondPhone?.required || errors.secondPhone?.phone
								? "danger"
								: ""
						}
					/>
				</div>
				{/*  Reservations Email address  */}
				<div className="col-6">
					<TextField
						label={supplierDetails.reservationsEmail}
						disabled={false}
						placeholder={supplierDetails.emailPlaceholder}
						type="text"
						name="email"
						id="basic-email"
						errors={errors.email}
						color={
							errors.email?.required || errors.email?.email ? "danger" : ""
						}
						onChange={(e) => {
							handleChange(e);
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									email: true,
								}),
							});
						}}
						value={info.email}
					/>
				</div>
				{/*  Business Email */}
				<div className="col-6">
					<TextField
						label={supplierDetails.officialEmail}
						disabled={false}
						placeholder={supplierDetails.emailPlaceholder}
						type="text"
						name="officialEmail"
						id="basic-officialEmail"
						errors={errors.officialEmail}
						color={
							errors.officialEmail?.required || errors.officialEmail?.email
								? "danger"
								: ""
						}
						onChange={(e) => {
							handleChange(e);
							// if (!isSameEmail) {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									email: true,
								}),
							});
							// }
						}}
						value={info.officialEmail}
					/>
				</div>
				{/* isSame */}
				{/* <div className="col-2 d-flex align-items-center">
          <div className="form-group form-check">
            <input
              id="basic-info-officialEmail"
              type="checkbox"
              className="form-check-input"
              onChange={(e) => {
                setIsSameEmail(!isSameEmail);
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "officialEmail", value: info.officialEmail },
                    {
                      required: false,
                      email: false
                    }
                  )
                });
              }}
              defaultChecked={isSameEmail}
            />
            <label className="form-check-label text-capitalize">
              {property.isSame}
            </label>
          </div>
        </div> */}
			</div>
			<div className="header-box my-3">{property.location}</div>
			<div className="row m-0">
				<div className="col-6">
					<TextField
						label={property.xCord}
						placeholder={property.xCordPlaceholder}
						disabled={false}
						value={info.latitude}
						type="number"
						pattern="[0-9]+([,\.][0-9]+)?"
						name="latitude"
						id="basic-latitude"
						onChange={(e) => {
							handleChange(e);
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									//number: true,
								}),
							});
						}}
						color={
							errors?.latitude?.required || errors?.latitude?.number
								? "danger"
								: ""
						}
						errors={errors?.latitude}
					/>
					<TextField
						label={property.yCord}
						placeholder={property.yCordPlaceholder}
						value={info.longitude}
						disabled={false}
						type="number"
						pattern="[0-9]+([,\.][0-9]+)?"
						name="longitude"
						id="basic-longitude"
						onChange={(e) => {
							handleChange(e);
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									//number: true,
								}),
							});
						}}
						color={
							errors?.longitude?.required || errors?.longitude?.number
								? "danger"
								: ""
						}
						errors={errors?.longitude}
					/>
				</div>
				<div className="col-6">
					<div className="map">
						{/*             
            <GoogleMap PropertyMarkers={{
              id: id,
              long: info.longitude,
              lat: info.latitude
              }} /> 
            */}
						<iframe
							title={info.id}
							name="gMap"
							width="100%"
							height="600"
							frameborder="0"
							scrolling="no"
							marginheight="0"
							marginwidth="0"
							src={`https://maps.google.com/maps?width=100%25&height=600&hl=en&q=${info.latitude},${info.longitude}&hl=es;z=14&output=embed`}
						></iframe>
					</div>
				</div>
			</div>
			{/* <ActionButton ID={id} StatusNum={statusNum} /> */}

			<div className="row my-5 mx-0 justify-content-between">
				<button
					onClick={() => history.push("/property-management")}
					className="btn cancel mx-2 "
					id="basic-cancel"
				>
					Back
				</button>

				<button
					className="btn btn-primary px-5"
					id="basic-submit"
					onClick={submit}
				>
					continue
				</button>
			</div>
		</>
	);
}

export default BasicInfo;
