import hotelImg from "assets/images/Hotels.svg";
import ConfirmModal from "components/Modals/ConfirmModal.js";
import { getNotification } from "helpers/makeNotifications.js";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { deleteCommission, fetchVendorList, ViewVendor } from "services/vendor";
import Locale from "translations";
import AddCommissionModal from "./modals/addCommissionModal.js";

const VendorCommission = () => {
	const { vendor, confirmation, backOffice } = Locale;
	const [commissionList, setCommissionList] = useState([]);
	const [confirmIsOpen, setConfirmIsOpen] = useState(false);
	const [selectDeleteId, setSelectDeleteId] = useState();
	const [providerLookups, setProviderLookups] = useState([]);
	const history = useHistory();
	const toggleConfirm = (id = null) => {
		setSelectDeleteId(id);
		setConfirmIsOpen(!confirmIsOpen);
	};
	const [renderState, setRenderState] = useState(false);
	const { id } = useParams();
	const [isOpen, setIsOpen] = useState(false);
	const [popupInfo, setPopupInfo] = useState({});
	const toggle = (commission = null, type = "add") => {
		if (type === "add") {
			setPopupInfo({ modal_type: type });
		} else {
			setPopupInfo({ ...commission, modal_type: type });
		}
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		const fetchData = async () => {
			const res = await ViewVendor(id);
			if (res?.status === 200) {
				setCommissionList(res.data.data);
			}
		};
		fetchData();
	}, [renderState]);

	//fetchVendorList
	useEffect(() => {
		const fetchProviders = async () => {
			const res = await fetchVendorList();

			let formated = res?.data?.data?.map((item, index) => ({
				value: item.id,
				label: item.name,
			}));
			setProviderLookups(formated);
		};
		fetchProviders();
	}, []);

	const remove = async (id) => {
		const res = await deleteCommission(id);
		if (res?.status === 200) {
			getNotification(res?.data?.message);
			setRenderState((prev) => !prev);
			toggleConfirm();
		}
	};
	const AllCommissionList =
		commissionList?.length > 0 ? (
			commissionList?.map((comm, index) => {
				return (
					<tr key={comm.id}>
						<td className="img-service">
							{comm?.service?.name === "hotels" ? (
								<div className="mx-2">
									<img src={hotelImg} alt="" width={22} />
								</div>
							) : null}
						</td>
						<td>{comm?.provider?.name}</td>
						<td>{comm.amount}%</td>
						<td>{comm.safa_amount}%</td>
						<td>{comm.vendor_amount}%</td>
						<td>
							<i
								class="fas fa-edit text-primary pointer"
								onClick={() => toggle(comm, "edit")}
							></i>
							<i
								class="fas fa-trash mx-3 text-danger pointer"
								onClick={() => toggleConfirm(comm.id)}
							></i>
						</td>
					</tr>
				);
			})
		) : (
			<tr>
				<td>-</td>
				<td>-</td>
				<td>-</td>
				<td>-</td>
				<td>-</td>
				<td>-</td>
			</tr>
		);

	return (
		<div className="p-2 vendor-commissions modal-body">
			<div className="d-flex justify-content-end">
				<button className="btn btn-primary  mx-2 p-2" onClick={toggle}>
					{vendor.addCommission}
				</button>
			</div>
			<div className="product-builder-list bg-transparent">
				<div className=" table-responsive our-border px-4">
					<table className="custom-table back-office-table table table-striped">
						<thead>
							<tr>
								<th>{vendor.Service}</th>
								<th>{vendor.Provider}</th>
								<th>{vendor.commission}</th>
								<th>{vendor.Safa}</th>
								<th>{vendor.vendor}</th>
								<th>{vendor.Control}</th>
							</tr>
						</thead>
						<tbody> {AllCommissionList}</tbody>
					</table>
				</div>
			</div>

			<AddCommissionModal
				isOpen={isOpen}
				toggle={toggle}
				popupInfo={popupInfo}
				setReload={setRenderState}
				setIsOpen={setIsOpen}
				providerLookups={providerLookups}
			/>

			<ConfirmModal
				IsOpen={confirmIsOpen}
				toggle={toggleConfirm}
				message={confirmation.confirmStatus}
				onConfirm={() => remove(selectDeleteId)}
				type="delete"
			/>

			<div className="d-flex  justify-content-end mt-5">
				<button
					className=" cancel-bg p-2 px-4 mx-3"
					onClick={() => {
						history.push(`/vendor-commission`);
					}}
				>
					{backOffice.back}
				</button>
			</div>
		</div>
	);
};

export default VendorCommission;
