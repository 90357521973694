import axios from "axios";
import { cleanEmpty } from "services/general";
const backOfficeApi = process.env.REACT_APP_API_URL + "/api";

export const ListReservations = async (id , params) => {
  return await axios
    .get(`${backOfficeApi}/company/${id}/reservation`, {
      params: cleanEmpty(params),
    })
    .then((res) => res)
    .catch((err) => err.response);
};

export const ViewHotelReservations = async (id , reservation_id) => {
  return await axios
    .get(`${backOfficeApi}/company/${id}/reservation/${reservation_id}`)
    .then((res) => res)
    .catch((err) => err.response);
};



export const cancelRoomReservations = async (data) => {
  return await axios
    .post(`${backOfficeApi}/reservation/cancel-room`,data)
    .then((res) => res)
    .catch((err) => err.response);
};
