import axios from "axios";
import { cleanEmpty } from "./general";
const vendorURL = process.env.REACT_APP_API_URL + "/api";

export const listVendor = async (params) => {
	return await axios
		.get(`${vendorURL}/vendors`, { params })
		.then((res) => res)
		.catch((err) => err.response);
};
export const addCommission = async (data) => {
	return await axios
		.post(`${vendorURL}/vendors/commissions`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateCommission = async (id, data) => {
	return await axios
		.put(`${vendorURL}/vendors/commissions/${id}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
export const ViewVendor = async (id) => {
	return await axios
		.get(`${vendorURL}/vendors/${id}/commission`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editVendor = async (id, data) => {
	return await axios
		.post(`${vendorURL}/vendors/${id}/commission`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
export const deleteCommission = async (id) => {
	return await axios
		.delete(`${vendorURL}/vendors/commissions/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchVendorList = async () => {
	return await axios
		.get(`${vendorURL}/marketplace/providers`)
		.then((res) => res)
		.catch((err) => err.response);
};
/********************Vendor Wallet***************** */
export const listVendorWallets = async (id) => {
	return await axios
		.get(`${vendorURL}/vendor/${id}/wallet`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const addVendorWallet = async (id, data) => {
	return await axios
		.post(`${vendorURL}/vendor/${id}/wallet`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const addBalancesVendor = async (id, id_wallet, data) => {
	return await axios
		.put(`${vendorURL}/vendor/${id}/wallet/${id_wallet}/balance`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const listVendorWalletsTransactions = async (id, id_wallet, params) => {
	return await axios
		.get(`${vendorURL}/vendor/${id}/wallet/${id_wallet}/transaction`, {
			params: params,
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const listVendorReservation = async (id, params) => {
	return await axios
		.get(`${vendorURL}/marketplace/providers/bookings/${id}`, {
			params: params,
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewVendorReservation = async (id, brn) => {
	return await axios
		.get(`${vendorURL}/marketplace/providers/bookings/${1}/view/${brn}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const editConfirmation = async (id, data) => {
	return await axios
		.post(
			`${vendorURL}/marketplace/providers/bookings/${id}/edit-confirmation-num`,
			data
		)
		.catch((err) => err.response);
};
