import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
	viewOnlineVisaRequest,
	viewOnlineVisaRequestWaiting,
} from "services/Request";
import Locale from "translations";
import ViewRequestHeader from "./ViewRequestHeader";

export default function ViewRequest() {
	const { requests, backOffice } = Locale;
	const [searchVal, setSearchVal] = useState(null);
	const [ListOfChecked, setListOfChecked] = useState([]);
	const { status, id } = useParams();
	let history = useHistory();

	const { locale } = useGlobalState();
	const [passangers, setPassangers] = useState([]);
	const [header, setHeader] = useState({});
	const handelCheck = (passenger, flag) => {
		if (flag) {
			setListOfChecked([...ListOfChecked, passenger.id]);
		} else {
			let newListChecked = ListOfChecked.filter((id) => id != passenger.id);
			setListOfChecked(newListChecked);
		}
	};

	const allPassengers =
		passangers?.length > 0
			? passangers?.map((passenger) => {
					return (
						<tr>
							<td className="d-flex align-items-center">
								{/* <input
                type="checkbox"
                checked={ListOfChecked.includes(passenger.id)}
                className="mx-1 insert-group-checkbox "
                onChange={(e) => {
                  handelCheck(passenger, e.target.checked);
                }}
              /> */}
								{passenger.passport_number}
							</td>

							<td>
								{locale === "en"
									? passenger.full_name_en
									: passenger.full_name_ar}
							</td>
							<td>{passenger.gender}</td>
							<td>{passenger.date_of_birth}</td>
							<td>{passenger.age}</td>
							<td>
								{passenger.relationship ? passenger.relationship[locale] : ""}
							</td>
							<td>{passenger.national_id}</td>
							<td>{passenger.nationality}</td>
							<td>
								<Link
									to={`/online-visa/view/passenger/${status}/${id}/${passenger.id}`}
								>
									{locale === "en" ? (
										<i class="fas fa-angle-right"></i>
									) : (
										<i class="fas fa-angle-left"></i>
									)}
								</Link>
							</td>
						</tr>
					);
			  })
			: [];

	useEffect(() => {
		const fetchRequestIssued = async () => {
			const response = await viewOnlineVisaRequest(id);
			if (response.status === 200 || response.status === 201) {
				setPassangers(response.data.data.passengers);
				setHeader(response.data.data);
			}
		};

		const fetchRequestWaiting = async () => {
			const response = await viewOnlineVisaRequestWaiting(id);
			if (response.status === 200 || response.status === 201) {
				setPassangers(response.data.data.passengers);
				setHeader(response.data.data);
			}
		};

		if (status === "waiting") {
			fetchRequestWaiting();
		} else {
			fetchRequestIssued();
		}
	}, []);

	return (
		<>
			<div>
				<ViewRequestHeader header={header} />
			</div>

			<div className="mt-3  border rounded ">
				<header className=" p-3 border-bottom">
					<p className="m-0 font-weight-bold text-black">
						{requests.Travelers} ({header.count_travellers}/{header.pax})
					</p>
				</header>

				<div className="d-flex   border-top px-3  align-items-center  justify-content-between  ">
					<div className="col-4 p-0">
						{/* <button
              className="btn btn-success m-1"
            >
              {requests.SendToOffline}
            </button> */}
					</div>

					<div className="col-6 m-0  justify-content-end d-flex align-items-center  ">
						{/* <button className="btn btn-primary  m-1">
              <i class="fas fa-file-excel mx-2"></i>
              {requests.ExportSelectedToExcel}
            </button> */}

						<div className=" mx-2 w-50">
							<TextField
								hasLabel={false}
								placeholder={requests.search}
								value={searchVal}
								onChange={(e) => {
									setSearchVal(e.target.value);
								}}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="border">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>{requests.PassportNumber}</th>
							<th>{requests.Name}</th>
							<th>{requests.Gender}</th>
							<th>{requests.Birthday}</th>
							<th>{requests.Age}</th>
							<th>{requests.Relationship}</th>
							<th>{requests.NationalId}</th>
							<th>{requests.Nationality}</th>
						</tr>
					</thead>
					<tbody>{allPassengers}</tbody>
				</table>
			</div>
			<div className="d-flex justify-content-end my-4">
				<button
					className="btn bg-gray mx-2 px-5"
					onClick={() => {
						history.push(`/online-visa/requests/${status}`);
					}}
				>
					{requests.back}
				</button>
			</div>
		</>
	);
}
