import Pagination from "components/shared/Pagination";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FetchTransaction } from "services/wallet";
import Locale from "translations";

export default function Transactions() {
	const history = useHistory();
	const { backOffice, wallet: walletLocale } = Locale;
	const [transactionList, setTransactionList] = useState([]);
	const { wallet_id } = useParams();
	const [meta, setMeta] = useState(null);
	const [filter, setFilter] = useState({
		search: undefined,
		name: undefined,
		page: 1,
	});
	const location = useLocation();
	const wallet = location.state?.wallet;

	useEffect(() => {
		const getTransaction = async () => {
			const res = await FetchTransaction(wallet_id, { ...filter });
			setTransactionList(res?.data?.data);
			debugger;
			setMeta(res?.data);
		};
		getTransaction();
	}, [filter.page]);

	const allTransitionRow =
		transactionList.length > 0 ? (
			transactionList.map((trans, index) => (
				<tr>
					<td width={"20%"}>{trans.transaction_number}</td>
					<td>{trans.transaction_type}</td>
					<td>{trans.action_type}</td>
					<td>
						{trans.current_balance} {wallet.currency}
					</td>
					<td>
						{trans.prev_balance}
						{wallet.currency}
					</td>
					<td>
						{trans.amount} {wallet.currency}
					</td>
				</tr>
			))
		) : (
			<tr>
				<td colSpan="6">
					<div className="product-no-data">
						<i className="fas fa-info-circle fa-lg"></i>{" "}
						<h4>{backOffice.noResult}</h4>
					</div>
				</td>
			</tr>
		);
	const goTo = (page) => {
		setFilter({ ...filter, page: +page });
	};

	return (
		<>
			{/* <div class="card bg-gray border-gray text-white-50 w-25 mt-3">
				<div class="card-body">
					<div className="d-flex">
						<h5 class="mb-1">{walletLocale.amount} : </h5>
						<p class="card-text mx-2">
							{wallet.amount} {wallet.currency}
						</p>
					</div>
				</div>
			</div> */}

			<div className="d-flex justify-content-between align-items-center my-3">
				<h6 className="main-title ">{walletLocale.transactions}</h6>
			</div>
			<div className=" table-responsive px-4 our-border">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>{walletLocale.TransactionsID}</th>
							<th>{walletLocale.Type}</th>
							<th>{walletLocale.Method}</th>
							<th>{walletLocale.currentBalance}</th>
							<th>{walletLocale.previousBalance}</th>
							<th>{walletLocale.amount}</th>
						</tr>
					</thead>

					<tbody>{allTransitionRow}</tbody>
				</table>
				<div className="px-2">
					<Pagination info={meta} goTo={goTo} />
				</div>
			</div>
		</>
	);
}
