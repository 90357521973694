import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";

export default function OfflineReservationRoom({
	updateRoomData,
	addNewRoom,
	removeRoom,
	roomLookups,
	room,
	index,
}) {
	function numberValidation(e) {
		let value = e.target.value;
		let maxValue = +e.target.max;
		if (value[0] == 0) {
			value = value.substring(1);
		} else if (+value > +maxValue) {
			value = maxValue;
		}
		return value;
	}
	return (
		<div className="d-flex align-items-center justify-content-start col-12 position-relative">
			{/* room type */}
			<div className="col-3">
				<div className="row mt-1">
					<div className="col-6 row p-0">
						<TextField
							label="Room Count"
							type="number"
							name="count"
							value={room?.count}
							onChange={(e) => {
								updateRoomData(index, "count", numberValidation(e));
							}}
							min={1}
							max={9}
						/>
					</div>
					<div className="col-6 p-SelectField-0 p-0">
						<SelectField
							label="Room Type"
							value={room?.room_type}
							options={roomLookups?.types}
							onChange={(e) => updateRoomData(index, "room_type", e)}
						/>
					</div>
				</div>
			</div>

			{/* room view */}
			<div className="col-2">
				<SelectField
					label="Room View"
					value={room?.room_view}
					options={roomLookups?.views}
					onChange={(e) => updateRoomData(index, "room_view", e)}
				/>
			</div>
			{/* number of adult */}
			<div className="col-1">
				<TextField
					label="Adult"
					type="number"
					name="number_of_adult"
					value={room?.number_of_adult}
					onChange={(e) => {
						updateRoomData(index, "number_of_adult", numberValidation(e));
					}}
					min={1}
					max={6}
				/>
			</div>
			{/* number of childs */}
			<div className="col-1">
				<TextField
					label="Child"
					type="number"
					name="number_of_childs"
					value={room?.number_of_childs}
					onChange={(e) => {
						updateRoomData(index, "number_of_childs", numberValidation(e));
					}}
					min={1}
					max={4}
				/>
			</div>
			{/* remove room */}
			{index > 0 && (
				<span
					className="position-absolute pointer user-select-none"
					style={{ top: "54%", right: "20px" }}
					onClick={() => removeRoom(index)}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="13.86"
						height="13.86"
						viewBox="0 0 13.86 13.86"
					>
						<path
							id="Path_3453"
							data-name="Path 3453"
							d="M8.93,2a6.93,6.93,0,1,0,6.93,6.93A6.951,6.951,0,0,0,8.93,2Zm2.564,8.524a.686.686,0,0,1-.97.97L8.93,9.9,7.336,11.494a.686.686,0,0,1-.97-.97L7.96,8.93,6.366,7.336a.686.686,0,0,1,.97-.97L8.93,7.96l1.594-1.594a.686.686,0,1,1,.97.97L9.9,8.93Z"
							transform="translate(-2 -2)"
							fill="#ff6868"
						/>
					</svg>
				</span>
			)}
			{/* add new room */}
			<span
				className="position-absolute pointer user-select-none"
				style={{ top: "54%", right: `${index > 0 ? "0" : "0"}` }}
				onClick={addNewRoom}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="20.321"
					height="20.321"
					viewBox="0 0 20.321 20.321"
				>
					<path
						id="Path_11489"
						data-name="Path 11489"
						d="M7.185,0a7.185,7.185,0,1,0,7.185,7.185A7.206,7.206,0,0,0,7.185,0ZM9.843,8.837A.711.711,0,0,1,8.837,9.843L7.185,8.19,5.532,9.843A.711.711,0,0,1,4.526,8.837L6.179,7.185,4.526,5.532A.711.711,0,0,1,5.532,4.526L7.185,6.179,8.837,4.526A.711.711,0,0,1,9.843,5.532L8.19,7.185Z"
						transform="translate(10.16) rotate(45)"
						fill="#28a745"
					/>
				</svg>
			</span>
		</div>
	);
}
