import Model from "components/model";
import { useGlobalState } from 'context/global';
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchSeason } from "services/lookups";
import Locale from "translations";

const BasicInfo = ({ state }) => {
	const { backOffice } = Locale;
	let locale = localStorage.getItem("currentLocale") || "en";
	const { allCountries } = useGlobalState();
	const [commissionList, setCommissionList] = useState();
	const [countries, setCountries] = useState([]);
	const [season, setSeason] = useState([]);
	const [selectorsSelected, setSelectorsSelected] = useState(null);

	const [platformDetails, setPlatformDetails] = useState({
		name: "",
		name_en: "",
		name_ar: "",
		website: "",
		reservation_officer_name: "",
		mobile: "",
		email: "",
		contract_start_date: null,
		contract_end_date: null,
		country_id: null,
		country: {
			name: "",
			name_en: "",
			name_ar: "",
		},
		is_active: 1,

		spin: false,
	});

	const { id } = useParams();

	useEffect(() => {
		if (id && state) {
			setPlatformDetails({
				...state?.platform,
			});
		}
	}, [id, state]);

	useEffect(() => {
		setCommissionList(platformDetails?.commissions);
	}, [platformDetails]);

	const [modal, setModal] = useState({
		isOpen: false,
		type: "",
		title: "",
	});

	const openModalHandler = (Selectors) => {
		setModal({
			isOpen: !modal.isOpen,
			type: "modal",
			title: "View Selectors",
			//item : {...item}
		});
		setSelectorsSelected(Selectors);
		console.log("Selectors", Selectors);
	};

	// Fetch Countries
	useEffect(() => {
		async function fetchLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);

			const resSeason = await fetchSeason();
			const format_season = resSeason.seasons.map((t) => ({
				value: t.id,
				label: t.name,
			}));
			setSeason(format_season);
		}

		fetchLookups();
	}, []);

	const allCommissions = commissionList?.map((item, index) => (
		<>
			<tr>
				<td>{item.name}</td>
				<td>{item.type}</td>
				<td>
					<button
						className="btn btn-link text-decoration-underlined"
						id={"selector" + index}
						onClick={() => openModalHandler(item.selectors)}
					>
						<i className="fas fa-eye"></i> {Object.keys(item.selectors).length}{" "}
						{backOffice.selectors}
					</button>
				</td>
				<td>
					{item.base_commission} {item.type == "base" ? "%" : backOffice.SAR}
				</td>
				<td>{item.safa_commission} %</td>
				<td>{item.platform_commission} %</td>
			</tr>
		</>
	));

	return (
		<>
			<div className="property-details">
				<div className="row m-0">
					<div className="col-12 p-0">
						<div className="row custom--row fix-text-white p-3">
							<div className="col-md-4">
								<div className="item--display">
									<div className="item--key">{backOffice.platformName}</div>
									<div className="item--value">{platformDetails.name}</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="item--display">
									<div className="item--key">
										{backOffice.platformWebsiteLink}
									</div>
									<div className="item--value">{platformDetails.website}</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="item--display">
									<div className="item--key">{backOffice.country}</div>
									<div className="item--value">
										{platformDetails.country?.["name_" + locale]}
									</div>
								</div>
							</div>

							<div className="col-md-4">
								<div className="item--display">
									<div className="item--key">{backOffice.dateOfContract}</div>
									<div className="item--value">
										{moment(platformDetails.contract_start_date).format(
											"DD-MM-YYYY"
										)}
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="item--display">
									<div className="item--key">{backOffice.contractEndDate}</div>
									<div className="item--value">
										{moment(platformDetails.contract_end_date).format(
											"DD-MM-YYYY"
										)}
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="item--display">
									<div className="item--key">
										{backOffice.reservationOfficerName}
									</div>
									<div className="item--value">
										{platformDetails.reservation_officer_name}
									</div>
								</div>
							</div>

							<div className="col-md-4">
								<div className="item--display">
									<div className="item--key">{backOffice.mobileNumber}</div>
									<div className="item--value">{platformDetails.mobile}</div>
								</div>
							</div>
							<div className="col-md-8">
								<div className="item--display">
									<div className="item--key">{backOffice.email}</div>
									<div className="item--value">{platformDetails.email}</div>
								</div>
							</div>
						</div>

						<div className="row custom--row p-3">
							<div className="col-md-12 head-manager p-0">
								<p>{backOffice.commissions}</p>
							</div>
							<div className="col our-border px-4">
								<table className="custom-table back-office-table table table-striped">
									<thead>
										<tr>
											<th>{backOffice.name}</th>
											<th>{backOffice.commissionType}</th>
											<th>{backOffice.selectors}</th>
											<th>{backOffice.baseCommission}</th>
											<th>{backOffice.safaCommission}</th>
											<th>{backOffice.platformCommission}</th>
										</tr>
									</thead>
									<tbody>
										{allCommissions ? (
											allCommissions
										) : (
											<tr>
												<td colSpan="6">
													<div className="product-no-data">
														<i className="fas fa-info-circle fa-lg"></i>{" "}
														<h4>{backOffice.noResult}</h4>
													</div>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>

						<div className="row custom--row p-3">
							<div className="col-md-12 head-manager p-0">
								<p>{backOffice.reservations}</p>
							</div>
							<div className="col py-4 our-border">
								<div className="row custom--row">
									<div className="col-3">
										<div
											className="item--display light-bg p-3"
											style={{ backgroundColor: "#00AD0D2E", color: "#fff" }}
										>
											<div className="item--key text-white">
												{backOffice.totalReservations}
											</div>
											<div className="item--value text-white">
												{platformDetails?.reservation_stats?.total_reservations}
											</div>
										</div>
									</div>
									<div className="col-3">
										<div className="item--display light-bg p-3">
											<div className="item--key">
												{backOffice.safaCommission}
											</div>
											<div className="item--value">
												{platformDetails?.reservation_stats?.safa_commission}
											</div>
										</div>
									</div>
									<div className="col-3">
										<div className="item--display light-bg p-3">
											<div className="item--key">
												{backOffice.platformCommission}
											</div>
											<div className="item--value">
												{
													platformDetails?.reservation_stats
														?.platform_commission
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{console.log("selectorsSelected", selectorsSelected?.country !== "")}
			<Model
				isOpen={modal.isOpen}
				type={modal.type}
				title={backOffice.selectors}
				toggle={openModalHandler}
				size="lg"
				hasFooter={false}
			>
				<div className="w-100 p-4">
					<div className="row custom--row">
						{selectorsSelected?.country !== "" && selectorsSelected?.country ? (
							<div className="col-12 mb-3">
								<div className="item--display light-bg p-3">
									<div className="item--key">{backOffice.country}</div>
									<div className="item--value">
										{selectorsSelected?.country === "all"
											? "All"
											: selectorsSelected?.country?.map((item) => {
												if (id) {
													let countryObject = countries.find(
														(country) => country.value === item.country_id
													);
													console.log("countryObject", countryObject);
													return countryObject.label + " , ";
												} else {
													let countryObject = countries.find(
														(country) => country.value === item
													);
													console.log("countryObject", countryObject);
													return countryObject.label + " , ";
												}
											})}
									</div>
								</div>
							</div>
						) : null}

						{selectorsSelected?.season !== "" && selectorsSelected?.season ? (
							<div className="col-12 mb-3">
								<div className="item--display light-bg p-3">
									<div className="item--key">{backOffice.season}</div>
									<div className="item--value">
										{selectorsSelected?.season === "all"
											? "All"
											: selectorsSelected?.season?.map((item) => {
												if (id) {
													let seasonObject = season.find(
														(itemSeason) => itemSeason.value === item.id
													);
													return seasonObject.label + " , ";
												} else {
													let seasonObject = season.find(
														(itemSeason) => itemSeason.value === item
													);
													return seasonObject.label + " , ";
												}
											})}
									</div>
								</div>
							</div>
						) : null}

						{selectorsSelected?.hotel !== "" && selectorsSelected?.hotel ? (
							<div className="col-12">
								<div className="item--display light-bg p-3">
									<div className="item--key">{backOffice.hotelLevel}</div>
									<div className="item--value">
										{selectorsSelected?.hotel === "all"
											? "All"
											: selectorsSelected?.hotel?.map((item) => {
												if (id) {
													return item?.name?.en + " , ";
												} else {
													return item + " , ";
												}
											})}
									</div>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</Model>
		</>
	);
};

export default BasicInfo;
