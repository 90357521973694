import Locale from "translations";

export default function Colums({
	toggleShow,
	show,
	columsList,
	handelCheck,
	checkedColums,
}) {
	const { booking } = Locale;

	const columsCheckList = columsList.map((item) => {
		return (
			<div key={item.id}>
				<input
					type="checkbox"
					className="mx-1  "
					checked={checkedColums.includes(item.id)}
					onChange={(e) => {
						handelCheck(item, e.target.checked);
					}}
				/>
				<span className="txt-colum mx-2">{item.name}</span>
			</div>
		);
	});

	return (
		<div className="w-100 position-relative   ">
			<div
				onClick={() => {
					toggleShow({ filter: false, colums: !show.colums });
				}}
				className={`border rounded filter-col  d-flex justify-content-between align-items-center p-2  ${
					show.colums ? "booking-active" : "txt-blue"
				}`}
			>
				<span className="">{booking.Colums}</span>
				<i class="fas fa-angle-down"></i>
			</div>

			{show.colums ? (
				<div className="booking-dropdown-colums border rounded p-2">
					{columsCheckList}
				</div>
			) : null}
		</div>
	);
}
