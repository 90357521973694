import ConfirmModal from "components/Modals/ConfirmModal";
import React from "react";
import Locale from "translations";

export default function GroupUser({ user,confirmIsOpen,
  setConfirmIsOpen,
  toggleConfirm }) {



  return (
    <>
    <tr>
      <td className="w-25">{user.user_name}</td>
      <td className="w-25">{user.email}</td>
      <td className="w-25">
        {user.status === "active" ? (
          <i class="far fa-check-circle icons-teamMange text-success"></i>
        ) : (
          <i class="far fa-times-circle icons-teamMange text-danger"></i>
        )}
      </td>
    </tr>
    

    </>
  );
}
