import TextField from "components/shared/TextField";
import { useEffect, useMemo, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import { addNotes, getListNotes } from "services/Request";
import { editConfirmation } from "services/vendor";
import Locale from "translations";

export default function Booking({ bookingInfo }) {
	const { backOffice, booking } = Locale;
	const { id } = useParams();

	const [notesState, setNotesState] = useState();
	const [notesInputs, setNotesInputs] = useState("");
	const [active, setactive] = useState(false);
	const [confirmationNumber, setConfirmationNumber] = useState(
		bookingInfo?.confirmation_number
	);

	const fetchNotes = async () => {
		const res = await getListNotes(id);
		setNotesState(res?.data?.data);
	};
	useEffect(() => {
		fetchNotes();
		setConfirmationNumber(bookingInfo?.confirmation_number);
	}, []);
	useEffect(() => {
		setConfirmationNumber(bookingInfo?.confirmation_number);
	}, [bookingInfo]);

	const addNotes1 = async () => {
		const res = await addNotes({
			id: bookingInfo?.brn,
			body: notesInputs,
		});
		if (res.status == 200) {
			store.addNotification({
				title: "Info!",
				message: "Add Note successfully!",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					fetchNotes();
				},
			});
			setNotesInputs("");
		}
	};

	const allNoteList = useMemo(() => {
		return notesState?.length > 0
			? notesState?.map((datas, index) => {
					return (
						<div className="bg-gray p-2 mt-2 border rounded" key={index}>
							<p className="m-0 txt-blue font-weight-bold">
								{datas.username} | {datas?.created_at}
							</p>
							<p className="m-0">{datas.body}</p>
						</div>
					);
			  })
			: [];
	}, [notesState]);

	const handleConfirmation = async () => {
		const res = await editConfirmation(id, {
			brn: bookingInfo?.brn,
			confirmation_number: confirmationNumber,
		});
		if (res.status == 200) {
			store.addNotification({
				title: "Info!",
				message: res?.data?.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					window.location.reload();
				},
			});
		}
	};

	return (
		<div className="mt-2">
			<div className="border bg-white">
				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView"> {booking.residence}</p>
					<p className="m-0 p-2 col-4 border-booking">
						{bookingInfo?.residence}
					</p>
					<p className="m-0 p-2 col-2 bg-bookingView">{booking.Agency}</p>
					<p className="m-0 p-2 col-4 border-booking">{bookingInfo?.agency}</p>
				</div>

				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView"> {booking.HotelName}</p>
					<p className="m-0 p-2 col-4 border-booking">
						{bookingInfo?.hotel_name}
					</p>
					<p className="m-0 p-2 col-2 bg-bookingView">{booking.CreatedBy}</p>
					<p className="m-0 p-2 col-4 border-booking">
						{bookingInfo?.created_by}
					</p>
				</div>

				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView"> {"Craeted At"}</p>
					<p className="m-0 p-2 col-4 border-booking">
						{bookingInfo?.created_at}
					</p>
					<p className="m-0 p-2 col-2 bg-bookingView">{"Payment Method"}</p>
					<p className="m-0 p-2 col-4 border-booking">
						{bookingInfo?.payment_method}
					</p>
				</div>

				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView"> {"Total Price"}</p>
					<p className="m-0 p-2 col-4 border-booking">
						{bookingInfo?.total_price}
						{bookingInfo?.currency}
					</p>
					<p className="m-0 p-2 col-2 bg-bookingView">
						{booking.ConfirmationNo}
					</p>
					{/* <p className="m-0 p-2 col-4">{bookingInfo?.confirmation_number}</p> */}
					<p
						className="m-0 col-4  border-booking  d-flex"
						style={{ background: "#fff" }}
					>
						<p className="m-0 col-11 inp-confirmation">
							<TextField
								type="text"
								hasLabel={false}
								value={confirmationNumber}
								onChange={(e) => {
									let ourvalue = e.target.value;
									setConfirmationNumber(e.target.value);
									{
										ourvalue == "" ? setactive(false) : setactive(true);
									}
								}}
							/>
						</p>
						<p
							className="m-0  col-1 pointer my-2 d-flex justify-content-end"
							style={{ background: "#fff" }}
							onClick={() => {
								handleConfirmation();
								setactive(false);
							}}
						>
							<span
								className={`btn btn-dark px-1 py-0 ${
									active == false ? "disabled" : ""
								}`}
							>
								{"save"}
							</span>
						</p>
					</p>
				</div>

				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView"> {booking.Passengers}</p>
					<p className="m-0 p-2 col-4 border-booking">
						{/* {bookingInfo?.payload?.rooms[0]?.passengers?.length} */}
						{bookingInfo?.payload?.rooms?.length > 0 &&
							bookingInfo.payload.rooms?.map(
								(item, index) => item.adults + item.children
							)}
					</p>
					<p className="m-0 p-2 col-2 bg-bookingView">{booking.TripStarts}</p>
					<p className="m-0 p-2 col-4 border-booking">
						<span className="text-success">{bookingInfo?.start_date}</span> -{" "}
						<span className="text-danger">{bookingInfo?.end_date}</span>
					</p>
				</div>

				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView">
						{" "}
						{booking.ContactEmail}
					</p>
					<p className="m-0 p-2 col-4 border-booking">
						{bookingInfo?.contact_email}
					</p>
					<p className="m-0 p-2 col-2 bg-bookingView">
						{booking.ContactPerson}
					</p>
					<p className="m-0 p-2 col-4 border-booking">
						{bookingInfo?.contact_person}
					</p>
				</div>

				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView"> {booking.Phone}</p>
					<p className="m-0 p-2 col-4 border-booking">{bookingInfo?.phone}</p>
					<p className="m-0 p-2 col-2 bg-bookingView ">
						{"Reservation Status"}
					</p>
					<p
						className={`m-0 p-2 col-4 ${
							bookingInfo?.reservation_status != "canceled"
								? "text-success"
								: "text-danger"
						}`}
					>
						{bookingInfo?.reservation_status}
					</p>
				</div>
			</div>

			{/* <div className="">
				<p className="bg-gray m-0 mt-2 p-2 font-weight-bold">{booking.Notes}</p>
				<div className="mt-2">
					<textarea
						value={notesInputs}
						className="w-100 p-4"
						placeholder={booking.addNotes}
						onChange={(e) => setNotesInputs(e.target.value)}
					/>
				</div>
				<div className="d-flex justify-content-end">
					<button
						className="btn companies-btn"
						// onClick={() => {
						// 	addNotes1();
						// }}
						disabled={notesInputs == ""}
					>
						{booking.addNotes}
					</button>
				</div>
			</div> */}

			{notesState?.length > 0 ? allNoteList : null}
		</div>
	);
}
