import { useState } from "react";
import Locale from "translations";
import AddEditSeason from "../AddEditSeason/AddEditSeason";
import SeasonsTableContent from "./SeasonsTableContent";

import ConfirmModal from "components/Modals/ConfirmModal";
import Model from "components/model";
import Pagination from "components/shared/Pagination";
import { store } from "react-notifications-component";
import { deleteSeason, listSeasons } from "services/seasons";

export default function SeasonsTable({
	setSeasonList,
	SeasonList,
	fetchData,
	meta,
	goTo,
}) {
	const { backOffice, confirmation } = Locale;
	const [modelSeasons, setModelSeasons] = useState(null);
	const [seasonsSelect, setSeasonsSelect] = useState(null);
	const [seasonIDSelect, setSeasonIDSelect] = useState(null);
	const [seasonsSelectIndex, setSeasonsSelectIndex] = useState(null);
	const [confirmIsOpen, setConfirmIsOpen] = useState(false);
	const [confirmData, setConfirmData] = useState({});

	const toggleConfirm = () => {
		setConfirmIsOpen(!confirmIsOpen);
	};
	const [modal, setModal] = useState({
		isOpen: false,
		type: "",
		title: "",
	});

	const openSeasonsModalHandler = (Model, Seasons, Index) => {
		setModal({
			isOpen: !modal.isOpen,
			type: "modal",
			title: "Edit Season",
		});

		setModelSeasons(Model);
		setSeasonsSelect(Seasons);
		setSeasonsSelectIndex(Index);
		setSeasonIDSelect(Seasons?.id);
	};

	const AllSeasons = SeasonList?.map((seasonItem, index) => (
		<SeasonsTableContent
			fetchData={fetchData}
			seasonItem={seasonItem}
			index={index}
			key={index}
			setSeasonList={setSeasonList}
			OpenSeasonsModalHandler={openSeasonsModalHandler}
			confirmIsOpen={confirmIsOpen}
			setConfirmIsOpen={setConfirmIsOpen}
			toggleConfirm={toggleConfirm}
			setConfirmData={setConfirmData}
		/>
	));

	const addSeason = () => {
		setModal({
			isOpen: !modal.isOpen,
			type: "modal",
			title: "Edit Season",
		});
	};
	const removeItem = async (seasonID) => {
		const res = await deleteSeason(seasonID);
		if (res.status === 200) {
			const resplatform = await listSeasons();
			setSeasonList(resplatform?.data?.seasons);
			store.addNotification({
				title: "Done!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			setConfirmIsOpen(false);
		}
	};
	return (
		<>
			<div className="px-4  our-border">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>{backOffice.seasonName}</th>
							<th>{backOffice.seasonStartDate}</th>
							<th>{backOffice.seasonEndDate}</th>
							<th>{backOffice.status}</th>
							<th>{backOffice.tools}</th>
						</tr>
					</thead>
					<tbody>
						{SeasonList?.length > 0 ? (
							AllSeasons
						) : (
							<tr>
								<td colSpan="7">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				{SeasonList?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}
			</div>
			<Model
				isOpen={modal.isOpen}
				type={modal.type}
				title={backOffice.editSeason}
				toggle={openSeasonsModalHandler}
				size="lg"
				hasFooter={false}
			>
				<div className="w-100 p-4">
					<AddEditSeason
						SeasonsSelect={seasonsSelect}
						setSeasonList={setSeasonList}
						addSeason={addSeason}
						SeasonID={seasonIDSelect}
						ModelSeasons={modelSeasons}
						SeasonsSelectIndex={seasonsSelectIndex}
					/>
				</div>
			</Model>

			<ConfirmModal
				IsOpen={confirmIsOpen}
				toggle={toggleConfirm}
				message={confirmation.confirmDeleteSeason}
				onConfirm={() => removeItem(confirmData?.id)}
			/>
		</>
	);
}
