import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useParams } from "react-router";
import CancelModal from "./CancelModal";
import Photo from "./Photo";
import Locale from "translations";

function Photos({ disabled, setDisabled, Files }) {
  const { backOffice } = Locale;
  const slides = useRef(null);
  // local photos
  const [canceled, setCanceled] = useState(false);


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div>
      <CancelModal isOpen={canceled} toggle={setCanceled} />
      <div className="my-3 head-manager">
        <p>{backOffice.uploadContractFile}</p>
      </div>

      <Carousel ref={slides} responsive={responsive} className="gallery">
        {Files?.length > 0 &&
          Files.map((photo, i) => {
            return (
              <div className="col-md-12 my-4">
                <div className="gallery-item">
                  <img src={photo} alt="gallery" className="img-fluid" />
                </div>
              </div>
            );
          })}
      </Carousel>

    </div>
  );
}

export default Photos;
