import { useState } from "react";
import { AddPaymentModal } from "../Main/AddPaymentModal";
import { EditPaymentModal } from "../Main/EditPaymentModal";
import { paymentsTotals, roomsTotals } from "../Main/helper";

function Payments(props) {
	const { details, handleReservationState, edit } = props;
	const payments = details["payments"];

	const [editPaymentModel, setEditPaymentModel] = useState(false);
	const [addPaymentModel, setAddPaymentModel] = useState(false);
	const [paymentIndex, setPaymentIndex] = useState(null);
	const { totalSellingPrice } = roomsTotals(details);
	const { paidPayments, unpaidPayments, refundPayments, total } =
		paymentsTotals(payments);

	const editPaymentModelHandler = () => {
		setEditPaymentModel((current) => !current);
		setPaymentIndex(null);
	};
	const addPaymentModelHandler = () => {
		setAddPaymentModel((current) => !current);
	};
	return (
		<div id="reserv-payment">
			{editPaymentModel && (
				<EditPaymentModal
					modalState={editPaymentModel}
					toggleModel={editPaymentModelHandler}
					details={details}
					paymentIndex={paymentIndex}
					handleReservationState={handleReservationState}
				/>
			)}

			{addPaymentModel && (
				<AddPaymentModal
					modalState={addPaymentModel}
					toggleModel={addPaymentModelHandler}
					details={details}
					handleReservationState={handleReservationState}
				/>
			)}

			<div className="payment_type_cards d-flex gap-3 ">
				{edit ? (
					<PaymentCard
						title={"Total required"}
						value={`${totalSellingPrice} ${details["currency"]}`}
						className="pay-gray"
					/>
				) : (
					""
				)}
				<PaymentCard
					title={"Total Payment"}
					value={`${total} ${details["currency"]}`}
					className="pay-gray"
				/>
				<PaymentCard
					title={"Paid"}
					value={`${paidPayments} ${details["currency"]}`}
					className="	"
				/>
				<PaymentCard
					title={"Unpaid"}
					value={`${unpaidPayments} ${details["currency"]}`}
					className="pay-danger"
				/>
				{refundPayments != 0 ? (
					<PaymentCard
						title={"Refund"}
						value={`${refundPayments} ${details["currency"]}`}
						className="pay-danger"
					/>
				) : (
					""
				)}
			</div>
			{edit ? (
				<div className="d-flex align-self-end justify-content-end">
					{" "}
					<button
						className="btn btn-primary mb-2"
						onClick={() => {
							addPaymentModelHandler();
						}}
					>
						Add Payment
					</button>
				</div>
			) : (
				""
			)}
			<div className="border px-3 payment_table">
				<table className="table">
					<thead>
						<tr>
							<th scope="col">Action</th>
							<th scope="col">Type</th>
							<th scope="col">Option Date</th>
							<th scope="col">Amount</th>
							<th scope="col">payment Method</th>
							<th scope="col">payment Date</th>
							<th scope="col">Status</th>
							<th scope="col">Actions</th>
						</tr>
					</thead>
					<tbody>
						{payments.map((i, index) => {
							const refund = i.action.includes("refund");
							const status =
								i.status === "confirmed"
									? "table_col_confirmed"
									: "table_col_pending";
							return (
								<tr
									key={i.id ?? i.key}
									className={`${refund ? "table_row_refund" : ""}`}
								>
									<td>{i.action}</td>
									<td>{i.type}</td>
									<td>{i.option_date}</td>
									<td>{i.amount}</td>
									<td>{i.payment_method}</td>
									<td>{i.payment_date}</td>
									<td className={status}>{i.status}</td>
									<td>
										{edit ? (
											<>
												{i.status == "pending" ||
												i.action == "refund Fees" ||
												i.action == "refund" ? (
													<>
														<i
															onClick={() => {
																editPaymentModelHandler();
																setPaymentIndex(index);
															}}
															// className="remove_add_icon"
															className="fas fa-edit text-info  mx-1"
														/>
														<i
															onClick={() => {
																let x = payments.filter(
																	(res, Ind) => Ind != index
																);
																handleReservationState({
																	name: "payments",
																	value: x,
																});
															}}
															className="fas fa-trash text-danger  mx-1"
														/>
													</>
												) : (
													""
												)}
												{/* <i className="fas fa-eye text-dark  mx-1" /> */}
											</>
										) : (
											""
										)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default Payments;

const PaymentCard = (props) => {
	const { className = "", title, value } = props;
	return (
		<div className={`card mb-3 shadow-sm border  ${className}`}>
			<div className="card-body text-center p-0">
				<p className="card-text m-0">{title}</p>
				<h5 className="card-title m-0">{value} </h5>
			</div>
		</div>
	);
};
