import { useGlobalDispatch } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { Link, NavLink, useRouteMatch } from "react-router-dom";
import { Collapse } from "reactstrap";
import { getPermissions } from "services/auth";
import Locale from "translations";
import {
	AddNewIcon,
	AllIcon,
	Arrow,
	BankTransferIcon,
	BookingsIcon,
	GeneralConfigurationIcon,
	MainMenuIcon,
	TeamIcon,
	TourismCompanyIcon,
	WalletIcon,
} from "./Icons";

const SideBar = ({ isOpen, isMobile }) => {
	const { backOffice, services, vendor, payment } = Locale;
	let { url } = useRouteMatch();
	const dispatch = useGlobalDispatch();
	const [routes, setRoutes] = useState([
		// {
		// 	id: 3,
		// 	icon: <PropertyIcon />,
		// 	text: `${backOffice.propertyManagement}`,
		// 	url: "/property-management?page=1",
		// 	permission: ["Property-management"],
		// 	show: url.includes("property-managemen"),
		// 	active: url.includes("property-managemen"),
		// },
		{
			id: 4,
			icon: <TourismCompanyIcon />,
			text: `${backOffice.tourismCompany}`,
			url: "tourism-company",
			permission: ["Tourism-Company"],
			show: url.includes("tourism-company"),
			active: url.includes("tourism-company"),
			subNavs: [
				{
					id: 44,
					url: "/tourism-company",
					text: `${backOffice.allTourismCompanies}`,
					icon: <AllIcon />,
				},
				{
					id: 45,
					url: "/tourism-company/add-company",
					text: `${backOffice.newTourismCompany}`,
					icon: <AddNewIcon />,
				},
			],
		},
		{
			id: 5,
			icon: <BookingsIcon />,
			text: `${backOffice.Bookings}`,
			url: "/bookings",
			permission: ["Bookings"],
			show: url.includes("bookings"),
			active: url.includes("bookings"),
		},
		// {
		// 	id: 16,
		// 	icon: <ManualReservationIcon />,
		// 	text: `${backOffice.manualReservation}`,
		// 	url: "manual-reservations",
		// 	permission: [""],
		// 	show: url.includes("manual-reservations"),
		// 	active: url.includes("manual-reservations"),
		// 	subNavs: [
		// 		{
		// 			id: 160,
		// 			url: "/manual-reservations/sbs-reservations",
		// 			text: `${backOffice.sbsReservation}`,
		// 			icon: <DatePickerIcon />,
		// 		},
		// 		{
		// 			id: 161,
		// 			url: "/manual-reservations/offline-reservations?page=1",
		// 			text: `${backOffice.offlineReservation}`,
		// 			icon: <DatePickerIcon />,
		// 		},
		// 	],
		// },
		// {
		// 	id: 6,
		// 	icon: <AllIcon />,
		// 	text: `${backOffice.requests}`,
		// 	url: "/requests",
		// 	permission: [
		// 		"Visa-Requests",
		// 		"Transportation-Requests",
		// 		"Ground-Service",
		// 	],
		// 	show: url.includes("/requests"),
		// 	active: url.includes("/requests"),
		// 	subNavs: [
		// 		{
		// 			id: 60,
		// 			url: "/online-visa/requests/waiting",
		// 			text: `${backOffice.Visa}`,
		// 			icon: <VisaIcon />,
		// 			permission: ["Visa-Requests"],
		// 		},
		// 		{
		// 			id: 61,
		// 			url: "/requests/transportation/waiting",
		// 			text: `${backOffice.Transportation}`,
		// 			icon: <TransportationIcon />,
		// 			permission: ["Transportation-Requests"],
		// 		},
		// 		{
		// 			id: 62,
		// 			url: "/requests/ground/waiting",
		// 			text: `${backOffice.GroundService}`,
		// 			icon: <GroundServiceIcon />,
		// 			permission: ["Ground-Service"],
		// 		},
		// 	],
		// },

		// {
		// 	id: 7,
		// 	icon: <ContractManagementIcon />,
		// 	text: `${backOffice.contractManagement}`,
		// 	url: "/contract",
		// 	permission: ["Contract-Requests", "Contract-List"],
		// 	show: url.includes("contract"),
		// 	active: url.includes("contract"),
		// 	subNavs: [
		// 		{
		// 			id: 70,
		// 			url: "/contract-request?page=1",
		// 			text: `${backOffice.contractRequest}`,
		// 			icon: <ContractRequestIcon />,
		// 			permission: ["Contract-Requests"],
		// 		},
		// 		{
		// 			id: 71,
		// 			url: "/contract-list?page=1",
		// 			text: `${backOffice.contractList}`,
		// 			icon: <AllIcon />,
		// 			permission: ["Contract-List"],
		// 		},
		// 	],
		// },
		// {
		// 	id: 8,
		// 	icon: <CommissionsIcon />,
		// 	text: `${backOffice.commissions}`,
		// 	url: "-commissions-",
		// 	permission: ["Platform-Commission", "Hotel-Commission"],
		// 	show: url.includes("-commissions-"),
		// 	active: url.includes("-commissions-"),
		// 	subNavs: [
		// 		{
		// 			id: 80,
		// 			url: "/platform-commissions-list",
		// 			text: `${backOffice.platformCommissions}`,
		// 			icon: <PlatformCommissionsIcon />,
		// 			permission: ["Platform-Commission"],
		// 		},
		// 		{
		// 			id: 81,
		// 			url: "/hotel-commissions-list",
		// 			text: `${backOffice.hotelCommissions}`,
		// 			icon: <HotelCommissionsIcon />,
		// 			permission: ["Hotel-Commission"],
		// 		},
		// 	],
		// },
		// {
		// 	id: 9,
		// 	icon: <SeasonsIcon />,
		// 	text: `${backOffice.seasons}`,
		// 	url: "/seasons?page=1",
		// 	permission: ["Season"],
		// 	show: url.includes("/seasons"),
		// 	active: url.includes("/seasons"),
		// },
		{
			id: 10,
			icon: <GeneralConfigurationIcon />,
			text: `${backOffice.generalConfiguration}`,
			url: "-configuration",
			permission: [
				"Visa-Configuration",
				"Ground-Configuration",
				"Bank-Transfer-Details",
			],
			show: url.includes("-configuration"),
			active: url.includes("-configuration"),
			subNavs: [
				// {
				// 	id: 100,
				// 	permission: ["Visa-Configuration"],
				// 	url: "/visa-configuration",
				// 	icon: <VisaIcon />,
				// 	text: `${backOffice.visaConfiguration}`,
				// },
				// {
				// 	id: 101,
				// 	permission: ["Ground-Configuration"],
				// 	url: "/Ground-configuration",
				// 	icon: <GroundServiceIcon />,
				// 	text: `${backOffice.Groundconfiguration}`,
				// },
				// {
				// 	id: 102,
				// 	permission: ["Ground-Configuration"],
				// 	url: "/Transportation-configuration",
				// 	icon: <TransportationIcon />,
				// 	text: `${services.transportation}`,
				// },
				{
					id: 103,
					permission: ["Bank-Transfer-Details"],
					url: "/bank-configuration",
					icon: <BankTransferIcon />,
					text: `${backOffice.BankTransferDetails}`,
				},
				// {
				// 	id: 104,
				// 	permission: [],
				// 	url: "/exchange-rate",
				// 	icon: <CommissionsIcon />,
				// 	text: `${backOffice.exchangeRate}`,
				// },
			],
		},
		// {
		// 	id: 11,
		// 	icon: <SuppliersIcon />,
		// 	text: `${backOffice.suppliers}`,
		// 	url: "/suppliers",
		// 	permission: ["Supplier"],
		// 	show: url.includes("/suppliers"),
		// 	active: url.includes("/suppliers"),
		// },
		// {
		// 	id: 12,
		// 	icon: <ActivityLogs />,
		// 	text: `${backOffice.activityLogs}`,
		// 	url: "/activityLogs",
		// 	permission: ["manage_activity_logs"],
		// 	show: url.includes("/activityLogs"),
		// 	active: url.includes("/activityLogs"),
		// },
		// {
		// 	id: 13,
		// 	icon: <CheckMarkIcon />,
		// 	text: `${vendor.vendor}`,
		// 	url: "/vendor-commission",
		// 	permission: ["manage_vendors"],
		// 	show: url.includes("/vendor-commission"),
		// 	active: url.includes("/vendor-commission"),
		// },
		// {
		// 	id: 14,
		// 	icon: <ToolsIcon />,
		// 	text: `${vendor.tools}`,
		// 	url: "/marketing-offers-list",
		// 	permission: ["manage_marketing_offers"],
		// 	show: url.includes("/marketing-offers-list"),
		// 	active: url.includes("/marketing-offers-list"),
		// 	subNavs: [
		// 		{
		// 			id: 140,
		// 			permission: [],
		// 			url: "/marketing-offers-list",
		// 			icon: <MarketingOfferIcon />,
		// 			text: "Marketing Offers",
		// 		},
		// 		{
		// 			id: 140,
		// 			permission: [],
		// 			url: "/popup-ads",
		// 			icon: <MarketingOfferIcon />,
		// 			text: "Popup Ads",
		// 		},
		// 	],
		// },
		// {
		// 	id: 15,
		// 	icon: <ManualSupplier />,
		// 	text: `Manual Suppliers`,
		// 	url: "/manual-suppliers",
		// 	permission: [],
		// 	show: url.includes("manual-suppliers"),
		// 	active: url.includes("manual-suppliers"),
		// },
		// {
		// 	id: 17,
		// 	icon: <TeamIcon />,
		// 	text: "B2C",
		// 	url: "/b2c-users",
		// 	permission: [],
		// 	show: false,
		// 	active: false,
		// 	subNavs: [
		// 		{
		// 			id: 170,
		// 			permission: [],
		// 			url: "/b2c-users",
		// 			icon: <TravelerIcon fill="#99a4b1" />,
		// 			text: `${backOffice.users}`,
		// 		},
		// 		{
		// 			id: 171,
		// 			permission: [],
		// 			url: "/all-bookings",
		// 			icon: <AllBookings />,
		// 			text: `${backOffice.allBooking}`,
		// 		},
		// 		{
		// 			id: 172,
		// 			permission: [],
		// 			url: "/all-transactions",
		// 			icon: <CommissionsIcon />,
		// 			text: `All Transctions`,
		// 		},
		// 		{
		// 			id: 173,
		// 			permission: [],
		// 			url: "/b2c-Commission",
		// 			icon: <CommissionsIcon />,
		// 			text: `Commission`,
		// 		},
		// 	],
		// },
		// {
		// 	id: 18,
		// 	icon: <FinancialIcon />,
		// 	text: "Financial",
		// 	url: "/Financial",
		// 	permission: [],
		// 	show: false,
		// 	active: false,
		// 	subNavs: [
		// 		{
		// 			id: 181,
		// 			permission: [],
		// 			url: "/Financial-onlineSuppliers",
		// 			icon: <FinancialIcon />,
		// 			text: `${backOffice.OnlineSuppliers}`,
		// 		},
		// 		{
		// 			id: 182,
		// 			permission: [],
		// 			url: "/Financial-offlineSuppliers",
		// 			icon: <FinancialIcon />,
		// 			text: `${backOffice.OfflineSuppliers}`,
		// 		},
		// 		{
		// 			id: 172,
		// 			permission: [],
		// 			url: "/Financial-platforms",
		// 			icon: <FinancialIcon />,
		// 			text: `${backOffice.Platforms}`,
		// 		},
		// 	],
		// },
		{
			id: 2,
			icon: <TeamIcon />,
			text: `${backOffice.teamManagement}`,
			url: "management-team/users",
			permission: ["Team-Management"],
			show: url.includes("management-team"),
			active: url.includes("management-team"),
		},
		{
			id: 5,
			icon: <WalletIcon />,
			text: `${backOffice.wallet}`,
			url: "/wallet",
			permission: [],
			show: url.includes("wallet"),
			active: url.includes("wallet"),
		},
	]);

	useEffect(() => {
		async function fetchPermissions() {
			const res = await getPermissions();
			dispatch({ type: "getPermissions", payload: res?.data });
		}
		fetchPermissions();
	}, []);

	return (
		<>
			<nav
				id="sideNav"
				className={`${isOpen && !isMobile ? "opend" : "closed"}`}
			>
				<ul className="sidenav_list">
					<li className="menu-title">Menu</li>
					<li className="sidenav_item">
						<Link
							exact
							to="/"
							className={`sidenav_link ${url == "/" ? "active" : ""}`}
						>
							<span className="sidenav_icon">
								<MainMenuIcon />
							</span>
							<span className="dash-text"> {backOffice.mainMenu}</span>
						</Link>
					</li>
					{routes.map((dashboard) => {
						const routeHasSubNav =
							dashboard?.subNavs && dashboard?.subNavs.length > 0
								? true
								: false;
						let active = url.includes(dashboard.url);

						return (
							<ShowForPermission permission={dashboard.permission}>
								<li className="sidenav_item">
									<NavLink
										to={dashboard.url}
										className={`sidenav_link ${dashboard.show ? "active" : ""}`}
										onClick={(e) => {
											routeHasSubNav && e.preventDefault();
											if (isOpen) {
												setRoutes(
													routes.map((route) => {
														return {
															...route,
															show:
																route.id === dashboard.id
																	? !dashboard.show
																	: false,
															active:
																route.id === dashboard.id ? !active : false,
														};
													})
												);
											}
										}}
									>
										<span className="sidenav_icon">{dashboard.icon}</span>
										<span className="dash-text"> {dashboard.text}</span>
										{routeHasSubNav ? (
											<span
												className={`sidenav_arrow ${
													dashboard.show && routeHasSubNav ? "toggled" : ""
												}`}
											>
												<Arrow />
											</span>
										) : null}
									</NavLink>
									{/* dropdown */}
									{routeHasSubNav ? (
										<ul className="sidenav_dropdown">
											{isOpen ? (
												<Collapse isOpen={dashboard.show}>
													{dashboard?.subNavs?.map((subNav) => {
														return (
															<li className="dropdown_item">
																<NavLink
																	exact
																	to={subNav?.url}
																	value={dashboard?.id}
																	className="dropdown_link"
																	activeClassName="active"
																>
																	<span className="sidenav_icon">
																		{subNav.icon}
																	</span>
																	<span className="dash-text">
																		{" "}
																		{subNav.text}
																	</span>
																</NavLink>
															</li>
														);
													})}
												</Collapse>
											) : (
												dashboard?.subNavs?.map((subNav) => {
													return (
														<li className="dropdown_item">
															<NavLink
																exact
																to={subNav?.url}
																value={dashboard?.id}
																className="dropdown_link"
																activeClassName="active"
															>
																{subNav?.text}
															</NavLink>
														</li>
													);
												})
											)}
										</ul>
									) : null}
								</li>
							</ShowForPermission>
						);
					})}
				</ul>
			</nav>
		</>
	);
};

export default SideBar;
