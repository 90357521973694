import SelectField from "components/shared/SelectField";
import { useGlobalState } from "context/global";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link } from "react-router-dom";
import { cleanEmpty } from "services/general";
import {
	addNewGroundDestination,
	ServiceTypeLookup,
	viewGroundNewConfigurations,
} from "services/ground";
import Locale from "translations";
import AddVisa from "./addCountry/addVisa";

export default function GroundConfiguration() {
	const { backOffice } = Locale;
	const [arrayCountry, setArrayCountry] = useState([]);
	let locale = localStorage.getItem("currentLocale") || "en";
	const { allCountries } = useGlobalState();
	const [destination, setDestination] = useState([]);
	const [indexConfiguration, setIndexConfiguration] = useState(-1);
	const [ShowAdd, setShowAdd] = useState(false);

	const [inputsState, setInputsState] = useState([]);
	const [serviceType, setServiceType] = useState([]);
	useEffect(() => {
		async function serviceLookups() {
			const res = await ServiceTypeLookup();
			const format = res?.data?.data?.map((t) => ({
				value: t.id,
				label: t.name,
			}));
			setServiceType(format);
		}
		serviceLookups();
	}, []);
	const add = () => {
		add2();
	};
	const [viewConfiguration, setviewConfiguration] = useState([]);
	const [country, setCountry] = useState({});
	const add2 = async () => {
		if (viewConfiguration?.length > 0) {
			console.log("dest0000", [...destination]);
			let x = viewConfiguration.map((res, index) => {
				return {
					destination_id: res.country_id ? res.country_id : res.country_code,
					residences: res.residences.map((residence) => {
						return {
							currency_code:
								residence.currency_code == ""
									? residence?.currency?.label
									: residence?.currency?.label ?? residence.currency_code,
							price: residence.price,
							residence_id:
								residence?.residence_id &&
								!residence?.residence?.hasOwnProperty("value")
									? residence.residence_id
									: residence?.residence?.value
									? residence?.residence?.value
									: residence?.residence?.id,
							// residence_id: residence?.residence_id
							// 	? residence?.residence_id?.id
							// 	: residence?.residence_id?.value,

							// add new fields
							//service_type: 1,
							service_type: residence?.service_type?.id
								? residence?.service_type?.id
								: residence?.service_type?.value,

							start_date: residence?.start_date
								? moment(residence?.start_date).format("YYYY-MM-DD")
								: residence?.start_date,
							end_date: residence?.end_date
								? moment(residence?.end_date).format("YYYY-MM-DD")
								: residence?.end_date,
							expiration_days: +residence?.expiration_days,
						};
					}),
				};
			});
			const res = await addNewGroundDestination({
				destinations: cleanEmpty(x),
			});
			if (res.status == 200) {
				view();
				store.addNotification({
					title: "Info!",
					message: res?.msg ? res?.msg : "done successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		} else {
			const res = await addNewGroundDestination({
				destinations: [],
				reset_config: 1,
			});
			if (res.status == 200) {
				view();
				store.addNotification({
					title: "Info!",
					message: res?.msg ? res?.msg : "done successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		}
	};

	{
		/********* View Configuration  ********* */
	}

	useEffect(() => {
		view();
	}, []);
	const view = async () => {
		const res = await viewGroundNewConfigurations();

		if (res.status == 200) {
			setviewConfiguration(res?.data?.data);
			setInputsState(res?.data?.data);
		}
	};

	const [countries, setCountries] = useState([]);
	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);

	const AddCountry = () => {
		let x = viewConfiguration;
		x.push({
			country: country?.label,
			country_code: country.value,
			residences: [{ residence: " ", currency: " " }],
		});
		setShowAdd(false);
		setviewConfiguration([...x]);
	};
	return (
		<div className="visa-configuration">
			{viewConfiguration && viewConfiguration?.length > 0 ? (
				<>
					<AddVisa
						destination={destination}
						setDestination={setDestination}
						indexConfiguration={indexConfiguration}
						setIndexConfiguration={setIndexConfiguration}
						inputsState={inputsState}
						setInputsState={setInputsState}
						arrayCountry={arrayCountry}
						setArrayCountry={setArrayCountry}
						viewConfiguration={viewConfiguration}
						setviewConfiguration={setviewConfiguration}
						country={country}
						countries={countries}
						serviceType={serviceType}
					/>
				</>
			) : null}
			<div className="d-flex justify-content-between align-items-start pb-5">
				{ShowAdd ? (
					<div className="row w-75">
						<div className="col-4 add-country-field-config">
							<SelectField
								label={backOffice.country}
								placeholder={backOffice.country}
								options={countries}
								id="basic-countries"
								name="country"
								onChange={(e) => {
									setCountry(e);
								}}
								value={country}
							/>
						</div>
						<div className="col-2 mt-4">
							<button
								className="btn btn-success  px-4"
								onClick={() => AddCountry()}
							>
								Add
							</button>
						</div>
					</div>
				) : (
					<button
						className="btn btn-success p-2 my-2"
						onClick={() => {
							setShowAdd(true);
						}}
					>
						<i class="fas fa-plus-circle text-white mx-1"></i>ADD COUNTRY
					</button>
				)}

				<div className="row custom--row mt-2 flex-row-reverse  pb-5 mb-5">
					<div className="mx-1">
						<button className="btn btn-primary w-100 px-5" onClick={add}>
							{backOffice.save}
						</button>
					</div>
					<div className="mx-1">
						<Link className={`btn btn-sbs-secondary w-100`} to={`/`}>
							{backOffice.back}
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
