import walletAdd from "assets/images/wallet/wallet-add.svg";
import FsLightbox from "fslightbox-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import {
	getReceipt,
	showRequests,
	showTransaction,
} from "services/multiWallets";
import Locale from "translations";

export default function ViewTransactionPopup({ isOpen, toggle, popupInfo }) {
	const { wallet } = Locale;
	const [deal, setDeal] = useState({});
	const { company_id } = useParams();
	const [toggler, setToggler] = useState(false);
	useEffect(() => {
		let res;
		if (isOpen) {
			const fetchInfo = async () => {
				if (popupInfo?.type === "request") {
					res = await showRequests(popupInfo.deal_id, {
						company_id,
						payment_card_id: popupInfo.wallet_id,
					});
				} else {
					res = await showTransaction(popupInfo.deal_id, {
						company_id,
						payment_card_id: popupInfo.wallet_id,
					});
				}
				if (res?.status === 200) {
					const resReceipt = await getReceipt(res?.data?.data?.photo);
					setDeal({ ...res.data.data, resReceipt: resReceipt.config.url });
				}
			};
			fetchInfo();
		}
	}, [isOpen]);

	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
				<p className=" text-secondary font-weight-bold">
					{wallet.ViewTransactions}
				</p>
				<i
					className="far fa-times-circle fa-lg text-secondary "
					onClick={toggle}
				></i>
			</div>

			<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3">
				<img src={walletAdd} alt="" />
				<div className="row m-0 p-0 mt-4 justify-content-center  align-items-center">
					<p className="col-5  font-weight-bold ">{wallet.receiptNumber} </p>
					<p className="col-5   ">
						{popupInfo?.type === "request"
							? deal.receipt_number
							: deal.transaction_number}
					</p>
					<p className="col-5  font-weight-bold ">{wallet.company_name}</p>

					<p className="col-5   ">{deal.company_name}</p>
					<p className="col-5  font-weight-bold ">{wallet.requestDate}</p>
					<p className="col-5   ">{deal.created_at}</p>
					<p className="col-5  font-weight-bold ">{wallet.amount}</p>
					<p className="col-5   ">{deal.amount}</p>
					<p className="col-5  font-weight-bold ">Deposit Photo</p>
					<figure className="col-5">
						<img
							src={deal.resReceipt}
							style={{ width: "inherit" }}
							alt="broken"
							onClick={() => setToggler(!toggler)}
						/>
					</figure>
					<FsLightbox toggler={toggler} sources={[deal.resReceipt]} />
				</div>
			</ModalBody>
		</Modal>
	);
}
