import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Collapse } from "reactstrap";
import Locale from "translations";
import GroupUser from "./GroupUser";

export default function Group({ group, toggleAdd, removeGroup }) {
	const [isOpen, setIsOpen] = useState(false);
	const { backOffice, Companies } = Locale;
	const { company_id } = useParams();

	const toggle = () => setIsOpen(!isOpen);
	let currentLocale = localStorage.getItem("currentLocale") ?? "en";

	const allUser = group?.users?.map((user) => {
		return <GroupUser user={user} />;
	});

	return (
		<div className="mt-3">
			<div className="row  d-flex justify-content-between align-items-center py-3 px-2 m-0   collapse-border">
				<p className="col-3 font-weight-bold">{group.name}</p>

				<p className="col-3 d-flex  align-items-center">
					<span className=" circly member-num  mx-1">
						{group?.users?.length}
					</span>
					{Companies.employees}
				</p>

				<p
					className="col-3 d-flex pointer   align-items-center"
					onClick={() => {
						toggleAdd(group);
					}}
				>
					<span className="circly member-num  mx-1 ">+</span>
					{Companies.addEmployee}
				</p>

				<p className="col-1 d-flex align-items-center">
					<Link
						to={{
							pathname: `/tourism-company/edit/group/${company_id}`,
							state: { group: group },
						}}
					>
						<i class="fas fa-edit mx-2"></i>
					</Link>

					{/*          <i class="fas fa-check text-success mx-2"></i> */}

					<i
						class="fas fa-trash text-danger pointer "
						onClick={() => {
							removeGroup(group.id);
						}}
					></i>
				</p>

				{/*     <Link
          type="button"
          class="col text-white d-flex justify-content-end align-items-center  px-4"
          to={`/management-team/edit/group/${group.id}`}
        >
          <i class="far fa-edit mx-2"></i>
          {teamManagement.editTeam}
        </Link> */}

				{isOpen ? (
					<i
						className="fas fa-angle-down icons_label font-mobile  mobile-remove"
						onClick={toggle}
					></i>
				) : currentLocale === "ar" ? (
					<i
						className="fas fa-angle-left  icons_label font-mobile mobile-remove "
						onClick={toggle}
					></i>
				) : (
					<i
						className="fas fa-angle-right  icons_label font-mobile mobile-remove"
						onClick={toggle}
					></i>
				)}
			</div>

			<Collapse isOpen={isOpen}>
				<table className="custom-table back-office-table table collapse-table-border table-striped">
					<thead>
						{allUser?.length > 0 ? (
							<tr>
								<th className="CompanyHead-title w-25 ">
									{Companies.userName}
								</th>
								<th className="CompanyHead-title w-25 ">{backOffice.email}</th>
								<th className="CompanyHead-title w-25 ">{backOffice.status}</th>
							</tr>
						) : (
							<th className="CompanyHead-title text-center">
								{backOffice.noUsers}{" "}
								<i class="fas fa-exclamation-circle text-warning"></i>
							</th>
						)}
					</thead>
					<tbody>{allUser?.length > 0 ? allUser : null}</tbody>
				</table>
			</Collapse>
		</div>
	);
}
