export default function Transaction({ transaction, toggleView }) {
	return (
		<tr>
			<td>{transaction?.transaction_number}</td>
			<td>{transaction?.transaction_type} </td>
			<td>{transaction?.amount} </td>
			<td>{transaction?.action_type}</td>
			<td>{transaction?.created_at}</td>
			<td
				className={`${
					transaction?.status === "approved" ? "text-success" : "text-danger"
				}`}
			>
				{transaction?.status}
			</td>
			<td>{transaction?.description}</td>
			<td>{transaction?.action_by}</td>
			<td>{transaction?.payment_method}</td>

			<td>
				{" "}
				{/* <i
          class="fas fa-eye "
          onClick={() =>
            toggleView(
              transaction.payment_card_id,
              transaction.id,
              "transaction"
            )
          }
        ></i> */}
			</td>
		</tr>
	);
}
