import closeButtonImage from "assets/images/offline-reservation-close-btn.svg";
import SelectField from 'components/Form/SelectField/SelectField';
import { Modal } from "reactstrap";

export default function ConvertOnlineModal(props) {
  const { convertModalIsOpen, setConvertModalIsOpen, companiesLookups, selectedCompany, setSelectedCompany, handleConvertToOnline } = props;

  return (
    <Modal isOpen={convertModalIsOpen} centered={true}>
      <div className="py-3">
        <div className="d-flex flex-row align-items-center justify-content-between px-3 w-100">
          <h4 className="font-weight-bolder m-0">Convert To Online</h4>
          <button type='button' className="btn modal_btn-close p-0" onClick={() => {
            setConvertModalIsOpen(false);
            setSelectedCompany(null);
          }}>
            <img src={closeButtonImage} alt="" />
          </button>
        </div>
        <div className="w-75 my-0 pt-3 mx-auto">
          <SelectField
            hasLabel={true}
            label="Company Name / Email Address"
            placeholder="Company Name / Email Address"
            options={companiesLookups}
            value={selectedCompany?.label}
            onChange={(e) => {
              setSelectedCompany(e);
            }}
          />
          {selectedCompany ?
            <div id="reserv-details" className="px-2 py-2  text-capitalize">
              <>
                <p className="mb-1 font-weight-bolder">Company Details</p>
                <div className="row">
                  <div class="reserv_details_info_key col-5">Company Name</div>
                  <div class="reserv_details_info_value col-7">{selectedCompany?.name}</div>
                </div>

                <div className="row">
                  <div class="reserv_details_info_key col-5">Email Address</div>
                  <div class="reserv_details_info_value col-7 text-lowercase">{selectedCompany?.business_email}</div>
                </div>

                <div className="row">
                  <div class="reserv_details_info_key col-5">Country</div>
                  <div class="reserv_details_info_value col-7">{selectedCompany?.country?.name}</div>
                </div>

                <div className="row">
                  <div class="reserv_details_info_key col-5">Name</div>
                  <div class="reserv_details_info_value col-7">{selectedCompany?.responsible_name}</div>
                </div>

                <div className="row">
                  <div class="reserv_details_info_key col-5">Phone</div>
                  <div class="reserv_details_info_value col-7">{selectedCompany?.phone?.phone_code}{selectedCompany?.phone?.phone}</div>
                </div>
              </>
            </div>

            :
            null
          }
          <div className="w-100 px-2 py-0 my-0">
            <button type='button' className={`btn btn-success my-2 w-100 ${selectedCompany?.value ? '' : 'disabled text-muted'}`}
              disabled={selectedCompany?.value ? false : true}
              onClick={handleConvertToOnline}
            >
              Convert
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
