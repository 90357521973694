import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from 'context/global';
import { getNotification } from "helpers/makeNotifications";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { addBranch, deleteBranch, editBranch, listBranches } from "services/companies";
import Locale from "translations";
import LooksupCities from "../lookups/fetchCities";
import ListBranches from "./ListBranches";

export default function Branches() {
  const { backOffice, Companies, teamManagement } = Locale;
  const { company_id } = useParams();
  const { allCountries } = useGlobalState();
  const [countries, setCountries] = useState([]);
  const [status, setStatus] = useState("add");
  const [reLoad, setReLoad] = useState(true);
  let locale = localStorage.getItem("currentLocale") || "en";
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setStatus("add");
    setBranch({
      name: "",
      address: "",
      phone: "",
      employee_count: "",
      representative_email: "",
      representative_name: "",
      country_id: null,
      city_id: null,
    });
    setIsOpen(!isOpen);
  };

  const toggleEdit = (data) => {
    setIsOpen(!isOpen);
    setStatus("edit");
    setBranch({
      ...data,
      country_id: {
        value: data?.country?.id,
        label: data?.country?.name,
      },
      city_id: {
        value: data?.city?.id,
        label: data?.city?.name,
      },
      phone: `${data?.phone?.phone}`,
    });
  };

  const [errors, setErrors] = useState({});

  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState({
    name: "",
    address: "",
    phone: "",
    employee_count: "",
    representative_email: "",
    representative_name: "",
    country_id: null,
    city_id: null,
  });

  useEffect(async () => {
    const res = await listBranches(company_id, {});
    if (res?.status === 200) {
      setBranches(res?.data?.data);
    }
  }, [reLoad]);

  const Cities = LooksupCities(branch?.country_id?.value);

  const checkFormErrors = () => {
    let SubmitError = {};
    Object.keys(branch).forEach((key) => {
      SubmitError = {
        ...SubmitError,
        ...validate(
          {
            name: key,
            value: branch[key],
          },
          { required: true }
        ),
      };
    });
    setErrors(SubmitError);
  };

  const createBranch = async () => {
    let res;
    checkFormErrors();
    if (status === "add") {
      if (isFormValid(errors)) {
        res = await addBranch(company_id, {
          ...branch,
          country_id: branch?.country_id?.value,
          city_id: branch?.city_id?.value,
          lang: locale,
          phone: `+${branch?.country_id?.value}${branch.phone}`,
        });
      }
    } else {
      res = await editBranch(company_id, branch.id, {
        ...branch,
        country_id: branch?.country_id?.value,
        city_id: branch?.city_id?.value,
        lang: locale,
        phone: `+${branch?.country_id?.value}${branch.phone}`,
        country: null,
        city: null,
      });
    }

    if (res.status === 200 || res.status === 201) {
      getNotification(res?.data?.message);
      setIsOpen(!isOpen);
      setReLoad(!reLoad);
    }
  };

  const removeBranch = async (id) => {
    const res = await deleteBranch(company_id, id);
    if (res.status === 200 || res.status === 201) {
      getNotification(res?.data?.message);
      setReLoad(!reLoad);

    }
  };

  useEffect(() => {
    async function countriesLookups() {
      const format = await allCountries.map((t) => ({
        value: t.id,
        label: t.names[locale],
        code: t.country_code,
      }));
      setCountries(format);
    }
    countriesLookups();
  }, [allCountries]);

  return (
    <>
      <div>
        <div className=" w-100  d-flex justify-content-end">
          <button className="btn rounded companies-btn " onClick={toggle}>
            {Companies.addBranch}
          </button>
        </div>
        <ListBranches
          branches={branches}
          toggleEdit={toggleEdit}
          removeBranch={removeBranch}
        />
      </div>

      <div>
        <Modal isOpen={isOpen} toggle={toggle}>
          <div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
            <p className=" text-secondary "> {Companies.addBranch}</p>
            <i className="far fa-times-circle fa-lg text-secondary" onClick={()=>toggle()}></i>
          </div>

          <ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3">
            <div className=" w-90">
              <TextField
                type="text"
                placeholder={Companies.name}
                hasLabel={true}
                name="name"
                label={"name"}
                value={branch.name}
                errors={errors?.name}
                color={errors?.name?.required ? "danger" : ""}
                onChange={(e) => {
                  setBranch({ ...branch, name: e.target.value });
                  setErrors({
                    ...errors,
                    ...validate(e.target, {
                      required: true,
                    }),
                  });
                }}
              />
            </div>

            <div className="w-90">
              {" "}
              <TextField
                type="text"
                placeholder={teamManagement.address}
                hasLabel={true}
                name="address"
                label={"address"}
                value={branch.address}
                errors={errors?.address}
                color={errors?.address?.required ? "danger" : ""}
                onChange={(e) => {
                  setBranch({ ...branch, address: e.target.value });
                  setErrors({
                    ...errors,
                    ...validate(e.target, {
                      required: true,
                    }),
                  });
                }}
              />
            </div>

            <div className="w-90">
              {" "}
              <TextField
                type="text"
                placeholder={Companies.phoneNumber}
                hasLabel={true}
                name="phone"
                label={"phone"}
                value={branch.phone}
                errors={errors?.phone}
                color={errors?.phone?.required ? "danger" : ""}
                onChange={(e) => {
                  setBranch({ ...branch, phone: e.target.value });
                  setErrors({
                    ...errors,
                    ...validate(e.target, {
                      required: true,
                    }),
                  });
                }}
              />
            </div>
            <div className="w-90">
              {" "}
              <TextField
                type="text"
                placeholder={Companies.employeeCount}
                hasLabel={true}
                name="employee_count"
                label={"employee_count"}
                value={branch.employee_count}
                errors={errors?.employee_count}
                color={errors?.employee_count?.required ? "danger" : ""}
                onChange={(e) => {
                  setBranch({ ...branch, employee_count: e.target.value });
                  setErrors({
                    ...errors,
                    ...validate(e.target, {
                      required: true,
                    }),
                  });
                }}
              />
            </div>
            <div className="w-90">
              {" "}
              <TextField
                type="text"
                placeholder={Companies.email}
                hasLabel={true}
                name="representative_email"
                label={"email"}
                value={branch.representative_email}
                errors={errors?.representative_email}
                color={errors?.representative_email?.required ? "danger" : ""}
                onChange={(e) => {
                  setBranch({
                    ...branch,
                    representative_email: e.target.value,
                  });
                  setErrors({
                    ...errors,
                    ...validate(e.target, {
                      required: true,
                    }),
                  });
                }}
              />
            </div>
            <div className="w-90">
              {" "}
              <TextField
                type="text"
                placeholder={Companies.representativeName}
                hasLabel={true}
                name="representative_name"
                label={"representative name"}
                value={branch.representative_name}
                errors={errors?.representative_name}
                color={errors?.representative_name?.required ? "danger" : ""}
                onChange={(e) => {
                  setBranch({
                    ...branch,
                    representative_name: e.target.value,
                  });
                  setErrors({
                    ...errors,
                    ...validate(e.target, {
                      required: true,
                    }),
                  });
                }}
              />
            </div>
            <div className="w-90">
              <SelectField
                hasLabel={true}
                placeholder={Companies.country}
                name="status"
                options={countries}
                label={"country"}
                value={branch.country_id}
                errors={errors?.country_id}
                color={errors?.country_id?.required ? "danger" : ""}
                onChange={(e) => {
                  const { label: value } = e;
                  setBranch({
                    ...branch,
                    country_id: e,
                  });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "country", value },
                      {
                        required: true,
                      }
                    ),
                  });
                }}
              />
            </div>

            <div className="w-90">
              <SelectField
                hasLabel={true}
                placeholder={"city"}
                name="status"
                options={Cities}
                label={"city"}
                value={branch.city_id}
                errors={errors?.city_id}
                color={errors?.city_id?.required ? "danger" : ""}
                onChange={(e) => {
                  const { label: value } = e;
                  setBranch({
                    ...branch,
                    city_id: e,
                  });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "city", value },
                      {
                        required: true,
                      }
                    ),
                  });
                }}
              />
            </div>
          </ModalBody>
          <div className="w-100 pb-5 border-top-0  text-center    p-0 ">
            <button
              className="btn rounded companies-btn  w-90"
              onClick={createBranch}
            >
              {Companies.addBranch}
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
}
