import React, { useState } from "react";
import MultiRangeSlider from "multi-range-slider-react";
function RangeSlider(props) {
const [minValue, set_minValue] = useState(props.minValue);
const [maxValue, set_maxValue] = useState(props.maxValue);
const handleInput = (e) => {
	set_minValue(e.minValue);
	set_maxValue(e.maxValue);
};

return (
	<div className="custom-range-slider">
		<MultiRangeSlider
			min={props.min}
			max={props.max}
			step={props.step}
			ruler={props.ruler}
			label={props.label}
			preventWheel={false}
			minValue={minValue}
			maxValue={maxValue}
			onInput={(e) => {
				handleInput(e);
			}}
			onChange={props.onChange}
		/>
	</div>
	);
}

export default RangeSlider;
