import React from "react";
import Locale from "translations";

export default function FilterTransaction(props) {
  const { payment } = Locale;
  return (
    <>
      <div className="product-header-list p-0">
        <h6 className="font-weight-bold dark-blue-color">
          {payment.messages.allTransctions}
        </h6>
        <div className="product-buttons col-md-2">
          <div className="main-filter-payment">
            <div className="main-label">
              {/*  onChange={this.filterList} */}
              <input
                id="input-search"
                className="main-input-search"
                type="text"
                placeholder={payment.messages.search}
                onChange={(e) => props.onSearch(e.target.value)}
                value={props.value}
              />
              <i className="fas fa-search fa-fw"></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
