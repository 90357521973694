import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { listHotels } from "services/hotels";
import HotelCommissionsFilter from "../Components/HotelCommissions/HotelCommissionsList/HotelCommissionsFilter";
import HotelCommissionsTable from "../Components/HotelCommissions/HotelCommissionsList/HotelCommissionsTable";

import Locale from "translations";

export default function HotelCommissionsList() {
	const history = useHistory();
	const { backOffice } = Locale;
	const { search } = useLocation();
	const [_, pages] = search.split("=");
	const [HotelList, setHotelList] = useState([]);
	const [meta, setMeta] = useState(null);

	const fetchData = async (filters = null, page = pages) => {
		const res = await listHotels(filters, page);
		if (res?.status === 200) {
			setHotelList(res?.data?.hotels);
			setMeta(res?.data?.meta);
		}
	};

	useEffect(() => {
		fetchData();
	}, [pages]);

	const goTo = (page) => {
		fetchData(null, page);
		const params = new URLSearchParams();
		if (page) {
			params.append("page", page);
		} else {
			params.delete("page", page);
		}
		history.push({ search: params.toString() });
	};

	return (
		<>
			<div className="d-flex justify-content-between align-items-center mb-2">
				<h6 className="main-title  h5">{backOffice.hotelCommissions}</h6>
				<Link className="btn btn-success" to={`/add-hotel`}>
					<i class="fas fa-plus-circle"></i> {backOffice.addNewHotel}
				</Link>
			</div>
			<HotelCommissionsFilter
				HotelList={HotelList}
				setHotelList={setHotelList}
				setMeta={setMeta}
				pages={pages}
			/>
			<HotelCommissionsTable
				HotelList={HotelList}
				fetchData={fetchData}
				goTo={goTo}
				meta={meta}
			/>
		</>
	);
}
