import axios from "axios";
const backOfficeApi = process.env.REACT_APP_API_URL + "/api";

export const ListNotifications = async (page = 1) => {
	return await axios
		.get(`${backOfficeApi}/notifications?page=` + page)
		.then((res) => res)
		.catch((err) => err.response);
};

export const readNotification = async (id) => {
	return await axios
		.get(`${backOfficeApi}/notifications/read/${id} `)
		.then((res) => res)
		.catch((err) => err.response);
};
