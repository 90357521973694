import TimePicker from "react-time-picker";
import Locale from "translations";

export default function TimePickerField(props) {
	const { commons } = Locale;

	const generateErrorsMsgs = (errors) => {
		let message = [];
		if (errors) {
			for (const [k, v] of Object.entries(errors)) {
				if (k === "required" && v) {
					message.push(commons.isRequired);
				} else if (k === "min" && v) {
					message.push(` should be at least ${props.min} chars`);
				} else if (k === "email" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "phone" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "number" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "date" && v) {
					message.push(commons.shouldBeValid);
				}
			}
			if (message.length === 1) {
				return `${props.label} ${message[0]}`;
			} else if (message.length > 1) {
				return `${props.label} ${message.join(" & ")}`;
			} else {
				return;
			}
		}
	};

	return (
		<>
			<div
				className={`my-2 control-field + ${
					props.color ? " control-field--" + props.color : ""
				}`}
			>
				{/* Date Range Body */}
				<div
					className={`control-field__body  position-relative ${
						props.disabled && props.readOnly ? "isDisabled" : ""
					}`}
				>
					{/* Label */}
					{props.hasLabel ? (
						<label
							className={`control-field__label ${
								props.color ? " control-field__label--" + props.color : ""
							}`}
						>
							{props.label}
						</label>
					) : null}

					{/* from */}
					<div className="row no-gutters time-picker">
						<TimePicker
							className="from"
							onChange={props.onFromChange}
							value={props.fromValue}
							disableClock={true}
							closeClock={false}
							clearIcon={null}
							{...props}
						/>

						{/* to */}
						{/* <TimePicker
							className="to"
							onChange={props.onToChange}
							value={props.toValue}
							disableClock={true}
							closeClock={false}
							clearIcon={null}
							{...props}
						/> */}
						<i className="fa fa-clock date-icon p-2  icon-time"></i>
					</div>
				</div>
			</div>

			{/* Feedback */}
			{/* <small
				className={`control-field__feedback control-field__feedback--${
					props.color
				} ${props.isInvalid ? "d-block" : "d-none"}`}
			>
				{props.feedbackMessage}
			</small> */}
			{/* Error Message */}
			<small
				className={`control-field__feedback control-field__feedback--${props.color} d-block error-message`}
			>
				{generateErrorsMsgs(props.errors)}
			</small>
		</>
	);
}

TimePickerField.defaultProps = {
	hasLabel: true,
};
