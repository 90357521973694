import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchViewHotelsSearch } from "services/wallet";
import Locale from "translations";
function ViewMarketingOffer() {
	const { marketPlace, booking, backOffice } = Locale;
	const [isopen, setisopen] = useState(false);
	const { id } = useParams();
	const [searchState, setsearchState] = useState({});
	const [multihotels, setmultihotels] = useState();

	useEffect(() => {
		const getView = async () => {
			const hotelView = await fetchViewHotelsSearch(id);
			if (hotelView?.status == 200) {
				setsearchState(hotelView.data.data);
			}
		};
		getView();
	}, []);
	return (
		<>
			<div className="row align-items-center w-100 no-gutter m-0 view-genertaLink">
				<h3 className="col-12">{searchState?.title}</h3>
				<div className="genlink-box  row align-items-center mb-3 w-100 no-gutter m-0 view-genertaLink">
					<div className="genlink-box-head col-12">
						<h4>{marketPlace.details}</h4>
					</div>
					<div className="col-6 ">
						<TextField
							disabled={true}
							type="text"
							label={marketPlace.Title}
							placeholder={marketPlace.Title}
							name="title"
							value={searchState?.title}
						/>
					</div>
					<div className="col-6">
						<SelectField
							disabled={true}
							label={marketPlace.searchType}
							name="searchType"
							placeholder={searchState?.is_offer == 0 ? "Hotels" : "Offers"}
						/>
					</div>
					<div className="col-6">
						<SelectField
							disabled={true}
							label={marketPlace.Hotels}
							multi={true}
							name="Hotels"
							value={searchState?.hotels?.map((res) => {
								return {
									value: res?.hotel_id,
									label: res?.name,
								};
							})}
						/>
						{/* <div className="selected-hotels">
						{searchState?.hotels?.map((res) => {
							return (
								<p className="hotel">
									<p className="hotel-name">{res?.name}</p>
									<i class="fas fa-times delete-hotel"></i>
								</p>
							);
						})}
					</div> */}
					</div>
					<div className="col-6">
						<SelectField
							disabled={true}
							label={marketPlace.Provider}
							// placeholder={marketPlace.Provider}
							placeholder={searchState?.provider?.name}
							name="Provider"
						/>
					</div>

					<div className="col-6">
						<DatePickerField
							type="text"
							disabled={true}
							placeholder={searchState?.search_payload?.date_from}
							hasLabel={true}
							label={marketPlace.checkin}
							name="CheckIn"
						/>
					</div>
					<div className="col-6">
						<DatePickerField
							type="text"
							disabled={true}
							placeholder={searchState?.search_payload?.date_to}
							hasLabel={true}
							label={marketPlace.Checkout}
						/>
					</div>
					<div className="col-6">
						<DatePickerField
							type="text"
							disabled={true}
							hasLabel={true}
							label={marketPlace.StartDate}
							placeholder={searchState?.start_date}
							name="StartDate"
						/>
					</div>

					<div className="col-6">
						<DatePickerField
							type="text"
							disabled={true}
							hasLabel={true}
							label={marketPlace.EndDate}
							placeholder={searchState?.end_date}
							name="EndDate"
						/>
					</div>

					<div className="col-6 ">
						<TextField
							disabled={true}
							type="number"
							label={"Discount"}
							name="discount"
							placeholder={searchState?.discount}
						/>
					</div>
					<div className="col-6">
						<DatePickerField
							type="text"
							disabled={true}
							hasLabel={true}
							label={marketPlace.expirationDate}
							placeholder={searchState?.expiration_date}
						/>
					</div>
					<div className="col-3">
						<SelectField
							disabled={true}
							multi={true}
							label={marketPlace.country}
							name="country_id"
							value={searchState?.country?.map((res) => {
								return {
									value: res?.country_id,
									label: res?.name,
								};
							})}
						/>
					</div>
					<div className="col-3">
						<SelectField
							disabled={true}
							label={marketPlace.residence}
							multi={true}
							id="basic-countries"
							name="residence"
							value={
								searchState?.residences != "All" &&
								searchState?.residences != null
									? searchState?.residences?.map((res) => {
											return {
												value: res?.country_code,
												label: res?.name,
											};
									  })
									: { value: "All", label: "All" }
							}
						/>
					</div>
					<div className="col-3">
						<SelectField
							disabled={true}
							multi={true}
							label={marketPlace.nationality}
							name="nationality"
							value={
								searchState?.nationalities != "All" &&
								searchState?.nationalities != null
									? searchState?.nationalities?.map((res) => {
											return {
												value: res?.country_code,
												label: res?.name,
											};
									  })
									: { value: "All", label: "All" }
							}
						/>
					</div>
					<div className="col-3">
						<SelectField
							disabled={true}
							multi={true}
							label={marketPlace.currency}
							name="currency"
							placeholder={searchState?.search_payload?.currency_code}
						/>
					</div>
				</div>

				{/**Start Rooms */}
				<div className="genlink-box row align-items-center w-100  no-gutter m-0 view-genertaLink">
					<div className="genlink-box-head col-12">
						<h5>{marketPlace?.rooms}</h5>
					</div>
					{searchState?.rooms_count_payload?.map((res) => {
						return (
							<div className="room-card col-4 d-flex justify-content-between">
								<div
									className={` ${
										res.children?.length > 0 ? "col-4" : "col-6"
									}  d-flex flex-column align-items-center `}
								>
									<span className="label-room">{backOffice.ROOMCOUNT}: </span>
									<span className="room-num h4">{res?.rooms_count}</span>
								</div>
								<div
									className={`${
										res.children?.length > 0 ? "col-4" : "col-6"
									}  d-flex flex-column align-items-center`}
								>
									<span className="label-room">
										{marketPlace.messages.adult}:{" "}
									</span>
									<span className="room-num h4">{res?.number_of_adult}</span>
								</div>
								{res.children?.length > 0 ? (
									<div className="col-4 d-flex flex-column align-items-center">
										<span className="label-room">
											{marketPlace.messages.child}:{" "}
										</span>
										<span className="room-num h4">{res?.children?.length}</span>
									</div>
								) : null}
							</div>
						);
					})}

					{/* 		<div className="col-md-11 m-auto">
						<div className=" product-build__product-collpase branch-collapse my-0 mb-2">
							<div className="row view-rooms-marketing">
								{searchState?.rooms_count_payload &&
								searchState?.rooms_count_payload?.length > 0
									? searchState?.rooms_count_payload?.map((res, index) => {
											return (
												<>
													<div
														className="col-md-11"
														style={{ padding: "0 15px" }}
													>
														<div className="button-collapse my-2">
															<div className="title-style-container">
																<div
																	className="w-100"
																	onClick={() => {
																		setisopen(!isopen);
																	}}
																>
																	<div className="w-100">
																		<p
																			className="global-input font-weight-bold border-0 "
																			value=""
																			type="text"
																		>
																			<span className="roomsNumber">
																				{"1"}
																				{marketPlace.messages.room}
																			</span>
																			<span className="adultNumber">
																				({res.number_of_adult}{" "}
																				{marketPlace.messages.adult} {"  "},
																				{res?.children?.length}{" "}
																				{marketPlace.messages.child}){" "}
																				{marketPlace.perroom}
																			</span>
																		</p>
																	</div>
																</div>
															</div>

																<div className="icon-items-section d-flex align-items-center">
																	<i
																		onClick={() => {
																			setisopen(!isopen);
																		}}
																		className={`fas fa-fw text-primary fa-lg ${
																			isopen == false
																				? "fa-chevron-right "
																				: "fa-chevron-down"
																		}`}
																	></i>
																</div>
															</div>

															{res.children ? (
																<div
																	className="row box-child"
																	//	ref={DetectClickOutside}
																>
																	<div
																		className="col-md-12"
																		style={{ padding: "0 15px" }}
																	>
																		<div className="row">
																			<div className="col-md-4 my-3">
																				<div className="container-centerLabel px-2">
																					<label
																						className="m-0 w-100"
																						htmlFor=""
																					>
																						{marketPlace.messages.rooms}
																					</label>
																				</div>
																			</div>
																			<div className="col-md-8">
																				<NumberField
																					hasLabel={false}
																					disabled={true}
																					placeholder={+res.rooms_count}
																					name="number_of_adult"
																					type="number"
																				/>
																			</div>
																		</div>
																	</div>

																	<div
																		className="col-md-12"
																		style={{ padding: "0 15px" }}
																	>
																		<div className="row">
																			<div className="col-md-4 my-3">
																				<div className="container-centerLabel px-2">
																					<label
																						className="m-0 w-100"
																						htmlFor=""
																					>
																						{marketPlace.messages.adult}
																					</label>
																				</div>
																			</div>
																			<div className="col-md-8">
																				<NumberField
																					hasLabel={false}
																					disabled={true}
																					label={marketPlace.messages.adult}
																					placeholder={res.number_of_adult}
																					name="number_of_adult"
																					type={"number"}
																				/>
																			</div>
																		</div>
																	</div>

																	<div
																		className="col-md-12"
																		style={{ padding: "0 15px" }}
																	>
																		<div className="row">
																			<div className="col-md-4 my-3">
																				<div className="container-centerLabel px-2">
																					<label
																						className="m-0 w-100"
																						htmlFor=""
																					>
																						{marketPlace.messages.child}
																					</label>
																				</div>
																			</div>
																			<div className="col-md-8">
																				<NumberField
																					hasLabel={false}
																					disabled={true}
																					label={marketPlace.messages.child}
																					name="rooms_count"
																					placeholder={res?.children?.length.toString()}
																					type={"number"}
																				/>
																			</div>
																			{res?.children?.length > 0 ? (
																				<div className="selected-hotels">
																					<label>Children Ages</label>
																					{res.children.map((res) => {
																						return (
																							<p className="hotel">
																								<p className="hotel-name">{`Child Age: ${res?.age}`}</p>
																								<i class="fas fa-times delete-hotel"></i>
																							</p>
																						);
																					})}
																				</div>
																			) : null}
																		</div>
																	</div>
																</div>
															) : null}
														</div>
													</div>
												</>
											);
									  })
									: null}
							</div>
						</div>
					</div> */}
				</div>

				{/* 					{res?.children?.length > 0 ? (
																	<div className="selected-hotels">
																		<label>Children Ages</label>
																		{res.children.map((res) => {
																			return (
																				<p className="hotel">
																					<p className="hotel-name">{`Child Age: ${res?.age}`}</p>
																					<i class="fas fa-times delete-hotel"></i>
																				</p>
																			);
																		})}
																	</div>
																) : null} */}
				{/**End Rooms */}
			</div>

			<div className="row custom--row mt-3 flex-row-reverse pb-5 mb-5">
				<div className="mx-1">
					<Link
						className={`btn btn-sbs-secondary w-100`}
						to={`/marketing-offers-list`}
					>
						{backOffice.back}
					</Link>
				</div>
			</div>
		</>
	);
}
export default ViewMarketingOffer;
