import axios from "axios";
import { cleanEmpty } from "services/general";
const platformURL = process.env.REACT_APP_API_URL + "/api/commission-management/platform";

// list All Platforms
export const listPlatforms = async (params, page = 1) => {
  return await axios
    .get(`${platformURL}?page=${page}`, { params })
    .then((res) => res)
    .catch((err) => err.response);
};

// view Platform Basic Info
export const viewPlatform = async (id) => {
  return await axios
    .get(`${platformURL}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

// list Platform Hotels
export const listPlatformHotels = async (id, params, page = 1) => {
  return await axios
    .get(`${platformURL}/${id}/hotel?page=${page}`, { params })
    .then((res) => res)
    .catch((err) => err.response);
};

// list Platform Reservations
export const listPlatformReservations = async (id, filter) => {
  return await axios
    .get(`${platformURL}/${id}/reservation`, {
			params: cleanEmpty(filter),
		})
    .then((res) => res)
    .catch((err) => err.response);
};

// add and edit
export const addPlatform = async (data) => {
  return await axios
    .post(`${platformURL}`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const editPlatform = async (id, data) => {
  return await axios
    .put(`${platformURL}/${id}`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const deleteCommission = async (platformId, commissionId) => {
  return await axios
    .delete(`${platformURL}/${platformId}/commission/${commissionId}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const editCommission = async (platformId, commissionId, data) => {
  return await axios
    .put(`${platformURL}/${platformId}/commission/${commissionId}`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const Add_Commission = async (platformId, data) => {
  return await axios
    .post(`${platformURL}/${platformId}/commission`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const AddOffer = async (hotelId, data) => {
  return await axios
    .post(`${platformURL}/${hotelId}/offer`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const EditOffer = async (hotelId,offerId, data) => {
  return await axios
    .put(`${platformURL}/${hotelId}/offer/${offerId}`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const deleteOffer = async (hotelId,offerId) => {
  return await axios
    .delete(`${platformURL}/${hotelId}/offer/${offerId}`)
    .then((res) => res)
    .catch((err) => err.response);
};
