import Pagination from "components/shared/Pagination";
import Locale from "translations";
import Filter from "./Filter";
import Reservation from "./Reservation";
export default function ReservationsList({
	reservations,
	setFilter,
	filters,
	meta,
}) {
	const { backOffice } = Locale;

	const goTo = (page) => {
		setFilter((prev) => ({ ...prev, page: page }));
	};

	const AllReservations =
		reservations.length > 0 &&
		reservations.map((reservation, index) => (
			<Reservation reservation={reservation} key={index} />
		));

	return (
		<>
			<div>
				<div className="d-flex">
					<Filter filter={filters} setFilter={setFilter} />
				</div>
				<div className="px-3 our-border border-top-0">
					<table className="custom-table back-office-table table table-striped mb-0">
						<thead>
							<tr>
								<th>{backOffice.refNo}</th>
								<th>{backOffice.tripType}</th>
								<th>{backOffice.tripPath}</th>
								<th>{backOffice.pax}</th>
								<th>{backOffice.amount}</th>
								<th>{backOffice.departureDate}</th>
								<th>{backOffice.bookingDate}</th>
								<th>{backOffice.visaStatus}</th>
								<th></th>
							</tr>
						</thead>

						<tbody>
							{reservations?.length > 0 ? (
								AllReservations
							) : (
								<tr>
									<td colSpan="15">
										<div className="product-no-data">
											<i className="fas fa-info-circle fa-lg"></i>{" "}
											<h4>{backOffice.noResult}</h4>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
					<Pagination info={meta} goTo={goTo} items={10} />
				</div>
			</div>
		</>
	);
}
