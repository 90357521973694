// import * as React from "react";

import globalReducer from "context/reducers/globalReducer";
import { createContext, useContext, useReducer } from "react";
const Context = createContext(undefined);
const GlobalDispatchContext = createContext(undefined);
const currentLocale = localStorage.getItem("currentLocale");
//const permissions = JSON.parse(localStorage.getItem("permissions"));

const initialState = {
	locale: currentLocale ?? "en",
	isAuth: localStorage.getItem("isAuth") ?? false,
	token: localStorage.getItem("token") ?? null,
	user_id: localStorage.getItem("user_id") ?? null,
	allCountries: [],
	allCurrencies: [],
	// permissions: permissions ? permissions : [],
};

function useGlobalState() {
	const context = useContext(Context);
	if (context === undefined) {
		throw new Error("useGlobalState must be used within a Provider");
	}
	return context;
}

function useGlobalDispatch() {
	const context = useContext(GlobalDispatchContext);
	if (context === undefined) {
		throw new Error("useGlobalDispatch must be used within a Provider");
	}
	return context;
}

function Provider({ children }) {
	const [state, dispatch] = useReducer(globalReducer, initialState);
	return (
		<Context.Provider value={state}>
			<GlobalDispatchContext.Provider value={dispatch}>
				{children}
			</GlobalDispatchContext.Provider>
		</Context.Provider>
	);
}

export { Provider, useGlobalState, useGlobalDispatch };
