import DatePickerField from "components/shared/DatePickerField";
import Pagination from "components/shared/Pagination";
import { useGlobalState } from "context/global";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { isInclusivelyAfterDay } from "react-dates";
import { store } from "react-notifications-component";
import { fetchCitiesSearch } from "services/lookups";
import { listBooking, UpdatePaymentExpirationDate } from "services/Request";

import Locale from "translations";
import BookItem from "./bookItem";
import Colums from "./Colums";
import MainFilter from "./MainFilter";
import CustomModal from "./Modal";
import SideFilter from "./SideFilter";

export default function Bookings() {
	const { backOffice, booking } = Locale;
	const { locale, allCountries } = useGlobalState();
	const [bookings, setBookings] = useState([]);
	const [selectItem, setSelectItem] = useState(null);

	const [extendIsOpen, setExtendIsOpen] = useState(false);
	const toggleExtend = () => {
		setExtendIsOpen(!extendIsOpen);
	};
	const colums = [
		// { id: "referral_code", label: booking?.Ref },
		{ id: "brn", label: booking.BRN },
		{ id: "agency", label: booking.Agency },
		{ id: "hotel_name", label: booking.HotelName },
		//{ id: "destination", label: booking.Destination },
		{ id: "rooms_number", label: booking.RoomCount },
		{ id: "created_at", label: booking.BookingDate },
		{ id: "start_date", label: booking.CheckinDate },
		{ id: "nights_count", label: booking.Nights },
		{ id: "paid_later", label: "Paid Later" },
		{ id: "reservation_status", label: booking.Status },

		{ id: "total_price", label: booking.TotalPrice },
		{ id: "total_refund", label: "Total Refund" },
		{ id: "total_paid", label: "Total Paid" },

		{ id: "destination", label: booking.Destination },

		{ id: "pax", label: booking.pax },
		// { id: "brn", label: booking.LeaderName, },
		// { id: "brn", label: booking.SupplierRef, },
		// { id: "brn", label: booking.CancellationDate, },
	];
	const [meta, setMeta] = useState(null);

	const [filter, setFilter] = useState({
		page: null,
		search: "",
		destination_id: null,
		visa_type_id: null,
		start_date: null,
		cancellation_date: null,
		booking_date: null,
		goingTo: null,
		nights_count: "",
		reference_no: "",
		brn: "",
		hotel_name: "",
		destination: "",
		leader_name: "",
		reservation_status: "",
	});
	const [dateExpiration, setDateExpiration] = useState(null);
	useEffect(() => {
		const fetchBookings = async () => {
			const res = await listBooking({
				...filter,

				visa_type_id: filter.visa_type_id?.label,
				reservation_status: filter.reservation_status?.label,
				destination: filter.destination,
				start_date: filter.start_date
					? moment(filter.start_date).format("YYYY-MM-DD")
					: null,
				cancellation_date: filter.cancellation_date
					? moment(filter.cancellation_date).format("YYYY-MM-DD")
					: null,
				booking_date: filter.booking_date
					? moment(filter.booking_date).format("YYYY-MM-DD")
					: null,
			});
			setBookings(res.data.data);
			setMeta(res?.data?.meta);
		};
		fetchBookings();
	}, [filter]);

	const columsList = colums.map((item, index) => {
		return {
			id: item.id,
			name: item.label,
		};
	});

	const [show, setShow] = useState({
		filter: false,
		colums: false,
	});

	const toggleShow = (active) => {
		setShow(active);
	};

	const [checkedColums, setCheckedColums] = useState(
		columsList.map((colum) => colum.id).slice(0, 10)
	);
	console.log(checkedColums);
	const handelCheck = (colum, flag) => {
		if (flag) {
			setCheckedColums([...checkedColums, colum.id]);
		} else {
			let newListChecked = checkedColums.filter((id) => id != colum.id);
			setCheckedColums(newListChecked);
		}
	};

	const tableHeaders = useMemo(() => {
		return columsList.map((colum) => {
			return checkedColums.includes(colum.id) ? (
				<th key={colum.id}>{colum.name}</th>
			) : null;
		});
	}, [checkedColums]);

	const allBooking = useMemo(() => {
		return bookings.map((data) => {
			return (
				<BookItem
					data={data}
					checkedColums={checkedColums}
					toggleExtend={toggleExtend}
					setSelectItem={setSelectItem}
				/>
			);
		});
	}, [checkedColums, bookings]);

	const goTo = (page) => {
		setFilter({ ...filter, page: +page });
		console.log("setFilter", filter);
	};
	const [countries, setCountries] = useState([]);
	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);

	const handleChange = (e, flag = false) => {
		const name = e.target?.name;
		//const value = e.target?.value;
		const value = e.value?.name;

		if (flag) {
			setFilter({
				...filter,
				country: e["value"], // countries[value],
				goingTo: e.value, //countries[value]?.code,
			});
		} else setFilter({ ...filter, destination: value });
		setFilter({ ...filter, destination: value });
	};
	const [listAuto, setListAuto] = useState([]);
	const getListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const countries = await fetchCitiesSearch(inputValue);
			let result = countries.map((item) => {
				return {
					...item,
					id: item.id,
					//name: item.name + " - " + item.country.name[locale],
					name: item.name,
					value: item.id,
					label: item.name,
				};
			});

			setListAuto(result);
		}
	};

	async function editExpirationDate() {
		const response = await UpdatePaymentExpirationDate({
			brn: selectItem.brn,
			payment_expiration: moment(dateExpiration).format("YYYY-MM-DD"),
		});
		if (response.status === 200 || response.status === 200) {
			toggleExtend();
			store.addNotification({
				title: "Info!",
				message: response?.message
					? response?.message
					: "Payment Expiration Date Updated successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}

	return (
		<>
			{show.filter || show.colums ? (
				<div
					className="booking-close-dropdown"
					onClick={() => {
						toggleShow({
							filter: false,
							colums: false,
						});
					}}
				/>
			) : null}

			<div className="row justify-content-between m-0 p-0 px-3">
				<h3 className="main-title col">{booking?.booking}</h3>

				<div className="row m-0 p-0 col-8   justify-content-end">
					<div className="col-xl-2  col-3">
						<SideFilter
							toggleShow={toggleShow}
							show={show}
							filter={filter}
							setFilter={setFilter}
							countries={countries}
							handleChange={handleChange}
							listAuto={listAuto}
							setListAuto={setListAuto}
							getListAuto={getListAuto}
						/>
					</div>

					<div className="col-xl-2 col-3">
						<Colums
							toggleShow={toggleShow}
							show={show}
							columsList={columsList}
							handelCheck={handelCheck}
							checkedColums={checkedColums}
						/>
					</div>

					<div className="col-xl-2 col-3">
						<div className="border rounded filter-col  d-flex justify-content-between align-items-center p-2 ">
							<span className="">{booking.ExportExcel}</span>
						</div>
					</div>
				</div>

				<div className="col-12 px-0">
					<MainFilter
						filter={filter}
						setFilter={setFilter}
						countries={countries}
						handleChange={handleChange}
						listAuto={listAuto}
						setListAuto={setListAuto}
						getListAuto={getListAuto}
					/>
				</div>

				<div className=" table-responsive px-4 our-border">
					<table className="custom-table back-office-table table table-striped">
						<thead>
							<tr className="text-nowrap">{tableHeaders}</tr>
						</thead>

						<tbody>
							{allBooking.length > 0 ? (
								allBooking
							) : (
								<tr>
									<td colSpan="15">
										<div className="product-no-data">
											<i className="fas fa-info-circle fa-lg"></i>{" "}
											<h4>{backOffice.noResult}</h4>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
					<div className="px-2">
						<Pagination info={meta} goTo={goTo} />
					</div>
				</div>
			</div>

			<CustomModal
				modalIsOpen={extendIsOpen}
				header={"Extend Date"}
				toggle={toggleExtend}
				size={"md"}
				centered={true}
			>
				<div className=" col-md-12">
					<div className="p-2 row">
						<div className="col-md-12 mt-2 px-3">
							<DatePickerField
								type="text"
								placeholder={"Expiration Date"}
								onOutsideClick={false}
								hasLabel={true}
								label={"Expiration Date"}
								date={dateExpiration}
								onChangeDate={(e) => {
									setDateExpiration(e);
								}}
								isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
							/>
						</div>
						<button
							disabled={!dateExpiration}
							style={{ backgroundColor: "#FF6868" }}
							className="btn companies-btn w-100 mx-3"
							onClick={() => editExpirationDate()}
						>
							Extend Date
						</button>
					</div>
				</div>
			</CustomModal>
		</>
	);
}
