import Pagination from "components/shared/Pagination";
import { useGlobalState } from "context/global";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchCountries } from "services/lookups";
import { listVendorReservation } from "services/vendor";
import Locale from "translations";
import Filter from "./filter";

const VendorReservation = () => {
	const { backOffice, vendor } = Locale;
	const { id } = useParams();
	const { locale } = useGlobalState();
	const [countries, setCountries] = useState([]);
	const [resvationList, setResvationList] = useState([]);
	const [meta, setMeta] = useState(null);
	const [filter, setFilter] = useState({
		search: undefined,
		start_date: undefined,
		reservation_status: undefined,
		destination: undefined,
		page: 1,
	});
console.log(resvationList);
	const AllReservations =
		resvationList?.length > 0 ? (
			resvationList?.map((res, index) => (
				<tr>
					{/* <td>{res.referral_code}</td> */}
					<td>{res.brn}</td>
					<td>{res.agency}</td>
					<td>{res.hotel_name}</td>
					<td>{res.destination}</td>
					<td>{res.rooms_number}</td>
					<td>{res.created_at}</td>
					<td>{res.start_date}</td>
					<td>{res.provider_price?.toFixed(2)}</td>
					<td>{res.total_price}</td>
					<td
						className={`${
							res.reservation_status === "approved" ||
							res.reservation_status === "paid"
								? "text-success"
								: "text-danger"
						}`}
					>
						{res.reservation_status}
					</td>
					<td>
						<Link
							to={{
								pathname: `/manage-vendor/${id}/view/reservation/${res.reservation_num}`,
							}}
							className="d-inline-block action--btn"
						>
							<i class="fas fa-chevron-right mx-1"></i>
						</Link>
					</td>
				</tr>
			))
		) : (
			<tr>
				<td colSpan="10">
					<div className="product-no-data">
						<i className="fas fa-info-circle fa-lg"></i>{" "}
						<h4>{backOffice.noResult}</h4>
					</div>
				</td>
			</tr>
		);

	const goTo = (page) => {
		setFilter({ ...filter, page: +page });
	};

	useEffect(() => {
		async function fetchReservation() {
			const res = await listVendorReservation(id, {
				...filter,
				reservation_status: filter.reservation_status?.label,
				destination: filter.destination?.value,
				start_date: filter.start_date
					? moment(filter.start_date).format("YYYY-MM-DD")
					: null,
			});
			setResvationList(res?.data?.data);
		}

		async function fetchLookups() {
			const res = await fetchCountries();
			if (res.status === 200 || res?.data?.length > 0) {
				const format = res?.data?.map((t) => ({
					value: t.id,
					label: t.names[locale],
				}));
				setCountries(format);
			}
		}
		fetchReservation();
		fetchLookups();
	}, [filter]);
	return (
		<>
			<Filter filter={filter} setFilter={setFilter} countries={countries} />
			<div className=" table-responsive px-4 our-border">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							{/* <th>Ref.</th> */}
							<th>BRN</th>
							<th>{vendor.agency}</th>
							<th>{vendor.hotelName}</th>
							<th>{vendor.destination}</th>
							<th>{vendor.roomCount}</th>
							<th>{vendor.bookingDate}</th>
							<th>{vendor.checkin}</th>
							<th>{vendor.Price_Before}</th>
							<th>{vendor.Total_Price}</th>
							<th>{vendor.status}</th>
						</tr>
					</thead>

					<tbody>{AllReservations}</tbody>
				</table>
				<div className="px-2">
					<Pagination info={meta} goTo={goTo} />
				</div>
			</div>
		</>
	);
};

export default VendorReservation;
