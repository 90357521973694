import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { getNotification } from "helpers/makeNotifications";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { addGroup, editGroup, listPermission } from "services/companies";
import Locale from "translations";
import LooksupBranches from "../lookups/fetchBranches";
import ListPermission from "./ListPermission";

export default function AddGroupTourism() {
	const { backOffice, Companies } = Locale;
	const { status } = useParams();
	const { company_id } = useParams();
	const history = useHistory();

	const Branches = LooksupBranches(company_id);
	let locale = localStorage.getItem("currentLocale") || "en";

	const [permissions, setPermissions] = useState([]);
	const [selectedPermissions, setSelectedPermissions] = useState([]);
	const [groupInfo, setGroupInfo] = useState({
		name: "",
		branch_id: null,
	});

	const GroupEdit = useLocation().state.group;

	useEffect(() => {
		if (status === "edit") {
			setSelectedPermissions(GroupEdit.permissions.map((per) => per.id));
			setGroupInfo({
				name: GroupEdit.name,
				branch_id: { value: GroupEdit.branch.id, label: GroupEdit.branch.name },
			});
		}
	}, [GroupEdit]);

	useEffect(async () => {
		const res = await listPermission(company_id);
		setPermissions(res?.data?.data);
	}, []);

	const createGroup = async () => {
		let res;
		if (status === "add") {
			res = await addGroup(company_id, {
				...groupInfo,
				lang: locale,
				branch_id: groupInfo?.branch_id?.value,
				permissions: [...selectedPermissions],
			});
		} else {
			res = await editGroup(company_id, GroupEdit.id, {
				...groupInfo,
				lang: locale,
				branch_id: groupInfo.branch_id.value,
				permissions: [...selectedPermissions],
			});
		}
		getNotification(res?.data?.message);
		history.push(`/tourism-company/groups/${company_id}`);
	};

	return (
		<div>
			<h3 className="font-weight-bold px-1 border-bottom py-2 w-90">
				{status === "add" ? backOffice.addGroup : backOffice.editGroup}
			</h3>
			<div className="w-50 mt-4">
				<TextField
					type="text"
					hasLabel={true}
					value={groupInfo.name}
					placeholder={backOffice.groupNamePlaceholder}
					label={backOffice.groupNamePlaceholder}
					onChange={(e) => {
						setGroupInfo({ ...groupInfo, name: e.target.value });
					}}
				/>

				<SelectField
					hasLabel={true}
					placeholder={Companies.branch}
					label={Companies.branch}
					name="status"
					value={groupInfo.branch_id}
					options={Branches}
					onChange={(e) => {
						setGroupInfo({ ...groupInfo, branch_id: e });
					}}
				/>
			</div>
			<h3 className="font-weight-bold px-1 border-bottom py-2 w-90">
				{backOffice.permissions}
			</h3>{" "}
			<ListPermission
				permissions={permissions}
				selectedPermissions={selectedPermissions}
				setSelectedPermissions={setSelectedPermissions}
			/>
			<div className="  w-90 d-flex justify-content-end my-2">
				<button className="btn rounded companies-btn " onClick={createGroup}>
					{status === "add" ? backOffice.addGroup : backOffice.editGroup}
				</button>
			</div>
		</div>
	);
}
