import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";

import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import Locale from "translations";

export default function PassengerFilter({ filter, setFilter }) {
	const { locale } = useGlobalState();
	const { requests, backOffice } = Locale;

	return (
		<div className="px-3 border-bottom ">
			<div className="row align-items-center w-100 no-gutter m-0">
				<div className="col">
					<TextField
						type="text"
						placeholder={backOffice.search}
						hasLabel={true}
						label={backOffice.search}
						value={filter?.search}
						onChange={(e) => {
							setFilter({
								...filter,
								search: e.target.value,
							});
						}}
					/>
				</div>

				<div className="col-3">
					<DatePickerField
						type="text"
						hasLabel={true}
						label={backOffice.arrivalDate}
						placeholder={"DD/MM/YYYY"}
						onOutsideClick={false}
						date={filter?.arrival_date}
						onChangeDate={(e) => {
							setFilter({
								...filter,
								arrival_date: e,
								page: null,
							});
						}}
					/>
				</div>
				<div className="col-3">
					<DatePickerField
						type="text"
						hasLabel={true}
						label={backOffice.departureDate}
						placeholder={"DD/MM/YYYY"}
						onOutsideClick={false}
						date={filter?.departure_date}
						onChangeDate={(e) => {
							setFilter({
								...filter,
								departure_date: e,
								page: null,
							});
						}}
					/>
				</div>
				<div className="col">
					<div
						className="d-flex align-items-center text-secondary pointer mt-4"
						onClick={() => {
							setFilter({
								departure_date: null,
								arrival_date: null,
								search: "",
								page: 1,
							});
						}}
					>
						<img src={resetIcon} alt="" srcset="" />
						<p className="text-caption">{backOffice.resetFilter}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
