import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { requestContracts } from "services/property";
import ContractFilter from "../Components/ContractRequest/ContractFilter";
import ContractTable from "../Components/ContractRequest/ContractTable";

import Locale from "translations";

export default function ContractRequest() {
	const history = useHistory();
	const { backOffice } = Locale;
	const { search } = useLocation();
	const [_, pages] = search.split("=");
	const [ProductList, setProductList] = useState([]);
	const [meta, setMeta] = useState(null);

	const fetchData = async (filters = null, page = pages) => {
		const res = await requestContracts(filters, page);
		if (res?.status === 200) {
			setProductList(res.data.data);
			setMeta(res.data.meta);
		}
	};

	useEffect(() => {
		fetchData();
	}, [pages]);

	const goTo = (page) => {
		fetchData(null, page);
		const params = new URLSearchParams();
		if (page) {
			params.append("page", page);
		} else {
			params.delete("page", page);
		}
		history.push({ search: params.toString() });
	};

	return (
		<>
			<div className="d-flex justify-content-between align-items-center">
				<h6 className="h4 main-title ">{backOffice.contractRequest}</h6>

				{/* <button className="btn text-white bg-blue-black rounded-0">
          Export
        </button> */}
			</div>
			<ContractFilter
				ProductList={ProductList}
				setProductList={setProductList}
				pages={pages}
			/>
			<ContractTable
				ProductList={ProductList}
				fetchData={fetchData}
				goTo={goTo}
				meta={meta}
			/>
		</>
	);
}
