import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { fetchreservations } from "services/manualSupplier";
import FilterReservation from "./FilterReserviton";
import ReservationTable from "./ReservationTable";
function ListReservation() {
	const [Reservation, setReservation] = useState([]);
	const { search } = useLocation();
	const history = useHistory();
	const [_, pages] = search.split("=");
	const [meta, setmeta] = useState([]);
	const { name, id } = useParams();
	

	const [filters, setFilters] = useState({
		hotel_name: "",
		checkin: "",
		destination_id: "",
		status: "",
	});
	// filter
	useEffect(() => {
		async function fetchReservationsFilterdData() {
			const data = {
				hotel_name: filters.hotel_name,
				destination_id: filters.destination_id?.value,
				checkin: filters?.checkin
					? moment(filters?.checkin).format("YYYY-MM-DD")
					: "",
				status: filters.status?.value,
			};
			const response = await fetchreservations(id, data, pages);
			setReservation(response?.data.data);
			setmeta(response?.data?.meta);
		}
		fetchReservationsFilterdData();
	}, [
		filters.hotel_name,
		filters.checkin,
		filters.destination_id,
		filters.status,
		pages,
	]);


	const goto = (page) => {
		const params = new URLSearchParams();
		if (page) {
			params.append("page", page);
		} else {
			params.delete("page", page);
		}
		history.push({ search: params.toString() });
	};
	return (
		<>
			<h5>
				Reservations <span className="tentative">{`(${name})`}</span>
			</h5>
			<FilterReservation setFilters={setFilters} filters={filters} />
			<ReservationTable
				Reservation={Reservation}
				setReservation={setReservation}
				meta={meta}
				goto={goto}
			/>
		</>
	);
}
export default ListReservation;
