import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";

import { useState } from "react";
import Locale from "translations";

export default function FinancialFilter() {
	const { backOffice } = Locale;
  const suppliers =[{value:"1",label:"supplier1",id:1},{value:"2",label:"supplier2",id:2}]
  const currency =[{value:"1",label:"currency1",id:1},{value:"2",label:"currency2",id:2}]
  

	const [filterState, setFilterState] = useState({
		Platforms: '',
		currency:'',
    datefrom:null,
    dateTo:null
	});

	const resetFilters = () => {
		setFilterState({
			Platforms: {},
		currency:{},
    datefrom:null,
    dateTo:null
		});
	};

	return (
		<>
			<div className="main-filter bg-white-blue py-2 mt-2">
				<div className="row align-items-center  w-100 no-gutter m-0">
					<div className="col-md-2">
						<SelectField
							label={backOffice.Platforms}
							 placeholder={backOffice.Platforms}
							name="Platforms"
              onChange={(e)=>{
                setFilterState({...filterState,Platforms: e});
              }}
							options={suppliers}
							value={filterState?.Platforms}
						/>
					</div>

					<div className="col-md-2">
						<SelectField
						  label={backOffice.currency}
							value={filterState?.currency}
							placeholder={backOffice.currency}
              onChange={(e)=>{
                setFilterState({...filterState,currency:e});
              }}
							name="currency"
							options={currency}
						/>
					</div>
          <div className="col-md-2">
						<DatePickerField
            	type="text"
              onOutsideClick={false}
						  label={backOffice.datefrom}
							date={filterState?.datefrom}
							placeholder={backOffice.datefrom}
							name="datefrom"
              onChangeDate={(e)=>{
                setFilterState({...filterState,datefrom:e});
              }}
						/>
					</div>
          
          <div className="col-md-2">
						<DatePickerField
            	type="text"
              onOutsideClick={false}
						  label={backOffice.dateTo}
							date={filterState?.dateTo}
							placeholder={backOffice.dateTo}
							name="dateTo"
              onChangeDate={(e)=>{
                setFilterState({...filterState,dateTo:e});
              }}
						/>
					</div>
          
          <div className="col-md d-flex align-items-center gap-2 ">
            <div className=" mt-4 ">
                      <button type="button" class="btn btn-success px-5">Search</button>
              </div>
					
						<div
            onClick={resetFilters} 
							className="d-flex align-items-center  text-secondary pointer mt-4 reset-btn">
							<img src={resetIcon} alt="" srcset="" />
							<p className="text-caption">{backOffice.resetFilter}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
