import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Locale from "translations";
import Details from "./details";

export default function SuppliersEdit() {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("1");
  const { backOffice } = Locale;

  const location = useLocation();
  const data = location.state?.detail;
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  console.log("activeTabactiveTab", data);
  return (
    <div className="edit-supplier container-fluid property">
      <div className=" text-black mb-3">
        <div className="d-flex justify-content-between ">
          <p className="font-weight-bold">{data?.name}</p>
          <p
            className={
              data?.status === "Active" ? "text-success" : "text-danger "
            }
          >
            <span className="text-muted ">{backOffice.currentStatus}:</span>
            {data.status == "Active"
              ? `${backOffice.active}`
              : `${backOffice.inActive}`}{" "}
            <i className="fas fa-edit" style={{ color: "#354F5C" }}></i>
          </p>
        </div>
      </div>
      <div className="border-bottom">
        <div className="text-black">
          <div className="d-flex route-block">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${activeTab === "1" ? "is-active" : ""}`}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  {backOffice.details}
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Details data={data} />
          </TabPane>
        </TabContent>

        <div className="d-flex  justify-content-end mt-5">
          <button
            className=" cancel-bg p-2 px-4 mx-3"
            onClick={() => {
              history.push(`/suppliers`);
            }}
          >
            {backOffice.back}
          </button>

          <button className="btn companies-btn">{backOffice.edit}</button>
        </div>
      </div>
    </div>
  );
}
