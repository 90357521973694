import axios from "axios";
const visaURL = process.env.REACT_APP_API_URL + "/api/ground-work";
const groundLookupsURL = process.env.REACT_APP_API_URL + "/api";

export const fetchType = async (id) => {
	return await axios
		.get(`${visaURL}/list/types/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchCurrencies = async () => {
	return await axios
		.get(`${visaURL}/list/currencies`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addDestination = async (data) => {
	return await axios
		.post(`${visaURL}/add/destination`, data)
		.then((res) => res)
		.catch((err) => err);
};

export const addNewGroundDestination = async (data) => {
	return await axios
		.post(`${visaURL}/create-ground-work-config`, data)
		.then((res) => res)
		.catch((err) => err);
};

export const addTransportationConfigurations = async (data) => {
	return await axios
		.post(
			`${process.env.REACT_APP_API_URL}/api/transportation/set-configs`,
			data
		)
		.then((res) => res)
		.catch((err) => err);
};

///visa/configurations
export const viewConfigurations = async () => {
	return await axios
		.get(`${visaURL}/visa/configurations`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewGroundNewConfigurations = async () => {
	return await axios
		.get(`${visaURL}/get-ground-work-config`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewTransportationConfigurations = async () => {
	return await axios
		.get(`${process.env.REACT_APP_API_URL}/api/transportation/get-configs`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const ServiceTypeLookup = async () => {
	return await axios
		.get(`${groundLookupsURL}/lookups/ground-service-types`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const vehicleModelLookup = async () => {
	return await axios
		.get(`${groundLookupsURL}/lookups/transportation-vehicle-models`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const vehicleTypeLookup = async () => {
	return await axios
		.get(`${groundLookupsURL}/lookups/transportation-vehicle-types`)
		.then((res) => res)
		.catch((err) => err.response);
};
