import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate from "helpers/validate";

const SupplierDetails = ({
	setSupplier,
	supplier,
	setErrors,
	errors,
	countries,
	cities,
}) => {
	return (
		<div className="supplier-details">
			<div className="bankConfigrationHeader p-3 text-visa-header font-weight-bold mt-3">
				<p className="m-0">Supplier Details</p>
			</div>
			<div className="row pb-3 px-5 our-border py-0 m-0">
				<div className="col-4 mt-2">
					<TextField
						type="text"
						label={"Supplier Name"}
						placeholder={"Enter Supplier Name"}
						value={supplier.supplierName}
						name="supplierName"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
								}),
							});
							setSupplier({
								...supplier,
								supplierName: e.target.value,
							});
						}}
						color={errors?.supplierName?.required ? "danger" : ""}
						errors={errors?.supplierName}
					/>
				</div>

				<div className="col-4">
					<SelectField
						label={"Country"}
						value={supplier.supplierCountry}
						name="supplierCountry"
						options={countries}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "supplierCountry", value: e.value },
									{
										required: true,
									}
								),
							});
							setSupplier({
								...supplier,
								supplierCountry: e,
								supplierCity: null,
							});
						}}
						color={errors?.supplierCountry?.required ? "danger" : ""}
						errors={errors?.supplierCountry}
					/>
				</div>
				<div className="col-4">
					<SelectField
						label={"City"}
						value={supplier.supplierCity}
						name="supplierCity"
						options={cities}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "supplierCity", value: e.value },
									{
										required: true,
									}
								),
							});
							setSupplier({
								...supplier,
								supplierCity: e,
							});
						}}
						color={errors?.supplierCity?.required ? "danger" : ""}
						errors={errors?.supplierCity}
					/>
				</div>
				<div className="col-4 mt-2">
					<TextField
						type="text"
						label={"Company Email Address"}
						placeholder={"Enter Company Email Address"}
						value={supplier.supplierEmail}
						name="supplierEmail"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									email: true,
								}),
							});
							setSupplier({
								...supplier,
								supplierEmail: e.target.value,
							});
						}}
						color={
							errors?.supplierEmail?.required || errors?.supplierEmail?.email
								? "danger"
								: ""
						}
						errors={errors?.supplierEmail}
					/>
				</div>
			</div>
		</div>
	);
};

export default SupplierDetails;
