import Model from "components/model";
import { useGlobalState } from 'context/global';
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchSeason } from "services/lookups";
import Locale from "translations";

const BasicInfo = ({ state }) => {
	const { backOffice } = Locale;
	let locale = localStorage.getItem("currentLocale") || "en";
	const { allCountries } = useGlobalState();
	const [commissionList, setCommissionList] = useState();
	const [countries, setCountries] = useState([]);
	const [season, setSeason] = useState([]);

	const [hotelDetails, setHotelDetails] = useState({
		area: { name: "", name_en: "", name_ar: "" },
		area_id: null,
		city: { name: "", name_en: "", name_ar: "" },
		country: { name: "", name_en: "", name_ar: "" },
		country_id: null,
		city_id: null,
		commissions: [],
		contract_end_date: null,
		contract_start_date: null,
		created_at: null,
		email: "",
		is_active: 1,
		mobile: "",
		name: "",
		name_ar: "",
		name_en: "",
		reservation_officer_name: "",
		updated_at: null,
		website: "",
		spin: false,
	});

	const { id } = useParams();

	useEffect(() => {
		if (id && state) {
			setHotelDetails({
				...state?.hotel,
			});
		}
	}, [id, state]);

	useEffect(() => {
		setCommissionList(hotelDetails?.commissions);
	}, [hotelDetails]);

	const [modal, setModal] = useState({
		isOpen: false,
		type: "",
		title: "",
	});

	const openModalHandler = (item) => {
		setModal({
			isOpen: !modal.isOpen,
			type: "modal",
			title: "View Selectors",
			item: { ...item },
		});
	};

	// Fetch Countries
	useEffect(() => {
		async function fetchLookups() {
			// res.push({country_code:'All', names:{en:'All', ar:'All'}});
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);

			const resSeason = await fetchSeason();
			const format_season = resSeason.seasons?.map((t) => ({
				value: t.id,
				label: t.name,
			}));
			setSeason(format_season);
		}

		fetchLookups();
	}, []);

	const allCommissions = commissionList?.map((item, index) => (
		<>
			<tr>
				<td>{item["name_" + locale]}</td>
				<td>{item.type}</td>
				<td>
					<button
						className="btn btn-link text-decoration-underlined"
						id={"selector" + index}
						onClick={() => openModalHandler(item)}
					>
						<i className="fas fa-eye"></i> {Object.keys(item.selectors).length}{" "}
						{backOffice.selectors}
					</button>
				</td>
				<td>
					{item.base_commission} {item.type == "base" ? "%" : backOffice.SAR}
				</td>
				<td>{item.safa_commission} %</td>
				<td>{item.platform_commission} %</td>
			</tr>
		</>
	));

	return (
		<>
			<div className="property-details">
				<div className="row m-0">
					<div className="col-12 p-0">
						<div className="row custom--row fix-text-white p-3">
							<div className="col-md-4">
								<div className="item--display">
									<div className="item--key">{backOffice.hotelName}</div>
									<div className="item--value">
										{hotelDetails["name_" + locale]}
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="item--display">
									<div className="item--key">
										{backOffice.country + "/" + backOffice.region}
									</div>
									<div className="item--value">
										{hotelDetails?.country?.["name_" + locale]}
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="item--display">
									<div className="item--key">{backOffice.city}</div>
									<div className="item--value">
										{hotelDetails.city?.["name_" + locale]}
									</div>
								</div>
							</div>

							<div className="col-md-4">
								<div className="item--display">
									<div className="item--key">{backOffice.area}</div>
									<div className="item--value">
										{hotelDetails.area?.["name_" + locale]}
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="item--display">
									<div className="item--key">{backOffice.dateOfContract}</div>
									<div className="item--value">
										{moment(hotelDetails.contract_end_date).format(
											"DD-MM-YYYY"
										)}
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="item--display">
									<div className="item--key">{backOffice.contractEndDate}</div>
									<div className="item--value">
										{moment(hotelDetails.contract_end_date).format(
											"DD-MM-YYYY"
										)}
									</div>
								</div>
							</div>

							<div className="col-md-4">
								<div className="item--display">
									<div className="item--key">
										{backOffice.reservationOfficerName}
									</div>
									<div className="item--value">
										{hotelDetails.reservation_officer_name}
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="item--display">
									<div className="item--key">{backOffice.mobileNumber}</div>
									<div className="item--value">{hotelDetails.mobile}</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="item--display">
									<div className="item--key">{backOffice.email}</div>
									<div className="item--value">{hotelDetails.email}</div>
								</div>
							</div>
						</div>

						<div className="row custom--row p-3">
							<div className="col-md-12 head-manager p-0">
								<p>{backOffice.commissions}</p>
							</div>
							<div className="col our-border px-4">
								<table className="custom-table back-office-table table table-striped">
									<thead>
										<tr>
											<th>{backOffice.name}</th>
											<th>{backOffice.commissionType}</th>
											<th>{backOffice.selectors}</th>
											<th>{backOffice.baseCommission}</th>
											<th>{backOffice.safaCommission}</th>
											<th>{backOffice.platformCommission}</th>
										</tr>
									</thead>
									<tbody>
										{allCommissions ? (
											allCommissions
										) : (
											<tr>
												<td colSpan="6">
													<div className="product-no-data">
														<i className="fas fa-info-circle fa-lg"></i>{" "}
														<h4>{backOffice.noResult}</h4>
													</div>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>

						<div className="row custom--row p-3">
							<div className="col-md-12 head-manager p-0">
								<p>{backOffice.reservations}</p>
							</div>
							<div className="col our-border py-5">
								<div className="row custom--row">
									<div className="col-3">
										<div
											className="item--display light-bg p-3"
											style={{ backgroundColor: "#00AD0D2E" }}
										>
											<div className="item--key">
												{backOffice.totalReservations}
											</div>
											<div className="item--value">
												{hotelDetails?.reservation_stats?.total_reservations}
											</div>
										</div>
									</div>
									<div className="col-3">
										<div className="item--display light-bg p-3">
											<div className="item--key">
												{backOffice.safaCommission}
											</div>
											<div className="item--value">
												{hotelDetails?.reservation_stats?.safa_commission}
											</div>
										</div>
									</div>
									<div className="col-3">
										<div className="item--display light-bg p-3">
											<div className="item--key">
												{backOffice.platformCommission}
											</div>
											<div className="item--value">
												{hotelDetails?.reservation_stats?.platform_commission}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Model
				isOpen={modal.isOpen}
				type={modal.type}
				title={
					backOffice.selectors + " (" + modal?.item?.["name_" + locale] + ")"
				}
				toggle={openModalHandler}
				size="lg"
				hasFooter={false}
			>
				<div className="w-100 p-4">
					<div className="row custom--row">
						{modal?.item?.selectors?.country !== "" &&
							modal?.item?.selectors?.country ? (
							<div className="col-12 mb-3">
								<div className="item--display light-bg p-3">
									<div className="item--key">{backOffice.country}</div>
									<div className="item--value">
										{modal?.item?.selectors?.country === "all"
											? "All"
											: modal?.item?.selectors?.country?.map((item) => {
												let countryObject = countries.find(
													(country) => country.value === item.country_id
												);
												console.log("countryObject", countryObject);
												return countryObject.label + " , ";
											})}
									</div>
								</div>
							</div>
						) : null}

						{modal?.item?.selectors?.season !== "" &&
							modal?.item?.selectors?.season ? (
							<div className="col-12 mb-3">
								<div className="item--display light-bg p-3">
									<div className="item--key">{backOffice.season}</div>
									<div className="item--value">
										{modal?.item?.selectors?.season === "all"
											? "All"
											: modal?.item?.selectors?.season?.map((item) => {
												let seasonObject = season.find(
													(itemSeason) => itemSeason.value === item.id
												);
												return seasonObject.label + " , ";
											})}
									</div>
								</div>
							</div>
						) : null}

						{modal?.item?.selectors?.hotel !== "" &&
							modal?.item?.selectors?.hotel ? (
							<div className="col-12 mb-3">
								<div className="item--display light-bg p-3">
									<div className="item--key">{backOffice.hotelLevel}</div>
									<div className="item--value">
										{modal?.item?.selectors?.hotel === "all"
											? "All"
											: modal?.item?.selectors?.hotel?.map((item) => {
												return item?.name?.en + " , ";
											})}
									</div>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</Model>

			{/* <Model
        isOpen={editCommissionModal.isOpen}
        type={editCommissionModal.type}
        title={backOffice.edit + ' ' + backOffice.commission }
        toggle={editItem}
        size="lg"
        hasFooter={false}
      >
        <div className="w-100 p-4">
          <div className="row custom--row">
            <div className="col-12 mb-3">
              <div className="item--display light-bg p-3">
                <div className="item--key">{backOffice.country}</div>
                <div className="item--value">Egypt , Saudi Arabia</div>
                <button className="btn btn-link edit-selector-btn btn-lg" onClick={() => editSelectorCountry(1)} title={backOffice.edit}><i className="fas fa-edit"></i> </button>
              </div>
            </div>
            <div className="col-12 mb-3">
              <div className="item--display light-bg p-3">
                <div className="item--key">{backOffice.season}</div>
                <div className="item--value">Ragab , Ramadan</div><button className="btn btn-link edit-selector-btn btn-lg" onClick={() => editSelectorSeason(1)} title={backOffice.edit}><i className="fas fa-edit"></i> </button>
              </div>
            </div>
            <div className="col-12">
              <div className="item--display light-bg p-3">
                <div className="item--key">{backOffice.hotelLevel}</div>
                <div className="item--value">5 Stars , 4 Stars</div><button className="btn btn-link edit-selector-btn btn-lg" onClick={() => editSelectorHotel(1)} title={backOffice.edit}><i className="fas fa-edit"></i> </button>
              </div>
            </div>
          </div>
        </div>
      </Model> */}
		</>
	);
};

export default BasicInfo;
