import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate from "helpers/validate";
import moment from "moment";
import { useState } from "react";
import { fetchCitiesSearch } from "services/lookups";
import Locale from "translations";

export default function OfflineReservationRequest({
	currencies,
	countries,
	requestDetails,
	setRequestDetails,
	errors,
	setErrors,
	getHotelsLookups,
	suppliersLookups,
	hotelsLookups,
	mealsLookup,
}) {
	const { booking, backOffice, requests } = Locale;

	const [listAuto, setListAuto] = useState([]);
	const getListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const countries = await fetchCitiesSearch(inputValue);
			let result = countries.map((item) => {
				return {
					...item,
					id: item.id,
					//name: item.name + " - " + item.country.name[locale],
					name: item.name,
					value: item.id,
					label: item.name,
				};
			});

			setListAuto(result);
		}
	};
	const handleChange = (e, flag = false) => {
		const name = e.target?.name;
		//const value = e.target?.value;
		const value = e.value?.name;

		if (flag) {
			setRequestDetails({
				...requestDetails,
				country: e["value"],
				goingTo: e.value,
			});
		} else
			setRequestDetails({
				...requestDetails,
				destination: {
					label: e?.value?.name,
					value: e?.value?.id,
					country: e?.value?.country,
				},
			});
		setRequestDetails({
			...requestDetails,
			destination: {
				label: e?.value?.name,
				value: e?.value?.id,
				country: e?.value?.country,
			},
		});
	};
	return (
		<>
			<div className="bankConfigrationHeader p-3 text-visa-header font-weight-bold mt-3">
				<p className="m-0">Request Details</p>
			</div>
			<div className="row pb-3 px-3 our-border p-0 m-0">
				{/* destination */}
				<div className="col-4">
					{/* <SelectField
            label={booking.Destination}
            placeholder={booking.Destination}
            value={requestDetails.destination}
            name="destination"
            options={countries}
            onChange={(e) => {
              setErrors({
                ...errors,
                ...validate(
                  { name: "destination", value: e.value },
                  { required: true }
                ),
              });
              setRequestDetails({
                ...requestDetails,
                destination: e
              });
            }}
            color={errors?.destination?.required ? "danger" : ""}
            errors={errors?.destination}
          /> */}
					<AutoCompleteField
						label={booking.Destination}
						placeholder={booking.Destination}
						hasLabel={true}
						flag={requestDetails.goingTo?.country?.flag}
						listAuto={listAuto}
						setListAuto={setListAuto}
						getListAuto={getListAuto}
						isSearchable={true}
						name="destination"
						value={requestDetails.destination?.label}
						onChange={(e) =>
							handleChange({ name: "destination", value: { name: e } }, true)
						}
						onSelectValue={(e) => {
							handleChange({ name: "destination", value: e }, true);
						}}
					/>
				</div>
				{/* supplier */}
				<div className="col-4">
					<SelectField
						label={requests.supplier}
						placeholder={requests.supplier}
						value={requestDetails.supplier}
						name="supplier"
						options={suppliersLookups}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "supplier", value: e.value },
									{ required: true }
								),
							});
							setRequestDetails({
								...requestDetails,
								supplier: e,
								hotel_name: null,
							});
							getHotelsLookups(e.id);
						}}
						color={errors?.supplier?.required ? "danger" : ""}
						errors={errors?.supplier}
					/>
				</div>
				{/* Hotel Name */}
				<div className="col-4">
					<SelectField
						label={backOffice.hotelName}
						placeholder={backOffice.hotelName}
						value={requestDetails.hotel_name}
						name="hotel_name"
						options={hotelsLookups}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "hotel_name", value: e.value },
									{ required: true }
								),
							});
							setRequestDetails({
								...requestDetails,
								hotel_name: e,
							});
						}}
						color={errors?.hotel_name?.required ? "danger" : ""}
						errors={errors?.supplier}
					/>
				</div>
				{/* check-in */}
				<div className="col-4">
					<div className="w-100 p-2">
						<DatePickerField
							type="text"
							placeholder={"DD/MM/YYYY"}
							label={backOffice.checkIn}
							date={requestDetails.check_in}
							onChangeDate={(checkInDate) => {
								setRequestDetails({
									...requestDetails,
									check_in: checkInDate,
									check_out:
										moment(checkInDate) >= moment(requestDetails?.check_out)
											? null
											: requestDetails?.check_out,
								});
								setErrors({
									...errors,
									...validate(
										{ name: "check_in", value: checkInDate },
										{ required: true }
									),
								});
							}}
							isOutsideRange={(day) =>
								!day.isAfter(moment(new Date()).add(0, "d"), "day")
							}
							color={errors?.check_in?.required ? "danger" : ""}
							errors={errors?.check_in}
						/>
					</div>
				</div>
				{/* check-out */}
				<div className="col-4">
					<div className="w-100 p-2">
						<DatePickerField
							type="text"
							placeholder={"DD/MM/YYYY"}
							label={backOffice.checkOut}
							date={requestDetails.check_out}
							onChangeDate={(checkOutDate) => {
								setRequestDetails({
									...requestDetails,
									check_in:
										moment(checkOutDate) <= moment(requestDetails?.check_in)
											? null
											: requestDetails?.check_in,
									check_out: checkOutDate,
								});
								setErrors({
									...errors,
									...validate(
										{ name: "check_out", value: checkOutDate },
										{ required: true }
									),
								});
							}}
							isOutsideRange={(day) =>
								!day.isAfter(moment(new Date()).add(1, "d"), "day")
							}
							color={errors?.check_out?.required ? "danger" : ""}
							errors={errors?.check_out}
						/>
					</div>
				</div>
				{/*  meals */}
				<div className="col-4">
					<SelectField
						label={backOffice.meals}
						placeholder={backOffice.meals}
						value={requestDetails.meal_plan}
						name="residence"
						options={mealsLookup}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "meal_plan", value: e.value },
									{ required: true }
								),
							});
							setRequestDetails({
								...requestDetails,
								meal_plan: e,
							});
						}}
						color={errors?.meal_plan?.required ? "danger" : ""}
						errors={errors?.meal_plan}
					/>
				</div>
				{/*  residence */}
				<div className="col-4">
					<SelectField
						label={backOffice.residence}
						placeholder={backOffice.residence}
						value={requestDetails.residence}
						name="residence"
						options={countries}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "residence", value: e.value },
									{ required: true }
								),
							});
							setRequestDetails({
								...requestDetails,
								residence: e,
							});
						}}
						color={errors?.residence?.required ? "danger" : ""}
						errors={errors?.residence}
					/>
				</div>
				{/* nationality */}
				<div className="col-4">
					<SelectField
						label={requests.Nationality}
						placeholder={requests.Nationality}
						value={requestDetails.nationality}
						name="nationality"
						options={countries}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "nationality", value: e.value },
									{ required: true }
								),
							});
							setRequestDetails({
								...requestDetails,
								nationality: e,
							});
						}}
						color={errors?.nationality?.required ? "danger" : ""}
						errors={errors?.nationality}
					/>
				</div>
				{/* Currency */}
				<div className="col-4">
					<SelectField
						label={requests.Currency}
						placeholder={requests.Currency}
						value={requestDetails.currency}
						name="currency"
						options={currencies}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "currency", value: e.value },
									{ required: true }
								),
							});
							setRequestDetails({
								...requestDetails,
								currency: e,
							});
						}}
						color={errors?.currency?.required ? "danger" : ""}
						errors={errors?.currency}
					/>
				</div>
				{/* remarks */}
				<div className="col-12">
					<TextField
						label={backOffice.remarks}
						placeholder={backOffice.remarks}
						value={requestDetails.remarks}
						name="remarks"
						onChange={(e) => {
							setRequestDetails({
								...requestDetails,
								remarks: e.target.value,
							});
						}}
					/>
				</div>
			</div>
		</>
	);
}
