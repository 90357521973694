import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import Locale from "translations";

export default function AdsFilter({ onReset, filters, setFilters }) {
	const { backOffice, booking, marketPlace } = Locale;
	

	const statusLookups = [
		{ value: 0, label: "unpublish" },
		{ value: 1, label: "publish" },
	];
  const typeLookups = [
		{ value: "hotels", label: "hotels" },
		{ value: "destinations", label: "destinations" },
	];

	function resetFilters() {
		setFilters({
			search: "",
			expiry_date: "",
			type: "",
			publish: "",
		});
	}



	return (
		<div className="main-filter bg-white-blue py-2 mt-2">
			<div className="row align-items-center  w-100 no-gutter m-0">
				<div className="col-md-3">
					<div className="main-label">
						<TextField
							type="text"
							placeholder={"search"}
							label={"search"}
							value={filters.search}
							onChange={(e) => {
								setFilters({
									...filters,
									search: e.target.value,
								});
							}}
						/>
						<i className="fas fa-search fa-fw"></i>
					</div>
				</div>

				<div className="col-md-2">
					<DatePickerField
						label={"expiry date"}
						placeholder={"expiry date"}
						date={filters.expiry_date}
						onChangeDate={(filterDate) => {
							setFilters({
								...filters,
								expiry_date: filterDate,
							});
						}}
						isOutsideRange={(day) => {
							return false;
						}}
					/>
				</div>
        <div className="col-md-2">
					<SelectField
						label={'type'}
						value={filters.type}
						placeholder={"type"}
						name="type"
						options={typeLookups}
						onChange={(e) => {
							setFilters({
								...filters,
								type: e,
							});
						}}
					/>
				</div>

				<div className="col-md-2">
					<SelectField
						label={"publish"}
						value={filters.publish}
						placeholder={"publish"}
						name="publish"
						options={statusLookups}
						onChange={(e) => {
							setFilters({
								...filters,
								publish: e,
							});
						}}
					/>
				</div>

				<div className="col-md" onClick={resetFilters}>
					<div
						onClick={onReset}
						className="d-flex align-items-center text-secondary pointer mt-4 reset-btn"
					>
						<img src={resetIcon} alt="" srcset="" />
						<p className="text-caption">{backOffice.resetFilter}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
