import CommissionList from "./CommissionList";

function CommissionB2c() {
	return (
		<>
			<CommissionList />
		</>
	);
}

export default CommissionB2c;
