import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getInvouce, viewBookingPassinger } from "services/Request";
import Locale from "translations";
import invoice from "../../../../../assets/images/onlineVisa/Layer2.svg";
import Booking from "./Booking";
import Financial from "./Financial";
import History from "./history";
import Passangers from "./Passangers";
import Payments from "./Payments";
export default function BookingView() {
	const { id } = useParams();
	const [activeTab, setActiveTab] = useState(1);
	const location = useLocation();
	const BookingInfo = location.state?.BookingInfo;
	const { backOffice, booking } = Locale;
	const [bookingPassengerData, setBookingPassengerData] = useState();
	const [invoiceData, setInvoiceData] = useState();
	const [Reload, setReload] = useState(true);
	useEffect(() => {
		const fetchBookingPassingerTab = async () => {
			const res = await viewBookingPassinger(id);
			setBookingPassengerData(res?.data?.data);
		};
		fetchBookingPassingerTab();
	}, [Reload]);
	//   useEffect(() => {

	//     const fetchInvoice = async () => {
	//         const res = await getInvouce(id)
	//         setInvoiceData(res?.data?.data)
	//       }
	//       fetchInvoice()
	//   }, [])
	console.log("invoiceData");
	const daweloadInvoice = async (brn) => {
		const res = await getInvouce(brn);
		const invoice = res?.data?.data;
		const allRooms =
			invoice?.rooms?.length > 0
				? invoice?.rooms.map((room, index) => {
						return `		<tr>
							<td
								class=${
									+index + 1 === +invoice?.rooms?.length
										? "last-td-border"
										: "remove-last-td-border"
								}
							>
								${+index === 0 ? invoice?.hotel_name : ""}
							</td>
							<td class="f-12">${room.name}</td>
							<td>
						
								${
									room?.passengers?.length > 0
										? room?.passengers.map((passenger) => {
												return `<p class="m-0 f-12">
												${passenger?.first_name} ${passenger?.last_name}
											</p>`;
										  })
										: "-"
								}
							</td>
							<td class="f-12">${invoice?.rooms?.length}</td>
							<td class="f-12">${room.adults}</td>
							<td class="f-12">${room.children}</td>
							<td class="f-12">${room.nights}</td>
							<td class="f-12">${room.from_date}</td>
							<td class="f-12">${room.to_date}</td>
							<td class="f-12">
								${room.price} ${invoice?.currency}
							</td>
						</tr>`;
				  })
				: [];
		if (invoice) {
			var mywindow = window.open("", "PRINT", "height=600,width=1000");

			mywindow.document.write("<html><head><title>" + "file name" + "</title>");
			mywindow.document.write(`
				<style>
				html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
	margin: 0;
	padding: 0;
}
a {
	text-decoration: none !important;
	cursor: pointer;
}
a:hover {
	text-decoration: none !important;
}

fieldset,
img {
	border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
	font-style: normal;
	font-weight: normal;
}
ol,
ul {
	list-style: none;
}
caption,
th {
	text-align: var(--left);
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 100%;
	font-weight: normal;
}
q:before,
q:after {
	content: "";
}
abbr,
acronym {
	border: 0;
	font-variant: normal;
}
sup {
	vertical-align: text-top;
}
sub {
	vertical-align: text-bottom;
}
input,
textarea,
select {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
}
legend {
	color: #000;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}
			* {
    font-family: "Cairo", sans-serif;
		box-sizing: border-box;
}

.container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}
 .container {
    max-width: 1160px;
}
 .py-4 {
    padding-bottom: 1.5rem !important;
 padding-top: 1.5rem !important;
	}
.mx-3 {
    margin-left: 1rem !important;
		   margin-right: 1rem !important;
}
.mt-2 {
    margin-top: 0.5rem !important;
}
.mt-3 {
    margin-top: 1rem !important;
}
.bg-gray {
    background: #e8e8e8 !important;
}
.p-4 {
    padding: 1.5rem !important;
}
.align-items-center {
    align-items: center !important;
}
.justify-content-around {
    justify-content: space-around !important;
}
.font-weight-bold {
    font-weight: 700 !important;
}

.mb-1, .my-1 {
    margin-bottom: 0.25rem !important;
}
.overFlow-hide{
	
}

element.style {
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 5px;
    padding-left: 5px;
}

element.style {
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 5px;
    padding-left: 5px;
}
.col-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
}
.col-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
}
.col-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
}
.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}
.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}
.col-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
.w-50{
width : 50%
}

element.style {
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 5px;
    padding-left: 5px;
}

.txt-blue {
    color: #0c3b5c;
    font-weight: 700;
}

.container-wrapper {
		border: 1px solid #0c3b5c;
	}
		.justify-content-between {
    justify-content: space-between !important;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.border-header {
	border-bottom: 1px solid #2d2d2d;
}
.border-section {
	border-bottom: 1px solid #0c3b5c;
}

.invoice-word {
	color: #0c3b5c;
	font-size: 40px;
	font-weight: 700;
}
.f-12{
font-size: 12px;
}

table {
		border-collapse: collapse;
	}
	td {
		border: 1px solid #0c3b5c;
		padding: 0.5rem;
	}
	th {
		border: 1px solid #0c3b5c;
		padding: 0.5rem;
	}
	thead th {
		border: 1px solid #0c3b5c;
		padding: 0.5rem;
	}
	tr:first-child td {
		border-top: 0;
	}
	tr td:first-child {
		border-left: 0;
	}

	tr td:last-child {
		border-right: 0;
	}
	tr th:first-child {
		border-left: 0;
	}
	tr th:last-child {
		border-right: 0;
	}
	.last-td-border {
		border: 0 !important;
	font-size: 12px;
		border-bottom: 1px solid #0c3b5c !important;
	}

.remove-last-td-border {
	border-bottom: 0 !important;
		border-top: 0 !important;
	font-size: 12px;
	}
.bordered-img-holder {
	display: flex;
	align-items: center;
	height: 20vh;
}
.width-10 {
	width: 11% !important;
}	
.invoice {
		height: 95vh !important;
		width : 100%;
		  padding-right: 5px;
    padding-left: 5px;

	}
.company-logo-font {
	font-size: 2rem !important;
}

	.container-wrapper {
		height: 95vh !important;
	}
 .p-5{
		  padding: 2rem;
 }
.d-flex {
    display: flex !important;
}
.justify-content-center {
    justify-content: center!important;
}
.flex-column{
	flex-direction: column;
}
 .border{
	border: 1px solid #dfdfdf;
 }
	.letter-logo {
	width: 40px;
	height: 40px;
	font-size: 16px;
	font-weight: 600;
	color: white;
	background-color: #cb9a51;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: capitalize;
}

			</style>
			</head>
			<body >`);
			mywindow.document.write(`
			<div class="invoice  py-4 bg-white  ">
			<div class="container-wrapper mt-3 ">
				<div class="bg-gray p-4 border-header">
					<div class="row justify-content-between align-items-center ">
						<div class="col-3">
							<img src=${invoice} alt="hotal-logo" class="img-fluid " />
						</div>
						<div class="col-5">
							<h4 class="txt-blue">SAFA SOFT</h4>
							<h4 class="txt-gray">9 Othman Towers</h4>
							<h4 class="txt-gray">
								Kornish El-Nile, Maadi, Cairo - Egypt
							</h4>
							<h4 class="txt-gray">Email: info@safasoft.com</h4>
						</div>
					</div>
				</div>

				<div class="border-section">
					<div class="row justify-content-around align-items-center  p-4 ">
						<div class="col-3 p-2">
							<h2 class="invoice-word ">INVOICE</h2>
						</div>

						<div class="row col-8">
							<div class="col-1 "></div>
							<div class="col-4">
								<h4 class="txt-gray mb-1">Invoice No.</h4>
								<h4 class="txt-gray mb-1">Invoice Date</h4>
								<h4 class="txt-gray mb-1">Confirmation No.</h4>
							</div>

							<div class="col-6">
								<h4 class="font-weight-bold mb-1">
									${invoice?.invoiceNumber}
								</h4>
								<h4 class="font-weight-bold mb-1">
									${invoice?.invoiceDate}
								</h4>
								<h4 class="font-weight-bold mb-1">
									${invoice?.confirmationNumber == null ? "-" : invoice?.confirmationNumber}
								</h4>
							</div>
						</div>
					</div>
				</div>

				<div class="">
					<div class="row justify-content-around align-items-center  p-4 ">
						<div class="col-3 border  p-2 d-flex  justify-content-center ">
							${
								invoice?.companyImage
									? `<img src=${invoice?.companyImage} alt="hotal-logo" class="img-fluid w-50" />`
									: `<div class="company-logo-font letter-logo p-5 ">
									${invoice?.company?.name[0]}
								</div>`
							}
						</div>

						<div class="row col-8">
							<div class="col-1	"></div>

							<div class="col-4 px-2">
								<h4 class="txt-gray mb-1">Agency Name</h4>
								<h4 class="txt-gray mb-1">Address</h4>
								<h4 class="txt-gray mb-1">Phone</h4>
							</div>

							<div class="col-6">
								<h4 class="font-weight-bold mb-1">
									${invoice?.company?.name}
								</h4>

								<h4 class="font-weight-bold mb-1">
									${invoice?.company?.address ? invoice?.company?.address : "-"}
								</h4>

								<h4 class="font-weight-bold mb-1">
									${invoice?.company?.phone}
								</h4>
							</div>
						</div>
					</div>
				</div>

				<div class="">
					<table class=" marketInvoice-table">
						<thead>
							<tr>
								<th scope="col  " class="font-weight-bold width-10 f-12">
									Hotel Name
								</th>
								<th scope="col  " class="font-weight-bold width-10 f-12">
									Room Name
								</th>
								<th scope="col  " class="font-weight-bold width-10 f-12">
									Guest Name
								</th>
								<th scope="col  " class="font-weight-bold f-12">
									Rooms
								</th>
								<th scope="col  " class="font-weight-bold f-12">
									Adult
								</th>
								<th scope="col  " class="font-weight-bold f-12">
									Child
								</th>
								<th scope="col  " class="font-weight-bold f-12">
									Nights
								</th>
								<th scope="col  " class="font-weight-bold f-12">
									Check-in
								</th>
								<th scope="col  " class="font-weight-bold f-12">
									Check-out
								</th>
								<th scope="col  " class="font-weight-bold f-12">
									Price
								</th>
							</tr>
						</thead>
						<tbody>${allRooms}</tbody>
					</table>
				</div>

				
				${
					invoice?.remark
						? `	<div class="border-section">
						<div class="row justify-content-start align-items-center  p-4 ">
							<div class=" col-12 d-flex flex-column">
								<p class="font-weight-bold mb-1">Agent Remarks</p>
								<p class="m-0">${invoice?.remark}</p>
							</div>
						</div>
					</div>`
						: ""
				}

				<div class="">
					<div class="row justify-content-start align-items-center  p-4 ">
						<div class=" col-3 d-flex flex-column">
							<p class="mb-1">Billed by</p>
							<p class="mb-1">Issued By</p>
							<p class="mb-1">Invoice Status</p>
							<p class="mb-1">Amount</p>
						</div>

						<div class=" col-6 d-flex flex-column">
							<p class="font-weight-bold mb-1"> ${invoice?.company?.name}</p>
							<p class="font-weight-bold mb-1">Safa Soft</p>
							<p class="font-weight-bold mb-1">Confirmed</p>
							<p class="font-weight-bold mb-1">
								${invoice?.total_price} ${invoice?.currency}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

	
	`);
			mywindow.document.write("</body></html>");

			mywindow.document.close(); // necessary for IE >= 10
			mywindow.focus(); // necessary for IE >= 10*/
			setTimeout(() => {
				mywindow.print();
			}, 500);

			return true;
		}
	};
	console.log("bookingPassengerData", bookingPassengerData);
	return (
		<div>
			<h3 className="txt-blue font-weight-bold col">
				Booking: {BookingInfo?.hotel_name}
			</h3>
			<div className="booking-view-header all-tabs mt-3 d-flex  justify-content-between  ">
				<div className="d-flex ">
					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                         ${activeTab === 1 ? "is-active" : ""}`}
						onClick={() => {
							setActiveTab(1);
						}}
					>
						{booking.booking}
					</button>

					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                         ${activeTab === 2 ? "is-active" : ""}`}
						onClick={() => {
							setActiveTab(2);
						}}
					>
						{booking.Passengers}
					</button>

					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                         ${activeTab === 3 ? "is-active" : ""}`}
						onClick={() => {
							setActiveTab(3);
						}}
					>
						{booking.Financial}
					</button>

					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                         ${activeTab === 4 ? "is-active" : ""}`}
						onClick={() => {
							setActiveTab(4);
						}}
					>
						{booking.Payments}
					</button>
					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                         ${activeTab === 5 ? "is-active" : ""}`}
						onClick={() => {
							setActiveTab(5);
						}}
					>
						{"History"}
					</button>
				</div>

				{activeTab === 1 ? (
					<div>
						<button
							type="button"
							class="btn btn-success font-weight-bold"
							onClick={() => daweloadInvoice(bookingPassengerData?.brn)}
						>
							<img src={invoice} alt="" className="" />
							{booking.downloadInvoice}
						</button>
					</div>
				) : null}
			</div>

			{activeTab === 1 ? (
				<Booking bookingInfo={bookingPassengerData} />
			) : activeTab === 2 ? (
				<Passangers
					passangerInfo={bookingPassengerData}
					setReload={setReload}
				/>
			) : activeTab === 3 ? (
				<Financial finacial={bookingPassengerData} />
			) : activeTab === 4 ? (
				<Payments payment={bookingPassengerData} />
			) : activeTab === 5 ? (
				<History />
			) : null}
		</div>
	);
}
