import TextField from "components/Form/TextField/TextField";
import Model from "components/model";
import { formatFetchReservationView } from "data/sbsREservaiton";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { cancelGroup } from "services/sbsReservations";
import { roomsTotals } from "./helper";

//////////////////////////////// CancelModal ///////////////////////////////

export const CancelModal = (props) => {
	const { modalState, toggleModel, details, setReservationDetails } = props;
	const { totalSellingPrice } = roomsTotals(details);

	const initState = { option_date: new Date(), amount: 0 };

	const [selected, setSelected] = useState("Free");

	const [tentativeState, setTentativeState] = useState({ amount: 0 });

	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const [errors, setErrors] = useState({});

	const groupStatusId = details?.status?.id;
	const newRequestStatus = groupStatusId === 1 || groupStatusId === 2;

	useEffect(() => {
		if (modalState) {
			setTentativeState([initState]);
		}

		return () => {};
	}, [modalState]);

	const { id } = useParams();

	const checkFormErrors = () => {
		const errorCheker = {
			...(selected === "Amount" &&
				validate(
					{ name: "amount", value: tentativeState.amount },
					{
						required: true,
						minNumber: 1,
						maxNumber: totalSellingPrice,
					}
				)),
		};
		setErrors(errorCheker);
	};

	const onComplete = () => {
		if (selected != "Amount") {
			let data;
			if (selected == "Free" && !newRequestStatus) {
				data = { type: "free" };
				cancelGroupHandler(data);
			} else {
				data = { type: "non-refundable" };
				cancelGroupHandler(data);
			}
		} else {
			checkFormErrors();
			if (!isErrorLoaded) {
				setIsErrorLoaded(true);
			} else {
				setIsErrorLoaded(false);
			}
		}
	};

	const cancelGroupHandler = async (data) => {
		const response = await cancelGroup(id, data);
		if (response.status == 200) {
			toggleModel();
			const data = formatFetchReservationView(response.data.data);
			setReservationDetails(data);
		}
	};

	useEffect(() => {
		if (isFormValid(errors)) {
			if (selected == "Amount") {
				let data = { type: "amount", amount: +tentativeState.amount };
				cancelGroupHandler(data);
			}
		}

		return () => {};
	}, [isErrorLoaded]);

	return (
		<Model
			isOpen={modalState}
			// type={tentativeModal.type}
			title={"Cancel Reservations"}
			toggle={() => {
				toggleModel();
				setSelected("full");
				setTentativeState([initState]);
			}}
			size="md"
			hasFooter={false}
		>
			<div className="w-100 px-5 reserv_modal row">
				{/*///////////// edit with add Inputs ///////////////*/}

				{newRequestStatus ? (
					<div className="d-flex flex-wrap w-100 justify-content-center">
						<i class="far fa-question-circle cancel_color display-3"></i>
						<h5>Are You Sure To Cancel This Request?</h5>
					</div>
				) : (
					<fieldset className="name-check d-flex justify-content-between form-group my-4 w-100">
						<div
							className="reserv_modal_radio form-check"
							onClick={() => {
								setSelected("Free");
							}}
						>
							<label
								htmlFor={"Free"}
								className={`pointer p-0  form-check-label`}
							>
								<input
									className="form-check-input"
									type="radio"
									disabled={false}
									checked={selected === "Free"}
									name={"Free"}
									id={"Free"}
								/>
								Free
							</label>
						</div>

						<div
							className="reserv_modal_radio form-check"
							onClick={() => {
								setSelected("Non-Refundable");
							}}
						>
							<label
								htmlFor={"Non-Refundable"}
								className={`pointer p-0  form-check-label`}
							>
								<input
									className="form-check-input"
									type="radio"
									disabled={false}
									checked={selected == "Non-Refundable"}
									name={"Non-Refundable"}
									id={"Non-Refundable"}
								/>
								Non-Refundable
							</label>
						</div>

						<div
							className="reserv_modal_radio form-check"
							onClick={() => {
								setSelected("Amount");
							}}
						>
							<label
								htmlFor={"Amount"}
								className={`pointer p-0  form-check-label`}
							>
								<input
									className="form-check-input"
									type="radio"
									disabled={false}
									checked={selected == "Amount"}
									name={"Amount"}
									id={"Amount"}
								/>
								Amount
							</label>
						</div>
					</fieldset>
				)}

				{selected == "Amount" ? (
					<div className="row m-0 modal-body px-0 reserv_modal_payment_fields">
						<div className="p-0 col-12 FIX_modal_append">
							<TextField
								type="text"
								label={"amount"}
								placeholder={"amount"}
								id={"room-amount"}
								name="amount"
								value={tentativeState?.amount}
								extraTextPosition="append"
								extraText={details?.currency}
								onChange={(e) => {
									setTentativeState({
										amount: +e.target.value.replace(/[^0-9.]/, ""),
									});
								}}
								color={
									""
									// indexedError?.amount?.required ||
									// indexedError?.amount?.minNumber
									// 	? "danger"
									// 	: ""
								}
								min={1}
								max={totalSellingPrice}
								errors={errors?.amount}
							/>
						</div>
					</div>
				) : (
					""
				)}

				<button
					className={`btn btn-res-primary w-100 ${
						newRequestStatus ? "cancel_bg_color" : ""
					}  mt-2`}
					onClick={onComplete}
				>
					{newRequestStatus ? "Cancel" : "Complete"}
				</button>
			</div>
		</Model>
	);
};
