import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import { useEffect, useState } from "react";
import Locale from "translations";

export default function Filter({ filter, setFilter, exportToFile }) {
	const { backOffice } = Locale;
	const { locale, allCountries } = useGlobalState();
	const [countries, setCountries] = useState([]);
	const statusOptions = [
		{ value: 1, label: "Active" },
		{ value: 0, label: "Inactive" },
	];
	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);
	return (
		<>
			<div className="main-filter  our-border bg-white-blue py-2 mt-2 w-100">
				<div className="row align-items-center w-100 no-gutter m-0">
					<div className="col-2">
						<TextField
							type="text"
							placeholder={backOffice.search}
							label={backOffice.search}
							/* 							hasLabel={false}
							 */ value={filter.name}
							onChange={(e) => {
								setFilter({
									...filter,
									name: e.target.value,
								});
							}}
						/>
					</div>

					<div className="col-2">
						<DatePickerField
							type="text"
							placeholder={"DD/MM/YYYY"}
							label={backOffice.date}
							/* 							hasLabel={false}
							 */ date={filter.date}
							onDateChange={(e) => {
								setFilter({
									...filter,
									date: e,
								});
							}}
						/>
					</div>

					<div className="col-2">
						<SelectField
							label={backOffice.country}
							/* 							hasLabel={false}
							 */ placeholder={backOffice.country}
							name="status"
							options={countries}
							value={filter.country_id}
							onChange={(e) => {
								setFilter({
									...filter,
									country_id: e,
								});
							}}
						/>
					</div>

					<div className="col-2">
						<SelectField
							label={backOffice.status}
							/* 							hasLabel={false}
							 */ placeholder={backOffice.status}
							name="status"
							options={statusOptions}
							value={filter.status}
							onChange={(e) => {
								setFilter({
									...filter,
									status: e,
								});
							}}
						/>
					</div>

					<div
						className="col-2"
						onClick={() =>
							setFilter({
								name: "",
								date: null,
								status: null,
								country_id: null,
							})
						}
					>
						<div className="d-flex align-items-center text-secondary pointer mt-4 reset-btn">
							<img src={resetIcon} alt="" srcset="" />
							{/* 							<i className="fas fa-retweet position-static px-1 text-secondary text-caption"></i>
							 */}{" "}
							<p className="text-caption">{backOffice.resetFilter}</p>
						</div>
					</div>
					<button className="btn btn-primary" onClick={() => exportToFile()}>
						Export To Excel
					</button>
				</div>
			</div>
		</>
	);
}
