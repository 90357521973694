import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";

import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useEffect, useRef, useState } from "react";
import { listSeasons } from "services/seasons";
import Locale from "translations";

import moment from "moment";
import { cleanEmpty } from "services/general";

export default function SeasonsFilter({ setSeasonList, setMeta, pages }) {
	const { backOffice } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";
	const firstRender = useRef(false);

	const [filterState, setFilterState] = useState({
		seasonName: null,
		date: null,
		status: "",
	});

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setFilterState({ ...filterState, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			setFilterState({ ...filterState, [name]: value });
		}
	};

	useEffect(() => {
		if (firstRender.current) {
			async function fetchData() {
				const data = {
					name: filterState.seasonName,
					start_date: filterState.date
						? moment(filterState.date).format("YYYY-MM-DD")
						: null,
					is_active: filterState.status?.value,
				};
				const res = await listSeasons(cleanEmpty(data), pages, pages);
				setSeasonList(res?.data.seasons);
				setMeta(res?.data.meta);
			}
			fetchData();
		}

		firstRender.current = true;
	}, [filterState.seasonName, filterState.date, filterState.status]);

	const Status = [
		{
			value: 0,
			label: "Inactive",
		},
		{
			value: 1,
			label: "Active",
		},
	];

	const resetFilters = () => {
		setFilterState({
			seasonName: null,
			date: null,
			status: "",
		});
	};

	return (
		<>
			<>
				<div className="main-filter bg-white-blue py-2 my-2">
					<div className="row align-items-center w-100 no-gutter m-0">
						<div className="col-md-2">
							<div className="main-label">
								<TextField
									type="text"
									placeholder={backOffice.seasonName}
									label={backOffice.seasonName}
									/* 									hasLabel={false}
									 */ value={filterState.seasonName}
									onChange={(e) => {
										setFilterState({
											...filterState,
											seasonName: e.target.value,
										});
									}}
								/>
							</div>
						</div>

						<div className="col-md-2">
							<DatePickerField
								/* 								hasLabel={false}
								 */ date={filterState.date}
								placeholder={backOffice.seasonStartDate}
								label={backOffice.seasonStartDate}
								onChangeDate={(filterDate) => {
									setFilterState({
										...filterState,
										date: filterDate,
									});
								}}
								isOutsideRange={(day) => {
									return false;
								}}
							/>
						</div>

						<div className="col-md-2">
							<SelectField
								/* 								hasLabel={false}
								 */ value={filterState.status}
								placeholder={backOffice.status}
								label={backOffice.status}
								name="status"
								options={Status}
								onChange={(e) => {
									setFilterState({
										...filterState,
										status: e,
									});
								}}
							/>
						</div>

						<div className="col-md">
							<div
								onClick={resetFilters}
								className="d-flex align-items-center text-secondary pointer mt-4 reset-btn"
							>
								<img src={resetIcon} alt="" srcset="" />
								{/* 								<i className="fas fa-retweet position-static px-1 text-secondary text-caption"></i>
								 */}{" "}
								<p className="text-caption">{backOffice.resetFilter}</p>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	);
}
