import Locale from "translations";

export default function InvitationTableContent({
	productItem,
	index,
	removeItem,
}) {
	const { backOffice } = Locale;
	return (
		<>
			<tr key={productItem.id}>
				<td scope="row">{productItem.companyName}</td>
				<td>{productItem.CommissionerName} </td>
				<td>{productItem.country} </td>
				<td>{productItem.mobileNo} </td>
				<td>{productItem.email} </td>
				<td>
					{productItem.status === true ? (
						<span className="text-success font-weight-bold">
							{backOffice.done}
						</span>
					) : (
						<span className="text-warning font-weight-bold">
							{backOffice.underway}
						</span>
					)}{" "}
				</td>
				<td>
					<span className="bg-blue-black text-white py-1 px-2 rounded pointer d-inline-block">
						{backOffice.copy}
					</span>
				</td>
				<td className="text-right">
					<i className="fas fa-file-download text-success fa-fw"></i>
					<i
						className="fas fa-trash text-danger fa-fw"
						onClick={() => removeItem(index)}
					></i>
				</td>
			</tr>
		</>
	);
}
