import moment from "moment";
import { Link } from "react-router-dom";

export default function Reservation({ reservation }) {
	return (
		<tr>
			<td>{reservation.reference_id}</td>
			<td>{reservation.trip_type}</td>
			<td>{reservation.trip_path}</td>
			<td>{reservation.pax}</td>
			<td>{reservation.paid_amount}</td>
			<td>{reservation.departure_date}</td>
			<td> {moment(reservation?.booking_date).format("YYYY-MM-DD")}</td>
			<td
				className={`${
					reservation?.visa_status === "Pending"
						? "tentative"
						: reservation?.visa_status === "Rejected"
						? "text-danger"
						: "text-definite"
				}`}
			>
				{reservation.visa_status}
			</td>
			<td>
				<Link
					to={{
						pathname: `/b2c-user-booking-details/${reservation.id}`,
						state: {
							traveller_name: reservation.traveller_name,
						},
					}}
				>
					<i class="fas fa-chevron-right" />
				</Link>
			</td>
		</tr>
	);
}
