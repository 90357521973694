import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { b2cUserView } from "services/b2c";
import Locale from "translations";
import ChangePassword from "./Profile/ChangePassword";
import EditUser from "./Profile/Edit";
import Profile from "./Profile/Profile";
import UserReservations from "./UserReservations";
import UserTransactions from "./UserTransactions";
// import UserTransactions from "./TransactionsList";

function ViewUser() {
	const { Companies, backOffice, wallet } = Locale;

	const [activeTab, setActiveTab] = useState(1);
	const [edit, setEdit] = useState(false);
	const [profileDetails, setProfileDetails] = useState(null);

	const { id } = useParams();
	useEffect(() => {
		const fetchList = async () => {
			const res = await b2cUserView(id);
			if (res?.status === 200) {
				setProfileDetails(res?.data?.data);
			}
		};
		fetchList();
	}, []);

	const [changePasswordModal, setChangePasswordModal] = useState(false);
	const toggleChangePassword = () => {
		setChangePasswordModal((prev) => !prev);
	};
	return (
		<div className="b2c-users">
			<h4>{profileDetails?.name}</h4>
			<div className="booking-view-header all-tabs mt-3 d-flex  justify-content-between">
				<div className="d-flex ">
					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                     ${activeTab === 1 ? "is-active" : ""}`}
						onClick={() => {
							setActiveTab(1);
						}}
					>
						{Companies.profile}
					</button>

					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                     ${activeTab === 2 ? "is-active" : ""}`}
						onClick={() => {
							setActiveTab(2);
						}}
					>
						{backOffice.reservations}
					</button>

					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                     ${activeTab === 3 ? "is-active" : ""}`}
						onClick={() => {
							setActiveTab(3);
						}}
					>
						{wallet.Transactions}
					</button>
				</div>

				{activeTab === 1 && !edit ? (
					<div className="mb-1">
						<button
							type="button"
							class="btn btn-success font-weight-bold me-2"
							onClick={() => toggleChangePassword()}
						>
							{backOffice.changePassword}
						</button>

						<button
							type="button"
							class="btn btn-success font-weight-bold"
							onClick={() => setEdit(true)}
						>
							{backOffice.edit}
						</button>
					</div>
				) : null}
			</div>

			{activeTab === 1 ? (
				edit ? (
					<EditUser setEdit={setEdit} details={profileDetails} />
				) : (
					<Profile profileDetails={profileDetails} />
				)
			) : activeTab === 2 ? (
				<div>
					<UserReservations />
				</div>
			) : activeTab === 3 ? (
				<UserTransactions />
			) : null}

			<Modal isOpen={changePasswordModal} toggle={toggleChangePassword}>
				<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0">
					<ChangePassword closeModal={toggleChangePassword} />
				</ModalBody>
			</Modal>
		</div>
	);
}

export default ViewUser;
