import resetIcon from "assets/images/wallet/resetIcon.png";
import TextField from "components/shared/TextField";
import Locale from "translations";

export default function AdsFilter({ filter, setFilter }) {
	const { backOffice } = Locale;

	function resetFilters() {
		setFilter({
			search: "",
		});
	}

	return (
		<div className="main-filter bg-white-blue py-2 mt-2">
			<div className="row align-items-center  w-100 no-gutter m-0">
				<div className="col-md-3">
					<div className="main-label">
						<TextField
							type="text"
							placeholder={"search"}
							label={"search"}
							value={filter.search}
							onChange={(e) => {
								setFilter({
									...filter,
									search: e.target.value,
								});
							}}
						/>
						<i className="fas fa-search fa-fw"></i>
					</div>
				</div>

				<div className="col-md">
					<div
						onClick={resetFilters}
						className="d-flex align-items-center text-secondary pointer mt-4 reset-btn"
					>
						<img src={resetIcon} alt="" srcset="" />
						<p className="text-caption">{backOffice.resetFilter}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
