import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { listPlatforms } from "services/platform";
import PlatformCommissionsFilter from "../Components/PlatformCommessions/PlatformCommissionsList/PlatformCommissionsFilter";
import PlatformCommissionsTable from "../Components/PlatformCommessions/PlatformCommissionsList/PlatformCommissionsTable";

import Locale from "translations";

export default function PlatformCommissionList() {
	const history = useHistory();
	const { backOffice } = Locale;
	const { search } = useLocation();
	const [_, pages] = search.split("=");
	const [PlatformList, setPlatformList] = useState([]);
	const [meta, setMeta] = useState(null);

	const fetchData = async (filters = null, page = pages) => {
		const res = await listPlatforms(filters, page);
		if (res?.status === 200) {
			//console.log(res.data);
			setPlatformList(res.data.platforms);
			setMeta(res.data.meta);
		}
	};

	useEffect(() => {
		fetchData();
	}, [pages]);

	const goTo = (page) => {
		fetchData(null, page);
		const params = new URLSearchParams();
		if (page) {
			params.append("page", page);
		} else {
			params.delete("page", page);
		}
		history.push({ search: params.toString() });
	};

	return (
		<>
			<div className="d-flex justify-content-between align-items-center mb-2">
				<h6 className="main-title ">{backOffice.platformCommissions}</h6>
				<Link className="btn btn-success" to={`/add-platform`}>
					<i class="fas fa-plus-circle"></i> {backOffice.addNewPlatform}
				</Link>
			</div>
			<PlatformCommissionsFilter
				PlatformList={PlatformList}
				setPlatformList={setPlatformList}
				pages={pages}
			/>
			<PlatformCommissionsTable
				PlatformList={PlatformList}
				fetchData={fetchData}
				goTo={goTo}
				meta={meta}
			/>
		</>
	);
}
