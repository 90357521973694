import React, { createContext, useContext, useReducer } from "react";
const HotelContext = createContext();
const HotelDispatchContext = createContext();
const initialState = {};

function HotelReducer(state, action) {
  switch (action.type) {
    case "HotelDetails": {
      return { ...state, HotelDetails: action.payload };
    }

    case "progress": {
      return {
        ...state,
        progress: action.progress,
        step: action.step,
        current: action.current,
      };
    }

    case "HotelDetailsList": {
      return { ...state, HotelDetailsList: action.payload };
    }

    case "progressList": {
      return {
        ...state,
        progress: action.progress,
        step: action.step,
        current: action.current,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function useHotelState() {
  const context = useContext(HotelContext);
  if (context === undefined) {
    throw new Error("useHotelState must be used within a HotelProvider");
  }
  return context;
}

function useHotelDispatch() {
  const context = useContext(HotelDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useHotelDispatch must be used within a HotelProvider"
    );
  }
  return context;
}

function HotelProvider({ children }) {
  const [state, dispatch] = useReducer(HotelReducer, initialState);
  return (
    <HotelContext.Provider value={state}>
      <HotelDispatchContext.Provider value={dispatch}>
        {children}
      </HotelDispatchContext.Provider>
    </HotelContext.Provider>
  );
}

export { HotelProvider, useHotelState, useHotelDispatch };
