const PaymentCard = (props) => {
	const { className = "", title, value, currency } = props;

	return (
		<div className={`card mb-3 shadow-sm border  ${className}`}>
			<div className="card-body text-center p-0">
				<p className="card-text m-0">{title}</p>
				<h5 className="card-title m-0">
					{value} {currency}{" "}
				</h5>
			</div>
		</div>
	);
};

export default PaymentCard;
