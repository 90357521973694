import { useGlobalState } from "context/global";
import BackOfficeLayout from "modules/backOffice/Components/backOfficeLayout";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { BackOfficeProvider } from "../context/backOffice";
import { ContractProvider } from "../context/contractRequest";
import { PlatformProvider } from "../context/platformRequest";
import { PropertyProvider } from "context/property";
import { HotelProvider } from "context/hotelRequest";
// React Component
export default function PlatformRoute({ component: Component, ...props }) {
  const { isAuth } = useGlobalState();

  if (isAuth) {
    return (
      <Route
        {...props}
        render={(matchProps) => (
          <BackOfficeProvider>
         
               <PlatformProvider>
                
                  <BackOfficeLayout>
                    <Component {...matchProps} />
                  </BackOfficeLayout>
            
               </PlatformProvider>
            
          </BackOfficeProvider>
        )}
      />
    );
  } else {
    return <Redirect to="/auth/login" />;
  }
}
