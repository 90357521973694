import { useGlobalDispatch } from "context/global";
import { usePropertyDispatch, usePropertyState } from "context/property";
import { formatGetAmenities, formatPostAmenities } from "data/property";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useParams } from "react-router-dom";
import { fetchAmenities } from "services/lookups";
import { addAmenities, EditAmenities } from "services/property";
import Locale from "translations";
import Amenity from "./Amenity";
import CancelModal from "./CancelModal";
import OtherAmenity from "./OtherAmenity";

const locale = localStorage.getItem("currentLocale") || "en";

function Amenities(statusNum) {
	const { id } = useParams();
	const history = useHistory();

	const { amenities } = Locale;
	const [lookups, setLookups] = useState({});
	const [allAmenities, setAllAmenities] = useState([]);
	const [roomTypes, setRoomTypes] = useState([]);
	const dispatch = useGlobalDispatch();
	const { propertyDetails } = usePropertyState();
	const propertyDispatch = usePropertyDispatch();
	const [canceled, setCanceled] = useState(false);

	const fetchLookups = async () => {
		const [amenities] = await Promise.all([fetchAmenities(locale)]);
		console.log(amenities);
		const { most_requested, ...rest } = amenities.data.data;
		setLookups({
			mostRequested: most_requested,
			rest,
		});
	};

	useEffect(() => {
		fetchLookups();
		if (propertyDetails?.property?.rooms) {
			setRoomTypes(
				propertyDetails?.property?.rooms.map(
					(room) =>
						(room = {
							label: room.custom_name || room.name.name,
							value: room.id,
						})
				)
			);
		}
	}, [propertyDetails]);

	const isAmenitiesValid = () => {
		let isValid = true;
		allAmenities.forEach((item) => {
			const { is_has_all_rooms } = item;
			if (!is_has_all_rooms && !item.rooms) {
				isValid = false;
			}
		});
		return isValid;
	};

	useEffect(() => {
		if (id && propertyDetails?.amenities) {
			const formatted = formatGetAmenities(propertyDetails.amenities);
			setAllAmenities(formatted);
		}
	}, [propertyDetails]);

	const submit = async () => {
		if (isAmenitiesValid()) {
			if (id && propertyDetails?.amenities?.length > 0) {
				const data = formatPostAmenities(allAmenities);
				const res = await EditAmenities(id, { amenities: data });
				if (res.status === 200) {
					dispatch({ type: "progress", step: "5", current: "5" });
					propertyDispatch({ type: "propertyDetails", payload: res.data });
					store.addNotification({
						title: "Amenities",
						message: "Edit Amenities Succussfully",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			} else {
				const data = formatPostAmenities(allAmenities);
				const res = await addAmenities(id, { amenities: data });
				if (res.status === 201) {
					dispatch({ type: "progress", step: "5", current: "5" });
					propertyDispatch({ type: "propertyDetails", payload: res.data });
				}
			}
		} else {
			store.addNotification({
				title: "warning!",
				message: "Please fill all fields",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	const reset = () => {
		setCanceled(true);
	};

	//
	console.log("allAmenities", allAmenities);
	return (
		<div>
			<CancelModal isOpen={canceled} toggle={setCanceled} />

			<div className="header-box my-3">{amenities.mostRequested}</div>
			<div className="most">
				{lookups.mostRequested &&
					lookups.mostRequested.map((amenity, i) => {
						return (
							<Amenity
								allAmenities={allAmenities}
								setAllAmenities={setAllAmenities}
								amenity={amenity}
								key={i}
								roomTypes={roomTypes}
								index={i}
							/>
						);
					})}
			</div>
			<div className="shadow-sm product-build__product-collpase branch-collapse mt-3">
				{lookups.rest &&
					Object.entries(lookups.rest).length > 0 &&
					Object.entries(lookups.rest).map(([key, amenity], i) => {
						return (
							<OtherAmenity
								allAmenities={allAmenities}
								setAllAmenities={setAllAmenities}
								name={key}
								category={amenity}
								key={i}
								roomTypes={roomTypes}
								index={i}
							/>
						);
					})}
			</div>
			{/* <ActionButton ID={id} StatusNum={statusNum} /> */}

			<div className="row my-5 mx-0 justify-content-between">
				<button
					onClick={() => history.push("/property-management")}
					className="btn cancel mx-2 "
					id="basic-cancel"
				>
					Back
				</button>

				<button
					className="btn btn-primary px-5"
					id="basic-submit"
					onClick={submit}
				>
					continue
				</button>
			</div>
		</div>
	);
}

export default Amenities;
