import axios from "axios";
import { useEffect, useState } from "react";
import Locale from "translations";
import PropertyTableContent from "./TravelTableContent";

export default function TravelTable(props) {
	const { backOffice } = Locale;
	const [ProductList, setProductList] = useState([]);

	useEffect(() => {
		axios.get("../invitationBackOffice.json").then((res) => {
			setProductList(res.data.productList);
		});
	}, []);

	//Remove Item
	const removeItem = (index) => {
		setProductList(ProductList.filter((k, i) => i !== index));
	};

	const AllProducts = ProductList.map((productItem, index) => (
		<PropertyTableContent
			productItem={productItem}
			index={index}
			key={index}
			removeItem={removeItem}
		/>
	));

	return (
		<>
			<table className="custom-table table-striped back-office-table table">
				<thead>
					<tr>
						<th>{backOffice.supplierName}</th>
						<th>{backOffice.contractType}</th>
						<th>{backOffice.country}</th>
						<th>{backOffice.supplierEmail}</th>
						<th>{backOffice.supplierPhone}</th>
						<th>{backOffice.registrationDate}</th>
						<th>{backOffice.validation}</th>
						<th>{backOffice.status}</th>
						{/* Icons */}
						<th>{backOffice.tools}</th>
					</tr>
				</thead>
				<tbody>
					{ProductList.length > 0 ? (
						AllProducts
					) : (
						<tr>
							<td colSpan="11">
								<div className="product-build__product-no-data">
									<i className="fas fa-info-circle fa-lg"></i>{" "}
									<h4>{backOffice.noResult}</h4>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{/* {ProductList.length > 0 && meta !== null ? (
				<CustomPagination
					from={meta.from}
					to={meta.to}
					total={meta.total}
					perPage={meta.per_page}
				/>
			) : null} */}
		</>
	);
}
