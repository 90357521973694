import React, { createContext, useContext, useReducer } from "react";
const ContractContext = createContext();
const ContractDispatchContext = createContext();
const initialState = {};

function ContractReducer(state, action) {
  switch (action.type) {
    case "ContractDetails": {
      return { ...state, ContractDetails: action.payload };
    }

    case "progress": {
      return {
        ...state,
        progress: action.progress,
        step: action.step,
        current: action.current,
      };
    }

    case "ContractDetailsList": {
      return { ...state, ContractDetailsList: action.payload };
    }

    case "progressList": {
      return {
        ...state,
        progress: action.progress,
        step: action.step,
        current: action.current,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function useContractState() {
  const context = useContext(ContractContext);
  if (context === undefined) {
    throw new Error("useContractState must be used within a ContractProvider");
  }
  return context;
}

function useContractDispatch() {
  const context = useContext(ContractDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useContractDispatch must be used within a ContractProvider"
    );
  }
  return context;
}

function ContractProvider({ children }) {
  const [state, dispatch] = useReducer(ContractReducer, initialState);
  return (
    <ContractContext.Provider value={state}>
      <ContractDispatchContext.Provider value={dispatch}>
        {children}
      </ContractDispatchContext.Provider>
    </ContractContext.Provider>
  );
}

export { ContractProvider, useContractState, useContractDispatch };
