import BackOfficeLayout from "modules/backOffice/Components/backOfficeLayout";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import CompaniesSubNav from "./layout/CompaniesSubNav";

// React Component
export function CompaniesLayoutRoute({ component: Component, ...props }) {
  const isAuth = localStorage.getItem("isAuth");
  if (isAuth) {
    return (
      <Route
        {...props}
        render={(matchProps) => (
          <>
            <BackOfficeLayout>
              <CompaniesSubNav />
              <Component {...matchProps} />
            </BackOfficeLayout>
          </>
        )}
      />
    );
  } else {
    return <Redirect to="/login" />;
  }
}
